<template>
  <div
    class="control-traffic-panel"
    :class="{
      'hide': isHidingControlTrafficPanel || $route.hash === '#cam' || $route.hash === '#measurement' || $route.hash === '#routing' || $route.hash === '#events' ||
        (!isHidingExplorePanel && isExpandingExplorePanel) ||
        (!isHidingSearchResultPanel && isExpandingSearchResultPanel) ||
        (!isHidingMyPlacePanel && isExpandingMyPlacePanel) ||
        (!isHidingExploreNearbyPanel && isExpandingExploreNearbyPanel) ||
        (!isHidingEventPanel && isExpandingEventPanel),
      'map3': isLongdoMapV3,
      'showing-popup': isShowingPopup,
      'place-card-step-full': placeCardStep === 'FULL',
      'place-card-step-mid': placeCardStep === 'MID',
      'place-card-step-mini': placeCardStep === 'MINI',
      'showing-explore-panel': !isHidingExplorePanel && isExpandingExplorePanel,
      'collapsing-explore-panel': !isHidingExplorePanel && !isExpandingExplorePanel,
      'showing-search-result-panel': !isHidingSearchResultPanel && isExpandingSearchResultPanel,
      'collapsing-search-result-panel': !isHidingSearchResultPanel && !isExpandingSearchResultPanel,
      'showing-my-place-panel': !isHidingMyPlacePanel && isExpandingMyPlacePanel,
      'collapsing-my-place-panel': !isHidingMyPlacePanel && !isExpandingMyPlacePanel,
      'showing-explore-nearby-panel': !isHidingExploreNearbyPanel && isExpandingExploreNearbyPanel,
      'collapsing-explore-nearby-panel': !isHidingExploreNearbyPanel && !isExpandingExploreNearbyPanel,
      'showing-event-panel': !isHidingEventPanel && isExpandingEventPanel,
      'collapsing-event-panel': !isHidingEventPanel && !isExpandingEventPanel
    }"
  >
    <button
      class="ripple expand"
      :class="{active: isExpandTrafficProductShortcut}"
      @click="isExpandTrafficProductShortcut = !isExpandTrafficProductShortcut"
      v-if="isTrafficProduct"
    >
      <i
        class="material-icons"
      >
        expand_less
      </i>
    </button>
    <div class="traffic-product-shortcut"
      :class="{less: !isExpandTrafficProductShortcut}"
      v-if="isTrafficProduct"
    >
      <select v-if="isActiveDropDownArea" @change="jumpToPlace" >
          <option value="" selected disabled hidden>{{ $t('searchPanel.flashPlaceList.selectArea') }}</option>
          <option v-for="place in flashPlaceList" :key="place" :value="place">{{ $t(`searchPanel.flashPlaceList.${place}`) }}</option>
      </select>
      <button :class="{active: isActiveDropDownArea}" @click="onClickSelectArea">
        <i
          class="material-icons-round"
        >
          zoom_in_map
        </i>
      </button>
      <button :class="{active: isActiveTrafficLayer}" @click="onClickTrafficLayer" v-if="false">
        <i
          class="material-icons-round"
        >
          traffic
        </i>
      </button>
      <button :class="{active: isActiveGasStation}" @click="onClickGasStation">
        <i
          class="material-icons-round"
        >
          local_gas_station
        </i>
      </button>
      <button :class="{active: isActiveCamera}" @click="onClickCamera">
        <i
          class="material-icons-round"
        >
          videocam
        </i>
      </button>
      <button :class="{active: isActiveEvent}" @click="onClickEvent">
        <img :src="`${baseUrl}img/traffic_shortcut/event.svg`">
      </button>
      <button :class="{active: isActiveAqi}" @click="onClickAqi">
        <img :src="`${baseUrl}img/traffic_shortcut/aqi.svg`">
      </button>
      <button :class="{active: isActiveRainRadarLayer}" @click="onClickRainRadarLayer">
        <img :src="`${baseUrl}img/traffic_shortcut/rain.svg`">
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ControlTrafficPanel',
  props: {
    isShowingPopup: {
      type: Boolean,
      default: false
    },
    placeCardStep: {
      type: String,
      default: 'MID'
    },
    map: {
      type: Object
    }
  },
  data () {
    return {
      isHidingControlTrafficPanel: false,
      isExpandTrafficProductShortcut: process.env.VUE_APP_PRODUCT === 'traffic',
      isActiveTrafficLayer: true,
      isActiveGasStation: JSON.parse(window.localStorage.isActiveGasStation || false),
      isActiveCamera: JSON.parse(window.localStorage.isActiveCamera || true),
      isActiveEvent: JSON.parse(window.localStorage.isActiveEvent || true),
      isActiveAqi: JSON.parse(window.localStorage.isActiveAqi || false),
      isActiveRainRadarLayer: JSON.parse(window.localStorage.isActiveRainRadarLayer || true),
      isHidingExplorePanel: true,
      isExpandingExplorePanel: true,
      isHidingSearchResultPanel: true,
      isExpandingSearchResultPanel: true,
      isHidingMyPlacePanel: true,
      isExpandingMyPlacePanel: true,
      isHidingExploreNearbyPanel: true,
      isExpandingExploreNearbyPanel: true,
      isHidingEventPanel: true,
      isExpandingEventPanel: true,
      flashPlaceList: ['bangkok', 'innerBangkok', 'outerBangkok', 'silom', 'victoryMonument',
        'ladprao', 'ramkhamhaeng', 'rangsit', 'ayutthaya', 'chainat',
        'saraburi', 'nakhonratchasima', 'chiangmai', 'chiangrai', 'phuket'],
      isActiveDropDownArea: false
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.toggleDisplayTrafficContorlPanel)
    self.$root.$on('afterOpenLayer', self.onAfterOpenLayer)
    self.$root.$on('afterHideLayer', self.onAfterHideLayer)
    self.$root.$on('changeIsShowingCamera', (isShowingCamera) => {
      self.isActiveCamera = isShowingCamera
      window.localStorage.isActiveCamera = self.isActiveCamera
    })
    self.$root.$on('changeIsShowingEvent', (isShowingEvent) => {
      self.isActiveEvent = isShowingEvent
      window.localStorage.isActiveEvent = self.isActiveEvent
    })

    self.$root.$on('changeHidingExplorePanel', value => {
      self.isHidingExplorePanel = value
    })
    self.$root.$on('changeExpandExplorePanel', value => {
      self.isExpandingExplorePanel = value
    })
    self.$root.$on('changeHidingSearchResultPanel', value => {
      self.isHidingSearchResultPanel = value
    })
    self.$root.$on('changeExpandSearchResultPanel', value => {
      self.isExpandingSearchResultPanel = value
    })
    self.$root.$on('changeHidingMyPlacePanel', value => {
      self.isHidingMyPlacePanel = value
    })
    self.$root.$on('changeExpandMyPlacePanel', value => {
      self.isExpandingMyPlacePanel = value
    })
    self.$root.$on('changeHidingExploreNearbyPanel', value => {
      self.isHidingExploreNearbyPanel = value
    })
    self.$root.$on('changeExpandExploreNearbyPanel', value => {
      self.isExpandingExploreNearbyPanel = value
    })
    self.$root.$on('changeHidingEventPanel', value => {
      self.isHidingEventPanel = value
    })
    self.$root.$on('changeExpandEventPanel', value => {
      self.isExpandingEventPanel = value
    })
  },
  methods: {
    onClickSelectArea () {
      const self = this
      self.isActiveDropDownArea = !self.isActiveDropDownArea
    },
    jumpToPlace (event) {
      const self = this
      const place = event.target.value
      switch (place) {
        case 'bangkok' :
          self.map.location({ lat: 13.75377, lon: 100.53115 }, false)
          self.map.zoom(12)
          break
        case 'innerBangkok' :
          self.map.location({ lat: 13.75377, lon: 100.53115 }, false)
          self.map.zoom(14)
          break
        case 'outerBangkok' :
          self.map.location({ lat: 13.75377, lon: 100.53115 }, false)
          self.map.zoom(10)
          break
        case 'silom' :
          self.map.location({ lat: 13.72616, lon: 100.52806 }, false)
          self.map.zoom(15, false)
          break
        case 'victoryMonument' :
          self.map.location({ lat: 13.76503, lon: 100.53831 }, false)
          self.map.zoom(15, false)
          break
        case 'ladprao' :
          self.map.location({ lat: 13.81397, lon: 100.55945 }, false)
          self.map.zoom(14, false)
          break
        case 'ramkhamhaeng' :
          self.map.location({ lat: 13.7482, lon: 100.60627 }, false)
          self.map.zoom(14, false)
          break
        case 'rangsit' :
          self.map.location({ lat: 14.06421, lon: 100.61725 }, false)
          self.map.zoom(12, false)
          break
        case 'ayutthaya' :
          self.map.location({ lat: 14.34788, lon: 100.58192 }, false)
          self.map.zoom(11, false)
          break
        case 'chainat' :
          self.map.location({ lat: 15.23561, lon: 100.20665 }, false)
          self.map.zoom(11, false)
          break
        case 'saraburi' :
          self.map.location({ lat: 14.52, lon: 100.91533 }, false)
          self.map.zoom(11, false)
          break
        case 'nakhonratchasima' :
          self.map.location({ lat: 14.967, lon: 102.0761 }, false)
          self.map.zoom(11, false)
          break
        case 'chiangmai' :
          self.map.location({ lat: 18.80, lon: 98.991 }, false)
          self.map.zoom(10, false)
          break
        case 'chiangrai' :
          self.map.location({ lat: 19.81, lon: 99.76 }, false)
          self.map.zoom(10, false)
          break
        case 'phuket' :
          self.map.location({ lat: 7.95541, lon: 98.991 }, false)
          self.map.zoom(10, false)
          break
      }
    },
    toggleDisplayTrafficContorlPanel (isShowingMapOnly) {
      const self = this
      self.isHidingControlTrafficPanel = isShowingMapOnly
    },
    onClickTrafficLayer () {
      const self = this
      self.isActiveTrafficLayer = !self.isActiveTrafficLayer
      if (self.isActiveTrafficLayer) {
        self.$root.$emit('openLayer', self.layerList[0].name)
      } else {
        self.$root.$emit('hideLayer', self.layerList[0].name, {
          isHideCamera: !self.isActiveCamera,
          isHideEvent: !self.isActiveEvent
        })
      }
    },
    onClickGasStation () {
      const self = this
      self.isActiveGasStation = !self.isActiveGasStation
      window.localStorage.isActiveGasStation = self.isActiveGasStation
      if (self.isActiveGasStation) {
        self.$emit('showTag', 'Gas_station')
      } else {
        self.$emit('hideTag', 'Gas_station')
      }
    },
    onClickCamera () {
      const self = this
      self.isActiveCamera = !self.isActiveCamera
      window.localStorage.isActiveCamera = self.isActiveCamera
      if (self.isActiveCamera) {
        self.$root.$emit('openCameraOnTrafficLayer')
      } else {
        self.$root.$emit('hideCameraOnTrafficLayer')
      }
    },
    onClickEvent () {
      const self = this
      self.isActiveEvent = !self.isActiveEvent
      window.localStorage.isActiveEvent = self.isActiveEvent
      if (self.isActiveEvent) {
        self.$root.$emit('openEventOnTrafficLayer')
      } else {
        self.$root.$emit('hideEventOnTrafficLayer')
      }
    },
    onClickAqi () {
      const self = this
      self.isActiveAqi = !self.isActiveAqi
      window.localStorage.isActiveAqi = self.isActiveAqi
      if (self.isActiveAqi) {
        self.$root.$emit('openLayer', self.layerList[3].name)
      } else {
        self.$root.$emit('hideLayer', self.layerList[3].name)
      }
    },
    onClickRainRadarLayer () {
      const self = this
      self.isActiveRainRadarLayer = !self.isActiveRainRadarLayer
      window.localStorage.isActiveRainRadarLayer = self.isActiveRainRadarLayer
      if (self.isActiveRainRadarLayer) {
        self.$root.$emit('openLayer', self.layerList[4].name)
      } else {
        self.$root.$emit('hideLayer', self.layerList[4].name)
      }
    },
    onAfterOpenLayer (layerName, options) {
      const self = this
      if (layerName === self.layerList[0].name) {
        self.isActiveTrafficLayer = true
        self.isActiveCamera = 'isHideCamera' in options ? !options.isHideCamera : self.isActiveCamera
        self.isActiveEvent = 'isHideEvent' in options ? !options.isHideEvent : self.isActiveEvent

      // window.localStorage.isActiveCamera = self.isActiveCamera
      // window.localStorage.isActiveEvent = self.isActiveEvent
      }
      if (layerName === self.layerList[3].name) {
        self.isActiveAqi = true
      // window.localStorage.isActiveAqi = self.isActiveAqi
      }
      if (layerName === self.layerList[4].name) {
        self.isActiveRainRadarLayer = true
      // window.localStorage.isActiveRainRadarLayer = self.isActiveRainRadarLayer
      }
    },
    onAfterHideLayer (layerName) {
      const self = this
      if (layerName === self.layerList[0].name) {
        self.isActiveTrafficLayer = false
        self.isActiveCamera = false
        self.isActiveEvent = false

      // window.localStorage.isActiveCamera = self.isActiveCamera
      // window.localStorage.isActiveEvent = self.isActiveEvent
      }
      if (layerName === self.layerList[3].name) {
        self.isActiveAqi = false
      // window.localStorage.isActiveAqi = self.isActiveAqi
      }
      if (layerName === self.layerList[4].name) {
        self.isActiveRainRadarLayer = false
      // window.localStorage.isActiveRainRadarLayer = self.isActiveRainRadarLayer
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (min-width: $mobileMaxSize) {
  .control-traffic-panel {
    display: none !important;
  }
}
.control-traffic-panel {
  width: max-content;
  height: max-content;
  background-color: transparent;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  z-index: 80;
  position: fixed;
  border-radius: 6px;
  bottom: calc(#{$toolBarHeight} + 0px);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
  right: 14px;
  transition: right 0.3s ease 0s,
    bottom 0.3s ease 0s;

  &.map3 {
    bottom: calc(#{$toolBarHeight} + 60px) !important
  }

  &.collapsing-explore-panel {
    bottom: calc(#{$toolBarHeight} + 32px);
  }

  &.collapsing-search-result-panel {
    bottom: calc(#{$toolBarHeight} + 32px);
  }

  &.collapsing-my-place-panel {
    bottom: calc(#{$toolBarHeight} + 32px);
  }

  &.collapsing-explore-nearby-panel {
    bottom: calc(#{$toolBarHeight} + 32px);
  }

  &.collapsing-event-panel {
    bottom: calc(#{$toolBarHeight} + 32px);
  }

  > button.expand {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    user-select: none;

    > i {
      color: $primary;
      font-size: 20px;
      user-select: none;
      pointer-events: none;
      transform: rotate(0deg);
      transition: transform 0.3s ease 0s;
    }

    &.active {
      border-radius: 0px 0px 6px 6px;
      i {
        transform: rotate(180deg);
      }
    }
  }

  .traffic-product-shortcut {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: height 0.3s ease 0s,
      opacity 0.2s linear 0s;
    height: fit-content;
    box-sizing: border-box;
    opacity: 1;
    padding: 0px 0px 0px 0px;

    &.less {
      height: 0px;
      opacity: 0;
    }
    > select {
      position: absolute;
      right: 50px;
      height: 36px;
      top: 0px;
      padding: 0px 10px 0px 10px;
      border-radius: 6px;
      border: 0.5px solid #BCBCBC;
    }

    button {
      &:first-child {
        border-radius: 6px 6px 0px 0px;
      }
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      padding: 0px;
      border: none;
      border-bottom: solid 1px $lightGrey;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(255, 255, 255);
      cursor: pointer;
      user-select: none;

      i {
        font-size: 20px;
        color: $lightGrey;
        user-select: none;
        pointer-events: none;
      }

      img {
        width: 20px;
        height: 20px;
        filter: grayscale(1) brightness(1.26);
      }

      &.active {
        &:nth-child(2) {
          border-radius: 6px 6px 0px 0px;
        }

        i {
          color: $primary;
        }

        img {
          filter: none;
        }
      }
    }
  }
}

.control-traffic-panel.hide {
  right: -250px;
}

.control-traffic-panel.showing-popup {
  bottom: calc(#{$toolBarHeight} + 30%);

  &.place-card-step-mini {
    bottom: calc(#{$toolBarHeight} + 72px);
  }
}

.control-traffic-panel.hide.showing-popup {
  bottom: calc(#{$toolBarHeight} + 30%);
}

.control-traffic-panel.traffic-product {
  padding-bottom: 40px;
}
</style>
