import UtmConverter from 'utm-converter'
import LongdoJsInterface from '@/longdo-js-interface/index.js'
import { Api } from './api.js'
import { longdoAccount } from '../../main.js'

export class Utility {
  transitIconList = ['', 'train', 'subway', 'tram', 'train', 'directions_bus', 'directions_bus', 'airport_shuttle', 'directions_boat', 'directions_walk', 'local_taxi']
  lji = new LongdoJsInterface()
  api = new Api()

  async getUserSession () {
    let userSession = null
    let isOnMobileApp = false
    if (this.lji) {
      isOnMobileApp = this.lji.Util.isIosNativeApp() || this.lji.Util.isAndroidNativeApp()
    }

    if (isOnMobileApp) {
      let username
      let longdousertoken
      try {
        username = await this.lji.getStorage({
          key: 'username'
        })
      } catch (error) {
      }

      try {
        longdousertoken = await this.lji.getStorage({
          key: 'ldtoken'
        })
      } catch (error) {
      }
      if (username && longdousertoken) {
        userSession = await this.api.getUserInfo({ username: username, ldtoken: longdousertoken })
        userSession = userSession?.data?.data
      }
    } else {
      const userInfo = longdoAccount.getUserSession()
      if (localStorage.username && localStorage.ldtoken) {
        if (userInfo.username === localStorage.username && userInfo.longdousertoken === localStorage.ldtoken) {
          return JSON.parse(localStorage.userdata)
        }
      }
      userSession = await this.api.getUserInfo({ username: userInfo.username, ldtoken: userInfo.longdousertoken })
      userSession = userSession?.data?.data
    }
    return userSession
  }

  getImgPath (fullname) {
    if (fullname) {
      try {
        return require(`@/assets/img/${fullname}`)
      } catch (ex) {
        return ''
      }
    }
    return ''
  }

  getImgPathSet (name) {
    const self = this
    if (name) {
      const x = `${name}@1x.png`
      const xx = `${name}@2x.png`
      const xxx = `${name}@3x.png`
      return `${self.getImgPath(x)} 1x, ${self.getImgPath(xx)} 2x, ${self.getImgPath(xxx)} 3x`
    }
    return ''
  }

  validateLatLonFromString (latLonString) {
    latLonString = latLonString.trim().replace(/ /g, '')
    const rex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
    const isLatLon = rex.test(latLonString)
    if (isLatLon) {
      const splitedLatLonString = latLonString.split(',')
      const lat = Number(splitedLatLonString[0])
      const lon = Number(splitedLatLonString[1])
      return {
        lat: lat,
        lon: lon,
        text: lat.toFixed(6) + ', ' + lon.toFixed(6)
      }
    } else {
      return false
    }
  }

  convertLatLonToUtm (latLon) {
    const converter = new UtmConverter()
    const wgsCoord = [parseFloat(latLon.lon), parseFloat(latLon.lat)]
    try {
      const utmResult = converter.toUtm({ coord: wgsCoord })
      const x = Number(utmResult.coord.x)
      const y = Number(utmResult.coord.y)
      const zone = Number(utmResult.zone)
      const hemi = utmResult.isSouthern ? 'S' : 'N'
      return {
        x: x,
        y: y,
        zone: zone,
        hemi: hemi,
        text: x.toFixed(1) + ', ' + y.toFixed(1) + ', ' + zone + hemi
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  convertLatLonToDms (latLon) {
    const dms = window.longdo.Util.locationToDMS(latLon)
    dms.text = `${dms.latDegree}°${dms.latMinute}′${dms.latSecond}″${dms.latHemisphere}, ${dms.lonDegree}°${dms.lonMinute}′${dms.lonSecond}″${dms.lonHemisphere}`
    return dms
  }

  validateUtmFromString (utmString) {
    utmString = utmString.trim().replace(/ /g, '')
    const rex = /^(-?\d*\.?\d+)\s*,\s*(-?\d*\.?\d+)\s*,\s*(\d*)(N|n|S|s)$/
    const isUtm = rex.test(utmString)
    if (isUtm) {
      const splitedUtmString = utmString.split(',')
      const x = Number(splitedUtmString[0])
      const y = Number(splitedUtmString[1])
      const zone = Number(splitedUtmString[2].substring(0, splitedUtmString[2].length - 1))
      const hemi = splitedUtmString[2][splitedUtmString.length - 1]
      return {
        x: x,
        y: y,
        zone: zone,
        hemi: hemi,
        text: x.toFixed(1) + ', ' + y.toFixed(1) + ', ' + zone + hemi
      }
    }
    return false
  }

  convertUtmToLatLon (utm) {
    const utmForConverting = {
      coord: {
        x: parseFloat(utm.x),
        y: parseFloat(utm.y)
      },
      zone: utm.zone,
      isSouthern: utm.hemi === 'S' || utm.hemi === 's'
    }
    const converter = new UtmConverter()
    try {
      const latLonResult = converter.toWgs(utmForConverting)
      const lat = Number(latLonResult.coord.latitude)
      const lon = Number(latLonResult.coord.longitude)
      return {
        lat: lat,
        lon: lon,
        text: lat.toFixed(6) + ', ' + lon.toFixed(6)
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  allTrim (x) {
    return x.trim().replace(/ +(?= )/g, '')
  }

  getContrastYIQ (hexColor) {
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
    return (yiq >= 128) ? 'black' : 'white'
  }

  hasClass (className, element) {
    if (element.classList) {
      return element.classList.contains(className)
    } else {
      new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className)
    }
  }

  getDateFromString (datetimeString) {
    const splited = datetimeString.replace('T', ' ').replace('Z', ' ').split(/[- :]/)
    const d = new Date(splited[0], splited[1] - 1, splited[2], splited[3], splited[4], splited[5])
    return d
  }

  getDateTimeStringFromUnixTimestamp (unixTimestamp, lang = 'th') {
    const month = {
      th: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
    const d = new Date(Number(unixTimestamp) * 1000)
    const m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
    const s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
    return `${d.getDate()} ${month[lang][d.getMonth()]} ${d.getFullYear() + (lang === 'th' ? 543 : 0)} ${d.getHours()}:${m}:${s}`
  }

  isTouchEnabled () {
    return ('ontouchstart' in window) || ((window.navigator || {}).maxTouchPoints || 0) > 0 || ((window.navigator || {}).msMaxTouchPoints || 0) > 0
  }

  resizeImage (base64Str, maxWidth, maxHeight, orientation, callback, quality = 1) {
    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')
    var img = document.createElement('img')
    img.onload = function () {
      var iw = img.width
      var ih = img.height
      var scale = Math.min(((maxWidth === -1 ? iw : maxWidth) / iw), ((maxHeight === -1 ? ih : maxHeight) / ih))
      var iwScaled = iw * scale
      var ihScaled = ih * scale
      switch (orientation) {
        case 2:
          canvas.width = iwScaled
          canvas.height = ihScaled
          // horizontal flip
          context.translate(iwScaled, 0)
          context.scale(-1, 1)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        case 3:
          canvas.width = iwScaled
          canvas.height = ihScaled
          // 180° rotate left
          context.translate(iwScaled, ihScaled)
          context.rotate(Math.PI)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        case 4:
          canvas.width = iwScaled
          canvas.height = ihScaled
          // vertical flip
          context.translate(0, ihScaled)
          context.scale(1, -1)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        case 5:
          canvas.width = ihScaled
          canvas.height = iwScaled
          // vertical flip + 90 rotate right
          context.rotate(0.5 * Math.PI)
          context.scale(1, -1)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        case 6:
          canvas.width = ihScaled
          canvas.height = iwScaled
          // 90° rotate right
          context.rotate(0.5 * Math.PI)
          context.translate(0, -ihScaled)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        case 7:
          canvas.width = ihScaled
          canvas.height = iwScaled
          // horizontal flip + 90 rotate right
          context.rotate(0.5 * Math.PI)
          context.translate(iwScaled, -ihScaled)
          context.scale(-1, 1)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        case 8:
          canvas.width = ihScaled
          canvas.height = iwScaled
          // 90° rotate left
          context.rotate(-0.5 * Math.PI)
          context.translate(-iwScaled, 0)
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
        default:
          canvas.width = iwScaled
          canvas.height = ihScaled
          context.drawImage(img, 0, 0, iwScaled, ihScaled)
          break
      }
      context.restore()
      // jpeg support lossless compress
      callback(canvas.toDataURL('image/jpeg', quality))
    }
    img.src = base64Str
  }

  isFacebookApp () {
    const ua = navigator.userAgent || navigator.vendor || window.opera
    return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)
  }

  addHtmlTag (option) {
    option = option || {}
    return new Promise((resolve, reject) => {
      var script = document.createElement(option.tag || 'script')
      script.onload = (e) => {
        resolve(e)
      }
      script.onreadystatechange = (e) => {
        resolve(e)
      }
      script.onerror = (e) => {
        reject(e)
      }
      for (var key in option) {
        script[key] = option[key]
      }
      document.head.appendChild(script)
    })
  }

  isObject (item) {
    return (item && typeof item === 'object' && !Array.isArray(item))
  }

  mergeDeep (target, ...sources) {
    if (!sources.length) {
      return target
    }
    const self = this
    const source = sources.shift()

    if (self.isObject(target) && self.isObject(source)) {
      for (const key in source) {
        if (self.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} })
          self.mergeDeep(target[key], source[key])
        } else {
          Object.assign(target, { [key]: source[key] })
        }
      }
    }

    return self.mergeDeep(target, ...sources)
  }
}
