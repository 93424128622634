<template>
  <div>
    <div ref="place-card" :class="{
      'place-card': !isLongdoMapV3,
      'place-card-map3': isLongdoMapV3,
      animate: isAnimate,
      full: step === 'FULL',
      mid: step === 'MID',
      mini: step === 'MINI',
      hide: isHide,
      empty: isEmptyPlaceDetail,
      draging: canDrag,
    }" :style="{ top }" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"
      @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
      <div class="head" ref="head" id="place-card-head" :class="{ traffic: product === 'traffic' }" @click="onClickHead">
        <button class="close ripple" @click.stop="onClickClose">
          <i class="material-icons-round"> arrow_back </i>
        </button>
        <div class="type">
          <img :src="placeCategoryIconSrc" :title="placeCategoryLabel" :alt="placeCategoryLabel">
        </div>
        <div class="name" :title="placeName" @click="copyPlaceName($event, true)">{{ placeName }}</div>
        <div class="sub-name" @click="copySubPlaceName" :title="subPlaceName">{{ subPlaceName }}</div>
      </div>
      <div ref="body" class="body" v-if="!isLoading">
        <div class="thumbnail" id="thumbnail" v-if="thumbnailSrc" :style="{
          height: thumbnailHeight,
          backgroundImage: `url('${thumbnailSrc || ''}')`
        }">
        </div>
        <div class="action-list" v-if="!isEmptyPlaceDetail">
          <button class="ripple" @click="onClickFav" v-if="!isMarkFav && isLongdoPoi" :disabled="isUpdatingFav">
            <i class="material-icons-round" v-if="!isUpdatingFav">star_border</i>
            <div v-else>
              <i class="material-icons-round" :class="{'animation-fav': isUpdatingFav}">star</i>
              <i class="material-icons-round border">star_border</i>
            </div>

          </button>
          <button class="ripple" @click="onClickUnfav" v-if="isAuthenticated && isMarkFav && isLongdoPoi" :disabled="isUpdatingFav">
            <i class="material-icons-round" v-if="!isUpdatingFav">star</i>
            <div v-else>
              <i class="material-icons-round" :class="{'animation-unfav': isUpdatingFav}">star</i>
              <i class="material-icons-round border">star_border</i>
            </div>

          </button>
          <button class="ripple" @click="onClickDirection" v-if="isPointPoi">
            <i class="material-icons-round">navigation</i>
          </button>
          <button class="ripple" @click="onClickShare">
            <i class="material-icons-round">share</i>
          </button>
          <button class="ripple" @click="copyPlaceName">
            <i class="material-icons-round">content_copy</i>
          </button>
          <button class="ripple" id="place-card-bound" @click="boundArea" v-if="!isPointPoi">
            <i class="material-icons-round">zoom_in_map</i>
          </button>
          <button class="ripple" @click="onClickEdit" v-if="canEdit && !isTrafficProduct && isLongdoPoi">
            <i class="material-icons-round">edit</i>
          </button>
          <button class="ripple" @click="onClickDelete" v-if="canDelete && !isTrafficProduct && isLongdoPoi">
            <i class="material-icons-round">delete</i>
          </button>
          <button class="ripple" @click="onClickReport" v-if="(!canEdit || (canEdit && !canDelete)) && !isTrafficProduct && !isReverseGeocoding && !isGeocoding" :disabled="!isLongdoPoi">
            <i class="material-icons-round">report</i>
          </button>
        </div>
        <div class="new-status notice" v-if="isNewStatus">! {{ $t('placeInfo.newStatus') }}</div>
        <div class="new-status notice" v-if="isUnverifyStatus && !isEmptyPlaceDetail">! {{ $t('placeInfo.newStatus') }}</div>
        <div class="obsoleted-status notice" v-if="isObsoletedStatus">! {{ $t('placeInfo.obsoletedStatus') }}</div>
        <div class="deleted-status notice" v-if="isDeletedStatus || isEmptyPlaceDetail">! {{
          $t('placeInfo.deletedStatus')
        }}</div>
        <div class="rejected-status notice" v-if="isRejectedStatus">! {{ $t('placeInfo.rejectedStatus') }}</div>

        <div class="field" v-if="!isEmptyPlaceDetail && isPromptPai">
          <iframe v-if="isPromptPai" :src="promptPaiIframe" width="119" height="26"
            style="border:none;overflow:hidden;margin: 0 0 0 0;"
            allowTransparency="true" allow="encrypted-media"></iframe>
        </div>
        <div class="field" v-if="dataSource !== null && !isLongdoPoi && !isEmptyPlaceDetail">
          <div class="field-icon">
            <i class="material-icons-round">person</i>
          </div>
          <div class="content">
            <a style="cursor: pointer;" @click="onClickUrl(dataSource.url)">
              {{ dataSource.name }}
            </a>
          </div>
        </div>
        <div class="field" v-if="address">
          <div class="field-icon">
            <i class="material-icons-round">place</i>
          </div>
          <div class="content">
            {{ address }}
          </div>
        </div>
        <div class="field" v-if="elevation">
          <div class="field-icon">
            <img :src="`${baseUrl}img/elevation_icon.svg`"/>
          </div>
          <div class="content">
            {{ elevation }} {{ $t('placeInfo.meter')}}
          </div>
        </div>
        <div class="field" v-if="dms">
          <div class="field-icon">
            DMS
          </div>
          <div class="content">
            {{ dms.text }}
            <button class="ripple" @click="copyDms" v-if="isReverseGeocoding">
              <i class="material-icons-round">content_copy</i>
            </button>
          </div>
        </div>
        <div class="field" v-if="utm">
          <div class="field-icon">
            UTM
          </div>
          <div class="content">
            {{ utm.text }}
            <button class="ripple" @click="copyUtm" v-if="isReverseGeocoding">
              <i class="material-icons-round">content_copy</i>
            </button>
          </div>
        </div>
        <div class="field" v-if="isGeocoding || isReverseGeocoding">
          <div class="field-icon">
            <i class="material-icons-round">add_location_alt</i>
          </div>
          <div class="content">
            <a style="cursor: pointer;" @click="onClickCreatePlace">
              {{ $t( 'main.createPlace' )}}
            </a>
          </div>
        </div>
        <div class="field connector-list" v-if="connectorList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">electrical_services</i>
          </div>
          <div class="content">
            <ul>
              <div v-for="(connector,index) in connectorList" :key="index">
                <span class="total-available">{{ connector.total_available }}</span> {{ connector.charger_type_name }} {{
                connector.connector_type_name }} {{ connector.discharge_electricity }}
              </div>
            </ul>
          </div>
        </div>
        <div class="field" v-if="busList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">directions_bus</i>
          </div>
          <div class="content">
            <div class="bus-list">
              <span v-for="(bus, i) in busList" :key="`bus-${i}`" class="bus ripple" @click="onClickBus(bus)">{{
                bus.display }}</span>
            </div>
          </div>
        </div>
        <div class="field" v-if="parentPlaceName">
          <div class="field-icon" @touchstart="onTouchstartIcon($event)">
            <i class="material-icons-round">home_work</i>
          </div>
          <div class="content">
            <a @click="gotoParentPlaceInfo" style="cursor: pointer;">{{ parentPlaceName }}</a>
          </div>
        </div>
        <div class="field" v-if="workingHoursList && workingHoursList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">schedule</i>
          </div>
          <div class="content">
            <div class="col">
              <div
                v-for="(workingHours, i) in workingHoursList"
                :key="`working-hours-${i}`"
                class="working-hours row"
              >
              <div class="row" v-if="!workingHours.close">
                <div class="day">{{ $t(`day.${workingHours.day}`) }}</div>
                <div class="start">{{ workingHours.start }}</div>
                <div class="to">-</div>
                <div class="end">{{ workingHours.end }}</div>
              </div>
              <div class="row" v-else>
                <div class="day">{{ $t(`day.${workingHours.day}`) }}</div>
                <div class="close">{{ $t('placeInfo.close')}}</div>
              </div>
              </div>
              <div class="working-hour-remark">{{ workingHoursRemark }}</div>
            </div>
          </div>
        </div>
        <div class="field" v-if="!isLongdoPoi && otherSourceTelNum">
          <div class="field-icon">
            <i class="material-icons-round">phone</i>
          </div>
          <div class="content">
            <a :href="`tel:${otherSourceTelNum}`">
              {{ otherSourceTelNum }}
            </a>
          </div>
        </div>
        <div class="field" v-if="mobileList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">phone_iphone</i>
          </div>
          <div class="content">
            <div class="col">
              <div
                v-for="(mobile, i) in mobileList"
                :key="`mobile-${i}`"
              >
                <div v-html="formatPhoneNumber(mobile)"></div>
                <div class="contact-remark" v-if="mobile.remark">{{ mobile.remark }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="field" v-if="telList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">phone</i>
          </div>
          <div class="content">
            <div class="col">
              <div class="tel-content" v-for="(tel, i) in telList" :key="`tel-${i}`">
                <div class="tel">
                  <div v-html="formatPhoneNumber(tel)"></div>
                </div>
                <div class="contact-remark" v-if="tel.remark">{{ tel.remark }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="field" v-if="faxList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">print</i>
          </div>
          <div class="content">
            <div class="col">
              <div
                v-for="(fax, i) in faxList"
                :key="`fax-${i}`"
              >
                <div v-html="formatPhoneNumber(fax)"></div>
                <div class="contact-remark" v-if="fax.remark">{{ fax.remark }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="field" v-if="specialTelList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">support_agent</i>
          </div>
          <div class="content">
            <div class="col">
              <div
                v-for="(tel, i) in specialTelList"
                :key="`tel-${i}`"
              >
                <div v-html="formatPhoneNumber(tel)"></div>
                <div class="contact-remark" v-if="tel.remark">{{ tel.remark }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="field" v-if="lineList.length > 0">
          <div class="field-icon">
            <div class="image" :style="{
              backgroundImage: `url('${lineLogoUrl}')`
            }"></div>
          </div>
          <div class="content">
            <div class="col">
              <div
                v-for="(line, i) in lineList"
                :key="`line-${i}`"
              >
                <span v-if="line.id">{{ line.id }}</span>
                <a v-else style="cursor: pointer;" @click="onClickUrl(`https://line.me/ti/p/${line.link}`)">{{ line.link }}</a>
                <div class="contact-remark" v-if="line.remark">{{ line.remark }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="field" v-if="website || ovmWebsite">
          <div class="field-icon">
            <i class="material-icons-round">public</i>
          </div>
          <div class="content">
            <a style="cursor: pointer;" @click="onClickUrl(website)">{{ website }}</a>
            <a style="cursor: pointer;" @click="onClickUrl(ovmWebsite)">{{ ovmWebsite }}</a>
          </div>
        </div>
        <div class="field" v-if="bookingcomUrl">
          <div class="field-icon">
            <i class="material-icons-round">hotel</i>
          </div>
          <div class="content">
            <a style="cursor: pointer;" @click="onClickUrl(bookingcomUrl)">Booking.com</a>
          </div>
        </div>
        <div class="field" v-if="placeTagList.length > 0">
          <div class="field-icon">
            <i class="material-icons-round">local_offer</i>
          </div>
          <div class="content">
            <div class="tag-list">
              <span v-for="(tag, i) in placeTagList" :key="`tag-${i}`" class="tag ripple" @click="onClickTag(tag)">{{ tag
              }}</span>
            </div>
          </div>
        </div>
        <div class="field" v-if="address_detail">
          <div class="field-icon">
            <i class="material-icons-round">info</i>
          </div>
          <div class="content">
            {{ address_detail }}
          </div>
        </div>
        <div class="field" v-if="showContributor() !== null && !(isOsmPoi || isOvmPoi )">
          <div class="field-icon">
            <i class="material-icons-round">person</i>
          </div>
          <div class="content">
            <a v-if="showContributor().url" style="cursor: pointer;" @click="onClickUrl(showContributor().url)">
              {{ showContributor().name }}
            </a>
            <span v-else>
              {{ showContributor().name }}
            </span>
          </div>
        </div>
        <div class="field full-description" v-if="descriptionHtml">
          <div class="field-icon">
            <i class="material-icons-round">description</i>
          </div>
          <div class="content" v-html="descriptionHtml">
          </div>
        </div>
        <div class="field" v-if="!isEmptyPlaceDetail">
          <div class="field-icon">
            <i class="material-icons-round">wifi_tethering</i>
          </div>
          <div class="content nearby">
            <div class="nearby-place-list" v-if="nearbyPlaceListNotEmpty">
              <div class="nearby-place" v-for="item in placeDetail.nearbyPlaceList" :key="item.id">
                <img :src="`${HDIconPath}${item.icon}`" />
                <span> <a class="nearby-link" @click="onClickNearbyPlace(item)">{{item.name}}</a> {{ `(${item.distanceDisplay}${$i18n.locale === 'th' ? ' ': ''}${$t('main.metre')})`}}</span>
              </div>
            </div>
            <div class="nearby-place-list" v-else>
              <div>{{ `${$t('placeInfo.notFoundNearby')} ${isPointPoi ? 20 : 100}${$i18n.locale === 'th' ? ' ': ''}${$t('main.metre')}` }}</div>
            </div>
            <div class="see-more-nearby">
              <i class="material-icons-round">search</i>
              <a @click="onClickMoreNearby">{{ $t('placeInfo.exploreMore') }}</a>
            </div>
          </div>
        </div>
        <div class="field" v-if="isLongdoPoi && !isTrafficProduct && !isEmptyPlaceDetail">
          <div class="field-icon">
            <i class="material-icons-round">image</i>
          </div>
          <div class="content">
            <div class="col">
              <div class="image-list row">
                <button
                  class="add-image ripple"
                  :style="{width: imageWidth, height: imageHeight}"
                  @touchstart="onClickAddImage"
                  @mousedown="onClickAddImage"
                >
                  <i class="material-icons-round">add_photo_alternate</i>
                  <div v-if="imageSize > 75">{{ $t('createPlace.addImage') }}</div>

                </button>
                <input ref="add-image-input" class="add-image-input" type="file" accept="image/*" @change="manageImageList()" v-show="false">
                <div  v-for="(image, i) in imageList" :key="i" class="image" @click="onPreviewImage(i)" :style="{
                  borderRadius: '10px',
                  width: imageWidth,
                  height: imageHeight,
                  backgroundSize: 'cover',
                  backgroundImage: `url(\'${image.preview}\')`,
                }">
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="date" v-html="date"></div>
        </div>
        <div v-else>
          <div style="display: flex; justify-content: center;">
            <div class="ldmap-loading-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="place-footer">
        </div>
      </div>
      <ImageWidgetModal v-if="isUploadImage" :imageList="uploadImageList"
        @addImage="onClickAddImage" @deleteImage="onDeleteImage" @clickCancel="onClickCancel"
        @uploadImage="onUploadImage"></ImageWidgetModal>
      <ConfirmDeletePlaceDialogVue v-if="requestDelete"></ConfirmDeletePlaceDialogVue>
      <SharePlaceWidget :poiId="poiId" :lat="lat" :lon="lon" :isShareInfoPage="false" :shareUrl="shareUrl"
        @close="isShowSharePlaceWidget = false" v-if="isShowSharePlaceWidget" style="position: fixed;" />
      <div class="loading-overlay" v-if="isDeleting || isUploading">
        <div class="background"></div>
        <img src="@/assets/img/loading.gif" alt="loading">
      </div>
      <input id="input-for-copy" ref="input-for-copy" type="link" readonly>
    </div>
</template>

<script>
import SharePlaceWidget from '@/components/SharePlaceWidget.vue'
import ConfirmDeletePlaceDialogVue from './ConfirmDeletePlaceDialog.vue'
import ImageWidgetModal from '@/components/ImageWidgetModal.vue'

export default {
  name: 'PlaceCard',
  components: {
    SharePlaceWidget,
    ConfirmDeletePlaceDialogVue,
    ImageWidgetModal
  },
  props: {
    poiId: {
      type: String,
      default: null
    },
    userData: {
      type: Object,
      default: null
    },
    map: {
      type: Object,
      default: null
    },
    geocodingResult: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      width: window.innerWidth,
      step: window.innerWidth > this.mobileMaxSize ? 'FULL' : 'MID',
      top: '62%',
      imageSize: 0,
      thumbnailHeight: window.innerWidth > this.mobileMaxSize ? '200px' : '0',
      canDrag: false,
      touchstartClientY: null,
      isAnimate: false,
      isLoading: false,
      placeDetail: null,
      isShowSharePlaceWidget: false,
      isUpdatingFav: false,
      isHidingToolBar: false,
      requestDelete: false,
      isDeleting: false,
      isUploadImage: false,
      uploadImageList: [],
      isUploading: false,
      isAuthenticated: false
    }
  },
  computed: {
    nearbyPlaceListNotEmpty () {
      const self = this
      return self.placeDetail?.nearbyPlaceList?.length !== 0
    },
    shareUrl () {
      const self = this
      let url = ''
      if (self.isReverseGeocoding) {
        url = `https://${process.env.VUE_APP_PRODUCT}.longdo.com/main/p/${self.poiId}?lat=${self.$route.query.lat}&lon=${self.$route.query.lon}`
      } else if (self.isGeocoding) {
        url = `https://${process.env.VUE_APP_PRODUCT}.longdo.com/main/?search=${self.$route.query.name}&lat=${self.$route.query.lat}&lon=${self.$route.query.lon}&GCP=1`
      } else {
        url = `https://${process.env.VUE_APP_PRODUCT}.longdo.com/main/p/${self.poiId}`
      }
      return url
    },
    HDIconPath () {
      return process.env.VUE_APP_LONGDO_MAP_HD_ICON
    },
    isLongdoPoi () {
      const self = this
      return (self.poiId && self.poiId[0] === 'A')
    },
    isOsmPoi () {
      const self = this
      return self.poiId && self.poiId.slice(0, 3) === 'OSM'
    },
    isOsmPPoi () {
      const self = this
      return self.poiId && self.poiId.slice(0, 5) === 'OSM:P'
    },
    isOvmPoi () {
      const self = this
      return self.poiId && (self.poiId.slice(0, 3) === 'OVM' || self.poiId[0] === 'H')
    },
    isGeocoding () {
      const self = this
      return self.poiId && self.poiId.slice(0, 3) === 'GCP'
    },
    isPointPoi () {
      const self = this
      return self.isLongdoPoi || self.isOsmPoi || self.isOvmPoi || self.isGeocoding || self.isReverseGeocoding || self.isBuilding
    },
    isKhet () {
      const self = this
      return self.poiId && self.poiId[0] === 'K'
    },
    isRoad () {
      const self = this
      return self.poiId && self.poiId[0] === 'L'
    },
    isGeom () {
      const self = this
      return self.poiId && self.poiId[0] === 'G' && self.poiId.slice(0, 3) !== 'GCP'
    },
    isLayer () {
      const self = this
      return self.poiId && self.poiId[0] === 'Y'
    },
    isWaterLine () {
      const self = this
      return self.poiId && self.poiId[0] === 'X'
    },
    isWaterArea () {
      const self = this
      return self.poiId && self.poiId[0] === 'W'
    },
    isBusRoute () {
      const self = this
      return self.poiId && self.poiId[0] === 'S'
    },
    isBuilding () {
      const self = this
      return self.poiId && self.poiId[0] === 'B'
    },
    isReverseGeocoding () {
      const self = this
      return self.poiId && self.poiId.slice(0, 4) === 'RGCP' && ('lat' in self.$route.query) && ('lon' in self.$route.query)
    },
    isInValidPoiId () {
      const self = this
      return !self.isLongdoPoi && !self.isOsmPoi && !self.isOvmPoi &&
        (!self.isGeocoding || (self.isGeocoding && !self.geocodingResult)) &&
        !self.isReverseGeocoding && !self.isKhet && !self.isRoad &&
        !self.isGeom && !self.isLayer && !self.isWaterLine && !self.isWaterArea &&
        !self.isBusRoute && !self.isBuilding
    },
    isHide () {
      const self = this
      return self.$route.name !== 'Place' ||
        (self.$route.name === 'Place' && self.isInValidPoiId) ||
        self.$route.hash === '#routing' ||
        self.$route.hash === '#search'
    },
    miniHeight () {
      return 72
    },
    toolbarHeight () {
      return 54
    },
    fullTopFactor () {
      return 0
    },
    midTopFactor () {
      return 0.62
    },
    staticThumbnailHeight () {
      return 200
    },
    previewImageMinWidth () {
      return 128
    },
    isEmptyPlaceDetail () {
      const self = this
      return ((!('status' in (self.placeDetail || {}))) && self.isLongdoPoi) || (!self.placeDetail && !self.isLongdoPoi)
    },
    isMarkFav () {
      const self = this
      return self.placeDetail?.marked_as_favorite || false
    },
    canEdit () {
      const self = this
      return self.placeDetail?.permission?.edit || false
    },
    canDelete () {
      const self = this
      return self.placeDetail?.permission?.delete || false
    },
    isNewStatus () {
      const self = this
      return self.placeDetail?.status === 'N'
    },
    isUnverifyStatus () {
      const self = this
      return self.isOvmPoi && !self.placeDetail?.verified
    },
    isApprovedStatus () {
      const self = this
      return self.placeDetail?.status === 'A'
    },
    parentPlaceName () {
      const self = this
      return (self.placeDetail || {})[`parent_name_${self.$i18n.locale}`] || ''
    },
    isObsoletedStatus () {
      const self = this
      return self.placeDetail?.status === 'O'
    },
    isDeletedStatus () {
      const self = this
      return self.placeDetail?.status === 'D'
    },
    isRejectedStatus () {
      const self = this
      return self.placeDetail?.status === 'R'
    },
    dataSource () {
      const self = this
      if (self.isOsmPoi) {
        return {
          name: self.placeDetail?.datasource.source || 'OpenStreetMap',
          url: self.placeDetail?.datasource.url || 'https://www.openstreetmap.org/copyright/'
        }
      } else if (self.isOvmPoi) {
        return {
          name: self.placeDetail?.contributor || 'overture_maps',
          url: 'https://overturemaps.org/'
        }
      }
      return null
    },
    placeName () {
      const self = this
      if (self.isOvmPoi || self.isReverseGeocoding) {
        return (self.placeDetail || {}).name
      } else {
        return (self.placeDetail || {})[`name_${self.$i18n.locale}`] || ''
      }
    },
    subPlaceName () {
      const self = this
      return (self.placeDetail || {})[`name_${self.$i18n.locale === 'th' ? 'en' : 'th'}`] || ''
    },
    date () {
      const self = this
      const createdTimestamp = (self.placeDetail || {}).created_timestamp || ''
      const uid = (self.placeDetail || {}).uid || ''
      const isShowCreator = (self.placeDetail?.permission?.edit || false) && (self.placeDetail?.permission?.delete || false)
      const updatedTimestamp = (self.placeDetail || {}).updated_timestamp || ''
      if (createdTimestamp || updatedTimestamp) {
        const dateList = []
        if (createdTimestamp) {
          const createdDate = new Date(Number(createdTimestamp) * 1000)
          const createdDateString = createdDate.toLocaleString(self.$i18n.locale, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
          dateList.push(`${self.$t('placeInfo.createdDate')}: ${createdDateString}`)
        }
        if (isShowCreator && uid) {
          const creatorName = self.placeDetail?.datasource?.source || ''
          dateList.push(`${self.$t('placeInfo.createdBy')} <a @click="onClickUrl(${process.env.VUE_APP_LONGDO_MAP_USER_INFO}${uid})">${creatorName}</a>`)
        }
        if (updatedTimestamp) {
          const updatedDate = new Date(Number(updatedTimestamp) * 1000)
          const updatedDateString = updatedDate.toLocaleString(self.$i18n.locale, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
          dateList.push(`${self.$t('placeInfo.updatedDate')}: ${updatedDateString}`)
        }
        return dateList.join(' ')
      } else {
        return ''
      }
    },
    placeCategoryIconSrc () {
      const self = this
      let path = ''
      if (self.placeDetail?.iconfile) {
        path = self.placeDetail?.iconfile.replace('2x', '4x')
      }
      return path || ''
    },
    placeCategoryLabel () {
      const self = this
      return (self.placeDetail || {})[`place_type_${self.$i18n.locale}`]
    },
    thumbnailSrc () {
      const self = this
      const imgUrl = self.placeDetail?.thumbnail || ''
      var i = document.createElement('img')

      if (self.placeDetail?.images && self.placeDetail?.images.length > 0) {
        i.src = self.placeDetail?.images[0].imagepath
      }
      i.onload = () => {
        const preview = document.getElementById('thumbnail')
        preview.style.backgroundImage = `url('${i.src}')`
        preview.style.transition = 'background-image 2s'
      }
      return imgUrl
    },
    promptPaiIframe () {
      const self = this
      const id = self.poiId.replace('A', '')
      return `https://promptpai.com/ws/promptpai-link?pp_id=${id}&size=normal&button_style=button&theme=light&locale=${self.$i18n.locale}`
    },
    isPromptPai () {
      const self = this
      if (!self.poiId) {
        return false
      }
      return (self.isNewStatus || self.isApprovedStatus) && self.placeDetail.is_promptpai
    },
    address () {
      const self = this
      if (self.isOvmPoi) {
        return (self.placeDetail || {}).address || ''
      } else if ((self.isReverseGeocoding || self.isBuilding) && self.placeDetail) {
        if (self.placeDetail?.address === null && self.isReverseGeocoding) {
          return ''
        }
        const temp = []
        if (self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].road) {
          temp.push(self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].road)
        }
        if (self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].subdistrict) {
          temp.push(self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].subdistrict)
        }
        if (self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].district) {
          temp.push(self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].district)
        }
        if (self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].province) {
          temp.push(self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].province)
        }
        if (self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].postcode) {
          temp.push(self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].postcode)
        }
        if (self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].country) {
          temp.push(self.placeDetail[`address${self.isBuilding ? '_' + self.$i18n.locale : ''}`].country)
        }
        return temp.join(self.$i18n.locale === 'th' ? ' ' : ', ')
      } else {
        return (self.placeDetail || {})[`address_${self.$i18n.locale}`] || ''
      }
    },
    address_detail () {
      const self = this
      return (self.placeDetail || {})[`address_detail_${self.$i18n.locale}`] || ''
    },
    busList () {
      const self = this
      return (self.placeDetail?.bus_routes || []).map(bus => {
        return {
          ...bus,
          display: `${bus.name} ${bus[`description_${self.$i18n.locale}`]}`
        }
      })
    },
    otherSourceTelNum () {
      const self = this
      return self.placeDetail?.otherSourceTelNum || ''
    },
    mobileList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'M').map(mobile => {
        return {
          href: `tel:${mobile.country_code ? '+' : ''}${mobile.country_code}${mobile.area_code}${mobile.tel_no}`,
          display: `${mobile.country_code ? '+' : ''}${mobile.country_code}${mobile.area_code} ${mobile.tel_no.substring(0, 3)} ${mobile.tel_no.substring(3, mobile.tel_no.length)}`,
          ...mobile
        }
      })
    },
    telList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'T').map(tel => {
        return {
          ...tel,
          main: `${tel.country_code ? '+' : ''}${tel.country_code}${tel.area_code}${tel.tel_no}`,
          ext: tel.ext === '' ? '' : `${self.$t('telInput.ext')} ${tel.ext}`
        }
      })
    },
    faxList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'F').map(fax => {
        return {
          href: `tel:${fax.country_code ? '+' : ''}${fax.country_code}${fax.area_code}${fax.tel_no}`,
          display: `${fax.country_code ? '+' : ''}${fax.country_code}${fax.area_code} ${fax.tel_no.substring(0, 3)} ${fax.tel_no.substring(3, fax.tel_no.length)} ${fax.ext ? self.$t('telInput.ext') : ''} ${fax.ext}`,
          ...fax
        }
      })
    },
    specialTelList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'S').map(tel => {
        return {
          href: `tel:${tel.tel_no}`,
          display: `${tel.tel_no}${tel.tel_no_to ? '-' : ''}${tel.tel_no_to} ${tel.ext ? self.$t('telInput.ext') : ''} ${tel.ext}`,
          ...tel
        }
      })
    },
    lineList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'L').map(line => {
        return {
          id: line.line_id,
          link: line.line_link,
          ...line
        }
      })
    },
    workingHoursList () {
      const self = this
      if ((self.placeDetail?.working_hours_list?.hours || []).length !== 0) {
        const temp = self.placeDetail.working_hours_list.hours
        const dayList = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
        dayList.forEach((day) => {
          const found = temp.some(el => el.day === day)
          if (!found) temp.push({ day: day, close: true })
        })
        return temp
      }
      return []
    },
    workingHoursRemark () {
      const self = this
      return (self.placeDetail?.working_hours_list || {}).remark || ''
    },
    website () {
      const self = this
      return self.placeDetail?.website || ''
    },
    ovmWebsite () {
      const self = this
      if (self.isOvmPoi) {
        return self.placeDetail?.url || ''
      }
      return ''
    },
    lineLogoUrl () {
      return require('@/assets/img/line_logo.png')
    },
    bookingcomUrl () {
      const self = this
      return self.placeDetail?.bookingcom_url || ''
    },
    placeTagList () {
      const self = this
      return self.placeDetail?.tags || []
    },
    lat () {
      const self = this
      if (!self.placeDetail) {
        return 0
      }
      return self.placeDetail?.location?.latitude || 0
    },
    lon () {
      const self = this
      if (!self.placeDetail) {
        return 0
      }
      return self.placeDetail?.location?.longitude || 0
    },
    elevation () {
      const self = this
      return self.placeDetail?.elevation || 0
    },
    utm () {
      const self = this
      return self.isReverseGeocoding ? self.utility.convertLatLonToDms({ lat: self.lat, lon: self.lon }) : null
    },
    dms () {
      const self = this
      return self.isReverseGeocoding ? self.utility.convertLatLonToUtm({ lat: self.lat, lon: self.lon }) : null
    },
    connectorList () {
      const self = this
      let connectorList = self.placeDetail?.custom_details?.list_connector
      const isMg = self.placeDetail?.custom_details?.current_ac || self.placeDetail?.custom_details?.current_dc || false
      const peaVoltaHtml = self.placeDetail?.custom_details?.description
      if (connectorList) {
        connectorList.forEach(connector => {
          connector.discharge_electricity = connector.discharge_electricity.replace('กำลังไฟฟ้า ', '')
        })
      } else if (isMg) {
        connectorList = []
        if (self.placeDetail?.custom_details?.current_ac) {
          connectorList.push({
            connector_type_id: 1,
            charger_type_name: 'AC',
            connector_type_name: '',
            discharge_electricity: '',
            total_available: self.placeDetail.custom_details.current_ac
          })
        }
        if (self.placeDetail?.custom_details?.current_dc) {
          connectorList.push({
            connector_type_id: 2,
            charger_type_name: 'DC',
            connector_type_name: '',
            discharge_electricity: '',
            total_available: self.placeDetail.custom_details.current_dc
          })
        }
      } else if (peaVoltaHtml) {
        connectorList = []
        const placeholder = document.createElement('div')
        placeholder.innerHTML = peaVoltaHtml
        placeholder.querySelectorAll('.charge-container > .charge > div:first-child').forEach((el, index) => {
          connectorList.push({
            connector_type_id: index,
            charger_type_name: el.childNodes[0].textContent,
            connector_type_name: '',
            discharge_electricity: el.childNodes[1].innerHTML.replace('(', '').replace(')', ''),
            total_available: 1
          })
        })
      }
      return connectorList || []
    },
    descriptionHtml () {
      const self = this
      return (self.placeDetail || {})[`description_${self.$i18n.locale}`]
    },
    imageWidth () {
      const self = this
      return self.imageSize + 'px'
    },
    imageHeight () {
      const self = this
      return self.imageSize + 'px'
    },
    imageList () {
      const self = this
      return (self.placeDetail?.images || []).map(image => {
        return {
          file: null,
          original: image.imagepath,
          preview: `${image.imagepath}/${self.imageSize * 2}`,
          comment: image.description,
          orientation: -1
        }
      })
    },
    isDesktop () {
      const self = this
      return self.width >= self.mobileMaxSize
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.toggleDisplayToolBar)
    self.$root.$on('resize', (size) => {
      self.width = size.width
    })
    self.$root.$on('confirmDeletePlace', self.deletePlace)
    self.$root.$on('cancelDeletePlace', self.cancelDeletePlace)
    self.calImageSize()
    if (!self.isHide) {
      self.showPlaceCard()
    }
    self.$parent.$on('setPlaceCardIsLoading', (value) => {
      const self = this
      self.isLoading = value
    })

    self.$parent.$on('setPlaceCardDetail', async (value) => {
      const self = this
      const nearbyPlaceList = await self.getNearbyPlace(value.location.latitude, value.location.longitude)
      self.placeDetail = { ...value, nearbyPlaceList }
      self.$nextTick(() => {
        self.isLoading = false
        self.$refs['place-card'].scrollTop = 0
        self.setTelLinkMobile()
      })
    })
  },
  methods: {
    boundArea () {
      const self = this
      self.$parent.$emit('placeCardBoundArea', self.poiId)
    },
    onClickMoreNearby () {
      const self = this
      if (self.placeDetail?.location?.latitude && self.placeDetail?.location?.longitude) {
        self.$router.push({
          name: 'Main',
          hash: '#explore',
          query: {
            lat: self.placeDetail.location.latitude,
            lon: self.placeDetail.location.longitude
          }
        })
      }
    },
    onClickCreatePlace () {
      const self = this
      if (self.placeDetail) {
        if (self.isLongdoMapV3) {
          window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${self.placeDetail.location.latitude}&lon=${self.placeDetail.location.longitude}`
        } else {
          self.$router.push({
            name: 'CreatePlace',
            params: {},
            query: {
              lat: Number(self.placeDetail.location.latitude),
              lon: Number(self.placeDetail.location.longitude)
            },
            hash: ''
          })
        }
      }
      self.storeEventToNativeAnalytic('geocoding_create_place')
    },
    async onClickNearbyPlace (placeData) {
      const self = this
      if (placeData?.id) {
        self.$router.push({
          name: 'Place',
          params: {
            poiId: placeData.id
          }
        })
      }
    },
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank').focus()
      }
    },
    async fetchUserInfo () {
      const self = this
      return await self.utility.getUserSession()
    },
    copyText (text, toastMessage = '') {
      const self = this
      var copytext = self.$refs['input-for-copy']
      copytext.value = text
      copytext.select()
      document.execCommand('Copy')
      self.$emit('showMessageToast', toastMessage)
    },
    copyPlaceName (event, clickHead = false) {
      const self = this
      if ((clickHead && window.innerWidth >= 992)) {
        self.copyText(self.placeName, self.$t('placeInfo.copyPlaceName'))
      } else if (!clickHead) {
        self.copyText(`${self.placeName} (${self.subPlaceName})`, self.$t('placeInfo.copyPlaceName'))
      }
    },
    copySubPlaceName () {
      const self = this
      if (self.width > self.mobileMaxSize) {
        self.copyText(self.subPlaceName, self.$t('placeInfo.copyPlaceName'))
      }
    },
    copyLatLon () {
      const self = this
      // self.copyText = isSwap ? `${self.location.lon.toFixed(6)}, ${self.location.lat.toFixed(6)}` : `${self.location.lat.toFixed(6)}, ${self.location.lon.toFixed(6)}`
      const copyText = `${self.placeDetail?.location?.latitude?.toFixed(6)}, ${self?.placeDetail?.location?.longitude.toFixed(6)}`
      self.copyText(copyText, self.$t('placeInfo.copyPlaceInfo'))
    },
    copyDms () {
      const self = this
      self.copyText(self.dms.text || '', self.$t('placeInfo.copyPlaceInfo'))
    },
    copyUtm () {
      const self = this
      self.copyText(self.utm.text || '', self.$t('placeInfo.copyPlaceInfo'))
    },
    showContributor () {
      const self = this
      const dataSource = self.placeDetail?.datasource
      if (self.placeDetail && dataSource) {
        if (dataSource.source !== undefined) {
          return {
            name: dataSource.source ? dataSource.source : '',
            url: dataSource.url ? dataSource.url : ''
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    toggleDisplayToolBar (isShowingMapOnly) {
      const self = this
      self.isHidingToolBar = isShowingMapOnly

      self.isAnimate = true
      const midTop = window.innerHeight * self.midTopFactor + (self.isHidingToolBar ? self.toolbarHeight : 0)
      if (self.step === 'MID') {
        self.top = `${midTop}px`
      }
      setTimeout(() => {
        self.isAnimate = false
      }, 300)
    },
    onMouseDown (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (!self.utility.isTouchEnabled()) {
        self.startDragCard(e)
      }
    },
    onMouseMove (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (!self.utility.isTouchEnabled()) {
        self.dragCard(e)
      }
    },
    onMouseUp (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (!self.utility.isTouchEnabled()) {
        self.endDragCard(e)
      }
    },
    onTouchStart (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (self.utility.isTouchEnabled()) {
        self.startDragCard(e)
      }
    },
    onTouchMove (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (self.utility.isTouchEnabled()) {
        self.dragCard(e)
      }
    },
    onTouchEnd (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (self.utility.isTouchEnabled()) {
        self.endDragCard(e)
      }
    },
    startDragCard (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (self.step === 'FULL') {
        if (self.$refs.head !== e.target && !self.$refs.head.contains(e.target)) {
          return false
        }
      }
      self.touchstartClientY = e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY
      self.canDrag = true
    },
    dragCard (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (self.step === 'FULL') {
        if (self.$refs.head !== e.target && !self.$refs.head.contains(e.target)) {
          return false
        }
      }
      if (!self.canDrag) {
        return false
      }
      const fullTop = window.innerHeight * self.fullTopFactor
      const midTop = window.innerHeight * self.midTopFactor + (self.isHidingToolBar ? self.toolbarHeight : 0)
      const miniTop = window.innerHeight - self.miniHeight - self.toolbarHeight
      const diff = (e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY) - self.touchstartClientY

      let newTop = midTop + diff // MID
      if (self.step === 'FULL') {
        newTop = fullTop + diff
      } else if (self.step === 'MINI') {
        newTop = miniTop + diff
      }

      if (newTop < fullTop) {
        self.top = `${fullTop}px`
      } else if (newTop > miniTop) {
        self.top = `${miniTop}px`
      } else {
        self.top = `${newTop}px`
      }

      const factor = newTop > midTop ? 1.0 : (1.0 * newTop / midTop)
      self.thumbnailHeight = `${self.staticThumbnailHeight * (1 - factor)}px`
    },
    endDragCard (e) {
      const self = this
      if (self.isDesktop) {
        return
      }
      if (self.step === 'FULL') {
        if (self.$refs.head !== e.target && !self.$refs.head.contains(e.target)) {
          return false
        }
      }

      self.canDrag = false
      self.isAnimate = true
      const fullTop = window.innerHeight * self.fullTopFactor
      const midTop = window.innerHeight * self.midTopFactor + (self.isHidingToolBar ? self.toolbarHeight : 0)
      const miniTop = window.innerHeight - self.miniHeight - self.toolbarHeight
      const diff = (e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY) - self.touchstartClientY

      if (self.step === 'MID' &&
        (
          (diff < 0 && Math.abs(diff) <= midTop / 3) ||
          (diff > 0 && Math.abs(diff) <= (window.innerHeight - midTop - self.toolbarHeight) / 3)
        )
      ) {
        self.top = `${midTop}px`
      } else if (self.step === 'FULL' &&
        (diff > 0 && Math.abs(diff) <= midTop / 3)
      ) {
        self.top = `${fullTop}px`
      } else if (self.step === 'MINI' &&
        (diff < 0 && Math.abs(diff) <= (window.innerHeight - midTop - self.toolbarHeight) / 3)
      ) {
        self.top = `${miniTop}px`
      } else if ((self.step === 'MID' && diff < 0) || (self.step === 'MINI' && Math.abs(diff) >= ((midTop / 3) + (window.innerHeight - midTop - self.toolbarHeight)))) {
        self.top = `${fullTop}px`
        self.step = 'FULL'
      } else if ((self.step === 'MID' && diff > 0) || (self.step === 'FULL' && Math.abs(diff) >= (midTop + ((window.innerHeight - midTop - self.toolbarHeight) / 3)))) {
        self.top = `${miniTop}px`
        self.step = 'MINI'
      } else if (self.step === 'FULL' && Math.abs(diff) >= (midTop / 3)) {
        self.top = `${midTop}px`
        self.step = 'MID'
      } else if (self.step === 'MINI' && Math.abs(diff) >= (window.innerHeight - midTop - self.toolbarHeight) / 3) {
        self.top = `${midTop}px`
        self.step = 'MID'
      }

      if (self.step === 'FULL') {
        self.thumbnailHeight = `${self.staticThumbnailHeight}px`
      } else {
        self.thumbnailHeight = '0px'
      }

      setTimeout(() => {
        self.isAnimate = false
      }, 300)
    },
    onClickHead () {
      const self = this
      if (self.isDesktop) {
        return
      }
      self.isAnimate = true
      const fullTop = window.innerHeight * self.fullTopFactor
      const midTop = window.innerHeight * self.midTopFactor + (self.isHidingToolBar ? self.toolbarHeight : 0)

      if (self.step === 'MID') {
        self.top = `${fullTop}px`
        self.step = 'FULL'
        self.thumbnailHeight = `${self.staticThumbnailHeight}px`
      } else if (self.step === 'FULL' || self.step === 'MINI') {
        self.top = `${midTop}px`
        self.step = 'MID'
        self.thumbnailHeight = '0px'
      }

      setTimeout(() => {
        self.isAnimate = false
      }, 300)
    },
    async getLongdoPoiDetail () {
      const self = this
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      const username = (userSession || {}).name || undefined
      const ldtoken = (userSession || {}).longdousertoken || undefined

      const result = await self.api.getPlaceDetail({
        id: self.poiId,
        locale: self.$i18n.locale,
        username,
        ldtoken
      })
      const status = result?.data?.status?.code || 500
      if (status === 200) {
        if (result?.data?.data && Object.keys(result.data.data).length !== 0) {
          if (result.data.data.bus_routes) {
            self.placeDetail.bus_routes.forEach((bus) => {
              self.map.Tags.remove('__ldmap_bus:' + bus.id)
            })
          }
          return result.data.data
        }
      }
      return null
    },
    async getAddress () {
      const self = this
      const result = await self.api.getAddress({
        lat: self.$route.query.lat,
        lon: self.$route.query.lon,
        locale: self.$i18n.locale
      })
      let address = null
      let dem = null
      if (result.status === 200) {
        address = result.data
        dem = result.data.elevation || null
      }
      return { address: address, dem: dem }
    },
    async getGeocodingDetail () {
      const self = this
      return {
        ...self.geocodingResult,
        name_th: self.geocodingResult.name,
        location: {
          latitude: self.geocodingResult.lat,
          longitude: self.geocodingResult.long
        },
        iconfile: self.geocodingResult.markicon2x
      }
    },
    async getReverseGeocodingDetail () {
      const self = this
      const addressData = await self.getAddress()
      return {
        name: `${self.$route.query.lat}, ${self.$route.query.lon}`,
        elevation: addressData.dem,
        address: addressData.address,
        location: {
          latitude: Number(self.$route.query.lat),
          longitude: Number(self.$route.query.lon)
        },
        iconfile: 'https://mmmap15.longdo.com/mmmap/images/icons_2x/reddot.png'
      }
    },
    async getNearbyPlace (lat, lon) {
      const self = this
      const params = {
        lon: lon,
        lat: lat,
        offset: 0,
        locale: self.$i18n.locale,
        limit: (self.isLongdoPoi || self.isOsmPPoi) ? 5 : 4,
        span: self.isPointPoi ? 0.00020 : 0.001,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p'
      }
      // if lat lon search find nearby place of that lat lon
      const nearby = await self.api.searchNearbyPlace(
        params
      )
      if (nearby?.data?.data) {
        return nearby.data.data.filter((place) => {
          return place.id !== self.poiId
        }).map((place) => {
          const distanceM = place.distance * 100000
          const distanceDisplay = distanceM < 1 ? '< 1' : distanceM.toFixed(1)

          return {
            ...place, distanceDisplay: distanceDisplay
          }
        })
      } else {
        return []
      }
    },
    async getOtherSourceDetail () {
      const self = this
      if (self.isOvmPoi) {
        const params = {
          id: self.$route.params.poiId,
          locale: self.$i18n.locale
        }
        const result = await self.api.getPoiOtherSourceDetail(params)
        const { data } = result.data
        const placeData = data ? data[0] : false
        if (placeData) {
          return {
            ...placeData,
            location: {
              latitude: placeData.lat,
              longitude: placeData.lon
            },
            iconfile: process.env.VUE_APP_LONGDO_MAP_HD_ICON.replace('2x', '4x') + placeData.icon,
            tags: placeData.tag,
            otherSourceTelNum: placeData.tel
          }
        }
      } else if (self.isOsmPoi) {
        const params = {
          id: self.$route.params.poiId
        }
        const result = await self.api.getPlaceInfo(params)
        const placeData = result.data?.data
        if (placeData) {
          return {
            ...placeData,
            otherSourceTelNum: placeData.telephone
          }
        }
      }
      return null
    },
    async getDetail () {
      const self = this
      if (!self.isLongdoPoi && !self.isGeocoding && !self.isReverseGeocoding &&
      !self.isOsmPoi && !self.isOvmPoi) {
        return false
      }
      self.isLoading = true
      self.placeDetail = null
      try {
        let detail = null
        if (self.isLongdoPoi) {
          detail = await self.getLongdoPoiDetail()
        } else if (self.isGeocoding) {
          detail = await self.getGeocodingDetail()
        } else if (self.isReverseGeocoding) {
          detail = await self.getReverseGeocodingDetail()
        } else {
          detail = await self.getOtherSourceDetail()
        }
        if (detail) {
          const nearbyPlaceList = await self.getNearbyPlace(detail.location.latitude, detail.location.longitude)
          self.placeDetail = { ...detail, nearbyPlaceList: nearbyPlaceList }
        }
        self.$nextTick(() => {
          self.$refs['place-card'].scrollTop = 0
          self.setTelLinkMobile()
          self.isLoading = false
        })
      } catch (err) {
        console.warn(err)
        self.isLoading = false
        return false
      }
    },
    setTelLinkMobile () {
      const self = this
      if (self.isOnMobileApp) {
        const mobileAppLinkList = document.querySelectorAll('.mobile-app-phone')
        mobileAppLinkList.forEach((elem) => {
          elem.addEventListener('click', () => { self.onClickUrl(elem.dataset.tel) })
        })
      }
    },
    onClickClose () {
      const self = this
      self.isShowSharePlaceWidget = false
      self.$router.back()
    },
    onClickTag (tag) {
      const self = this
      self.$router.push({
        name: 'Main',
        query: {
          search: `tag: ${tag}`
        }
      })
    },
    async onClickFav () {
      const self = this
      if (self.isUpdatingFav) {
        return false
      }
      self.isUpdatingFav = true
      if (self.isAuthenticated) {
        let userSession = await self.utility.getUserSession()
        userSession = userSession || self.userData
        const username = userSession.name || userSession.username
        const ldtoken = userSession.longdousertoken
        const response = await self.api.updateFav({
          username,
          ldtoken,
          value: 1,
          ooiid: self.poiId
        })
        if (response.data.result.rs_code === '1') {
          self.placeDetail.marked_as_favorite = true
        }
        self.$root.$emit('getMyPlace')
      } else {
        self.$root.$emit('showLongdoLoginForm')
      }
      self.isUpdatingFav = false
    },
    async onClickUnfav () {
      const self = this
      if (self.isUpdatingFav) {
        return false
      }
      self.isUpdatingFav = true
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      const username = userSession.name || userSession.username
      const ldtoken = userSession.longdousertoken
      const response = await self.api.updateFav({
        username,
        ldtoken,
        value: 0,
        ooiid: self.poiId
      })
      if (response.data.result.rs_code === '1') {
        self.placeDetail.marked_as_favorite = false
      }
      self.$root.$emit('getMyPlace')
      self.isUpdatingFav = false
    },
    onClickDirection () {
      const self = this
      self.$parent.$emit('routeTo', {
        lat: self.lat,
        lon: self.lon
      })
    },
    onClickShare () {
      const self = this
      if (typeof window.navigator !== 'undefined') {
        if (window.navigator.share) {
          window.navigator.share({
            url: self.shareUrl,
            title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
          })
        } else {
          self.isShowSharePlaceWidget = true
        }
      } else {
        self.isShowSharePlaceWidget = true
      }
    },
    async onClickEdit () {
      const self = this
      const userSession = await self.utility.getUserSession()
      if (self.isLongdoMapV3) {
        window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}p/${self.$route.params.poiId}/edit?map=v2`
      } else {
        self.$router.push({
          name: 'EditPlace',
          params: {
            poiId: self.$route.params.poiId,
            userData: userSession || self.userData
            // userData: self.longdoComAPI.getUserInfo() || self.userData
          }
        })
      }
    },
    onClickDelete () {
      const self = this
      self.requestDelete = true
    },
    cancelDeletePlace () {
      const self = this
      self.requestDelete = false
    },
    async deletePlace () {
      const self = this
      self.requestDelete = false
      self.isDeleting = true

      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData || self.user
      const username = userSession.name || userSession.username
      const ldtoken = userSession.longdousertoken
      const response = await self.api.deletePlace({
        username,
        ldtoken,
        ooiid: self.poiId
      })
      if (response.data.result.rs_code === 1) {
        self.$router.replace({
          name: 'Main',
          params: {},
          query: {},
          hash: ''
        })
      }
      self.isShowActionList = false
      self.isDeleting = false
      self.$root.$emit('getMyPlace')
    },
    async onClickReport () {
      const self = this
      const userSession = await self.utility.getUserSession()
      if (!userSession && self.isOnMobileApp) {
        self.$root.$emit('showLongdoLoginForm', {
          success: () => {
            window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}p/${self.$route.params.poiId}/report?map=v2`
          }
        })
      } else {
        if (self.isLongdoMapV3 && self.product !== 'traffic') {
          window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}p/${self.$route.params.poiId}/report?map=v2`
        } else {
          self.$router.push({
            name: 'ReportPlace',
            params: {
              poiId: self.$route.params.poiId,
              userData: userSession || self.userData
            // userData: self.longdoComAPI.getUserInfo() || self.userData
            }
          })
        }
      }
    },
    showPlaceCard () {
      const self = this
      self.isAnimate = true
      setTimeout(() => {
        self.isAnimate = false
        self.top = '62%'
        self.touchstartClientY = null
        self.thumbnailHeight = self.width > self.mobileMaxSize ? `${self.staticThumbnailHeight}px` : 'MID'
        self.step = self.width > self.mobileMaxSize ? 'FULL' : 'MID'
      }, 300)
    },
    onClickBus (bus) {
      const self = this
      self.$router.push({
        name: 'Place',
        params: {
          poiId: bus.id
        }
      })
    },
    gotoParentPlaceInfo () {
      const self = this
      const poiId = self.placeDetail?.parent_ooiid || ''
      if (poiId) {
        self.$router.push({
          name: 'Place',
          params: {
            poiId: poiId
          }
        })
      }
    },
    calImageSize () {
      const self = this
      const itemsPerRow = self.width <= self.mobileMaxSize ? 6 : 4
      const gap = 0
      self.imageSize = (self.$refs.body.clientWidth - (gap * (itemsPerRow + 1))) / itemsPerRow
    },
    onPreviewImage (i) {
      const self = this
      self.$root.$emit('onPreviewDisplay', {
        imageIndex: i,
        images: self.imageList
      })
    },
    onClickAddImage () {
      const self = this
      let userSession = self.longdoAccount.getUserSession()
      userSession = userSession || self.userData
      if (!userSession) {
        self.$root.$emit('showLongdoLoginForm')
      } else {
        self.$refs['add-image-input'].click()
      }
    },
    onDeleteImage (index) {
      const self = this
      self.uploadImageList.splice(index, 1)
    },
    onClickCancel () {
      const self = this
      self.resetUploadImage()
    },
    resetUploadImage () {
      const self = this
      self.uploadImageList = []
      self.isUploadImage = false
      if (self.$refs['add-image-input']) {
        self.$refs['add-image-input'].value = ''
      }
    },
    async onUploadImage () {
      const self = this
      self.isUploading = true
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      const username = (userSession || {}).name || undefined
      const ldtoken = (userSession || {}).longdousertoken || undefined
      // wait for multiple upload -> remove [0]
      const data = {
        username: username,
        ldtoken: ldtoken,
        ooiid: self.placeDetail.id,
        image: self.uploadImageList[0]
      }

      const uploadResult = await self.api.uploadImagePlace(data)
      if (uploadResult?.data?.result?.rs_code === '1') {
        // show toast success
        self.$root.$emit('showMessageToast', self.$t('placeInfo.uploadImageComplete'))
      } else {
        // show upload error
        self.$root.$emit('showMessageToast', self.$t('placeInfo.uploadImageError'))
      }

      self.resetUploadImage()
      self.isUploading = false
    },
    manageImageList () {
      const self = this
      self.isUploadImage = true
      const imageFileList = self.$refs['add-image-input'].files

      const loadImage = (index) => {
        return (e) => {
          self.utility.resizeImage(e.target.result, 2048, 2048, self.uploadImageList[index].orientation, (originalUrl) => {
            self.uploadImageList[index].original = originalUrl
          }, 0.8)
          self.utility.resizeImage(e.target.result, self.previewImageMinWidth * 2, self.previewImageMinWidth * 2, self.uploadImageList[index].orientation, (previewUrl) => {
            self.uploadImageList[index].preview = previewUrl
          }, 0.8)
        }
      }

      const orientationCallback = function (index) {
        return (orientation) => {
          self.uploadImageList[index].orientation = orientation
          const reader = new FileReader()
          reader.onload = loadImage(index)
          reader.readAsDataURL(self.uploadImageList[index].file)
        }
      }

      for (let i = 0; i < imageFileList.length; i++) {
        if (imageFileList[i]) {
          self.uploadImageList.push({
            file: imageFileList[i],
            original: '',
            preview: '',
            comment: '',
            orientation: -1
          })
          orientationCallback(self.uploadImageList.length - 1)(self.uploadImageList[self.uploadImageList.length - 1].orientation)
        }
      }

      self.$refs['add-image-input'].value = ''
    },
    formatPhoneNumber (data) {
      const self = this
      const phoneNumberExt = (moreData) => {
        if (moreData.ext !== '' && moreData.tel_no_to !== '') {
          const hasExtWord = moreData.ext.indexOf('ต่อ') !== -1 || moreData.ext.indexOf('Ext') !== -1
          if (hasExtWord) {
            return '-' + moreData.tel_no_to + ' ' + moreData.ext
          }
          return `-${moreData.tel_no_to} ${self.$t('telInput.ext')} ${moreData.ext}`
        }
        if (moreData.ext !== '') {
          const hasExtWord = moreData.ext.indexOf('ต่อ') !== -1 || moreData.ext.indexOf('Ext') !== -1
          //  มีต่อในเบอร์
          if (hasExtWord) {
            return ` ${moreData.ext}`
          } else {
            // ไม่มีต่อ
            return ` ${self.$t('telInput.ext')} ${moreData.ext}`
          }
        }
        if (moreData.tel_no_to !== '') {
          return '-' + moreData.tel_no_to
        }
        return ''
      }

      const telFormatter = (tel, type) => {
        switch (type) {
          case 'phone':
            return `${tel}`.replace(/(\d)(?=(\d{4})+$)/g, '$1 ')
          case 'mobile':
            return `${tel}`.replace(/^(\d{2})(\d{3})(\d{4})$/, '$1 $2 $3')
        }
      }

      const phoneNumberLink = (name, link) => {
        if (self.isOnMobileApp) {
          return `<a data-tel="${link.href || ''}" class='mobile-app-phone'>${name}</a>`
        } else {
          return `<a href="${link.href || ''}">${name}</a>`
        }
      }
      const formatter = (value, rawData) => {
        value = `${value}`.split('tel:')[1]
        const isMobileNumber = ['8', '9', '6']
        const extractRegion = `${value}`.split('+66')
        const phoneNumberOnly = extractRegion[1] || ''
        if (phoneNumberOnly.length > 2) {
          // Check is tel or mobile phone
          if (isMobileNumber.indexOf(phoneNumberOnly[0]) === -1) {
            if (phoneNumberOnly[0] === '2') {
              const bangkoktel = '0' + phoneNumberOnly[0] + ' ' + phoneNumberOnly.substring(1, phoneNumberOnly.length)
              return phoneNumberLink(telFormatter(bangkoktel, 'phone'), rawData) + phoneNumberExt(rawData)
            } else {
              const otherTel = '0' + phoneNumberOnly.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
              return phoneNumberLink(telFormatter(otherTel, 'phone'), rawData) + phoneNumberExt(rawData)
            }
          // Mobile phone
          } else {
            const otherCase = phoneNumberOnly.replaceAll(' ', '')
            if (otherCase.length <= 8) {
              return `<a href="tel:${otherCase}" style="color:gray">${'0' + otherCase}</a>`
            } else {
              return phoneNumberLink('0' + telFormatter(otherCase, 'mobile'), rawData)
            }
          }
        }
        // Other case return defualt display T^T
        return phoneNumberLink(value, rawData)
      }

      if (data.href) {
        return formatter(data.href, data)
      } else {
        data.href = 'tel:' + data.main
        return formatter(data.href, data)
      }
    }
  },
  watch: {
    userData (newValue) {
      const self = this
      self.isAuthenticated = !!newValue
      self.$nextTick(async () => {
        if (self.poiId) {
          await self.getDetail()
        } else {
          self.placeDetail = null
        }
      })
    },
    isUploading () {
      const self = this
      self.$nextTick(async () => {
        if (!self.isUploading) {
          await self.getDetail()
        }
      })
    },
    isHide () {
      const self = this
      self.showPlaceCard()
    },
    poiId: {
      immediate: true,
      handler () {
        const self = this
        self.$nextTick(async () => {
          if (self.poiId) {
            await self.getDetail()
          } else {
            setTimeout(() => {
              self.placeDetail = null
            }, 500)
          }
        })
      }
    },
    step: {
      immediate: true,
      handler (newValue) {
        const self = this
        self.$emit('changeStep', newValue)
      }
    },
    '$route' (to, from) {
      const self = this
      self.resetUploadImage()
      if (to.params?.poiId?.slice(0, 4) === 'RGCP' && from.params?.poiId?.slice(0, 4) === 'RGCP' &&
      (to.query.lat !== from.query.lat || to.query.lon !== from.query.lon)) {
        self.$nextTick(async () => {
          await self.getDetail()
        })
      }
    },
    '$i18n.locale' () {
      const self = this
      self.$nextTick(async () => {
        if (self.isReverseGeocoding || self.isOvmPoi) {
          await self.getDetail()
        } else {
          self.isLoading = true
          self.placeDetail.nearbyPlaceList = await self.getNearbyPlace(self.placeDetail.location.latitude, self.placeDetail.location.longitude)
          self.isLoading = false
        }
      })
    }
  },
  filters: {
    phoneFormater: function (value) {
      var phoneNumberRAW = `${value}`.trim()
      if (phoneNumberRAW.indexOf('+') !== -1) {
        const extractPhoneByRegion = phoneNumberRAW.split(/(\+\d{2})/).filter(Boolean)
        const afterRegionNo = extractPhoneByRegion[1].replaceAll(' ', '')
        const _addedZero = '0' + extractPhoneByRegion[1]
        switch (afterRegionNo.length) {
          case 8:
            return `(${extractPhoneByRegion[0]}) ${_addedZero.replace(/^(\d{2})(\d{3})(\d{4})$/, '$1 $2 $3')}`
          case 9:
            return `(${extractPhoneByRegion[0]}) ${_addedZero.replaceAll(' ', ' ')}`
        }

        // Other case
        return phoneNumberRAW
      }
      return value
    }
  }
}
</script>

<style scoped lang="scss">
// Desktop
  .loading-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
    > .background {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-color: $grey;
      opacity: 0.5;
    }
    > img {
      width: 36px;
      height: 36px;
      z-index: 20;
    }
  }
.place-card-map3 {

  #input-for-copy {
    position: fixed;
    top: 9999px;
    left: 9999px;
  }
  .place-footer {
    left: 0 !important;
    background-color: $darkPrimary;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    i {
      color: white;
    }
  }

  .image-list {
    flex-wrap: wrap;
    > .add-image {
      border: 2px solid $primary;
      border-radius: 10px;
      margin-right: 12px;
      margin-bottom: 12px;
      padding: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: white;
      font-family: Prompt;
      color: $primary;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      > i {
        color: $primary;
        font-size: 24px !important;
      }

      > div {
         color: $primary;
      }
    }
    .image {
      margin-right: 12px;
      margin-bottom: 12px;
      &:hover{
        cursor: pointer;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  // Mobile V3
  @media only screen and (max-width: $mobileMaxSize) {
    width: 100%;
    height: calc(100% - #{$toolBarHeight});
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: white;
    z-index: 80;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px 10px 0 0;
    transition: height 0.3s ease 0s;
    overflow: hidden;

    &.hide {
      top: 100% !important;
    }

    .head {
      background-color: $darkPrimary;
      padding: 12px 6px 0 6px;
      height: 72px;
      position: relative;
      box-sizing: border-box;
      border-bottom: 4px solid $lightPrimary;

      &::before {
        content: " ";
        width: 28px;
        height: 4px;
        border-radius: 3px;
        background-color: $lightPrimary;
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translate(-50%, 0%);
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
      }

      // &::after {
      //   content: " ";
      //   width: 36px;
      //   height: 42px;
      //   background-image: linear-gradient(90deg, rgba(0, 116, 229, 0) 0%, rgba(0, 116, 229, 1) 100%);
      //   background-color: transparent;
      //   position: absolute;
      //   top: 0;
      //   right: 46px;
      //   pointer-events: none;
      // }

      &.traffic::after {
        background-image: linear-gradient(90deg, rgba(22, 166, 104, 0) 0%, rgba(22, 166, 104, 1) 100%);
      }

      button.close {
        position: absolute;
        top: 24px;
        left: 12px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        width: 24px;
        height: 24px;
        border: none;

        i {
          color: $darkPrimary;
          font-size: 20px;
        }
      }

      .type {
        width: 36px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translate(0%, calc(6px - 50%));

        img {
          max-width: 36px;
          max-height: 36px;
        }
      }

      .name {
        font-family: 'Prompt',serif;
        color: white;
        font-size: 20px;
        width: calc(100% - 48px - 6px - 24px - 12px);
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 24px 0 0;
        margin: 0 auto 0 48px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .sub-name {
        font-family: 'Prompt',serif;
        color: rgba(255, 255, 255, 0.75);
        width: calc(100% - 48px - 6px - 24px - 12px);
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 24px 0 0;
        margin: 0 auto 0 48px;
        cursor: pointer;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &.animate {
      transition: top 0.3s ease 0s,
        height 0.3s ease 0s;

      .thumbnail {
        transition: height 0.2s ease 0s;
      }
    }

    &.draging {
      z-index: 160;
    }

    &.full {
      z-index: 160;
      height: 100%;
      border-radius: 0;
      overflow-y: auto;

      .head {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    &.empty {
      .head {
        background-color: white;
        border-bottom: 4px solid white;

        &::after {
          background-image: none;
        }

        button.close {
          background-color: $extraLightGrey;

          i {
            color: $grey;
          }
        }
      }
    }

    .body {
      width: 100%;
      height: calc(100% - 72px);
      overflow: hidden;
      overflow-y: auto;

      .thumbnail {
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: none;
        background-color: $extraLightGrey;
      }

      .action-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        button {
          flex: 1;
          height: 48px;
          border: none;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;

          ::v-deep i {
            color: $tangerine;
            font-size: 24px;
          }

          > div {
              display: flex;

              > i {
                &.border {
                  position:absolute;
                }
                &.animation-fav {
                  animation: favplace 2s ease-in-out infinite;
                  -webkit-animation: favplace 2s ease-in-out infinite;
                }

                &.animation-unfav {
                    animation: unfavplace 2s ease-in-out infinite;
                    -webkit-animation: unfavplace 2s ease-in-out infinite;
                }
                @keyframes favplace {
                  0% {
                      transform: scale(0.05);
                      -webkit-transform: scale(0.05);
                  }
                  100% {
                      transform: scale(1.0);
                      -webkit-transform: scale(1.0);
                  }
                }
                @keyframes unfavplace {
                  0% {
                      transform: scale(1.0);
                      -webkit-transform: scale(1.0);
                  }
                  100% {
                      transform: scale(0.05);
                      -webkit-transform: scale(0.05);
                  }
                }
              }
          }
        }
      }

      .notice {
        padding: 3px 6px;
        border-radius: 3px;
        font-family: 'Prompt',serif;
        font-size: 14px;
        background-color: $primary;
        color: white;
        margin: 12px 12px 0 12px;
        text-align: center;

        &.new-status {
          background-color: $tangerine;
        }

        &.obsoleted-status {
          background-color: $red;
        }

        &.deleted-status {
          background-color: $red;
        }

        &.rejected-status {
          background-color: $red;
        }
      }

      .promptpai {
        padding: 0 12px 0 12px;
        margin: 0 0px -6px 0px;
      }

      .field {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 9px 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        .field-icon {
          width: 24px;
          height: 24px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          position: relative;
          color: $lightGrey;

          i {
            font-size: 24px;
            color: $lightGrey;
          }

          .image {
            background-position: center;
            background-size: contain;
            filter: grayscale(1);
            width: 24px;
            height: 24px;
          }
        }

        .content {
          user-select: text;
          -webkit-user-select: text;
          width: calc(100% - 36px);
          font-size: 16px;
          padding-left: 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          line-height: 1.6;
          &.nearby {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 6px;
            >.nearby-place-list {
            display: flex;
            flex-direction: column;
            gap:6px;
            width: fit-content;
              >.nearby-place {
                gap: 6px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                > img {
                  width: 24px;
                  height: 24px
                }
                > span {
                  text-wrap: pretty;

                  > a {
                    cursor: pointer;
                  }
                }
              }
            }
            >.see-more-nearby {
              display: flex;
              gap: 6px;
              width: 100%;
              border-top: 1px solid rgba(0, 0, 0, 0.05);
              align-items: center;
              padding: 6px 0px;

              > a {
                cursor: pointer;
              }

              > i {
                color: $lightGrey;
                width: 24px;
                height: 24px;
                font-size: 24px;
              }
            }
          }

          > button {
            border: none;
            background: transparent;
            display: flex;
            margin-left: 6px;
          }
          ::v-deep pre {
            text-wrap: normal;
          }

          .col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          /deep/ * {
          user-select: text;
          -webkit-user-select: text;
            font-size: 16px;
            max-width: 100%;
          }

          /deep/ a {
            color: $primary;
            text-decoration: none;
          }

          /deep/ .contact-remark {
            font-size: 12px;
            color: $lightGrey;
          }

          /deep/ .working-hour-remark {
            margin-top: 16px;
            font-size: 16px;
            color: $lightGrey;
          }

          .link {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;

            i {
              font-size: 16px;
              margin-left: 3px;
              color: $primary;
            }
          }

          .working-hours {
            .day {
              width: 90px;
            }

            .start,
            .end {
              width: auto;
            }

            .to {
              padding: 0px 12px;
            }
            .close {
              color: red
            }
          }

          .tel-content {
            display: flex;
            flex-direction: column;

            .tel {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 5px;
            }
        }

          .bus-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -12px;

            .bus {
              box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
              padding: 2px 6px;
              border-radius: 3px;
              margin: 0 12px 12px 0;
              font-family: 'Prompt',serif;
              font-size: 16px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .tag-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -10px;

            .tag {
              box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
              padding: 2px 10px;
              border-radius: 15px;
              margin: 0 10px 10px 0;
              font-family: 'Prompt',serif;
              font-size: 13px;
              color: $primary;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &.connector-list {
          ul {
            margin: 0;
            padding: 0 0 0 0;

            div {
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }

              .total-available {
                background-color: rgb(5, 200, 155);
                color: white;
                padding: 0 12px;
                border-radius: 12px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
    .date {
      font-size: 12px;
      color: $lightGrey;
      padding: 12px;
      text-align: right;
    }
  }

  //  Desktop
  @media only screen and (min-width: $mobileMaxSize) {
    // transform: translate(0%, -95%);
    transform: translate(0%, 80px);
    width: 460px;
    height: calc(60% - (#{$toolBarHeight} - 4px));
    position: fixed;
    left: 10px;
    top: 22px !important;
    background-color: white;
    z-index: 110;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: height 0.3s ease 0s;
    overflow: hidden;

    .ldmap-loading-ripple {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }

    .ldmap-loading-ripple div {
      position: absolute;
      border: 4px solid $primary;
      opacity: 1;
      border-radius: 50%;
      animation: ldmap-loading-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .ldmap-loading-ripple div:nth-child(2) {
      animation-delay: -0.5s;
    }

    @keyframes ldmap-loading-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }

      4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }

      5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
      }

      100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
      }
    }

    &.hide {
      left: -100% !important;
    }

    .head {

      background-color: $darkPrimary;
      padding: 6px 6px 0 6px;
      height: 72px;
      position: relative;
      box-sizing: border-box;
      border-bottom: 4px solid $lightPrimary;

      &::before {
        display: none;
        content: " ";
        width: 28px;
        height: 4px;
        border-radius: 3px;
        background-color: $lightPrimary;
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translate(-50%, 0%);
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
      }

      button.close {
        position: absolute;
        top: 12px;
        left: 12px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        width: 24px;
        height: 24px;
        border: none;

        i {
          color: $darkPrimary;
          font-size: 20px;
        }

        i.place-close {
          display: none;
        }
      }

      .type {
        width: 36px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 40%;
        right: 12px;
        transform: translate(0%, calc(6px - 50%));

        img {
          max-width: 36px;
          max-height: 36px;
        }
      }

      .name {
        user-select: text !important;
        font-family: 'Prompt',serif;
        color: white;
        font-size: 20px;
        width: calc(100% - 48px - 6px - 24px - 25px);
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 24px 0 0;
        margin: 0 auto 0 48px;
        cursor: pointer;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .sub-name {
        user-select: text !important;
        font-family: 'Prompt',serif;
        color: rgba(255, 255, 255, 0.75);
        width: calc(100% - 48px - 6px - 24px - 25px);
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 24px 0 0;
        margin: 0 auto 0 48px;
        cursor: pointer;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    // &.animate {
    //   transition: top 0.3s ease 0s,
    //     height 0.3s ease 0s;

    //   .thumbnail {
    //     transition: height 0.2s ease 0s;
    //   }
    // }

    &.draging {
      z-index: 160;
    }

    &.full {
      border-radius: 10px;
      transform: translate(0%, 102px);
      width: 460px;
      top: 0 !important;
      height: calc(100% - 102px - 64px);
      z-index: 190;
      overflow-y: auto;
      .head {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    &.empty {
      .head {
        background-color: white;
        border-bottom: 4px solid white;

        &::after {
          background-image: none;
        }

        button.close {
          background-color: $extraLightGrey;

          i {
            color: $grey;
          }
        }
      }
    }

    .body {
      width: 100%;
      height: calc(100% - 72px);
      overflow: hidden;
      overflow-y: auto;

      .thumbnail {
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $extraLightGrey;
      }

      .action-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        button {
          flex: 1;
          height: 48px;
          border: none;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;

          ::v-deep i {
            color: $tangerine;
            font-size: 24px;
          }

          > div {
              display: flex;

              > i {
                &.border {
                  position:absolute;
                }
                &.animation-fav {
                  animation: favplace 2s ease-in-out infinite;
                  -webkit-animation: favplace 2s ease-in-out infinite;
                }

                &.animation-unfav {
                    animation: unfavplace 2s ease-in-out infinite;
                    -webkit-animation: unfavplace 2s ease-in-out infinite;
                }
                @keyframes favplace {
                  0% {
                      transform: scale(0.05);
                      -webkit-transform: scale(0.05);
                  }
                  100% {
                      transform: scale(1.0);
                      -webkit-transform: scale(1.0);
                  }
                }
                @keyframes unfavplace {
                  0% {
                      transform: scale(1.0);
                      -webkit-transform: scale(1.0);
                  }
                  100% {
                      transform: scale(0.05);
                      -webkit-transform: scale(0.05);
                  }
                }
              }
          }
        }
      }

      .notice {
        padding: 3px 6px;
        border-radius: 3px;
        font-family: 'Prompt',serif;
        font-size: 14px;
        background-color: $primary;
        color: white;
        margin: 12px 12px 0 12px;
        text-align: center;

        &.new-status {
          background-color: $tangerine;
        }

        &.obsoleted-status {
          background-color: $red;
        }

        &.deleted-status {
          background-color: $red;
        }

        &.rejected-status {
          background-color: $red;
        }
      }

      .promptpai {
        padding: 0 12px 0 12px;
        margin: 0 0px -6px 0px;
      }

      .field.full-description .content{
          display: block;
          ::v-deep pre {
            text-wrap: normal;
          }
      }

      .field {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 9px 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        .field-icon {
          width: 24px;
          height: 24px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          position: relative;
          color: $lightGrey;

          i {
            font-size: 24px;
            color: $lightGrey;
          }

          .image {
            background-position: center;
            background-size: contain;
            filter: grayscale(1);
            width: 24px;
            height: 24px;
          }
        }

        .content {
          user-select: text;
          -webkit-user-select: text;
          width: calc(100% - 36px);
          font-size: 16px;
          padding-left: 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          line-height: 1.6;
          word-wrap: break-word;
          word-break: break-word;
          &.nearby {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 6px;
            >.nearby-place-list {
              display: flex;
              flex-direction: column;
              gap:6px;
              width: fit-content;

              >.nearby-place {
                gap: 6px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                > img {
                  width: 24px;
                  height: 24px
                }
                > span {
                  text-wrap: pretty;

                  > a {
                    cursor: pointer;
                  }
                }
              }
            }
            >.see-more-nearby {
              display: flex;
              gap: 6px;
              width: 100%;
              border-top: 1px solid rgba(0, 0, 0, 0.05);
              align-items: center;
              padding: 6px 0px;

              > a {
                cursor: pointer;
              }

              > i {
                color: $lightGrey;
                width: 24px;
                height: 24px;
                font-size: 24px;
              }
            }
          }

          > button {
            border: none;
            background: transparent;
            display: flex;
            margin-left: 6px;
          }

          ::v-deep pre {
            text-wrap: normal;
          }

          .col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          /deep/ * {
            user-select: text;
            -webkit-user-select: text;
            font-size: 16px;
            max-width: 100%;
          }

          /deep/ a {
            color: $primary;
            text-decoration: none;
          }

          /deep/ .contact-remark {
            font-size: 12px;
            color: $lightGrey;
          }

          /deep/ .working-hour-remark {
            margin-top: 16px;
            font-size: 16px;
            color: $lightGrey;
          }

          .link {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;

            i {
              font-size: 16px;
              margin-left: 3px;
              color: $primary;
            }
          }

          .working-hours {
            .day {
              width: 90px;
            }

            .start,
            .end {
              width: auto;
            }

            .to {
              padding: 0px 12px;
            }
            .close {
              color: red
            }
          }

          .tel-content {
            display: flex;
            flex-direction: column;

            .tel {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 5px;
            }
        }

          .bus-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -12px;

            .bus {
              box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
              padding: 2px 6px;
              border-radius: 3px;
              margin: 0 12px 12px 0;
              font-family: 'Prompt',serif;
              font-size: 16px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .tag-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -10px;

            .tag {
              cursor: pointer;
              box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
              padding: 2px 10px;
              border-radius: 15px;
              margin: 0 10px 10px 0;
              font-family: 'Prompt',serif;
              font-size: 13px;
              color: $primary;

              &:hover {
                color: $darkPrimary;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &.connector-list {
          ul {
            margin: 0;
            padding: 0 0 0 0;

            div {
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }

              .total-available {
                background-color: rgb(5, 200, 155);
                color: white;
                padding: 0 12px;
                border-radius: 12px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
    .date {
      font-size: 12px;
      color: $lightGrey;
      padding: 12px;
      text-align: right;
    }

    .place-footer {
      p {
        color: rgba(0, 0, 0, 0.2);
      }

      position: absolute;
      bottom: 0;
      left: 10px;
    }
  }
}

// Mobile v2
.place-card {
  width: 100%;
  height: calc(100% - #{$toolBarHeight});
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: white;
  z-index: 80;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0 0;
  transition: height 0.3s ease 0s;
  overflow: hidden;

  #input-for-copy {
    position: fixed;
    top: 9999px;
    left: 9999px;
  }

  &.animate {
    transition: top 0.3s ease 0s,
      height 0.3s ease 0s;

    .thumbnail {
      transition: height 0.2s ease 0s;
    }
  }

  &.draging {
    z-index: 160;
  }

  &.full {
    z-index: 160;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;

    .head {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &.hide {
    top: 100% !important;
  }

  &.empty {
    .head {
      background-color: white;
      border-bottom: 4px solid white;

      &::after {
        background-image: none;
      }

      button.close {
        background-color: $extraLightGrey;

        i {
          color: $grey;
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .head {
    background-color: $darkPrimary;
    padding: 12px 6px 0 6px;
    height: 72px;
    position: relative;
    box-sizing: border-box;
    border-bottom: 4px solid $lightPrimary;

    &::before {
      content: " ";
      width: 28px;
      height: 4px;
      border-radius: 3px;
      background-color: $lightPrimary;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translate(-50%, 0%);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
    }

    &::after {
      content: " ";
      width: 36px;
      height: 42px;
      background-image: linear-gradient(90deg, rgba(0, 116, 229, 0) 0%, rgba(0, 116, 229, 1) 100%);
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 46px;
      pointer-events: none;
    }

    &.traffic::after {
      background-image: linear-gradient(90deg, rgba(22, 166, 104, 0) 0%, rgba(22, 166, 104, 1) 100%);
    }

    button.close {
      position: absolute;
      top: 24px;
      left: 12px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 18px;
      width: 24px;
      height: 24px;
      border: none;
      background-color: $lightPrimary;

      i {
        color: $darkPrimary;
        font-size: 20px;
      }
    }

    .type {
      width: 36px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0%, calc(6px - 50%));

      img {
        max-width: 36px;
        max-height: 36px;
      }
    }

    .name {
      font-family: 'Prompt',serif;
      color: white;
      font-size: 20px;
      width: calc(100% - 48px - 6px - 24px - 12px);
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      box-sizing: border-box;
      padding: 0 24px 0 0;
      margin: 0 auto 0 48px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .sub-name {
      font-family: 'Prompt',serif;
      color: rgba(255, 255, 255, 0.75);
      width: calc(100% - 48px);
      overflow: hidden;
      white-space: nowrap;
      padding: 0 0 0 0;
      margin: 0 auto 0 48px;
      cursor: pointer;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .body {
    width: 100%;
    height: calc(100% - 72px);
    overflow: hidden;
    overflow-y: auto;

    .thumbnail {
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: background-image 10s ease-in-out;
      background-color: $extraLightGrey;
    }

    .action-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      button {
        flex: 1;
        height: 48px;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;

        ::v-deep i {
            color: $tangerine;
            font-size: 24px;
          }

          > div {
              display: flex;

              > i {
                &.border {
                  position:absolute;
                }
                &.animation-fav {
                  animation: favplace 2s ease-in-out infinite;
                  -webkit-animation: favplace 2s ease-in-out infinite;
                }

                &.animation-unfav {
                    animation: unfavplace 2s ease-in-out infinite;
                    -webkit-animation: unfavplace 2s ease-in-out infinite;
                }
                @keyframes favplace {
                  0% {
                      transform: scale(0.05);
                      -webkit-transform: scale(0.05);
                  }
                  100% {
                      transform: scale(1.0);
                      -webkit-transform: scale(1.0);
                  }
                }
                @keyframes unfavplace {
                  0% {
                      transform: scale(1.0);
                      -webkit-transform: scale(1.0);
                  }
                  100% {
                      transform: scale(0.05);
                      -webkit-transform: scale(0.05);
                  }
                }
              }
          }
      }
    }

    .notice {
      padding: 3px 6px;
      border-radius: 3px;
      font-family: 'Prompt',serif;
      font-size: 14px;
      background-color: $primary;
      color: white;
      margin: 12px 12px 0 12px;
      text-align: center;

      &.new-status {
        background-color: $tangerine;
      }

      &.obsoleted-status {
        background-color: $red;
      }

      &.deleted-status {
        background-color: $red;
      }

      &.rejected-status {
        background-color: $red;
      }
    }

    .promptpai {
      padding: 0 12px 0 12px;
      margin: 0 0px -6px 0px;
    }

    .field {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 9px 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .field-icon {
        width: 24px;
        height: 24px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        position: relative;
        color: $lightGrey;

        i {
          font-size: 24px;
          color: $lightGrey;
        }

        .image {
          background-position: center;
          background-size: contain;
          filter: grayscale(1);
          width: 24px;
          height: 24px;
        }
      }

      .content {
        user-select: text;
        -webkit-user-select: text;
        width: calc(100% - 36px);
        font-size: 16px;
        padding-left: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.6;
        word-wrap: break-word;
        word-break: break-word;

        &.nearby {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 6px;

            >.nearby-place-list {
              display: flex;
              flex-direction: column;
              gap:6px;
              width: fit-content;

              >.nearby-place {
                gap: 6px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                > img {
                  width: 24px;
                  height: 24px
                }
                > span {
                  text-wrap: pretty;

                  > a {
                    cursor: pointer;
                  }
                }
              }
           }

           >.see-more-nearby {
            display: flex;
            gap: 6px;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            align-items: center;
            padding: 6px 0px;

            > a {
              cursor: pointer;
            }

            > i {
              color: $lightGrey;
              width: 24px;
              height: 24px;
              font-size: 24px;
            }
          }

        }

        > button {
          border: none;
          background: transparent;
          display: flex;
          margin-left: 6px;
        }

        ::v-deep pre {
            text-wrap: normal;
          }

        .col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        /deep/ * {
          user-select: text;
          -webkit-user-select: text;
          font-size: 16px;
          max-width: 100%;
        }

        /deep/ a {
          color: $primary;
          text-decoration: none;
        }

        /deep/ .contact-remark {
            font-size: 12px;
            color: $lightGrey;
        }

        /deep/ .working-hour-remark {
          margin-top: 16px;
          font-size: 16px;
          color: $lightGrey;
        }

        .link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;

          i {
            font-size: 16px;
            margin-left: 3px;
            color: $primary;
          }
        }

        .working-hours {
            .day {
              width: 90px;
            }

            .start,
            .end {
              width: auto;
            }

            .to {
              padding: 0px 12px;
            }
            .close {
              color: red
            }
          }

        .tel-content {
          display: flex;
          flex-direction: column;

          .tel {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
          }
        }

        .bus-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: -12px;

          .bus {
            box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
            padding: 2px 6px;
            border-radius: 3px;
            margin: 0 12px 12px 0;
            font-family: 'Prompt',serif;
            font-size: 16px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .tag-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: -10px;

          .tag {
            box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
            padding: 2px 10px;
            border-radius: 15px;
            margin: 0 10px 10px 0;
            font-family: 'Prompt',serif;
            font-size: 13px;
            color: $primary;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &.connector-list {
        ul {
          margin: 0;
          padding: 0 0 0 0;

          div {
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }

            .total-available {
              background-color: rgb(5, 200, 155);
              color: white;
              padding: 0 12px;
              border-radius: 12px;
              margin-right: 12px;
            }
          }
        }
      }
    }
    .date {
      font-size: 12px;
      color: $lightGrey;
      padding: 12px;
      text-align: right;
    }
  }
}
</style>
