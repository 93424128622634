<template>
  <div v-if="isLongdoMapV3" class="navigation-bar" :class="{hide: isHidingNavigationBar}">
    <div class="ldmap-header">
      <div class="nav-header">
        <div class="nav-title">
          <a :href="`https://${product}.longdo.com`">
            <img id="longdo-logo" :src="longdoMapLogo2X"
              :srcset="longdoMapLogo1X + ' 1x, ' + longdoMapLogo2X + ' 2x'"
              loading="lazy"
              alt="Longdo Map: Thailand &amp; World Map" title="Longdo Map: Thailand &amp; World Map"
            >
          </a>
        </div>
      </div>

      <div class="nav-links">
        <a :href="product === 'traffic' ? 'https://traffic.longdo.com/info' : 'https://map.longdo.com/longdomap'">
          &#8592; {{ $t('navigationBarDesktop.switchSite') }}
        </a>
        <div class="divider"></div>
        <a href="https://map.longdo.com/products" target="_blank">{{ $t('navigationSideBar.product') }}</a>
        <div class="divider"></div>
        <!-- <a href="#">My Longdo Map</a>
        <div class="divider"></div> -->
        <a v-if="!isLogin" @click="onLogin">{{ $t('navigationSideBar.loginAndRegister') }}</a>
        <a v-else @click="onShowSidebar">{{ displayName }}</a>
        <div class="divider"></div>
        <a class="ldmap-language" style="width: 30px; text-align: center;" @click="selectLanguage">
          <!-- {{ $i18n.locale === 'th' ? 'English' : 'ไทย' }} -->
          <img v-if="$i18n.locale === 'th'"  src="https://map.longdo.com/modules/contributes/localizer/flags/en.png" alt="th">
          <img v-else src="https://map.longdo.com/modules/contributes/localizer/flags/th.png" alt="en">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Settings } from 'luxon'
export default {
  name: 'NavigationBarDesktop',
  props: {
    map: {
      type: Object
    }
  },
  data () {
    return {
      uid: '',
      profilePicture: '',
      displayName: '',
      isLogin: false
    }
  },
  mounted () {
    const self = this
    self.$root.$on('loginComplete', self.onLoginComplete)
  },
  watch: {
    async $route (to, from) {
      const self = this
      if (from.name === 'CreatePlace' || from.name === 'EditPlace' || from.name === 'InfoPlace') {
        if (!self.isLogin) {
          const userSession = await self.utility.getUserSession()
          const user = userSession
          // const user = self.longdoComAPI.getUserInfo()
          if (user) {
            self.onLoginComplete(user)
          }
        }
      }
    }
  },
  methods: {
    selectLanguage () {
      const language = this.$i18n.locale === 'th' ? 'en' : 'th'
      const self = this
      self.$i18n.locale = language
      localStorage.lang = language
      Settings.defaultLocale = language
    },
    onLogin () {
      const self = this
      self.$root.$emit('showLongdoLoginForm')
    },
    onLoginComplete (user) {
      const self = this
      self.uid = user.uid
      self.profilePicture = user !== null ? (process.env.VUE_APP_LONGDO_ACCOUNT_PROFILE_IMAGE + user.uid) : ''
      self.displayName = user !== null ? user.display_name : ''
      self.isLogin = true
    },
    onShowSidebar () {
      const self = this
      self.$root.$emit('showNavigationSideBar')
    }
  },
  computed: {
    isHidingNavigationBar () {
      const self = this
      return self.$route.hash === '#navigation'
    }
  }
}
</script>

<style scoped lang="scss">
.navigation-bar {
  top:0;
  height: 30px;
  width: 100%;
  z-index: 190;
  background-color: $darkPrimary;
  position: absolute;

  &.hide {
    display: none !important;
  }
  .nav-header {
    display: inline;

    .nav-title {
      display: inline-block;
      font-size: 22px;
      color: #fff;
      padding: 4px 10px 0 10px;

      img{
        height: 24px;
      }
    }
  }

  .ldmap-language {
    img{

      height: 14px;
    }
  }

  .nav-btn {
    display: none;
  }

  .nav-links {
    display: flex;
    float: right;
    font-size: 18px;

    a {
      height: 30px;
      box-sizing: border-box;
      font-family: 'Prompt';
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 10px 1px 10px;
      text-decoration: none;
      color: #efefef;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }

      img {
        cursor: pointer;
      }
    }
  }

  .divider {
    margin-top: 5px;
    height: 20px;
    top:10%;
    bottom:10%;
    border-left: 2px solid rgba(175, 220, 255,1);
  }

  #nav-check {
    display: none;
  }
}

@media only screen and (max-width: $mobileMaxSize) {
  .navigation-bar {
    display: none;
  }
}
</style>
