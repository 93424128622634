<template>
  <div class="navigation">
    <div id="guide" v-if="currentTurnCode !== null">
      <div class="detail left">
        <div class="direction" :style="{
          backgroundImage: `url(${directionImagePath})`
        }"></div>
        <div class="distance" v-if="distanceDisplay">
          {{ distanceDisplay }}<span class="unit">{{ distanceUnit }}</span>
        </div>
      </div>
      <div class="detail right">
        {{ guideDetail }}
      </div>
      <div id="next-guide" v-if="nextTurnCode !== null && !isFinishNavigation">
        <div class="direction" :style="{
          backgroundImage: `url(${nextDirectionImagePath})`
        }"></div>
        {{ $t('routingPanel.nextTurn') }}
      </div>
    </div>
    <div id="map"></div>
    <div id="control">
      <button class="perspective-view ripple" @click="onClickReRoute" v-show="isShowReRouteButton">
        <i class="material-icons-round">refresh</i>
      </button>
      <button class="ripple" :class="{ active: isActiveSpeak }" @click="onClickSpeakButton">
        <i class="material-icons-round">{{ isActiveSpeak ? 'volume_up' : 'volume_off' }}</i>
      </button>
      <button class="perspective-view ripple" :class="{ active: isActivePerspectiveView }"
        @click="onClickPerspectiveButton">
        <i class="material-icons-round">grid_on</i>
      </button>
      <a class="longdo-map-logo" href="https://map.longdo.com/terms" target="_blank">
        <img src="https://api.longdo.com/map/images/logo.png" srcset="https://api.longdo.com/map/images/logo-2x.png 2x"
          alt="Longdo Map">
      </a>
    </div>
    <button id="re-center" class="ripple" @click="onClickReCenter" v-show="isShowReCenterButton">re-center</button>
    <button id="re-routing" class="ripple" v-show="isReRouting">Rerouting...</button>
    <div id="footer">
      <div class="summary" v-if="!isFinishNavigation">
        <div class="main">{{ totalDuration }}</div>
        <div class="sub">{{ totalDistance }} - {{ arrivalTime }}</div>
      </div>
      <button class="ripple view-all" @click="onClickViewAllButton" v-if="!isFinishNavigation">
        <i class="material-icons-round">route</i>
      </button>
      <button class="ripple exit-navi" @click="onClickExitNavi()" v-if="!isFinishNavigation">
        <i class="material-icons-round">logout</i>
      </button>
      <button class="ripple return-main" @click="returnToMainPage()" v-if="isFinishNavigation">
        {{ $t('routingPanel.finished') }}
      </button>
    </div>
    <!-- <iframe id="ttsiframe"></iframe> -->
    <audio controls id="tts-audio" />
  </div>
</template>

<script>
// import {
//   MOCK_GEOLOCATION,
//   DURATION_PER_MOCK_GEOLOCATION
// } from '@/navimock/mock2.js'
import { Settings } from 'luxon'
export default {
  name: 'Navigation',
  props: {
    meta: {
      type: Object
    },
    guide: {
      type: Array
    },
    path: {
      type: Array
    },
    mainMap: {
      type: Object
    },
    baseMap: {
      type: String
    }
  },
  computed: {
    directionImagePath () {
      const self = this
      // let turnCode = self.currentTurnCode > 3 ? 5 : self.currentTurnCode
      let turnCode = 5
      if (self.currentDistance < self.alertTurnMeterThreshold && self.nextTurnCode !== null && self.nextTurnCode < 4) {
        return `${self.baseUrl}img/turn/turn${self.nextTurnCode}@1x.png`
      }
      if (self.currentTurnCode === 6) {
        turnCode = 6
      }
      if (self.currentTurnCode === 9) {
        turnCode = 9
      }
      if (self.currentTurnCode === null) {
        turnCode = 9
      }
      return `${self.baseUrl}img/turn/turn${turnCode}@1x.png`
    },
    distanceDisplay () {
      const self = this
      if (self.currentTurnCode === 9) {
        return 0
      }
      if (self.currentTurnCode === null) {
        return 0
      }
      if (self.currentDistance < 10) {
        return Math.floor(self.currentDistance)
      } else if (self.currentDistance < 100) {
        return Math.floor(self.currentDistance / 10) * 10
      } else if (self.currentDistance < 1000) {
        return Math.floor(self.currentDistance / 100) * 100
      } else {
        return (self.currentDistance / 1000).toFixed(1)
      }
    },
    distanceUnit () {
      const self = this
      if (self.currentDistance < 10) {
        return self.$t('routingPanel.meter')
      } else if (self.currentDistance < 100) {
        return self.$t('routingPanel.meter')
      } else if (self.currentDistance < 1000) {
        return self.$t('routingPanel.meter')
      } else {
        return self.$t('routingPanel.kilometer')
      }
    },
    guideDetail () {
      const self = this
      if (self.currentDistance < self.alertTurnMeterThreshold && self.nextTurnCode !== null && self.nextTurnCode < 4) {
        return `${self.$t(`routingPanel.turnCode[${self.nextTurnCode}]`)} ${self.nextGuideName}`
      }
      if (self.currentTurnCode === null) {
        return `${self.currentGuideName}`
      }
      return `${self.$t(`routingPanel.turnCode[${self.currentTurnCode < 4 ? 4 : self.currentTurnCode}]`)} ${self.currentGuideName}`
    },
    guideDetailSpeak () {
      const self = this
      if (self.currentTurnCode === null) {
        return `${self.currentGuideName} ${self.distanceDisplay} ${self.distanceUnit}`
      }
      if (self.currentTurnCode < 4) {
        if (self.distanceUnit === self.$t('routingPanel.meter') && self.distanceDisplay <= self.alertTurnMeterThreshold) {
          return `${self.$t(`routingPanel.turnCode[${self.currentTurnCode}]`)} ${self.currentGuideName}`
        }
        return `${self.$t('routingPanel.turnCode[4]')} ${self.currentGuideName} ${self.distanceDisplay} ${self.distanceUnit}`
      }
      if (self.currentTurnCode === 9) {
        return `${self.$t(`routingPanel.turnCode[${self.currentTurnCode}]`)} ${self.currentGuideName}`
      }
      return `${self.$t(`routingPanel.turnCode[${self.currentTurnCode}]`)} ${self.currentGuideName} ${self.distanceDisplay} ${self.distanceUnit}`
    },
    nextDirectionImagePath () {
      const self = this
      let turnCode = self.nextTurnCode > 3 ? 5 : self.nextTurnCode
      if (self.currentDistance < self.alertTurnMeterThreshold && self.nextTurnCode !== null && self.nextTurnCode < 4) {
        turnCode = 5
      } else {
        if (self.nextTurnCode === 6) {
          turnCode = 6
        }
        if (self.nextTurnCode === 9) {
          turnCode = 9
        }
      }
      return `${self.baseUrl}img/turn/turn${turnCode}@1x.png`
    },
    totalDuration () {
      const self = this
      if ((self.guide || []).length < 1) {
        return ''
      }
      let totalDuration = 0
      self.guide.forEach(g => {
        totalDuration += g.guide.filter((o, i) => i >= self.currentPolylineIndex).map((o, i) => i === 0 ? Math.round(o.interval * 1.0 * self.currentDistance / o.distance) : o.interval).reduce((total, t) => (total + t))
      })
      return self.timeFormat(totalDuration)
    },
    totalDistance () {
      const self = this
      if ((self.guide || []).length < 1) {
        return ''
      }
      let totalDistance = 0
      self.guide.forEach(g => {
        totalDistance += g.guide.filter((o, i) => i >= self.currentPolylineIndex).map((o, i) => i === 0 ? self.currentDistance : o.distance).reduce((total, t) => (total + t))
      })

      if (totalDistance < 10) {
        totalDistance = Math.floor(totalDistance)
      } else if (totalDistance < 100) {
        totalDistance = Math.floor(totalDistance / 10) * 10
      } else if (totalDistance < 1000) {
        totalDistance = Math.floor(totalDistance / 100) * 100
      }

      return self.kmFormat(totalDistance)
    },
    arrivalTime () {
      const self = this
      let sec = 0
      if ((self.guide || []).length > 0) {
        self.guide.forEach(g => {
          sec += g.guide.filter((o, i) => i >= self.currentPolylineIndex).map((o, i) => i === 0 ? Math.round(o.interval * 1.0 * self.currentDistance / o.distance) : o.interval).reduce((total, t) => (total + t))
        })
      }
      const arrivalTime = new Date().getTime() + (sec * 1000)
      return new Date(arrivalTime).toLocaleTimeString('th-TH', {
        hour: 'numeric',
        minute: '2-digit'
      })
    },
    countDownReRouteThreshold () {
      return 2
    },
    alertTurnMeterThreshold () {
      return 40
    }
  },
  data () {
    return {
      map: null,
      mergePathList: [],
      // animatePathGeoJson: {
      //   type: 'FeatureCollection',
      //   features: [
      //     {
      //       type: 'Feature',
      //       geometry: {
      //         type: 'LineString',
      //         coordinates: []
      //       }
      //     }
      //   ]
      // },
      trackingMarker: null,
      lastPoint: null,
      animation: null,
      lastCurrentLocation: null,
      lastTime: 0,
      playTime: 0,
      isGettingGeolocation: false,
      geolocationNativeResult: null,
      geolocationNavigatorResult: null,
      currentTurnCode: null,
      currentDistance: 0,
      currentGuideName: '',
      nextTurnCode: null,
      nextGuideName: '',
      isActiveSpeak: true,
      isActivePerspectiveView: true,
      checkPitchTask: null,
      isShowReCenterButton: false,
      isShowReRouteButton: true,
      countReRoute: 0,
      isReRouting: false,
      lastSpeakTurnOfPolylineIndex: -1,
      animateDuration: 700,
      synth: null,
      originalDot: null,
      predictDot: null,
      isFinishNavigation: false,
      currentPolylineIndex: 0
    }
  },
  async mounted () {
    const self = this
    self.geolocationNavigatorResult = [window.currentLocation.lat, window.currentLocation.lon]

    if ('lang' in self.$route.query || 'locale' in self.$route.query) {
      self.$i18n.locale = (self.$route.query.lang || self.$route.query.locale) === 'th' ? 'th' : 'en'
      localStorage.lang = self.$i18n.locale
      Settings.defaultLocale = self.$i18n.locale
    } else {
      if (localStorage.lang) {
        self.$i18n.locale = localStorage.lang
        Settings.defaultLocale = localStorage.lang
      }
    }
    self.initialWatchGeolocationNavigator()
    await self.initialScript()
    self.initialMap()
    self.countReRouteTask = setInterval(() => {
      self.countReRoute--
    }, 1000)
    self.$parent.$on('redrawNavigationPath', self.initialNavigation)
  },
  methods: {
    returnToMainPage () {
      const self = this
      self.$router.replace({
        name: 'Main',
        hash: ''
      })
    },
    async initialScript () {
      const self = this
      if (!('longdo3' in window)) {
        if (!self.isOffline) {
          await self.utility.addHtmlTag({
            tag: 'script',
            src: `${process.env.VUE_APP_LONGDO_MAP_API3_LONGDO3}?key=${process.env.VUE_APP_LONGDO_MAP_KEY}`,
            type: 'text/javascript'
          })
        }
      }
    },
    initialMap () {
      const self = this
      self.map = new window.longdo3.Map({
        placeholder: document.getElementById('map'),
        lastView: false,
        layer: self.isLongdoMapV3 && self.baseMap ? window.longdo3.Layers[self.baseMap] : null,
        attributionControl: false
      })
      self.map.Event.bind('ready', () => {
        self.map.Ui.Geolocation.visible(false)
        self.map.Ui.Terrain.visible(false)
        self.map.Renderer.on('load', () => {
          self.onRederedMap()
        })
      })
    },
    onRederedMap () {
      const self = this
      if (self.mainMap.Layers.list().filter(l => l.source === 'traffic').length > 0) {
        self.map.Layers.add(window.longdo3.Layers.TRAFFIC)
      }
      self.initialNavigation()
      self.map.Ui.LayerSelector.visible(false)
      if (self.map.Ui.Fullscreen) {
        self.map.Ui.Fullscreen.visible(false)
      }
      self.map.Ui.Zoombar.visible(false)
      self.map.Ui.DPad.visible(false)
      self.map.Renderer.on('dragend', () => {
        self.isShowReCenterButton = true
      })
      self.map.Renderer.on('touchmove', () => {
        self.isShowReCenterButton = true
      })
      self.map.Renderer.on('pitchend', () => {
        self.isActivePerspectiveView = Math.round(self.map.Renderer.getPitch()) !== 0
      })
      self.map.Renderer.on('pitch', () => {
        self.updateNaviPin()
      })
      self.map.Renderer.on('rotate', () => {
        self.updateNaviPin()
      })
    },
    updateNaviPin () {
      const self = this
      if (self.trackingMarker !== null) {
        let heading
        if (window.courses) {
          heading = window.courses
        } else {
          heading = window.animateHeading || 0
        }
        if (self.map.Renderer.getPitch() <= 30) {
          self.trackingMarker.element().querySelector('img').style.width = '75px'
          self.trackingMarker.element().querySelector('img').style.height = '75px'
        } else {
          self.trackingMarker.element().querySelector('img').style.width = '100px'
          self.trackingMarker.element().querySelector('img').style.height = '100px'
        }
        self.trackingMarker.element().querySelector('img').style.transform = `rotateX(${self.map.Renderer.getPitch()}deg) rotateZ(${360 - self.map.Renderer.getBearing() + heading}deg)`
      }
    },
    drawPath () {
      const self = this
      const id = 'naviPath'

      if (!self.map.Renderer.getSource(id)) {
        self.map.Renderer.addSource(id, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: self.mergePathList.map(l => [l.lon, l.lat])
                }
              }
            ]
          }
        })
      } else {
        self.map.Renderer.getSource(id).setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: self.mergePathList.map(l => [l.lon, l.lat])
              }
            }
          ]
        })
      }

      if (self.map.Renderer.getLayer(id)) {
        self.map.Renderer.removeLayer(id)
      }
      if (self.map.Renderer.getLayer('border' + id)) {
        self.map.Renderer.removeLayer('border' + id)
      }
      if (self.map.Renderer.getLayer('outer' + id)) {
        self.map.Renderer.removeLayer('outer' + id)
      }
      self.map.Renderer.addLayer({
        id: 'border' + id,
        type: 'line',
        source: id,
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        },
        paint: {
          'line-color': '#999999',
          'line-width': 24,
          'line-opacity': 0.8
        }
      })
      self.map.Renderer.addLayer({
        id: 'outer' + id,
        type: 'line',
        source: id,
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        },
        paint: {
          'line-color': '#ffffff',
          'line-width': 22,
          'line-opacity': 1
        }
      })

      self.map.Renderer.addLayer({
        id: id,
        type: 'line',
        source: id,
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        },
        paint: {
          'line-color': '#3894ee',
          'line-width': 16,
          'line-opacity': 0.8
        }
      })
    },
    async initialNavigation () {
      const self = this

      // if path is null (directly enter from url or reload)
      if (!self.path) {
        self.$router.replace({
          name: 'Main',
          hash: '#routing',
          params: {},
          query: {}
        })
      } else {
        self.mergePathList = []
        self.path.forEach(path => {
          self.mergePathList = self.mergePathList.concat(path)
        })
        self.drawPath()

        // if (self.map.Renderer.getLayer('borderAnimatePath')) {
        //   self.map.Renderer.removeLayer('borderAnimatePath')
        // }
        // if (self.map.Renderer.getLayer('outerAnimatePath')) {
        //   self.map.Renderer.removeLayer('outerAnimatePath')
        // }
        // if (self.map.Renderer.getLayer('animatePath')) {
        //   self.map.Renderer.removeLayer('animatePath')
        // }
        // remove green line because not matching route path and for app performance
        // if (!self.map.Renderer.getSource('animatePath')) {
        //   await self.map.Renderer.addSource('animatePath', {
        //     type: 'geojson',
        //     data: self.animatePathGeoJson
        //   })
        // } else {
        //   self.map.Renderer.getSource('animatePath').setData(self.animatePathGeoJson)
        // }
        // self.map.Renderer.addLayer({
        //   id: 'borderAnimatePath',
        //   type: 'line',
        //   source: 'animatePath',
        //   layout: {
        //     'line-cap': 'round',
        //     'line-join': 'round'
        //   },
        //   paint: {
        //     'line-color': '#999999',
        //     'line-width': 24,
        //     'line-opacity': 1
        //   }
        // })

        // self.map.Renderer.addLayer({
        //   id: 'outerAnimatePath',
        //   type: 'line',
        //   source: 'animatePath',
        //   layout: {
        //     'line-cap': 'round',
        //     'line-join': 'round'
        //   },
        //   paint: {
        //     'line-color': '#ffffff',
        //     'line-width': 22,
        //     'line-opacity': 1
        //   }
        // })

        // self.map.Renderer.addLayer({
        //   id: 'animatePath',
        //   type: 'line',
        //   source: 'animatePath',
        //   layout: {
        //     'line-cap': 'round',
        //     'line-join': 'round'
        //   },
        //   paint: {
        //     'line-color': '#8bc34a',
        //     'line-width': 16,
        //     'line-opacity': 1
        //   }
        // })

        const currentLocation = await self.getGeolocation()
        if (self.trackingMarker == null) {
          self.trackingMarker = new window.longdo3.Marker({
            lat: currentLocation[0],
            lon: currentLocation[1]
          }, {
            icon: {
              html: `<img style="width: 100px; height: 100px;" src="${self.baseUrl}img/navi@2x.png" srcset="${self.baseUrl}img/navi@1x.png 1x,${self.baseUrl}img/navi@2x.png 2x">`,
              offset: {
                x: 0,
                y: 0
              }
            }
          })
          self.map.Overlays.add(self.trackingMarker)
        // self.animatePathGeoJson.features[0].geometry.coordinates = [[currentLocation[0], currentLocation[1]]]
        }
        if (!self.lastPoint) {
          self.lastPoint = {
            x: currentLocation[0],
            y: currentLocation[1]
          }
        }
        self.map.Renderer.easeTo({
          center: [self.lastPoint.y, self.lastPoint.x],
          padding: { top: 400 },
          zoom: 19,
          pitch: 60,
          speed: 5,
          duration: 1000
        })
        self.lastTime = performance.now()
        self.animation = window.requestAnimationFrame(self.animateNavigation)
      }
    },
    async animateNavigation (timestamp) {
      const self = this
      const durationTime = timestamp - self.lastTime // millisecond
      self.playTime += durationTime
      self.lastTime = timestamp // millisecond

      let currentLocation = await self.getGeolocation()
      currentLocation = {
        lat: currentLocation[0],
        lon: currentLocation[1]
      }

      // self.map.Overlays.add(new window.longdo3.Dot(currentLocation, {
      //   lineWidth: 6,
      //   lineColor: 'rgba(0, 255, 0, 0.8)',
      //   draggable: false
      // }))

      // if (self.lastCurrentLocation) {
      //   const calSpeedLat = self.distance([
      //     {
      //       lat: self.lastCurrentLocation.lat,
      //       lon: 0
      //     },
      //     {
      //       lat: currentLocation.lat,
      //       lon: 0
      //     }
      //   ]) / durationTime // meter per millisecond
      //   const calSpeedLon = self.distance([
      //     {
      //       lat: 0,
      //       lon: self.lastCurrentLocation.lon
      //     },
      //     {
      //       lat: 0,
      //       lon: currentLocation.lon
      //     }
      //   ]) / durationTime // meter per millisecond
      //   if (calSpeedLat !== null && calSpeedLon !== null) {
      //     const offsetLat = (calSpeedLat * 50) / window.longdo3.Util.latitudeLength(currentLocation.lat) // degree
      //     const offsetLon = (calSpeedLon * 50) / window.longdo3.Util.longitudeLength(currentLocation.lat) // degree
      //     const predictLat = currentLocation.lat + offsetLat
      //     const predictLon = currentLocation.lon + offsetLon

      //     console.log('durationTime => ' + JSON.stringify(durationTime))
      //     console.log('lastCurrentLocation => ' + JSON.stringify(self.lastCurrentLocation))
      //     console.log('currentLocation => ' + JSON.stringify(currentLocation))
      //     console.log('calSpeedLat => ' + JSON.stringify(calSpeedLat))
      //     console.log('calSpeedLon => ' + JSON.stringify(calSpeedLon))
      //     console.log('offsetLat => ' + JSON.stringify(offsetLat))
      //     console.log('offsetLon => ' + JSON.stringify(offsetLon))
      //     console.log('predictLat => ' + JSON.stringify(predictLat))
      //     console.log('predictLon => ' + JSON.stringify(predictLon))

      //     self.lastCurrentLocation = currentLocation
      //     currentLocation = {
      //       lat: predictLat,
      //       lon: predictLon
      //     }
      //   } else {
      //     self.lastCurrentLocation = currentLocation
      //   }
      // } else {
      //   self.lastCurrentLocation = currentLocation
      // }

      const distanceDestination = self.distance([
        currentLocation,
        self.meta.to
      ])

      let minD = null
      let currentPolylineIndex = self.currentPolylineIndex
      self.path.forEach((locationList, i) => {
        const cp = self.closestPointOnPolyline(currentLocation, locationList).location
        if (cp) {
          const d = self.distance([
            currentLocation,
            {
              lat: cp.x,
              lon: cp.y
            }
          ])
          if (minD === null || d < minD) {
            minD = d
            currentPolylineIndex = i
          }
        }
      })
      self.currentPolylineIndex = currentPolylineIndex

      let totalGuide = 0
      self.guide.forEach(g => {
        totalGuide = g.guide.length
      })

      const closestPoint = self.closestPointOnPolyline(currentLocation, self.mergePathList).location
      let pointOnLastPolyline = false
      if (currentPolylineIndex === totalGuide - 1) {
        self.path[self.path.length - 1].forEach((point, i) => {
          if (point.lat === closestPoint.x && point.lon === closestPoint.y) {
            pointOnLastPolyline = true
          }
        })
      }

      const isFinished = (distanceDestination < self.guide[self.guide.length - 1].tdistance + 5) && currentPolylineIndex === totalGuide - 1 && pointOnLastPolyline
      if (!isFinished && self.lastCurrentLocation) {
        if (self.lastCurrentLocation.lat.toFixed(6) === self.currentLocation.lat.toFixed(6) && self.lastCurrentLocation.lon.toFixed(6) === self.currentLocation.lon.toFixed(6)) {
          self.lastCurrentLocation = self.currentLocation
          self.animation = window.requestAnimationFrame(self.animateNavigation)
          return false
        }
      }
      self.lastCurrentLocation = self.currentLocation

      if (isFinished) {
        self.currentTurnCode = 9
        self.currentGuideName = self.$t('routingPanel.destination')
        self.currentDistance = 0
      } else if (currentPolylineIndex < totalGuide) {
        let i = 0
        let j = 0
        let temp = 0
        for (i = 0; i < self.guide.length; i++) {
          if (currentPolylineIndex < temp + self.guide[i].guide.length) {
            j = currentPolylineIndex - temp
            break
          } else {
            temp += self.guide[i].guide.length
          }
        }
        self.currentTurnCode = Number(self.guide[i].guide[j].turn)
        self.currentGuideName = self.guide[i].guide[j].name

        if (currentPolylineIndex + 1 < totalGuide) {
          i = 0
          j = 0
          temp = 0
          for (i = 0; i < self.guide.length; i++) {
            if ((currentPolylineIndex + 1) < temp + self.guide[i].guide.length) {
              j = (currentPolylineIndex + 1) - temp
              break
            } else {
              temp += self.guide[i].guide.length
            }
          }
          self.nextTurnCode = Number(self.guide[i].guide[j].turn)
          self.nextGuideName = self.guide[i].guide[j].name
        } else {
          self.nextTurnCode = null
          self.nextGuideName = ''
        }
      } else {
        self.currentTurnCode = null
        self.currentGuideName = ''
        self.nextTurnCode = null
        self.nextGuideName = ''
      }

      if (closestPoint || isFinished) {
        if (self.isShowReCenterButton) {
          if (!isFinished) {
            self.trackingMarker.move({ lat: closestPoint.x, lon: closestPoint.y })
            self.animation = window.requestAnimationFrame(self.animateNavigation)
          } else {
            self.showFinishedDialog()
          }
          return false
        }

        // const samePoint = self.lastPoint.x === closestPoint.x && self.lastPoint.y === closestPoint.y
        // if (self.map.Renderer.isEasing() || samePoint) {
        if (self.map.Renderer.isEasing()) {
          const cp = self.closestPointOnPolyline({ lat: closestPoint.x, lon: closestPoint.y }, self.path[currentPolylineIndex])
          let m = self.distance([
            { lat: closestPoint.x, lon: closestPoint.y },
            self.path[currentPolylineIndex][cp.index + 1]
          ])
          for (let i = cp.index + 1; i < self.path[currentPolylineIndex].length - 1; i++) {
            m += self.distance([
              self.path[currentPolylineIndex][i],
              self.path[currentPolylineIndex][i + 1]
            ])
          }

          self.currentDistance = m
          if (m < self.alertTurnMeterThreshold && (self.nextTurnCode < 4 || self.nextTurnCode === 6) && self.lastSpeakTurnOfPolylineIndex !== currentPolylineIndex) {
            self.lastSpeakTurnOfPolylineIndex = currentPolylineIndex
            self.speak(`${self.$t(`routingPanel.turnCode[${self.nextTurnCode !== null ? self.nextTurnCode : self.currentTurnCode}]`)} ${self.nextGuideName}`)
          }
          // self.trackingMarker.move({ lat: closestPoint.x, lon: closestPoint.y })
          if (!isFinished) {
            self.animation = window.requestAnimationFrame(self.animateNavigation)
          } else {
            self.showFinishedDialog()
          }
          return false
        }

        const nearPoint = Math.abs(self.lastPoint.x - closestPoint.x) < 0.000007 && Math.abs(self.lastPoint.y - closestPoint.y) < 0.000007

        // please fix bug https://mmtech.slack.com/archives/C02FDEMLP/p1651561086321789?thread_ts=1651560999.180959&cid=C02FDEMLP
        const bearing = nearPoint ? self.map.Renderer.getBearing() : self.bearing(
          self.lastPoint.x,
          self.lastPoint.y,
          closestPoint.x,
          closestPoint.y
        )

        window.animateHeading = bearing

        self.map.Renderer.easeTo({
          center: [closestPoint.y, closestPoint.x],
          zoom: 19,
          pitch: self.isActivePerspectiveView ? 60 : 0,
          bearing: window.courses ? window.courses : bearing,
          speed: 0.1,
          curve: 0.5,
          duration: self.animateDuration,
          essential: true,
          easing: (t) => {
            if (!self.isFinished) {
              if (self.lastPoint) {
                const tranlatePoint = {
                  lat: self.lastPoint.x + ((closestPoint.x - self.lastPoint.x) * t),
                  lon: self.lastPoint.y + ((closestPoint.y - self.lastPoint.y) * t)
                }
                const closestTranlatePoint = self.closestPointOnPolyline(tranlatePoint, self.mergePathList).location
                if (closestTranlatePoint) {
                  self.trackingMarker.move({
                    lat: Number(closestTranlatePoint.x),
                    lon: Number(closestTranlatePoint.y)
                  })
                  // self.animatePathGeoJson.features[0].geometry.coordinates.push([closestTranlatePoint.y, closestTranlatePoint.x])
                  // self.map.Renderer.getSource('animatePath').setData(self.animatePathGeoJson)

                  const cp = self.closestPointOnPolyline({ lat: closestTranlatePoint.x, lon: closestTranlatePoint.y }, self.path[currentPolylineIndex])
                  let m = self.distance([
                    { lat: closestTranlatePoint.x, lon: closestTranlatePoint.y },
                    self.path[currentPolylineIndex][cp.index + 1]
                  ])
                  for (let i = cp.index + 1; i < self.path[currentPolylineIndex].length - 1; i++) {
                    m += self.distance([
                      self.path[currentPolylineIndex][i],
                      self.path[currentPolylineIndex][i + 1]
                    ])
                  }

                  self.currentDistance = m
                }
              }
              if (t === 1) {
                self.lastPoint = closestPoint
              }
              return t
            } else {
              self.showFinishedDialog()
            }
          }
        })
        self.countReRoute = 0
      } else {
        self.trackingMarker.move(currentLocation)
        // const cp = self.closestPointOnPolyline(currentLocation, self.path[currentPolylineIndex])
        // let m = self.distance([
        //   { lat: closestTranlatePoint.x, lon: closestTranlatePoint.y },
        //   self.path[currentPolylineIndex][cp.index + 1]
        // ])
        //         for (let i = cp.index + 1; i < self.path[currentPolylineIndex].length - 1; i++) {
        //           m += self.distance([
        //             self.path[currentPolylineIndex][i],
        //             self.path[currentPolylineIndex][i + 1]
        //           ])
        //         }

        //         self.currentDistance = m
        if (!self.isReRouting) {
          if (self.countReRoute === 0) {
            self.onClickReRoute()
          } else if (self.countReRoute > self.countDownReRouteThreshold || self.countReRoute < 0) {
            self.countReRoute = self.countDownReRouteThreshold
          }
        }
      }

      if (!isFinished) {
        self.animation = window.requestAnimationFrame(self.animateNavigation)
      } else {
        self.showFinishedDialog()
      }
    },
    showFinishedDialog () {
      const self = this
      self.trackingMarker.move({ lat: self.meta.to.lat, lon: self.meta.to.lon })
      if (self.isShowReCenterButton) {
        self.onClickReCenter()
      }
      self.isFinishNavigation = true
      if (self.animateNavigation) {
        window.cancelAnimationFrame(self.animateNavigation)
      }
    },
    bearing (lat1, lon1, lat2, lon2) {
      const self = this
      let brng = Math.atan2(lat2 - lat1, lon2 - lon1)
      brng = self.toDegrees(brng)
      brng = (brng + 360) % 360
      brng = 360 - brng + 90
      return brng
    },
    toDegrees (angle) {
      return angle * (180 / Math.PI)
    },
    distance (e) {
      const EARTH_DIAMETER = 12742018
      const RAD = Math.PI / 180
      for (var t, o = 0, a = 1, r = e[0]; e[a]; ++a) {
        t = r
        r = e[a]
        var i = Number(r.lat) * RAD
        var l = Number(t.lat) * RAD
        var s = i - l
        var d = (Number(r.lon) - Number(t.lon)) * RAD
        var u = Math.sin(s / 2) * Math.sin(s / 2) + Math.cos(i) * Math.cos(l) * Math.sin(d / 2) * Math.sin(d / 2)
        o += Math.atan2(Math.sqrt(u), Math.sqrt(1 - u)) * EARTH_DIAMETER
      }
      return o
    },
    closestPointOnPolyline (point, locationList, threshold = 30) {
      const self = this
      if (locationList.length < 2) {
        return null
      }
      let min = null
      let ans = null
      let index = null
      for (let i = 0; i < locationList.length - 1; i++) {
        const pointOnLine = self.closestPointOnLine(point.lat, point.lon, locationList[i].lat, locationList[i].lon, locationList[i + 1].lat, locationList[i + 1].lon)
        const d = self.distance([
          point,
          { lat: pointOnLine.x, lon: pointOnLine.y }
        ])
        if (min === null || d < min) {
          min = d
          ans = pointOnLine
          index = i
        }
      }
      if (threshold !== null && threshold !== undefined && threshold > 0) {
        if (min > threshold) {
          return {
            location: null,
            index: null
          }
        }
      }
      return {
        location: ans,
        index: index
      }
    },
    closestPointOnLine (e, n, t, o, a, r) {
      var i = a - t
      var l = r - o
      if (!i && !l) {
        return {
          x: t,
          y: o
        }
      }
      var s = ((e - t) * i + (n - o) * l) / (i * i + l * l)
      return s < 0 ? {
        x: t,
        y: o
      } : s > 1 ? {
        x: a,
        y: r
      } : {
        x: t + s * i,
        y: o + s * l
      }
    },
    async getGeolocation () {
      const self = this
      // const floatIndex = self.playTime / DURATION_PER_MOCK_GEOLOCATION
      // let index = Math.floor(floatIndex)
      // let geolocation
      // if (index < 0) {
      //   index = 0
      //   geolocation = MOCK_GEOLOCATION[index]
      // } else if (index === MOCK_GEOLOCATION.length - 1) {
      //   geolocation = MOCK_GEOLOCATION[index]
      // } else if (index >= MOCK_GEOLOCATION.length) {
      //   index = MOCK_GEOLOCATION.length - 1
      //   geolocation = MOCK_GEOLOCATION[index]
      // } else {
      //   const t = floatIndex - index
      //   geolocation = [
      //     MOCK_GEOLOCATION[index][0] + ((MOCK_GEOLOCATION[index + 1][0] - MOCK_GEOLOCATION[index][0]) * t),
      //     MOCK_GEOLOCATION[index][1] + ((MOCK_GEOLOCATION[index + 1][1] - MOCK_GEOLOCATION[index][1]) * t)
      //   ]
      // }
      // window.currentLocation.lat = geolocation[1]
      // window.currentLocation.lon = geolocation[0]
      // return geolocation
      if (self.lji.Util.isAndroidNativeApp()) {
        return [window.currentLocation.lat, window.currentLocation.lon]
      } else if (self.lji.Util.isIosNativeApp()) {
        return [window.currentLocation.lat, window.currentLocation.lon]
        // try {
        //   if (!self.isGettingGeolocation) {
        //     self.isGettingGeolocation = true
        //     self.geolocationNativeResult = await self.lji.getGeolocation()
        //     self.isGettingGeolocation = false
        //   }
        //   return [self.geolocationNativeResult.latitude, self.geolocationNativeResult.longitude]
        // } catch (e1) {
        //   self.isGettingGeolocation = false
        //   const location = self.map.location()
        //   return [location.lat, location.lon]
        // }
      } else {
        return self.geolocationNavigatorResult
      }
    },
    initialWatchGeolocationNavigator () {
      const self = this
      const success = (e) => {
        self.geolocationNavigatorResult = [e.coords.latitude, e.coords.longitude]
      }
      const error = (e) => {
        const location = self.map.location()
        self.geolocationNavigatorResult = [location.lat, location.lon]
        console.log(e)
      }
      const options = {
        maximumAge: 1e3,
        timeout: 15e3,
        enableHighAccuracy: true
      }
      navigator.geolocation.watchPosition(success, error, options)
    },
    timeFormat (sec) {
      const self = this
      if (sec < 60) {
        return sec + ' ' + self.$t('routingPanel.sec')
      } else {
        const min = Math.floor(sec / 60)
        if (min < 60) {
          return min + ' ' + self.$t('routingPanel.min')
        } else {
          const hr = Math.floor(min / 60)
          return hr + ' ' + self.$t('routingPanel.hr') + ((min - (hr * 60)) > 0 ? ' ' + (min - (hr * 60)) + ' ' + self.$t('routingPanel.min') : '')
        }
      }
    },
    kmFormat (meter) {
      const self = this
      if (meter < 1000) {
        return meter + ' ' + self.$t('routingPanel.meter')
      } else {
        const km = (meter / 1000.0).toLocaleString(self.$i18n.locale, {
          maximumFractionDigits: 1
        })
        return km + ' ' + self.$t('routingPanel.kilometer')
      }
    },
    async speak (text) {
      const self = this

      text = text.replaceAll('ถนนเชื่อมต่อ', 'ถนนข้างหน้า')
      text = text.toLowerCase().replaceAll('connecting road', 'ahead road')

      // const audioEl = window.document.getElementById('tts-audio')
      // var link = `${process.env.VUE_APP_LONGDO_MAP_GOOGLE_TRANSLATE_TTS}?text=${text}&lang=${self.$i18n.locale}&rd=${new Date().getTime()}`
      // audioEl.src = link
      // audioEl.play()

      if (!self.isActiveSpeak) {
        return false
      }

      if (self.lji.Util.isAndroidNativeApp()) {
        try {
          const result = await self.lji.speak({
            text: text,
            lang: 'th'
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        return false
      }

      if (!('SpeechSynthesisUtterance' in window)) {
        return false
      }

      const speech = new window.SpeechSynthesisUtterance(text)
      speech.lang = 'th'

      if (self.synth) {
        self.synth.cancel()
      } else {
        self.synth = window.speechSynthesis
      }
      self.synth.speak(speech)
    },
    onClickSpeakButton () {
      const self = this
      self.isActiveSpeak = !self.isActiveSpeak
    },
    onClickPerspectiveButton () {
      const self = this
      self.isActivePerspectiveView = !self.isActivePerspectiveView
      self.map.Renderer.easeTo({
        pitch: self.isActivePerspectiveView ? 60 : 0
      })
    },
    async onClickViewAllButton () {
      const self = this
      var locationList = []
      self.path.forEach(path => {
        locationList = locationList.concat(path.map(location => {
          return {
            lat: Number(location.lat),
            lon: Number(location.lon)
          }
        }))
      })
      locationList.push(window.currentLocation)
      const bbox = window.longdo.Util.locationBound(locationList)
      const offsetLat = (bbox.maxLat - bbox.minLat) * 0.3
      const offsetLon = (bbox.maxLon - bbox.minLon) * 0.3
      bbox.minLat -= offsetLat
      bbox.minLon -= offsetLon
      bbox.maxLat += offsetLat
      bbox.maxLon += offsetLon
      self.map.pitch(0)
      self.map.Renderer.setPadding({ top: 0 })
      self.map.bound(bbox)
      self.isShowReCenterButton = true
    },
    onClickReCenter () {
      const self = this
      const location = self.trackingMarker.location()
      self.map.Renderer.easeTo({
        center: [location.lon, location.lat],
        padding: { top: 400 },
        zoom: 19,
        pitch: 60,
        speed: 5,
        duration: 1000
      })
      self.isShowReCenterButton = false
    },
    async onClickReRoute () {
      const self = this
      self.isReRouting = true
      self.$parent.$emit('reRoute')
    },
    onClickExitNavi () {
      const self = this
      if (self.animation) {
        window.cancelAnimationFrame(self.animation)
      }
      clearInterval(self.checkPitchTask)
      clearInterval(self.countReRouteTask)
      if (self.synth) {
        self.synth.cancel()
      }
      self.$router.back()
    }
  },
  watch: {
    guideDetail () {
      const self = this
      setTimeout(() => {
        if (!(self.currentDistance < self.alertTurnMeterThreshold && self.nextTurnCode !== null && self.nextTurnCode < 4)) {
          self.speak(self.guideDetailSpeak)
        }
      }, 1500)
    },
    meta () {
      const self = this
      self.$nextTick(async () => {
        self.mergePathList = []
        self.path.forEach(path => {
          self.mergePathList = self.mergePathList.concat(path)
        })

        self.drawPath()

        // const currentLocation = await self.getGeolocation()
        // self.animatePathGeoJson.features[0].geometry.coordinates = [[currentLocation[1], currentLocation[0]]]
        self.onClickReCenter()
        self.isReRouting = false
      })
    }
  },
  destroyed () {
    const self = this
    if (self.animation) {
      window.cancelAnimationFrame(self.animation)
    }
    clearInterval(self.checkPitchTask)
    clearInterval(self.countReRouteTask)
  }
}
</script>

<style lang="scss">
  .navigation {
    .maplibregl-ctrl-bottom-left.mapboxgl-ctrl-bottom-left,
    .maplibregl-ctrl-bottom-left {
      bottom: 10px;
    }
    .maplibregl-ctrl-bottom-right.mapboxgl-ctrl-bottom-right,
    .maplibregl-ctrl-bottom-right {
      bottom: 10px;
    }
  }
</style>

<style lang="scss" scoped>
.navigation {
  width: 100%;
  height: 100%;
  background-color: #eeebe5;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 300;

  #map {
    width: 100%;
    height: calc(100% - 84px + 12px);
  }

  #guide {
    background-color: rgba(41, 136, 240, 0.93);
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 18px 18px 12px 18px;
    width: calc(100% - 24px);
    z-index: 1;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;

    .detail {
      color: white;
      font-family: Prompt;
      font-size: 22px;

      &.left {
        width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &.right {
        flex: 1;
        padding: 0px 0px 0px 24px;
        line-height: 1.25;
      }

      .direction {
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        background-size: 32px 32px;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 6px;
      }

      .distance {
        color: white;
        font-family: Prompt;
        font-size: 20px;

        .unit {
          color: white;
          font-family: Prompt;
          font-size: 14px;
          padding-left: 3px;
        }
      }
    }

    #next-guide {
      background-color: rgba(24, 59, 127, 0.93);
      position: absolute;
      top: 100%;
      left: 0%;
      padding: 6px 18px;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: Prompt;
      font-size: 16px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .direction {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        margin-right: 18px;
        background-size: 18px 18px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  #control {
    position: absolute;
    bottom: 99px;
    right: 12px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;

    button {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      border: none;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;

      i {
        color: $grey;
      }

      &.active {
        i {
          color: rgba(24, 59, 127, 1);
        }
      }

      &.perspective-view {
        i {
          color: rgba(24, 59, 127, 1);
          transition: transform 0.3s ease 0s;
        }

        &.active {
          i {
            color: rgba(24, 59, 127, 1);
            transform: perspective(80px) rotateX(40deg);
          }
        }
      }
    }

    a {
      height: 16px;
    }
  }

  #footer {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 84px;
    width: 100%;
    background-color: white;
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;

    .summary {
      .main {
        color: #183B7F;
        font-family: Prompt;
        font-size: 24px;
        font-weight: bold;
      }

      .sub {
        font-family: Prompt;
        font-size: 16px;
      }
    }

    button {
      background-color: white;
      border: none;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      padding: 0px;
      border-radius: 25px;
      font-size: 18px;
      font-family: Prompt;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 44px;
      box-sizing: border-box;

      &.exit-navi{
        width: 44px;
        i {
          color: #CB1431;
        }
      }

      &.view-all {
        width: 44px;
        margin-left: auto;
        margin-right: 18px;

        i {
          color: #183B7F;
          margin: 0px;
        }
      }
      &.return-main {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding: 6px 12px;
        background-color: $primary;
        color:white;
      }
    }
  }

  button#re-center {
    position: absolute;
    bottom: 129px;
    left: 12px;
    z-index: 1;
    background-color: rgba(24, 59, 127, 0.93);
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 0px 18px;
    border-radius: 25px;
    height: 44px;
    font-size: 18px;
    font-family: Prompt;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  button#re-routing {
    position: absolute;
    bottom: 186px;
    left: 12px;
    z-index: 1;
    background-color: rgba(203, 20, 49, 0.93);
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 0px 18px;
    border-radius: 25px;
    height: 44px;
    font-size: 18px;
    font-family: Prompt;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
</style>
