<template>
  <div :class="{
    'control-map-panel-v2': !isLongdoMapV3,
    'control-map-panel': isLongdoMapV3,
    'hide': isHidingControlMapPanel || $route.hash === '#cam',
    'showing-popup': isShowingPopup,
    'place-card-step-full': placeCardStep === 'FULL',
    'place-card-step-mid': placeCardStep === 'MID',
    'place-card-step-mini': placeCardStep === 'MINI',
    'showing-explore-panel': !isHidingExplorePanel && isExpandingExplorePanel,
    'collapsing-explore-panel': !isHidingExplorePanel && !isExpandingExplorePanel,
    'showing-search-result-panel': !isHidingSearchResultPanel && isExpandingSearchResultPanel,
    'collapsing-search-result-panel': !isHidingSearchResultPanel && !isExpandingSearchResultPanel,
    'showing-my-place-panel': !isHidingMyPlacePanel && isExpandingMyPlacePanel,
    'collapsing-my-place-panel': !isHidingMyPlacePanel && !isExpandingMyPlacePanel,
    'showing-explore-nearby-panel': !isHidingExploreNearbyPanel && isExpandingExploreNearbyPanel,
    'collapsing-explore-nearby-panel': !isHidingExploreNearbyPanel && !isExpandingExploreNearbyPanel,
    'showing-event-panel': !isHidingEventPanel && isExpandingEventPanel,
    'collapsing-event-panel': !isHidingEventPanel && !isExpandingEventPanel,
    'routing': $route.hash === '#routing'
  }">
    <button @touchend="onClickNorthUp()" class="ripple"
      :class="{ tracking: isTrackingDirection }" @touchstart="startDragCompass" @touchmove="onDragCompass">
        <img v-if="isTrackingDirection" id="map-control-compass" :src="`${baseUrl}img/compass-tracking-${product}.svg`"/>
        <img v-else id="map-control-compass" :src="`${baseUrl}img/compass-${product}.svg`"/>
    </button>
    <button @click="onClickGeolocation()"  :title="$t('mapControlPanel.currentLocation')" class="ripple"
      :class="{ tracking: isTracking }">
      <i class="material-icons">
        {{ isGeolocationMobileAppDisabled ? 'location_disabled' : 'my_location' }}
      </i>
    </button>
    <button @mousedown="onMousedownZoomIn()" @mouseup="onMouseupZoomIn()" @mouseleave="onMouseupZoomIn()"
      @touchstart="onTouchstartZoomIn()" @touchend="onTouchendZoomIn()" :title="$t('mapControlPanel.zoomIn')"
      v-if="enableZoomButton" :disabled="disableZoomIn" :class="{ripple: !disableZoomIn}">
      <i class="material-icons">
        add
      </i>
    </button>
    <button @mousedown="onMousedownZoomOut()" @mouseup="onMouseupZoomOut()" @mouseleave="onMouseupZoomOut()"
      @touchstart="onTouchstartZoomOut()" @touchend="onTouchendZoomOut()" :title="$t('mapControlPanel.zoomOut')"
      v-if="enableZoomButton" :disabled="disableZoomOut" :class="{ripple: !disableZoomOut}">
      <i class="material-icons">
        remove
      </i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ControlMapPanel',
  props: {
    map: {
      type: Object
    },
    isShowingPopup: {
      type: Boolean,
      default: false
    },
    placeCardStep: {
      type: String,
      default: 'MID'
    },
    isGeolocationMobileAppDisabled: {
      type: Boolean,
      default: false
    },
    isTracking: {
      type: Boolean,
      default: false
    },
    isTrackingDirection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isHidingControlMapPanel: false,
      enableZoomButton: localStorage.enableZoomButton ? (localStorage.enableZoomButton === 'true') : true,
      zoomInTask: null,
      zoomOutTask: null,
      isHidingExplorePanel: true,
      isExpandingExplorePanel: true,
      isHidingSearchResultPanel: true,
      isExpandingSearchResultPanel: true,
      isHidingMyPlacePanel: true,
      isExpandingMyPlacePanel: true,
      isHidingExploreNearbyPanel: true,
      isExpandingExploreNearbyPanel: true,
      isHidingEventPanel: true,
      isExpandingEventPanel: true,
      holdButton: false,
      disableZoomIn: false,
      disableZoomOut: false,
      rotateBeforeDrag: 0,
      isDraggingCompass: false,
      prevIsTracking: false,
      prevIsTrackingDirection: true
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.toggleDisplayMapContorlPanel)
    self.$root.$on('changeEnableMeasurementToolButton', self.onChangeEnableMeasurementToolButton)
    self.$root.$on('changeEnableZoomButton', self.onChangeEnableZoomButton)
    self.$root.$on('changeHidingExplorePanel', value => {
      self.isHidingExplorePanel = value
    })
    self.$root.$on('changeExpandExplorePanel', value => {
      self.isExpandingExplorePanel = value
    })
    self.$root.$on('changeHidingSearchResultPanel', value => {
      self.isHidingSearchResultPanel = value
    })
    self.$root.$on('changeExpandSearchResultPanel', value => {
      self.isExpandingSearchResultPanel = value
    })
    self.$root.$on('changeHidingMyPlacePanel', value => {
      self.isHidingMyPlacePanel = value
    })
    self.$root.$on('rotateCompass', () => {
      self.rotateCompass()
    })
    self.$root.$on('changeExpandMyPlacePanel', value => {
      self.isExpandingMyPlacePanel = value
    })
    self.$root.$on('changeHidingExploreNearbyPanel', value => {
      self.isHidingExploreNearbyPanel = value
    })
    self.$root.$on('changeExpandExploreNearbyPanel', value => {
      self.isExpandingExploreNearbyPanel = value
    })
    self.$root.$on('changeHidingEventPanel', value => {
      self.isHidingEventPanel = value
    })
    self.$root.$on('changeExpandEventPanel', value => {
      self.isExpandingEventPanel = value
    })
    self.map.Event.bind('zoom', () => {
      self.disableZoomIn = self.map.zoom() === 22
      self.disableZoomOut = self.map.zoom() < 1
    })
    if (self.isLongdoMapV3) {
      self.map.Renderer.on('rotate', () => {
        self.rotateCompass()
      })
    } else {
      self.map.Event.bind('rotate', () => {
        self.rotateCompass()
      })
    }
  },
  methods: {
    async onClickGeolocation () {
      const self = this
      if (!self.isGeolocationMobileAppDisabled) {
        self.storeEventToNativeAnalytic('map_control_panel_geolocation')
        if (self.isLongdoMapV3) {
          self.$root.$emit('setIsTracking', true)
          if (self.isOnMobileApp) {
            self.$root.$emit('setIsTrackingDirection', true)
          }
          self.map.Ui.Geolocation.trigger()
          self.$emit('getCurrentLocation')
        } else {
          self.$root.$emit('setIsTracking', true)
          if (self.isOnMobileApp) {
            self.$root.$emit('setIsTrackingDirection', true)
          }
          self.$emit('getCurrentLocation')
        }
      } else {
        self.$root.$emit('openMobilePermissionDialog')
      }
    },
    onClickNorthUp () {
      const self = this
      if (!self.isDraggingCompass) {
        self.storeEventToNativeAnalytic('map_control_panel_click_north_up')
        if (self.isTracking) {
          // when tracking we cant use rotate animation since new tracking animation will override this
          if (self.isLongdoMapV3) {
            self.map.rotate(0, false)
          } else {
            self.map.rotate(0)
          }
        } else {
          if (self.isLongdoMapV3) {
            self.map.rotate(0, true)
          } else {
            self.map.rotate(0)
          }
        }
      } else {
        self.storeEventToNativeAnalytic('map_control_panel_drag_compass')
      }
      self.$root.$emit('setIsTrackingDirection', false)
    },
    rotateCompass () {
      const self = this
      const compass = document.getElementById('map-control-compass')
      if (self.isLongdoMapV3) {
        compass.style.transform = `rotateZ(${360 - self.map.Renderer.getBearing()}deg)`
      } else {
        compass.style.transform = `rotateZ(${360 - self.map.rotate()}deg)`
      }
    },
    startDragCompass () {
      const self = this
      self.rotateBeforeDrag = self.map.rotate()
      self.isDraggingCompass = false
    },
    onDragCompass (event) {
      const self = this
      if (!self.isTrackingDirection) {
        self.isDraggingCompass = true
        const touch = event.targetTouches[0] || event.changedTouches[0]
        const compass = document.getElementById('map-control-compass').getBoundingClientRect()
        const position = compass.left + window.scrollX + compass.width / 2
        const offset = (touch.pageX - position) * 6 / 5
        self.map.rotate(self.rotateBeforeDrag - (offset % 360), false)
        self.rotateCompass()
      }
    },
    toggleDisplayMapContorlPanel (isShowingMapOnly) {
      const self = this
      self.isHidingControlMapPanel = isShowingMapOnly
    },
    onChangeEnableZoomButton (enableZoomButton) {
      const self = this
      self.enableZoomButton = enableZoomButton
    },
    onMousedownZoomIn () {
      const self = this
      if (!self.utility.isTouchEnabled()) {
        self.onButtonDown(true)
      }
    },
    onMouseupZoomIn () {
      const self = this
      if (!self.utility.isTouchEnabled()) {
        self.onButtonUp()
      }
    },
    onMousedownZoomOut () {
      const self = this
      if (!self.utility.isTouchEnabled()) {
        self.onButtonDown(false)
      }
    },
    onMouseupZoomOut () {
      const self = this
      if (!self.utility.isTouchEnabled()) {
        self.onButtonUp()
      }
    },
    onTouchstartZoomIn () {
      const self = this
      if (self.utility.isTouchEnabled()) {
        self.storeEventToNativeAnalytic('map_control_panel_zoom_in')
        self.onButtonDown(true)
      }
    },
    onTouchendZoomIn () {
      const self = this
      if (self.utility.isTouchEnabled()) {
        self.onButtonUp()
      }
    },
    onTouchstartZoomOut () {
      const self = this
      if (self.utility.isTouchEnabled()) {
        self.storeEventToNativeAnalytic('map_control_panel_zoom_out')
        self.onButtonDown(false)
      }
    },
    onTouchendZoomOut () {
      const self = this
      if (self.utility.isTouchEnabled()) {
        self.onButtonUp()
      }
    },
    onButtonDown (isZoomIn = true) {
      const self = this
      self.prevIsTracking = self.isTracking
      self.prevIsTrackingDirection = self.isTrackingDirection
      self.$root.$emit('setIsTracking', false)
      self.holdButton = true
      const p = self.map.placeholder()
      const c = { x: p.offsetWidth >> 1, y: p.offsetHeight >> 1 }
      const x = () => {
        if (self.isLongdoMapV3) {
          if (isZoomIn) {
            self.map.zoom(self.map.zoom() + 0.05, false)
          } else {
            self.map.zoom(self.map.zoom() - 0.05, false)
          }
        } else {
          self.map.pinch(isZoomIn ? 1.05 : 1 / 1.05, c)
        }
        if (self.holdButton) {
          window.requestAnimationFrame(x)
        } else {
          const zoom = self.map.zoom()
          const roundedZoom = isZoomIn ? Math.ceil(zoom) : Math.floor(zoom)
          self.map.zoom(roundedZoom)
          if (!self.isLongdoMapV3) {
            self.map.pinchEnd(c)
          }
        }
      }
      window.requestAnimationFrame(x)
    },
    onButtonUp () {
      const self = this
      self.holdButton = false
      self.$root.$emit('setIsTracking', self.prevIsTracking)
      self.$root.$emit('setIsTrackingDirection', self.prevIsTrackingDirection)
      self.prevIsTracking = false
      self.prevIsTrackingDirection = false
    }
  }
}
</script>

<style scoped lang="scss">
.control-map-panel-v2 {
  width: max-content;
  height: max-content;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 80;
  position: fixed;
  border-radius: 6px;
  bottom: calc(#{$toolBarHeight} + 44px);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
  left: 14px;
  transition: left 0.3s ease 0s,
    bottom 0.3s ease 0s;

  &.collapsing-explore-panel {
    bottom: calc(#{$toolBarHeight} + 60px + 32px);
  }

  &.collapsing-search-result-panel {
    bottom: calc(#{$toolBarHeight} + 60px + 32px);
  }

  &.collapsing-my-place-panel {
    bottom: calc(#{$toolBarHeight} + 60px + 32px);
  }

  &.collapsing-explore-nearby-panel {
    bottom: calc(#{$toolBarHeight} + 60px + 32px);
  }

  &.collapsing-event-panel {
    bottom: calc(#{$toolBarHeight} + 44px);
  }

  >button {
    &:first-child {
      border-radius: 6px 6px 0px 0px;
    }
    &:last-child {
      border-bottom: none;
      border-radius: 0px 0px 6px 6px;
    }
    width: 36px;
    height: 36px;
    border: none;
    border-bottom: solid 1px $lightGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    user-select: none;

    &.tracking {
      // background-color: $primary;

      >i {
        color: $extraDarkPrimary;
      }
    }

    >i {
      color: $primary;
      font-size: 20px;
      user-select: none;
      pointer-events: none;
    }
  }
}

.control-map-panel-v2.hide {
  left: -64px;
}

.control-map-panel-v2.showing-popup {
  bottom: calc(#{$toolBarHeight} + 30% + 60px);

  &.place-card-step-mini {
    bottom: calc(#{$toolBarHeight} + 72px + 60px);
  }
}

.control-map-panel-v2.hide.showing-popup {
  bottom: calc(#{$toolBarHeight} + 30% + 60px);
}

.control-map-panel-v2.showing-explore-panel {
  bottom: calc(40% + 38px + 6px) !important;
}

.control-map-panel-v2.hide.showing-explore-panel {
  bottom: calc(40% + 38px + 6px) !important;
}

.control-map-panel-v2.showing-search-result-panel {
  bottom: calc(40% + 38px + 6px) !important;
}

.control-map-panel-v2.hide.showing-search-result-panel {
  bottom: calc(40% + 12px) !important;
}

.control-map-panel-v2.showing-my-place-panel {
  bottom: calc(40% + 38px + 6px) !important;
}

.control-map-panel-v2.hide.showing-my-place-panel {
  bottom: calc(40% + 12px) !important;
}

.control-map-panel-v2.showing-explore-nearby-panel {
  bottom: calc(40% + 38px + 6px) !important;
}

.control-map-panel-v2.hide.showing-explore-nearby-panel {
  bottom: calc(40% + 12px) !important;
}

.control-map-panel-v2.showing-event-panel {
  bottom: calc(40% + 38px + 6px) !important;
}

.control-map-panel-v2.hide.showing-event-panel {
  bottom: calc(40% + 12px) !important;
}

.control-map-panel-v2.routing {
  left: -64px
}

@media only screen and (min-width: $mobileMaxSize) and (min-height: $mobileMaxHeight) {
  .control-map-panel {
    display: none;
  }
}

@media only screen and (max-width: $mobileMaxSize), (max-height: $mobileMaxHeight) {
  .control-map-panel {
    width: max-content;
    height: max-content;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
    border: none;
    align-items: center;
    z-index: 80;
    position: fixed;
    bottom: calc(#{$toolBarHeight} + 60px);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
    left: 14px;
    transition: left 0.3s ease 0s,
      bottom 0.3s ease 0s;

    &.collapsing-explore-panel {
      bottom: calc(#{$toolBarHeight} + 60px + 32px);
    }

    &.collapsing-search-result-panel {
      bottom: calc(#{$toolBarHeight} + 60px + 32px);
    }

    &.collapsing-my-place-panel {
      bottom: calc(#{$toolBarHeight} + 60px + 32px);
    }

    &.collapsing-explore-nearby-panel {
      bottom: calc(#{$toolBarHeight} + 60px + 32px);
    }

    &.collapsing-event-panel {
      bottom: calc(#{$toolBarHeight} + 80px);
    }

    >button {
      &:first-child {
        border-radius: 6px 6px 0px 0px;
      }
      &:last-child {
        border-bottom: none;
        border-radius: 0px 0px 6px 6px;
      }
      width: 36px;
      height: 36px;
      border: none;
      border-bottom: solid 1px $lightGrey;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(255, 255, 255);
      outline: none;
      cursor: pointer;
      user-select: none;

      &.tracking {
        // background-color: $primary;

        >i {
          color: $extraDarkPrimary;
        }
      }
      >i {
        color: $primary;
        font-size: 20px;
        user-select: none;
        pointer-events: none;
      }

      >img {
        width: 30px;
        height: 30px;
      }

      &:disabled {
        >i {
          color: $extraLightPrimary;
        }
      }
    }
  }

  .control-map-panel.hide {
    left: -64px;
  }

  .control-map-panel.showing-popup {
    bottom: calc(#{$toolBarHeight} + 30% + 60px);

    &.place-card-step-mini {
      bottom: calc(#{$toolBarHeight} + 72px + 60px);
    }
  }

  .control-map-panel.hide.showing-popup {
    bottom: calc(#{$toolBarHeight} + 30% + 60px);
  }

  .control-map-panel.showing-explore-panel {
    bottom: calc(40% + 38px + 6px) !important;
  }

  .control-map-panel.hide.showing-explore-panel {
    bottom: calc(40% + 38px + 6px) !important;
  }

  .control-map-panel.showing-search-result-panel {
    bottom: calc(40% + 38px + 6px) !important;
  }

  .control-map-panel.hide.showing-search-result-panel {
    bottom: calc(40% + 12px) !important;
  }

  .control-map-panel.showing-my-place-panel {
    bottom: calc(40% + 38px + 6px) !important;
  }

  .control-map-panel.hide.showing-my-place-panel {
    bottom: calc(40% + 12px) !important;
  }

  .control-map-panel.showing-explore-nearby-panel {
    bottom: calc(40% + 38px + 6px) !important;
  }

  .control-map-panel.hide.showing-explore-nearby-panel {
    bottom: calc(40% + 12px) !important;
  }

  .control-map-panel.showing-event-panel {
    bottom: calc(40% + 38px + 6px) !important;
  }

  .control-map-panel.hide.showing-event-panel {
    bottom: calc(40% + 12px) !important;
  }

  .control-map-panel.routing {
    left: -64px
  }

}
</style>
