var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'special-control-map-panel-map3': _vm.isLongdoMapV3,
  'special-control-map-panel': !_vm.isLongdoMapV3,
  'hide': _vm.isHidingControlMapPanel || _vm.$route.hash === '#cam' || _vm.$route.hash === '#measurement' || _vm.$route.hash === '#routing' || _vm.$route.hash === '#events' || _vm.isShowingPopup ||
    (!_vm.isHidingExplorePanel && _vm.isExpandingExplorePanel) ||
    (!_vm.isHidingSearchResultPanel && _vm.isExpandingSearchResultPanel) ||
    (!_vm.isHidingMyPlacePanel && _vm.isExpandingMyPlacePanel) ||
    (!_vm.isHidingExploreNearbyPanel && _vm.isExpandingExploreNearbyPanel) ||
    (!_vm.isHidingEventPanel && _vm.isExpandingEventPanel),
  'traffic-product':_vm.isTrafficProduct,
  'showing-popup': _vm.isShowingPopup,
  'place-card-step-full': _vm.placeCardStep === 'FULL',
  'place-card-step-mid': _vm.placeCardStep === 'MID',
  'place-card-step-mini': _vm.placeCardStep === 'MINI',
  'showing-explore-panel': !_vm.isHidingExplorePanel && _vm.isExpandingExplorePanel,
  'collapsing-explore-panel': !_vm.isHidingExplorePanel && !_vm.isExpandingExplorePanel,
  'showing-search-result-panel': !_vm.isHidingSearchResultPanel && _vm.isExpandingSearchResultPanel,
  'collapsing-search-result-panel': !_vm.isHidingSearchResultPanel && !_vm.isExpandingSearchResultPanel,
  'showing-my-place-panel': !_vm.isHidingMyPlacePanel && _vm.isExpandingMyPlacePanel,
  'collapsing-my-place-panel': !_vm.isHidingMyPlacePanel && !_vm.isExpandingMyPlacePanel,
  'showing-explore-nearby-panel': !_vm.isHidingExploreNearbyPanel && _vm.isExpandingExploreNearbyPanel,
  'collapsing-explore-nearby-panel': !_vm.isHidingExploreNearbyPanel && !_vm.isExpandingExploreNearbyPanel,
  'showing-event-panel': !_vm.isHidingEventPanel && _vm.isExpandingEventPanel,
  'collapsing-event-panel': !_vm.isHidingEventPanel && !_vm.isExpandingEventPanel
}},[(!_vm.isTrafficProduct && !_vm.isDisableLoginFeature && _vm.$route.hash !== '#routing')?_c('button',{staticClass:"ripple",attrs:{"title":_vm.$t('specialMapControlPanel.createPlace')},on:{"click":function($event){return _vm.clickCreatePlace()}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" add_location_alt ")])]):_vm._e(),(_vm.isTrafficProduct && !_vm.isDisableLoginFeature && _vm.$route.hash !== '#routing')?_c('button',{staticClass:"ripple report-event-btn",attrs:{"title":_vm.$t('specialMapControlPanel.createTrafficIncident')},on:{"click":function($event){return _vm.clickCreateTrafficIncident()}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" campaign ")])]):_vm._e(),(_vm.enableMeasurementToolButton)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLongdoMapV3 && !_vm.isTrafficProduct),expression:"!isLongdoMapV3 && !isTrafficProduct"}],staticClass:"ripple",attrs:{"title":_vm.$t('specialMapControlPanel.measurement')},on:{"click":function($event){return _vm.clickMeasurement()}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" straighten ")])]):_vm._e(),(_vm.isCheckNearby)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLongdoMapV3 && !_vm.isTrafficProduct),expression:"!isLongdoMapV3 && !isTrafficProduct"}],staticClass:"ripple check-nearby",attrs:{"title":_vm.$t('specialMapControlPanel.checkNearby')},on:{"click":function($event){return _vm.clickCheckNearby()}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" radar ")])]):_vm._e(),(_vm.isCheckNearby && !_vm.isLongdoMapV3 && !_vm.isTrafficProduct)?_c('button',{class:{ inactive: !_vm.isActiveCityPlanThailand },on:{"click":_vm.onClickCityPlanThailandLayer}},[_c('i',{staticClass:"material-icons-round"},[_vm._v(" view_quilt ")])]):_vm._e(),(_vm.isActiveCityPlanThailand  && _vm.cityplanThailandAreaName !== '')?_c('div',{staticClass:"city-plan-thailand-area-name"},[_vm._v(" "+_vm._s(_vm.cityplanThailandAreaName)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }