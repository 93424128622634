<template>
  <div id="app" :class="{
    map: product === 'map',
    traffic: product === 'traffic'
  }">
    <NavigationBar/>
    <NavigationSideBar />
    <MobileEnablePermission v-if="isOnMobileApp"/>
    <div class="content">
      <router-view/>
    </div>
    <div id="truehits" style="display: none;" v-if="!isOffline">
      <iframe :id="trueHits + '?page=' + page" :src="trueHits + '?page=' + page"
        :key="'iframe-' + trueHits + '?page=' + page"
        width=14 height=17 frameborder=0 marginheight=0 marginwidth=0 scrolling=no
      ></iframe>
    </div>
    <div class="update-alert" v-if="updateExists">
      {{ $t(`app.alertUpdate.${product}`) }} ({{ appVersion }})
      <button class="ripple" @click="refreshApp">
        {{ $t('app.update') }}
      </button>
    </div>
  </div>
</template>

<script>
import { Settings } from 'luxon'
import NavigationBar from '@/components/NavigationBar.vue'
import NavigationSideBar from '@/components/NavigationSideBar.vue'
import MobileEnablePermission from '@/components/MobileEnablePermission.vue'

export default {
  name: 'App',
  components: {
    NavigationBar,
    NavigationSideBar,
    MobileEnablePermission
  },
  data () {
    return {
      trueHits: process.env.VUE_APP_TRUE_HITS,
      registration: null,
      updateExists: false,
      refreshing: false,
      hasClickedRefeshApp: false,
      isGeolocationMobileAppDisabled: false,
      isRealLocation: false,
      timeoutCountAskedActivityTrackingPermission: 0
    }
  },
  computed: {
    page () {
      const self = this
      if (self.$route.name === 'Place') {
        return `${process.env.VUE_APP_PRODUCT}-mobile-ooi-map`
      } else {
        return `${process.env.VUE_APP_PRODUCT}-mobile`
      }
    }
  },
  created () {
    const self = this
    document.addEventListener('swUpdated', self.updateAvailable, { once: true })
    if ('navigator' in window) {
      if ('serviceWorker' in window.navigator) {
        window.navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (!self.hasClickedRefeshApp) {
            return
          }
          // We'll also need to add 'refreshing' to our data originally set to false.
          if (self.refreshing) {
            return
          }
          self.refreshing = true
          self.hasClickedRefeshApp = false
          // Here the actual reload of the page occurs
          window.location.reload()
        })
      }
    }

    window.addEventListener('resize', function (event) {
      self.$root.$emit('resize', {
        width: window.innerWidth,
        height: window.innerHeight
      })
    })
  },
  async mounted () {
    const self = this
    self.$root.$on('setOnWatchGeolocation', self.onWatchGeolocationAndroid)
    if (self.isOnMobileApp) {
      const element = document.getElementById('app')
      element.classList.add('app-native')
    }
    if (self.lji.Util.isAndroidNativeApp()) {
      self.onWatchGeolocationAndroid()
    } else if (self.lji.Util.isIosNativeApp()) {
      // await self.lji.stopWatchGeolocation()
      self.lji.setOnWatchGeolocation({
        callback: (result) => {
          if (self.isGeolocationMobileAppDisabled) {
            self.isGeolocationMobileAppDisabled = false
            window.isGeolocationMobileAppDisabled = false
            self.$root.$emit('MobileAppGeolocationEnabled')
          }
          if (!self.isRealLocation) {
            self.isRealLocation = true
            self.$root.$emit('setIsRealLocation', true)
          }
          const coors = JSON.parse(result)
          window.currentLocation = {
            lat: Number(coors.latitude || window.currentLocation.lat),
            lon: Number(coors.longitude || window.currentLocation.lon)
          }
          window.accuracy = Number(coors.accuracy || window.accuracy || 5)
          window.heading = Number(coors.heading || window.heading)
          if (coors.courses !== -1) {
            window.courses = Number(coors.courses)
          }
          window.speed = Number(coors.speed || coors.speed) * 3.6 // native return m/s -> convert to km/hr
        },
        errorCallback: (errorMessage, errorCode) => {
          console.log({
            code: errorCode,
            message: errorMessage
          })
          if (errorMessage.code === 401) {
            if (!self.isGeolocationMobileAppDisabled) {
              self.$root.$emit('MobileAppGeolocationDisabled')
              window.isGeolocationMobileAppDisabled = true
              self.isGeolocationMobileAppDisabled = true
            }
          }
        }
      })

      // setTimeout(() => {
      //   self.askActivityTrackingPermissions()
      // }, 10000)
    }

    self.lji.alwaysOn({
      on: true
    })

    if ('lang' in self.$route.query || 'locale' in self.$route.query) {
      self.$i18n.locale = (self.$route.query.lang || self.$route.query.locale) === 'th' ? 'th' : 'en'
      localStorage.lang = self.$i18n.locale
      Settings.defaultLocale = self.$i18n.locale
    } else {
      if (localStorage.lang) {
        self.$i18n.locale = localStorage.lang
        Settings.defaultLocale = localStorage.lang
      } else if (self.isOnMobileApp) {
        try {
          const tmp = await self.lji.getLanguage()
          // {String} lang
          if (self.lji.Util.isIosNativeApp()) {
            self.$i18n.locale = tmp
            Settings.defaultLocale = tmp
          } else if (self.lji.Util.isAndroidNativeApp()) {
            if (tmp.includes('th')) {
              self.$i18n.locale = 'th'
              Settings.defaultLocale = 'th'
            } else {
              self.$i18n.locale = 'en'
              Settings.defaultLocale = 'en'
            }
          }
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
    }
    if (!self.isOffline) {
      self.longdoAccount.bind('loginFormClose', () => {
        self.$root.$emit('loginFormClose')
        return false
      })
      self.longdoAccount.bind('loginComplete', (user) => {
        self.longdoAccount.pdpa.init({
          lang: self.$i18n.locale,
          client: process.env.VUE_APP_PRODUCT
        })
        if (self.lji !== null) {
          if (self.lji.Util.isAndroidNativeApp()) {
            self.lji.flushCookie()
          }
        }
        self.$root.$emit('loginComplete', user)
        return false
      })
    }

    const userSession = await self.utility.getUserSession()
    if (userSession) {
      if (userSession.longdousertoken !== '' && userSession.longdousertoken !== null && userSession.longdousertoken !== undefined) {
        self.$root.$emit('loginComplete', userSession)
      }
    }
  },
  methods: {
    onWatchGeolocationAndroid () {
      const self = this
      self.lji.stopWatchGeolocation()
      self.lji.setOnWatchGeolocation({
        callback: (result) => {
          if (self.isGeolocationMobileAppDisabled) {
            self.isGeolocationMobileAppDisabled = false
            window.isGeolocationMobileAppDisabled = false
            self.$root.$emit('MobileAppGeolocationEnabled')
          }
          if (!self.isRealLocation) {
            self.isRealLocation = true
            self.$root.$emit('setIsRealLocation', true)
          }
          const coors = JSON.parse(result)
          window.currentLocation = {
            lat: Number(coors.latitude),
            lon: Number(coors.longitude)
          }
          window.accuracy = Number(coors.accuracy || 5)

          // https://mmtech.slack.com/archives/G02FEAQ1S/p1709880849534439
          window.heading = Number(coors.heading || window.heading)
          window.courses = Number(coors.heading || window.heading)

          window.speed = Number(coors.speed || coors.speed) * 3.6 // native return m/s -> convert to km/hr
          window.isGeolocationError402 = false
        },
        errorCallback: async (errorMessage, errorCode) => {
          console.log({
            code: errorCode,
            message: errorMessage
          })
          window.isGeolocationError402 = false
          if (errorMessage.code === 401) {
            if (!self.isGeolocationMobileAppDisabled) {
              self.$root.$emit('MobileAppGeolocationDisabled')
              window.isGeolocationMobileAppDisabled = true
              self.isGeolocationMobileAppDisabled = true
              self.lji.stopWatchGeolocation()
            }
          } else if (errorMessage.code === 402) {
            if (!self.isGeolocationMobileAppDisabled) {
              self.$root.$emit('MobileAppGeolocationDisabled')
              window.isGeolocationMobileAppDisabled = true
              self.isGeolocationMobileAppDisabled = true
              window.isGeolocationError402 = true
              self.lji.stopWatchGeolocation()
            }
          }
        }
      })
    },
    askActivityTrackingPermissions () {
      const self = this
      self.timeoutCountAskedActivityTrackingPermission++
      if (self.timeoutCountAskedActivityTrackingPermission <= 20 && window.isEnableGA !== true) {
        // ask activity tracking permision
        self.lji.manageActivityTrackingPermissions()
          .then(
            (result) => {
              if (result) {
                window.onCanEnableGA()
              } else {
                setTimeout(() => {
                  self.askActivityTrackingPermissions()
                }, 5000)
              }
            },
            (_) => {
              setTimeout(() => {
                self.askActivityTrackingPermissions()
              }, 1000)
            }
          )
      }
    },
    updateAvailable (event) {
      const self = this
      self.registration = event.detail
      self.updateExists = true
    },
    refreshApp () {
      const self = this
      self.hasClickedRefeshApp = true
      self.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!self.registration || !self.registration.waiting) {
        return
      }
      // Send message to SW to skip the waiting and activate the new SW
      self.registration.waiting.postMessage('skipWaiting')
    }
  },
  watch: {
    async $route (to, from) {
      const self = this
      self.storePathToNativeAnalytic(to, from)
      if (from.name === 'CreatePlace' || from.name === 'EditPlace' || from.name === 'InfoPlace') {
        self.isRealLocation = false
        self.$root.$emit('setIsRealLocation', false)
      }
    }
  }
}
</script>

<style lang="scss">
// @import '@/assets/style/variables.scss';
@import '@/assets/style/main.scss';

#app {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  > .content {
    width: 100%;
    height: calc(100% - #{$navigationBarHeight});
  }
}
</style>

<style lang="scss" scoped>
.update-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  background-color: rgb(255, 255, 255);
  padding: 12px 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  max-width: calc(100% - 48px);
  font-size: 16px;
  font-family: Prompt;

  button {
    border-radius: 6px;
    border: 1px solid $primary;
    background-color: transparent;
    padding: 6px 24px;
    font-weight: bold;
    color: $darkPrimary;
    margin-top: 12px;
    font-size: 16px;
    font-family: Prompt;
    width: max-content;
  }
}
</style>
