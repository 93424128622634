var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'map-type-panel': !_vm.isLongdoMapV3,
  'map-type-panel-map3': _vm.isLongdoMapV3,
  'hide': _vm.isHidingMapTypePanel
}},[_c('label',{staticClass:"panel-name"},[_vm._v(" "+_vm._s(_vm.$t('mapTypePanel.name'))+" ")]),_c('div',{staticClass:"divider-h"}),_c('button',{staticClass:"close ripple",on:{"click":_vm.clickClose}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("close")])]),(!_vm.isHidingMapTypePanel)?_c('div',{staticClass:"panel-content"},[(_vm.isLongdoMapV3)?_c('button',{staticClass:"toggle-input",class:{active: _vm.isShowingPoi && !_vm.isGoogleBaseMap},on:{"click":function($event){$event.stopPropagation();_vm.isShowingPoi = !_vm.isShowingPoi}}},[_vm._m(0),_c('label',[_vm._v(_vm._s(_vm.$t('mapTypePanel.showPoi')))])]):_vm._e(),(_vm.isLongdoMapV3)?_c('button',{staticClass:"toggle-input",class:{active: _vm.isShowingShade},on:{"click":function($event){$event.stopPropagation();_vm.isShowingShade = !_vm.isShowingShade}}},[_vm._m(1),_c('label',[_vm._v(_vm._s(_vm.$t('mapTypePanel.showShade')))])]):_vm._e(),_vm._l((_vm.mapTypeList),function(group){return _c('div',{key:group.groupTitle,staticClass:"map-type-group"},[_c('label',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t((_vm.isLongdoMapV3 ? 'mapTypePanel.v3.group.' : '') + group.groupTitle))+" ")]),_c('div',{staticClass:"map-type-list"},_vm._l((group.items),function(mapType){return _c('button',{key:mapType,staticClass:"map-type ripple",class:{
          'map-type-v2': !_vm.isLongdoMapV3,
          'active': mapType === _vm.selectedMapType
        },on:{"click":function($event){return _vm.clickMapType(mapType)}}},[_c('img',{staticClass:"thumbnail",attrs:{"src":_vm.utility.getImgPath(("mapType/" + (_vm.isLongdoMapV3 ? 'v3/' : 'v2/') + mapType + "@3x.png")),"srcset":_vm.utility.getImgPathSet(("mapType/" + (_vm.isLongdoMapV3 ? 'v3/' : 'v2/') + mapType)),"loading":"lazy"}}),_c('label',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('mapTypePanel.' + (_vm.isLongdoMapV3 ? 'v3.' : '') + mapType))+" ")])])}),0)])})],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle"},[_c('div',{staticClass:"track"}),_c('div',{staticClass:"thumb"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle"},[_c('div',{staticClass:"track"}),_c('div',{staticClass:"thumb"})])}]

export { render, staticRenderFns }