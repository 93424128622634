<template>
  <div :class="{
    'choose-location-widget': !isLongdoMapV3,
    'choose-location-widget-map3': isLongdoMapV3
  }">
    <div></div>
    <div class="head">
      <button class="back ripple" @click="onClickBack">
        <i class="material-icons-round">
          arrow_back
        </i>
      </button>
      <input ref="search-input" type="text" :placeholder="$t('chooseLocationWidget.placeholder')" :value="keywordInput"
        @input="changeSearchInput" />
      <button class="clear ripple" @click="onClickClear">
        <i class="material-icons-round">
          clear
        </i>
      </button>
    </div>
    <button class="ripple current-location" @click="chooseCurrentLocation">
      <i class="material-icons-round">
        my_location
      </i>
      <label>
        {{ $t('chooseLocationWidget.chooseCurrentLocation') }}
      </label>
    </button>
    <button class="ripple show-map" @click="isChoosingOnMap = true">
      <i class="material-icons-round">
        map
      </i>
      <label>
        {{ $t('chooseLocationWidget.chooseOnMap') }}
      </label>
    </button>
    <div class="choose-on-map-container" v-show="isChoosingOnMap">
      <div class="head">
        <button @click="isChoosingOnMap = false">
          <i class="material-icons-round">
            arrow_back
          </i>
        </button>
        <label>
          {{ $t('chooseLocationWidget.moveToChoose') }}
        </label>
        <button class="confirm ripple" @click="confirmChooseLocationOnMap">
          {{ $t('chooseLocationWidget.confirm') }}
        </button>
      </div>
      <img src="https://map.longdo.com/mmmap/images/ic_pin.svg" alt="" style="
      height: 56px;
      left: 50%;
      position: absolute;
      top: 50%;
      z-index: 9999;
      transform: translate(-50%,-100%)" />
      <longdo-map class="map-container" @load="loadedLongdoMap" />
    </div>
    <div ref="search-result-list" class="search-result-list">
      <template v-for="(searchResult, index) in searchResultList">
        <div class="ripple search-result" :key="'search-result-for-choose-location-' + index"
          @click="onClickSearchResult(searchResult)">
          <div class="icon">
            <img :src="searchResult.markicon2x"
              :srcset="searchResult.markicon + ' 1x, ' + searchResult.markicon2x + ' 2x'" loading="lazy">
          </div>
          <div class="detail">
            <div class="name">{{ searchResult.name }}</div>
            <div class="address">{{ searchResult.address }}</div>
          </div>
          <i class="material-icons-round">
            input
          </i>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseLocationWidget',
  props: {
    initialKeyword: {
      type: String,
      default: ''
    },
    trickInit: {
      type: Boolean
    },
    lat: {
      type: Number
    },
    lon: {
      type: Number
    },
    zoom: {
      type: Number
    }
  },
  data () {
    return {
      keywordInput: '',
      keyword: '',
      crosshairLocation: {
        lat: localStorage.lat ? Number(localStorage.lat) : Number(process.env.VUE_APP_DEFAULT_LAT),
        lon: localStorage.lon ? Number(localStorage.lon) : Number(process.env.VUE_APP_DEFAULT_LON)
      },
      searchLocation: {
        lat: localStorage.lat ? Number(localStorage.lat) : Number(process.env.VUE_APP_DEFAULT_LAT),
        lon: localStorage.lon ? Number(localStorage.lon) : Number(process.env.VUE_APP_DEFAULT_LON)
      },
      offset: 0,
      limit: 20,
      hasMore: false,
      cacheData: [],
      searchResultList: [],
      isLoadingSearch: false,
      searchTask: null,
      isChoosingOnMap: false,
      map: null
    }
  },
  computed: {
  },
  mounted () {
    const self = this
    self.init()
  },
  methods: {
    init () {
      const self = this
      self.isChoosingOnMap = false
      self.keywordInput = self.initialKeyword
      if (self.$route.hash === '#choose-location-for-routing') {
        setTimeout(() => {
          self.$refs['search-input'].focus()
        }, 210)
      }
      self.search()
    },
    onClickBack () {
      const self = this
      self.$emit('clickBack')
    },
    onClickClear () {
      const self = this
      self.keywordInput = ''
      self.$refs['search-input'].focus()
      self.search()
    },
    onClickSearchResult (searchResult) {
      const self = this
      self.$emit('clickSearchResult', searchResult)
    },
    loadedLongdoMap (map) {
      const self = this
      self.map = map
      if (!self.isLongdoMapV3) {
        self.map.Layers.externalOptions({
          googleQuery: 'key=' + process.env.VUE_APP_GOOGLE_MAP_TILE_KEY,
          mapboxKey: process.env.VUE_APP_MAPBOX_KEY
        })
        self.map.Ui.DPad.visible(false)
        self.map.Ui.Geolocation.visible(false)
        self.map.Ui.LayerSelector.visible(false)
        self.map.Ui.Toolbar.visible(false)
        self.map.Ui.Zoombar.visible(false)
        self.map.Ui.Fullscreen.visible(false)
        self.map.Event.bind('ready', () => {
          self.map.Event.bind('location', () => {
            self.$emit('locationChange', self.map.location())
          })
          self.map.Event.bind('zoom', () => {
            self.$emit('zoomChange', self.map.zoom())
          })
        })
        var dolLayer = new window.longdo.Layer('', {
          type: window.longdo.LayerType.Custom,
          url: function (projection, tile, zoom, hd) {
            const mode = 'dol_hd'
            return `https://ms.longdo.com/mmmap/img.php?mode=${mode}&proj=${projection.longdoName}&zoom=${zoom}&x=${tile.u}&y=${tile.v}`
          },
          zoomRange: {
            min: 17,
            max: 20
          },
          source: {
            max: 19
          },
          opacity: 0.145
        })
        self.map.Layers.add(dolLayer)
      } else {
        self.map.Event.bind('ready', () => {
          self.map.Event.bind('location', () => {
            self.$emit('locationChange', self.map.location())
          })
          self.map.Event.bind('zoom', () => {
            self.$emit('zoomChange', self.map.zoom())
          })
        })
        self.map.Renderer.dragRotate.disable()
        self.map.Renderer.touchZoomRotate.disableRotation()
        // self.map.Ui.context.visible(false)
      }
    },
    async confirmChooseLocationOnMap () {
      const self = this
      const location = self.map.location()
      location.lat = parseFloat(Number(location.lat).toFixed(6))
      location.lon = parseFloat(Number(location.lon).toFixed(6))
      const params = {
        lat: location.lat,
        lon: location.lon,
        offset: 0,
        limit: 1,
        locale: self.$i18n.locale,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p',
        span: '100m'
      }
      const result = await self.api.searchNearbyPlace(params)
      if (result.status === 200) {
        if (result.data.data.length > 0) {
          const isSnap = Number(result.data.data[0].distance.replace('m', '')) < 2
          self.$emit('clickSearchResult', {
            name: result.data.data[0].name,
            address: result.data.data[0].address,
            // lat: isSnap ? result.data.data[0].lat : location.lat,
            // lon: isSnap ? result.data.data[0].lon : location.lon
            lat: isSnap ? location.lat : location.lat,
            lon: isSnap ? location.lon : location.lon
          })
          return
        }
      }
      const address = await self.getAddressString(location.lat, location.lon)
      self.$emit('clickSearchResult', {
        name: location.lat + ', ' + location.lon,
        address: address,
        lat: location.lat,
        lon: location.lon
      })
      self.isChoosingOnMap = false
    },
    changeSearchInput (evt) {
      const self = this
      self.keywordInput = evt.target.value
      if (self.searchTask) {
        clearTimeout(self.searchTask)
      }
      self.searchTask = setTimeout(() => {
        self.search()
      }, 300)
    },
    async search () {
      const self = this
      self.isLoadingSearch = true
      self.keyword = self.utility.allTrim(self.keywordInput)
      if (self.keyword.length < 2) {
        self.searchResultList = []
        self.hasMore = false
        return false
      }

      const latLon = self.utility.validateLatLonFromString(self.keyword)
      const utm = self.utility.validateUtmFromString(self.keyword)

      if (latLon) {
        latLon.lat = Number(latLon.lat).toFixed(6)
        latLon.lon = Number(latLon.lon).toFixed(6)
        self.keywordInput = latLon.lat + ', ' + latLon.lon
        const address = await self.getAddressString(latLon.lat, latLon.lon)
        self.searchResultList = [{
          name: latLon.lat + ', ' + latLon.lon,
          address: address,
          markicon: process.env.VUE_APP_LONGDO_MAP_PIN,
          markicon2x: process.env.VUE_APP_LONGDO_MAP_PIN,
          lat: Number(latLon.lat),
          lon: Number(latLon.lon)
        }]
        self.hasMore = false
        self.$refs['search-result-list'].scrollTop = 0
      } else if (utm) {
        const latLonFromUtm = self.utility.convertUtmToLatLon(utm)
        latLonFromUtm.lat = Number(latLonFromUtm.lat).toFixed(6)
        latLonFromUtm.lon = Number(latLonFromUtm.lon).toFixed(6)
        self.keywordInput = latLonFromUtm.lat + ', ' + latLonFromUtm.lon
        const address = await self.getAddressString(latLonFromUtm.lat, latLonFromUtm.lon)
        self.searchResultList = [{
          name: latLonFromUtm.lat + ', ' + latLonFromUtm.lon,
          address: address,
          markicon: process.env.VUE_APP_LONGDO_MAP_PIN,
          markicon2x: process.env.VUE_APP_LONGDO_MAP_PIN,
          lat: latLonFromUtm.lat,
          lon: latLonFromUtm.lon
        }]
        self.hasMore = false
        self.$refs['search-result-list'].scrollTop = 0
      } else if (self.keyword.length >= 2) {
        self.searchLocation = self.crosshairLocation
        self.offset = 0
        const params = {
          keyword: self.keyword,
          lat: self.searchLocation.lat,
          lon: self.searchLocation.lon,
          offset: self.offset,
          limit: self.limit,
          locale: self.$i18n.locale,
          // dataset: 'poi-changed-today,poi-contrib,main2p,osm-points'
          dataset: 'data2p,con,change,osmpnt,overture2p'
        }

        const cacheKey = JSON.stringify(params)
        const cacheIndex = self.cacheData.map(c => c.key).indexOf(cacheKey)
        let result
        if (cacheIndex !== -1) {
          result = self.cacheData[cacheIndex].value
        } else {
          // result = await self.api.searchNearbyPlace(params)
          result = await self.api.searchPlaceRestApi(params, self.isUseMwaAndNso)
          if (result.data.data.length === 0) {
            params.isSmart = true
            result = await self.api.searchPlaceRestApi(params, self.isUseMwaAndNso)
          }
          result.data.data.forEach(item => {
            item.markicon = process.env.VUE_APP_LONGDO_MAP_ICON + item.icon
            item.markicon2x = process.env.VUE_APP_LONGDO_MAP_HD_ICON + item.icon
          })
          self.cacheData.push({
            key: cacheKey,
            value: result
          })
        }
        self.searchResultList = result.data.data
        self.hasMore = result.data.meta.hasmore
        self.$refs['search-result-list'].scrollTop = 0
      } else {
        self.searchResultList = []
        self.hasMore = false
      }

      self.isLoadingSearch = false
    },
    async getAddressString (lat, lon) {
      const self = this
      const addressKeyList = ['aoi', 'water', 'house_num', 'moo_name', 'moo', 'road', 'subdistrict', 'district', 'province', 'postcode', 'country']
      const result = await self.api.getAddress({
        lat: lat,
        lon: lon,
        locale: self.$i18n.locale
      })
      let address = ''
      if (result.status === 200) {
        addressKeyList.forEach(addressKey => {
          address += (addressKey in result.data) ? (result.data[addressKey] + (self.$i18n.locale === 'th' ? ' ' : ', ')) : ''
        })
        address = address.substr(0, address.length - (self.$i18n.locale === 'th' ? 1 : 2))
      }
      return address
    },
    chooseCurrentLocation () {
      const self = this
      self.$emit('clickCurrentLocation')
    }
  },
  watch: {
    trickInit () {
      const self = this
      self.$nextTick(() => {
        if (self.trickInit) {
          self.init()
        }
      })
    },
    isChoosingOnMap () {
      const self = this
      self.$nextTick(() => {
        self.map.location({ lat: self.lat, lon: self.lon }, false)
        self.map.zoom(self.zoom, false)
        self.map.resize()
      })
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: $mobileMaxSize) {
  .choose-location-widget-map3 {
    top: 0 !important
  }
}

.choose-location-widget-map3 {

  @media only screen and (max-width: $mobileMaxSize) {
    position: fixed;
    left: 0px;
    top: 30px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 160;
  }

  @media only screen and (min-width: $mobileMaxSize) {
    position: fixed;
    left: 10px;
    top: 50px;
    width: 490px;
    height: calc(100% - 150px);
    background-color: white;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 160;

  }

  >.head {
      padding: 12px;
      position: relative;

      >button.back {
        position: absolute;
        width: 36px;
        height: 36px;
        border: none;
        background-color: transparent;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 18px;
        left: 18px;

        >i {
          color: $grey;
        }
      }

      >input {
        line-height: 48px;
        width: 100%;
        height: 48px;
        padding: 0px calc(6px + 36px + 6px);
        background-color: rgba(255, 255, 255, 0.95);
        box-sizing: border-box;
        border-radius: 12px;
        border: none;
        font-size: 18px;
        outline: none;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
        appearance: none;
      }

      >button.clear {
        position: absolute;
        width: 36px;
        height: 36px;
        border: none;
        background-color: transparent;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 18px;
        right: 18px;

        >i {
          color: $grey;
        }
      }
    }

  >button.current-location,
  >button.show-map {
    border: none;
    padding: 12px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    >i {
      color: $primary;
    }

    >label {
      padding-left: 12px;
    }
  }

  >.search-result-list {
    height: calc(100% - 72px - 48px - 48px);
    overflow: hidden;
    overflow-y: auto;
    border-top: 1px solid $extraLightGrey;
    box-sizing: border-box;

    >.search-result {
      padding: 12px;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      >.icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      >.detail {
        width: calc(100% - 24px - 24px);
        box-sizing: border-box;
        padding: 0px 12px 0px 12px;

        >.address {
          font-size: 13px;
          color: $grey;
        }
      }

      >i {
        width: 24px;
        height: 24px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $extraLightGrey;
      }
    }
  }

  >.choose-on-map-container {
    @media only screen and (max-width: $mobileMaxSize) {
      height: 100%;
      top: 55px;
    }
    @media only screen and (min-width: $mobileMaxSize) {
      height: 100%;
      top: 30px;
    }
    width: 100%;
    position: fixed;
    z-index: 170;
    left: 0px;
    background-color: rgb(255, 255, 255);

    >.head {
      @media only screen and (max-width: $mobileMaxSize) {
        top: 0px;
        background-color: $primary;

        >button {
          width: 36px;
          height: 36px;
          background-color: transparent;
          padding: 0px;
          border: none;
          color: rgb(255, 255, 255);

          >i {
            color: rgb(255, 255, 255);
          }
        }

        >button.confirm {
          width: 54px;
          text-align: right;
          padding-right: 6px;
        }

        >label {
          text-align: center;
          width: calc(100% - 36px - 54px);
          color: rgb(255, 255, 255);
        }
      }
      @media only screen and (min-width: $mobileMaxSize) {
        top: 54px;
        width: 400px;
        left: calc(50vw - 200px);
        background-color: rgb(255, 255, 255);
        border-radius: 24px;
        padding: 0px 20px;

        >button {
          width: 36px;
          height: 36px;
          background-color: transparent;
          padding: 0px;
          border-color: transparent;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          >i {
            color: $primary;
          }
        }

        >button.confirm {
          width: 66px;
          background-color: $primary;
          color:rgb(255, 255, 255);
        }

        >label {
          text-align: center;
          width: calc(100% - 36px - 54px);
          color: rgb(0,0,0);
        }
      }
      height: 54px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;position: fixed;
      z-index: 180;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      padding: 6px;
      box-sizing: border-box;

    }

    >.map-container {
      width: 100%;
      height: calc(100% - 54px);
    }
  }
}

.choose-location-widget {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 160;

  >.head {
    padding: 12px;
    position: relative;

    >button.back {
      position: absolute;
      width: 36px;
      height: 36px;
      border: none;
      background-color: transparent;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 18px;
      left: 18px;

      >i {
        color: $grey;
      }
    }

    >input {
      line-height: 48px;
      width: 100%;
      height: 48px;
      padding: 0px calc(6px + 36px + 6px);
      background-color: rgba(255, 255, 255, 0.95);
      box-sizing: border-box;
      border-radius: 12px;
      border: none;
      font-size: 18px;
      outline: none;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      appearance: none;
    }

    >button.clear {
      position: absolute;
      width: 36px;
      height: 36px;
      border: none;
      background-color: transparent;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 18px;
      right: 18px;

      >i {
        color: $grey;
      }
    }
  }

  >button.current-location,
  >button.show-map {
    border: none;
    padding: 12px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    >i {
      color: $primary;
    }

    >label {
      padding-left: 12px;
    }
  }

  >.search-result-list {
    height: calc(100% - 72px - 48px - 48px);
    overflow: hidden;
    overflow-y: auto;
    border-top: 1px solid $extraLightGrey;
    box-sizing: border-box;

    >.search-result {
      padding: 12px;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      >.icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      >.detail {
        width: calc(100% - 24px - 24px);
        box-sizing: border-box;
        padding: 0px 12px 0px 12px;

        >.address {
          font-size: 13px;
          color: $grey;
        }
      }

      >i {
        width: 24px;
        height: 24px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $extraLightGrey;
      }
    }
  }

  >.choose-on-map-container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 170;
    top: 0px;
    left: 0px;
    background-color: rgb(255, 255, 255);

    >.head {
      @media only screen and (max-width: $mobileMaxSize) {
        top: 0px;
        background-color: $primary;

        >button {
          width: 36px;
          height: 36px;
          background-color: transparent;
          padding: 0px;
          border: none;
          color: rgb(255, 255, 255);

          >i {
            color: rgb(255, 255, 255);
          }
        }

        >button.confirm {
          width: 54px;
          text-align: right;
          padding-right: 6px;
        }

        >label {
          text-align: center;
          width: calc(100% - 36px - 54px);
          color: rgb(255, 255, 255);
        }
      }
      @media only screen and (min-width: $mobileMaxSize) {
        top: 24px;
        width: 400px;
        left: calc(50vw - 200px);
        background-color: rgb(255, 255, 255);
        border-radius: 24px;
        padding: 0px 20px;

        >button {
          width: 36px;
          height: 36px;
          background-color: transparent;
          padding: 0px;
          border-color: transparent;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          >i {
            color: $primary;
          }
        }

        >button.confirm {
          width: 66px;
          background-color: $primary;
          color:rgb(255, 255, 255);
        }

        >label {
          text-align: center;
          width: calc(100% - 36px - 54px);
          color: rgb(0,0,0);
        }
      }
      height: 54px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;position: fixed;
      z-index: 180;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      padding: 6px;
      box-sizing: border-box;

    }

    >.map-container {
      width: 100%;
      height: calc(100% - 54px);
    }
  }
}
</style>
