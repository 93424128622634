<template>
  <div class="image-modal">
    <div
    class="background"
  >
  </div>
  <div class="modal">
    <div class="top-modal">
        <div class="header">{{ $t('placeInfo.uploadImage') }}</div>
        <div class="content show-scrollbar">
            <ImageWidget
                v-for="(image, index) in imageList"
                :key="`image-${index}`"
                :image="image"
                :width="previewImageSize"
                :height="previewImageSize"
                :canEdit="image.file !== null"
                :canDelete="image.file !== null"
                @delete="onDeleteImage(index)"
            />
            <button
                class="add-image ripple"
                :style="{width: previewImageSize, height: previewImageSize}"
                @click="onClickAddImage"
                v-if="!emptyUploadImageList"
            >
                <i class="material-icons-round">add_photo_alternate</i>
                {{ $t('createPlace.addImage') }}
            </button>
        </div>
    </div>
    <div class="footer">
        <div class="button-list">
          <button class="cancel ripple" @click="onClickCancel">{{ $t('placeInfo.cancel') }}</button>
        <button class="ripple" @click="onClickConfirm">{{ $t('placeInfo.confirm') }}</button>
        </div>
    </div>
    </div>
  </div>

</template>

<script>
import ImageWidget from '@/components/ImageWidget.vue'

export default {
  components: {
    ImageWidget
  },
  props: {
    imageList: {
      type: Array,
      default: null
    }
  },
  computed: {
    previewImageSize () {
      // wait for multiple upload change to 128
      return '200px'
    },
    emptyUploadImageList () {
      // wait for multiple upload -> remove this var
      const self = this
      return self.imageList?.length
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    onClickAddImage () {
      const self = this
      self.$emit('addImage')
    },
    onDeleteImage (index) {
      const self = this
      self.$emit('deleteImage', index)
    },
    onClickCancel () {
      const self = this
      self.$emit('clickCancel')
    },
    onClickConfirm () {
      const self = this
      self.$emit('uploadImage')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-modal {
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    > .background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.4;
    }

    > .modal {
        // wait for multiple upload
        // width: 50vw;
        // height: 50vh;
        @media only screen and (max-width: $mobileMaxSize) , (max-height: $mobileMaxHeight) {
            // width: 80vw;
            // height: 80vh;
        }

        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        padding: 24px;
        border-radius: 12px;
        z-index: 10;
        gap: 12px;

        > .top-modal {
            display: flex;
            flex-direction: column;
            height: calc( 100% - 54px);
            width: 100%;
            gap: 12px;
            z-index: 10;

            > .header {
                color: $primary;
                font-size: 28px;
                text-align: start;
                width: max-content;
            }

            > .content {
                align-items: flex-start;
                width: 100%;
                height:fit-content;
                display: flex;
                gap: 12px;
                justify-content: center;
                flex-shrink: 1;
                overflow: auto;
                flex-wrap: wrap;

                > .add-image {
                    border: 2px solid $primary;
                    border-radius: 10px;
                    padding: 0px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    background-color: white;
                    font-family: Prompt;
                    color: $primary;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;

                    > i {
                        color: $primary;
                        // font-size: 24px !important;
                    }
                }
            }
        }

        > .footer {
            width: 100%;
            display: flex;
            flex-direction: column;

            justify-content: space-between;
            gap: 4px;

            > .warning {
              width: 100%;
            }
            > .button-list {
              display: flex;
              width: 100%;
              // wait for multiple upload
              // justify-content: flex-end;
              justify-content: space-between;
              > button {
                height: 36px;
                width: fit-content;
                background-color: $darkPrimary;
                padding: 12px 18px;
                border-radius: 16px;
                color: white;
                border: 1px solid $darkPrimary;
                font-family: Prompt;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 0px 6px;
                transition: background-color 0.3s ease 0s,
                  color 0.3s ease 0s;

                &.cancel {
                  border-color: $red;
                  background-color: $red;
                }
              }
            }
        }

    }

}
</style>
