<template>
  <div class="layer-panel"
    :class="{
      'hide': isHidingLayerPanel
    }"
  >
    <label class="panel-name">
      {{ $t('layerPanel.name') }}
    </label>
    <button class="close ripple" @click="clickClose">
      <i class="material-icons-round">close</i>
    </button>
    <div class="layer-list">
      <template v-for="layer in layerList.filter((layer) => { return layer.v2 === true })">
        <div :key="layer.name"
          class="layer-container"
        >
          <button :key="layer.name"
            class="layer"
            :class="{
              link: layer.type === 'link',
              active: selectedLayer.map(l => l.name).indexOf(layer.name) !== -1
            }"
            @click="toggleLayer(layer)"
          >
            <div>
              <div class="title">
                {{ layer.displayName[$i18n.locale] }}
                <i class="material-icons-round"
                  v-if="layer.type !== 'link' && ('description' in layer || 'legend' in layer || 'source' in layer)"
                  @click.stop="toggleInfo(layer)"
                >
                  help_outline
                </i>
              </div>
              <i class="material-icons-round close"
                v-if="layer.type !== 'link' && selectedLayer.map(l => l.name).indexOf(layer.name) !== -1"
              >
                close
              </i>
              <i class="material-icons-round link"
                v-if="layer.type === 'link'"
              >
                open_in_new
              </i>
            </div>
            <div class="control-traffic"
              v-if="layer.type === 'traffic' && selectedLayer.map(l => l.name).indexOf(layer.name) !== -1"
            >
              <button class="toggle-input"
                @click.stop="isShowingCamera = !isShowingCamera"
                :class="{active: isShowingCamera}"
              >
                <div class="toggle">
                  <div class="track"></div>
                  <div class="thumb"></div>
                </div>
                <label>{{ $t('layerPanel.camera') }}</label>
              </button>
              <button class="toggle-input"
                @click.stop="isShowingEvents = !isShowingEvents"
                :class="{active: isShowingEvents}"
              >
                <div class="toggle">
                  <div class="track"></div>
                  <div class="thumb"></div>
                </div>
                <label>{{ $t('layerPanel.events') }}</label>
              </button>
            </div>
            <div class="input-container"
              v-if="layer.type !== 'link' && 'opacity' in layer"
            >
              <input type="range"
                min="0"
                max="1"
                step="0.1"
                @click.stop="clickOpacityInput"
                @input="changeOpacity(layer)"
                v-model="layer.opacity"
              >
            </div>
            <img :src="baseUrl + 'img/layer/' + layer.name.replace(':', '') + '.jpg?version=' + appVersion" loading="lazy">
          </button>
          <div class="layer-info"
            v-if="layer.isShowingInfo && layer.type !== 'link' && selectedLayer.map(l => l.name).indexOf(layer.name) !== -1 && ('description' in layer || 'legend' in layer || 'source' in layer)"
          >
            <div class="field description"
              v-if="'description' in layer"
            >
              <label>{{ $t('layerPanel.description') }}</label>
              <div v-html="layer.description[$i18n.locale]"></div>
            </div>
            <div class="field legend"
              v-if="'legend' in layer"
            >
              <img :src="layer.legend[$i18n.locale]"
                :style="{maxWidth: layer.legend.maxWidth}"
                @click="clickLegend(layer.legend[$i18n.locale])"
              >
            </div>
            <div class="field source"
              v-if="'source' in layer"
            >
              <label>{{ $t('layerPanel.source') }}</label>
              <div v-html="layer.source[$i18n.locale]"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div id="rain-radar-panel"
      v-show="selectedLayer.filter(l => l.type === 'rainviewer').length > 0 &&
        ['#cam', '#layers', '#routing'].indexOf($route.hash) < 0 &&
        Object.getOwnPropertyNames($route.query).indexOf('search') < 0 &&
        ['Place', 'Event', 'Cam', 'AqiAir4Thai', 'AqiCN', 'Rainfall'].indexOf($route.name) < 0"
    >
      <div id="time-radar"></div>
      <div class="action">
        <button class="ripple" @click="rainRadar.rainBack()">
          <i class="material-icons-round">skip_previous</i>
        </button>
        <button class="ripple" @click="playRainRadar()">
          <i class="material-icons-round" v-if="!isAnimation">play_arrow</i>
          <i class="material-icons-round" v-if="isAnimation">stop</i>
        </button>
        <button class="ripple" @click="rainRadar.rainNext()">
          <i class="material-icons-round">skip_next</i>
        </button>
      </div>
    </div>
    <div
      ref="nearest-aqi"
      class="nearest-aqi"
      :class="{'shift-top': isShiftTop}"
      v-show="nearestAqiAir4Thai !== null || nearestAqiCN !== null"
    >
      <div ref="nearest-aqi-air4thai" class="nearest-aqi-air4thai" v-if="nearestAqiAir4Thai !== null" :style="nearestAqiAir4Thai !== null ? {backgroundColor: nearestAqiAir4Thai.aqi_attr.bgcolor} : {}">
        <div class="head">
          <div class="title" :style="nearestAqiAir4Thai !== null ? {color: nearestAqiAir4Thai.aqi_attr.fontcolor} : {}">AQI</div>
          <div class="icon"></div>
        </div>
        <div class="value" :style="nearestAqiAir4Thai !== null ? {color: nearestAqiAir4Thai.aqi_attr.fontcolor} : {}">{{ nearestAqiAir4Thai.aqi_value }}</div>
      </div>
      <!-- <div ref="nearest-aqi-cn" class="nearest-aqi-cn" v-if="nearestAqiCN !== null" :style="nearestAqiCN !== null ? {backgroundColor: nearestAqiCN.aqi_attr.bgcolor} : {}">
        <div class="head">
          <div class="title" :style="nearestAqiCN !== null ? {color: nearestAqiCN.aqi_attr.fontcolor} : {}">AQI</div>
        </div>
        <div class="value" :style="nearestAqiCN !== null ? {color: nearestAqiCN.aqi_attr.fontcolor} : {}">{{ nearestAqiCN.aqi_value }}</div>
      </div> -->
    </div>
    <!-- <a
      ref="traffic-index"
      href="https://traffic.longdo.com/trafficindex"
      target="_blank"
      class="ripple traffic-index"
      :class="{'shift-top': isShiftTop}"
      v-show="isTrafficProduct && isInBkkBbox"
    >
      <div class="number" :style="{color: trafficIndexColor}">{{ trafficIndex.toFixed(1) }}</div>
      <label class="province">
        {{ $t('layerPanel.bkkTrafficIndex') }}
      </label>
    </a> -->
  </div>
</template>

<script>
export default {
  name: 'LayerPanel',
  props: {
    map: {
      type: Object
    }
  },
  data () {
    return {
      selectedLayer: [],
      isShowingCamera: true,
      isShowingEvents: true,
      isForceOpenCamera: false,
      isForceOpenEvent: false,
      nearestAqiAir4Thai: null,
      nearestAqiCN: null,
      rainfallAccumulation2563: null,
      updateOpacityRainfallAccumulation2563Task: null,
      updateNearestAqiTask: null,
      isShiftTop: false,
      trafficIndex: 0.0,
      isInBkkBbox: false,
      isAnimation: false
    }
  },
  computed: {
    isHidingLayerPanel () {
      const self = this
      return self.$route.hash !== '#layers'
    },
    trafficIndexColor () {
      const self = this
      if (self.trafficIndex > 5.0) {
        return `rgb(255, ${Math.round(255 * (1 - self.trafficIndex * 0.1))}, 0)`
      } else if (self.trafficIndex > 0.0) {
        return `rgb(${Math.round(255 * self.trafficIndex * 0.2)}, 128, 0)`
      } else {
        return 'red'
      }
    }
  },
  mounted () {
    const self = this
    if (self.isTrafficProduct) {
      self.getTrafficIndex()
      self.updateShowingTrafficIndex()
      setInterval(() => {
        self.getTrafficIndex()
      }, 1000 * 60 * 5)
    }
    self.$parent.$on('clickMap', (r) => {
      self.isShiftTop = r
    })
    self.$root.$on('openLayer', self.onOpenLayer)
    self.$root.$on('hideLayer', self.onHideLayer)
    self.$root.$on('openCameraOnTrafficLayer', () => {
      self.isForceOpenCamera = true
      self.isShowingCamera = true
    })
    self.$root.$on('hideCameraOnTrafficLayer', () => {
      self.isShowingCamera = false
    })
    self.$root.$on('openEventOnTrafficLayer', () => {
      self.isForceOpenEvent = true
      self.isShowingEvents = true
    })
    self.$root.$on('hideEventOnTrafficLayer', () => {
      self.isShowingEvents = false
    })
    self.map.placeholder().appendChild(self.$refs['nearest-aqi'])
    // self.map.placeholder().appendChild(self.$refs['traffic-index'])
    self.map.Event.bind('location', () => {
      if (self.updateNearestAqiTask) {
        clearTimeout(self.updateNearestAqiTask)
      }
      self.updateNearestAqiTask = setTimeout(() => {
        self.updateNearestAqiAir4Thai()
        self.updateNearestAqiCN()
      }, 150)

      self.updateShowingTrafficIndex()
    })
    self.map.Event.bind('zoom', () => {
      self.updateNearestAqiAir4Thai()
      self.updateNearestAqiCN()
    })
  },
  methods: {
    updateShowingTrafficIndex () {
      const self = this
      self.isInBkkBbox = window.longdo.Util.contains(self.map.location(), [
        { lat: 13.9551981792133, lon: 100.327912387173 },
        { lat: 13.9551981792133, lon: 100.938516257044 },
        { lat: 13.4933895713799, lon: 100.938516257044 },
        { lat: 13.4933895713799, lon: 100.327912387173 },
        { lat: 13.9551981792133, lon: 100.327912387173 }
      ])
    },
    async getTrafficIndex () {
      const self = this
      const result = await self.api.getTrafficIndex()
      if (result.status === 200) {
        if (result.data) {
          self.trafficIndex = result.data.index || 0.0
        }
      }
    },
    async toggleLayer (layer, options) {
      const self = this
      if (layer.type === 'link') {
        window.open(layer.url, '_blank')
      } else {
        let activeAllLayerNameList = []
        const index = self.selectedLayer.map(l => l.name).indexOf(layer.name)
        if (index !== -1) {
          if (self.selectedLayer[index].type === 'WMS') {
            self.map.Layers.remove(self.selectedLayer[index].layerObject)
          } else if (
            self.selectedLayer[index].type === 'TMS' ||
            self.selectedLayer[index].type === 'Custom' ||
            self.selectedLayer[index].type === 'dol'
          ) {
            self.map.Layers.remove(self.selectedLayer[index].layerObject)
            if (layer.name === 'ldd_ortho') {
              activeAllLayerNameList = self.map.Layers.list().map(l => l.name())
              const removeLayList = ['iconstransp', 'iconstransp-en']
              if (activeAllLayerNameList.indexOf('thaichote') < 0) {
                self.map.Layers.list().forEach(l => {
                  if (removeLayList.indexOf(l.name()) >= 0) {
                    self.map.Layers.remove(l)
                  }
                })
              }
            }
          } else if (self.selectedLayer[index].type === 'future_masstransit') {
            self.map.Tags.remove('future_masstransit')
            self.map.Tags.remove('future_masstransit_station')
          } else if (self.selectedLayer[index].type === 'gistda_flood_year') {
            self.map.Layers.remove(self.selectedLayer[index].layerObject)
          } else if (self.selectedLayer[index].type === 'traffic') {
            self.map.Layers.remove(self.selectedLayer[index].layerObject)
            let isHideCamera = true
            if ('isHideCamera' in (options || {})) {
              isHideCamera = options.isHideCamera
            }
            if (isHideCamera) {
              self.map.Overlays.unload(window.longdo.Overlays.cameras)
            }
            let isHideEvent = true
            if ('isHideEvent' in (options || {})) {
              isHideEvent = options.isHideEvent
            }
            if (isHideEvent) {
              if (self.isTrafficProduct) {
                self.$emit('hideTrafficEvent')
              } else {
                self.map.Overlays.unload(window.longdo.Overlays.events)
              }
            }
          } else if (self.selectedLayer[index].type === 'aqiair4thai') {
            self.map.Overlays.list().forEach(o => {
              if ('aqiAir4ThaiData' in o) {
                self.map.Overlays.remove(o)
              }
            })
          } else if (self.selectedLayer[index].type === 'aqicn') {
            self.map.Tags.remove(self.getAqiCN)
            self.map.Overlays.list().forEach(o => {
              if ('aqiCNData' in o) {
                self.map.Overlays.remove(o)
              }
            })
          } else if (self.selectedLayer[index].type === 'rainfall_accumulation_2563') {
            self.hideRainfallAccumulation2563()
          } else if (self.selectedLayer[index].type === 'rainviewer') {
            self.playRainRadar()
            self.rainRadar.clearLayers(true)
            self.rainRadar.isAnimation = false
            self.isAnimation = self.rainRadar.isAnimation
          }
          self.selectedLayer.splice(index, 1)
          self.$root.$emit('afterHideLayer', layer.name)
        } else {
          if (layer.type === 'WMS') {
            if (!('layerObject' in layer)) {
              const layerOption = {
                type: window.longdo.LayerType.WMS,
                url: layer.url,
                srs: layer.srs,
                opacity: layer.opacity,
                weight: layer.weight
              }
              if ('refresh' in layer) {
                layerOption.refresh = layer.refresh
              }
              layer.layerObject = new window.longdo.Layer(layer.name, layerOption)
            }
            self.map.Layers.add(layer.layerObject)
          } else if (layer.type === 'TMS') {
            if (!('layerObject' in layer)) {
              const option = {
                type: window.longdo.LayerType.TMS,
                url: layer.url,
                zoomRange: layer.zoomRange,
                opacity: layer.opacity,
                weight: layer.weight
              }
              if ('refresh' in layer) {
                option.refresh = layer.refresh
              }
              layer.layerObject = new window.longdo.Layer('', option)
            }
            self.map.Layers.add(layer.layerObject)
            activeAllLayerNameList = self.map.Layers.list().map(l => l.name())
            if (layer.name === 'ldd_ortho' && activeAllLayerNameList.indexOf('thaichote') < 0) {
              const layerName = self.$i18n.locale === 'th' ? 'iconstransp' : 'iconstransp-en'
              const poiLayer = new window.longdo.Layer(layerName, {
                weight: -1
              })
              self.map.Layers.add(poiLayer)
            }
          } else if (layer.type === 'future_masstransit') {
            self.map.Tags.add('future_masstransit')
            self.map.Tags.add('future_masstransit_station')
          } else if (layer.type === 'gistda_flood_year') {
            if (!('layerObject' in layer)) {
              layer.layerObject = new window.longdo.Layer(layer.name, {
                opacity: layer.opacity,
                weight: layer.weight
              })
            }
            self.map.Layers.add(layer.layerObject)
          } else if (layer.type === 'traffic') {
            if (!('layerObject' in layer)) {
              layer.layerObject = window.longdo.Layers.TRAFFIC
            }
            layer.layerObject.opacity = layer.opacity
            self.map.Layers.insert(1000, layer.layerObject)
            if (self.isShowingCamera) {
              self.map.Overlays.load(window.longdo.Overlays.cameras)
            }
            if (self.isShowingEvents) {
              if (self.isTrafficProduct) {
                self.$emit('showTrafficEvents')
              } else {
                self.map.Overlays.load(window.longdo.Overlays.events)
              }
            }
          } else if (layer.type === 'aqiair4thai') {
            self.api.getAqiAir4Thai({
              locale: self.$i18n.locale
            }).then(result => {
              if (result.status === 200) {
                result.data.result.forEach(d => {
                  const aqiMarker = new window.longdo.Marker({
                    lat: Number(d.location.lat),
                    lon: Number(d.location.lon)
                  }, {
                    icon: {
                      html: `<div class="aqi-air-4-thai-marker" style="color: ${d.aqi_attr.fontcolor}; background-image: url('${d.aqi_attr.iconmarker}');">${d.aqi_value}</div>`,
                      offset: {
                        x: 16,
                        y: 32
                      }
                    },
                    popup: {
                      title: `<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">${self.$t('layerPanel.titleAqiAir4Thai')}</div>`,
                      detail: `
                        <div class="aqi-head">
                          <div class="aqi-value" style="background-color: ${d.aqi_attr.bgcolor};">
                            <div class="title" style="color: ${d.aqi_attr.fontcolor};">${d.aqi_attr.status_text}</div>
                            <div class="icon" style="background-image: url('${d.aqi_attr.iconstatus}')"></div>
                            <div class="value" style="color: ${d.aqi_attr.fontcolor};">${d.aqi_value}</div>
                          </div>
                          <div class="aqi-owner">
                            <div class="name">${d.name}</div>
                            <div class="address">${d.address}</div>
                            <div class="last-update">${self.$t('layerPanel.lastUpdate')} ${self.utility.getDateTimeStringFromUnixTimestamp(d.lastupdate, self.$i18n.locale)}</div>
                          </div>
                        </div>
                        <div class="aqi-info-row">
                          <div class="aqi-info">
                            <div class="title">${d.aqi_info.PM10.title}</div>
                            <div class="value">${d.aqi_info.PM10.value} <span class="unit">${d.aqi_info.PM10.unit}</span></div>
                          </div>
                          <div class="aqi-info">
                            <div class="title">${d.aqi_info.PM25.title}</div>
                            <div class="value">${d.aqi_info.PM25.value} <span class="unit">${d.aqi_info.PM25.unit}</span></div>
                          </div>
                          <div class="aqi-info">
                            <div class="title">${d.aqi_info.SO2.title}</div>
                            <div class="value">${d.aqi_info.SO2.value} <span class="unit">${d.aqi_info.SO2.unit}</span></div>
                          </div>
                        </div>
                        <div class="aqi-info-row">
                          <div class="aqi-info">
                            <div class="title">${d.aqi_info.CO.title}</div>
                            <div class="value">${d.aqi_info.CO.value} <span class="unit">${d.aqi_info.CO.unit}</span></div>
                          </div>
                          <div class="aqi-info">
                            <div class="title">${d.aqi_info.NO2.title}</div>
                            <div class="value">${d.aqi_info.NO2.value} <span class="unit">${d.aqi_info.NO2.unit}</span></div>
                          </div>
                          <div class="aqi-info">
                            <div class="title">${d.aqi_info.O3.title}</div>
                            <div class="value">${d.aqi_info.O3.value} <span class="unit">${d.aqi_info.O3.unit}</span></div>
                          </div>
                        </div>
                      `
                    }
                  })
                  aqiMarker.aqiAir4ThaiData = d
                  self.map.Overlays.add(aqiMarker)
                })
                self.updateNearestAqiAir4Thai()
              }
            })
          } else if (layer.type === 'aqicn') {
            self.map.Tags.add(self.getAqiCN)
          } else if (layer.type === 'rainfall_accumulation_2563') {
            if (self.rainfallAccumulation2563 !== null) {
              self.showRainfallAccumulation2563(layer)
            } else {
              self.api.getRainfallAccumulation2563().then(result => {
                if (result !== false) {
                  if (result.status === 200) {
                    self.rainfallAccumulation2563 = result.data
                    self.showRainfallAccumulation2563(layer)
                  }
                }
              })
            }
          } else if (layer.type === 'Custom') {
            if (!('layerObject' in layer)) {
              const option = {
                type: window.longdo.LayerType.Custom,
                url: function (projection, tile, zoom, hd) {
                  return `${layer.url}&proj=${projection.longdoName}&zoom=${zoom}&x=${tile.u}&y=${tile.v}`
                },
                zoomRange: layer.zoomRange,
                opacity: layer.opacity,
                weight: layer.weight
              }
              if ('refresh' in layer) {
                option.refresh = layer.refresh
              }
              layer.layerObject = new window.longdo.Layer('', option)
            }
            self.map.Layers.add(layer.layerObject)
            activeAllLayerNameList = self.map.Layers.list().map(l => l.name())
            if (layer.name === 'ldd_ortho' && activeAllLayerNameList.indexOf('thaichote') < 0) {
              const layerName = self.$i18n.locale === 'th' ? 'iconstransp' : 'iconstransp-en'
              const poiLayer = new window.longdo.Layer(layerName, {
                weight: -1
              })
              self.map.Layers.add(poiLayer)
            }
          } else if (layer.type === 'dol') {
            if (!('layerObject' in layer)) {
              const option = {
                type: window.longdo.LayerType.Custom,
                url: function (projection, tile, zoom, hd) {
                  const mode = 'dol_hd'
                  return `${layer.url}?proj=${projection.longdoName}&zoom=${zoom}&x=${tile.u}&y=${tile.v}&mode=${mode}`
                },
                zoomRange: layer.zoomRange,
                opacity: layer.opacity,
                weight: layer.weight
              }
              if ('source' in layer) {
                option.source = layer.source
              }
              if ('refresh' in layer) {
                option.refresh = layer.refresh
              }
              layer.layerObject = new window.longdo.Layer('', option)
            }
            self.map.Layers.add(layer.layerObject)
            activeAllLayerNameList = self.map.Layers.list().map(l => l.name())
            if (layer.name === 'ldd_ortho' && activeAllLayerNameList.indexOf('thaichote') < 0) {
              const layerName = self.$i18n.locale === 'th' ? 'iconstransp' : 'iconstransp-en'
              const poiLayer = new window.longdo.Layer(layerName, {
                weight: -1
              })
              self.map.Layers.add(poiLayer)
            }
          } else if (layer.type === 'rainviewer') {
            if (!self.rainRadar) {
              await self.lji.Util.addScriptTag({
                src: `${self.baseUrl}rainradar.js`
              })

              self.rainRadar = new window.RainRadar(self.map, {
                opacity: layer.opacity, // Opacity display in map.
                timeDisplay: 'time-radar', // If you want to display time of radar you can set id element.
                speed: 1000,
                color: 8,
                locale: self.$i18n.locale === 'th' ? 'th-Th' : 'en-Us'
              })
            }
            self.rainRadar.setLocale(self.$i18n.locale === 'th' ? 'th-Th' : 'en-Us')
            self.rainRadar.reload()
          }
          if ('zoomRange' in layer) {
            if (self.map.zoom() < layer.zoomRange.min) {
              self.map.zoom(layer.zoomRange.min)
            }
            if (self.map.zoom() > layer.zoomRange.max) {
              self.map.zoom(layer.zoomRange.max)
            }
          }

          const trafficLayerIndex = self.selectedLayer.map(l => l.name).indexOf('traffic')
          if (layer.type !== 'traffic' && trafficLayerIndex !== -1) {
            self.map.Layers.remove(self.selectedLayer[trafficLayerIndex].layerObject)
            self.map.Layers.insert(1000, self.selectedLayer[trafficLayerIndex].layerObject)
          }

          self.selectedLayer.push(layer)
          layer.isShowingInfo = true
          self.$root.$emit('afterOpenLayer', layer.name, {
            isHideCamera: !self.isShowingCamera,
            isHideEvent: !self.isShowingEvents
          })
        }
      }
      self.updateNearestAqiAir4Thai()
      self.updateNearestAqiCN()
      self.$root.$emit('updateSelectedLayer', self.selectedLayer)
    },
    toggleInfo (layer) {
      const self = this
      const isActive = self.selectedLayer.map(l => l.name).indexOf(layer.name) !== -1
      if (isActive) {
        const index = self.layerList.map(l => l.name).indexOf(layer.name)
        self.layerList[index].isShowingInfo = !self.layerList[index].isShowingInfo
        self.$set(self.layerList, index, self.layerList[index])
      } else {
        self.toggleLayer(layer)
      }
    },
    changeOpacity (layer) {
      const self = this
      if ('layerObject' in layer) {
        self.map.Layers.remove(layer.layerObject)
        layer.layerObject.opacity = layer.opacity
      }
      if (layer.name === 'traffic') {
        self.map.Layers.insert(1000, layer.layerObject)
      } else if (layer.type === 'rainfall_accumulation_2563') {
        if (self.updateOpacityRainfallAccumulation2563Task !== null) {
          clearTimeout(self.updateOpacityRainfallAccumulation2563Task)
        }
        self.updateOpacityRainfallAccumulation2563Task = setTimeout(() => {
          self.hideRainfallAccumulation2563()
          self.showRainfallAccumulation2563(layer)
          self.updateOpacityRainfallAccumulation2563Task = null
        }, 1000)
      } else if (layer.type === 'rainviewer') {
        self.rainRadar.setOpacity(layer.opacity)
      } else {
        self.map.Layers.add(layer.layerObject)
        const trafficLayerIndex = self.selectedLayer.map(l => l.name).indexOf('traffic')
        if (trafficLayerIndex !== -1) {
          self.map.Layers.remove(self.selectedLayer[trafficLayerIndex].layerObject)
          self.map.Layers.insert(1000, self.selectedLayer[trafficLayerIndex].layerObject)
        }
      }
    },
    clickOpacityInput () {
    },
    onOpenLayer (layerName) {
      const self = this
      if (self.selectedLayer.map(l => l.name).indexOf(layerName) === -1) {
        const index = self.layerList.map(l => l.name).indexOf(layerName)
        if (index !== -1) {
          self.toggleLayer(self.layerList[index])
        }
      }
    },
    onHideLayer (layerName, options) {
      const self = this
      if (self.selectedLayer.map(l => l.name).indexOf(layerName) !== -1) {
        const index = self.layerList.map(l => l.name).indexOf(layerName)
        if (index !== -1) {
          self.toggleLayer(self.layerList[index], options)
        }
      }
    },
    clickLegend (url) {
      window.open(url, '_blank')
    },
    clickClose () {
      const self = this
      self.$emit('clickClose')
    },
    updateNearestAqiAir4Thai () {
      const self = this
      const aqiMarkerList = self.map.Overlays.list().filter(o => 'aqiAir4ThaiData' in o)
      if (aqiMarkerList.length > 0) {
        const location = self.map.location()
        let nearestAqiMarkerIndex = 0
        let minDistance = window.longdo.Util.distance([
          location,
          aqiMarkerList[nearestAqiMarkerIndex].location()
        ])
        for (let i = 1; i < aqiMarkerList.length; i++) {
          const distance = window.longdo.Util.distance([
            location,
            aqiMarkerList[i].location()
          ])
          if (minDistance > distance) {
            nearestAqiMarkerIndex = i
            minDistance = distance
          }
        }
        if (minDistance < 50000) {
          self.nearestAqiAir4Thai = aqiMarkerList[nearestAqiMarkerIndex].aqiAir4ThaiData
        } else {
          self.nearestAqiAir4Thai = null
        }
      } else {
        self.nearestAqiAir4Thai = null
      }
    },
    async getAqiCN (tile, zoom) {
      const self = this
      const boundingBox = window.longdo.Util.boundOfTile(self.map.projection(), tile)
      const result = await self.api.getAqiCN({
        locale: self.$i18n.locale,
        minLat: boundingBox.minLat,
        minLon: boundingBox.minLon,
        maxLat: boundingBox.maxLat,
        maxLon: boundingBox.maxLon
      })
      if (result.status === 200) {
        result.data.result.forEach(d => {
          const aqiMarkerList = self.map.Overlays.list().filter(o => 'aqiCNData' in o)
          const index = aqiMarkerList.map(a => a.aqiCNData.id).indexOf(d.id)
          if (index >= 0) {
            return false
          }
          const aqiMarker = new window.longdo.Marker({
            lat: Number(d.location.lat),
            lon: Number(d.location.lon)
          }, {
            icon: {
              html: `<div class="aqi-cn-marker" style="color: ${d.aqi_attr.fontcolor}; background-image: url('${d.aqi_attr.iconmarker}');">${d.aqi_value}</div>`,
              offset: {
                x: 16,
                y: 32
              }
            },
            popup: {
              title: `<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">${self.$t('layerPanel.titleAqiCN')}</div>`,
              detail: `
                <div class="aqi-head">
                  <div class="aqi-value" style="background-color: ${d.aqi_attr.bgcolor};">
                    <div class="title" style="color: ${d.aqi_attr.fontcolor};">${d.aqi_attr.status_text}</div>
                    <div class="icon" style="background-image: url('${d.aqi_attr.iconstatus}')"></div>
                    <div class="value" style="color: ${d.aqi_attr.fontcolor};">${d.aqi_value}</div>
                  </div>
                  <div class="aqi-owner">
                    <div class="name">${d.name}</div>
                    <div class="last-update">${self.$t('layerPanel.lastUpdate')} ${self.utility.getDateTimeStringFromUnixTimestamp(d.lastupdate, self.$i18n.locale)}</div>
                  </div>
                </div>
                <div class="aqi-info-row">
                  <div class="aqi-info PM10">
                    <div class="title">PM₁₀</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info PM25">
                    <div class="title">PM₂₅</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info SO2">
                    <div class="title">SO₂</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                </div>
                <div class="aqi-info-row">
                  <div class="aqi-info CO">
                    <div class="title">CO</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info NO2">
                    <div class="title">NO₂</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info O3">
                    <div class="title">O₃</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                </div>
              `,
              loadDetail: async (element) => {
                const result = await self.api.getAqiCNInfo({
                  id: d.id
                })
                if (result.status === 200) {
                  for (const key in result.data.result) {
                    element.querySelector('.' + key + ' img.loading').remove()
                    element.querySelector('.' + key + ' .value').innerHTML = result.data.result[key].value + ' ' + element.querySelector('.' + key + ' .value').innerHTML
                    element.querySelector('.' + key + ' .unit').innerHTML = result.data.result[key].unit
                  }
                }
              }
            }
          })
          aqiMarker.aqiCNData = d
          self.map.Overlays.add(aqiMarker)
        })
        if (self.selectedLayer.map(l => l.type).indexOf('aqicn') < 0) {
          self.map.Tags.remove(self.getAqiCN)
          self.map.Overlays.list().forEach(o => {
            if ('aqiCNData' in o) {
              self.map.Overlays.remove(o)
            }
          })
        }
      }
      self.updateNearestAqiCN()
    },
    updateNearestAqiCN () {
      const self = this
      const aqiMarkerList = self.map.Overlays.list().filter(o => 'aqiCNData' in o)
      if (aqiMarkerList.length > 0) {
        const location = self.map.location()
        let nearestAqiMarkerIndex = 0
        let minDistance = window.longdo.Util.distance([
          location,
          aqiMarkerList[nearestAqiMarkerIndex].location()
        ])
        for (let i = 1; i < aqiMarkerList.length; i++) {
          const distance = window.longdo.Util.distance([
            location,
            aqiMarkerList[i].location()
          ])
          if (minDistance > distance) {
            nearestAqiMarkerIndex = i
            minDistance = distance
          }
        }
        if (minDistance < 50000) {
          self.nearestAqiCN = aqiMarkerList[nearestAqiMarkerIndex].aqiCNData
        } else {
          self.nearestAqiCN = null
        }
      } else {
        self.nearestAqiCN = null
      }
      self.$root.$emit('onShowBadge', self.nearestAqiCN)
    },
    showRainfallAccumulation2563 (layer) {
      const self = this
      self.rainfallAccumulation2563.features.forEach(feature => {
        let overlay = null
        if (feature.geometry.type === 'Point') {
          overlay = new window.longdo.Marker({
            lon: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1]
          })
        } else if (feature.geometry.type === 'MultiPolygon') {
          const locationList = []
          feature.geometry.coordinates[0].forEach(x => {
            x.forEach(loc => {
              locationList.push({ lon: loc[0], lat: loc[1] })
            })
          })

          const dn = `${feature.properties.DN.toLocaleString('th')} ${self.$t('layerPanel.rainfallAccumulationUnit')}`
          const color = self.getRainAccumulationColor(feature.properties.DN)
          overlay = new window.longdo.Polygon(locationList, {
            title: dn,
            lineColor: 'rgba(0, 0, 0, 0)',
            lineWidth: 0,
            popup: {
              title: `<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">${layer.description[self.$i18n.locale]}</div>`,
              detail: `
                <div class="rainfall-accumulation">
                  <div class="value">
                    <img src="https://map.longdo.com/mmmap/images/legend-rain-accumulation-icon.png">
                    <span>${dn}</span>
                  </div>
                  <div class="legend" style="background-image: url('${layer.legend[self.$i18n.locale]}')"></div>
                </div>
              `
            },
            fillColor: `rgba(${color}, ${layer.opacity})`
          })
          overlay.color = color
        }
        if (overlay !== null) {
          overlay.rainfallAccumulation2563Data = feature
          self.map.Overlays.add(overlay)
        }
      })
    },
    getRainAccumulationColor (val) {
      return val > 3600 ? '0, 0, 255'
        : val > 3200 ? '28, 28, 255'
          : val > 2800 ? '57, 57, 255'
            : val > 2400 ? '85, 85, 255'
              : val > 2000 ? '113, 113, 255'
                : val > 1600 ? '142, 142, 255'
                  : val > 1200 ? '170, 170, 255'
                    : val > 800 ? '198, 198, 255'
                      : val > 400 ? '227, 227, 255'
                        : '255, 255, 255'
    },
    hideRainfallAccumulation2563 () {
      const self = this
      self.map.Overlays.list().forEach(o => {
        if ('rainfallAccumulation2563Data' in o) {
          self.map.Overlays.remove(o)
        }
      })
    },
    playRainRadar () {
      const self = this
      self.rainRadar.playAnimation()
      self.isAnimation = self.rainRadar.isAnimation
    }
  },
  watch: {
    '$i18n.locale' () {
      const self = this
      self.$nextTick(() => {
        if (self.rainRadar) {
          if (self.rainRadar.isDisplay) {
            self.rainRadar.setLocale(self.$i18n.locale === 'th' ? 'th-Th' : 'en-Us')
          }
        }
        self.map.Overlays.list().forEach(o => {
          if (o instanceof window.longdo.Marker && 'popup' in o) {
            if ('aqiAir4ThaiData' in o) {
              o.popup().title().querySelector('.title').innerHTML = self.$t('layerPanel.titleAqiAir4Thai')
              o.popup().detail().querySelector('.last-update').innerHTML = `${self.$t('layerPanel.lastUpdate')} ${self.utility.getDateTimeStringFromUnixTimestamp(o.aqiAir4ThaiData.lastupdate, self.$i18n.locale)}`
            }
            if ('aqiCNData' in o) {
              o.popup().title().querySelector('.title').innerHTML = self.$t('layerPanel.titleAqiCN')
              o.popup().detail().querySelector('.last-update').innerHTML = `${self.$t('layerPanel.lastUpdate')} ${self.utility.getDateTimeStringFromUnixTimestamp(o.aqiCNData.lastupdate, self.$i18n.locale)}`
            }
          }
        })
      })
    },
    isShowingCamera () {
      const self = this
      self.$nextTick(() => {
        const trafficLayerIndex = self.selectedLayer.map(l => l.name).indexOf('traffic')
        if (self.isShowingCamera && (trafficLayerIndex !== -1 || self.isForceOpenCamera)) {
          self.isForceOpenCamera = false
          self.map.Overlays.load(window.longdo.Overlays.cameras)
        } else {
          self.map.Overlays.unload(window.longdo.Overlays.cameras)
        }
        self.$root.$emit('changeIsShowingCamera', self.isShowingCamera)
      })
    },
    isShowingEvents () {
      const self = this
      self.$nextTick(() => {
        const trafficLayerIndex = self.selectedLayer.map(l => l.name).indexOf('traffic')
        if (self.isShowingEvents && (trafficLayerIndex !== -1 || self.isForceOpenEvent)) {
          self.isForceOpenEvent = false
          if (self.isTrafficProduct) {
            self.$emit('showTrafficEvent')
          } else {
            self.map.Overlays.load(window.longdo.Overlays.events)
          }
        } else {
          if (self.isTrafficProduct) {
            self.$emit('hideTrafficEvent')
          } else {
            self.map.Overlays.unload(window.longdo.Overlays.events)
          }
        }
        self.$root.$emit('changeIsShowingEvent', self.isShowingEvents)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.layer-panel {
  width: 100%;
  height: 75%;
  border-radius: 12px 12px 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 12px 0px 54px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0%;
  left: 0px;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

  > label.panel-name {
    font-family: Prompt;
    font-size: 18px;
    color: $primary;
  }

  > button.close {
    padding: 0px;
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    i {
      color: $grey;
    }
  }

  > .layer-list {
    width: 100%;
    height: calc(100% - 28px);
    padding: 12px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .layer-container {
      margin: 12px auto;
      width: 400px;

      > button.layer {
        position: relative;
        width: 100%;
        padding: 24px;
        border: 2px solid $lightGrey;
        border-radius: 6px;
        box-sizing: border-box;
        outline: none;
        appearance: none;
        overflow: hidden;
        background-image: linear-gradient(90deg, $darkPrimary 0%, transparent 60%);
        transition: background-image 0.3s ease 0s,
          width 0.1s ease 0s,
          height 0.1s ease 0s,
          border 0.1s ease 0s;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          z-index: 1;

          > .title {
            text-align: left;
            display: inline-block;
            font-family: Prompt;
            font-size: 24px;
            z-index: 1;
            text-shadow: 2px 2px 3px rgb(0, 0, 0);
            color: rgb(255, 255, 255);

            > i {
              color: rgb(255, 255, 255);
              font-size: 24px;
              opacity: 0;
              transition: opacity 0.3s ease 0s;
              z-index: 1;
              display: none;
              vertical-align: middle;
            }
          }

          > i {
            color: rgb(255, 255, 255);
            font-size: 24px;
            opacity: 0;
            transition: opacity 0.3s ease 0s;
            align-self: flex-start;
            z-index: 1;
          }

          > i.close {
            margin-left: auto;
            color: rgb(253, 216, 53);
            font-weight: bold;
            z-index: 1;
          }

          > i.link {
            margin-top: 6px;
            margin-left: auto;
            z-index: 1;
            color: rgb(255, 255, 255);
          }
        }

        > .input-container {
          z-index: 1;
          width: 100%;

          * {
            z-index: 1;
          }

          > input[type="range"] {
            width: 100%;
            display: none;
            margin-top: 18px;
            z-index: 1;
          }
        }

        > img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center bottom;
          z-index: 0;
          opacity: 0.7;
        }

        .control-traffic {
          z-index: 1;

          * {
            z-index: 1;
          }
        }
      }

      > button.layer.active {
        border: 2px solid $primary;
        background-image: linear-gradient(90deg, $darkPrimary 0%, $primary 100%);

        > div {
          > .title {
            padding-right: 24px;
            color: rgb(255, 255, 255);

            > i {
              opacity: 1;
              display: inline-block;
            }
          }

          > i {
            opacity: 1;
          }
        }

        > .input-container {
          > input[type="range"] {
            display: block;
          }
        }

        > img {
          opacity: 0.25;
        }

        .control-traffic {
          display: block;
          margin-top: 6px;

          .toggle-input {
            height: 32px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            padding: 0px;
            border: none;

            > .toggle {
              position: relative;
              width: 30px;
              height: 100%;

              > .track {
                background-color: rgb(255, 255, 255);
                width: 100%;
                height: 18px;
                border-radius: 9px;
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
              }

              > .thumb {
                width: 18px;
                height: 18px;
                border-radius: 9px;
                background-color: $lightGrey;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                border: 3px solid rgb(255, 255, 255);
                box-sizing: border-box;
                transition: left 0.3s ease 0s,
                  background-color 0.3s ease 0s;
              }
            }

            > label {
              color: rgb(255, 255, 255);
              padding-left: 12px;
              font-size: 16px;
            }
          }

          .toggle-input.active {
            > .toggle {
              > .thumb {
                background-color: $darkPrimary;
                left: calc(100% - 18px);
              }
            }
          }
        }
      }

      > button.layer.link {
        > div {
          > label {
            color: rgb(255, 255, 255);
          }

          > i {
            opacity: 1;
            color: rgb(255, 255, 255);
            text-shadow: 0px 0px 6px rgb(0, 0, 0);
          }
        }
      }

      > .layer-info {
        width: 100%;
        padding: 0px 6px;
        box-sizing: border-box;

        > .field {
          margin-top: 12px;

          > label {
            font-weight: bold;
            font-size: 16px;
          }

          > div {
            font-size: 16px;
          }
        }

        > .legend {
          width: 100%;
          text-align: center;

          > img {
            width: 100%;
          }
        }
      }
    }
  }
}

.layer-panel.hide {
  bottom: calc(0px - 75% - 12px);
  box-shadow: none;
}

input[type=range] {
  width: 100%;
  margin: 6px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: rgb(255, 255, 255);
  border: 0.1px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 6px;
  cursor: pointer;
  border-radius: 3px;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -6.1px;
  width: 18px;
  height: 18px;
  background: $darkPrimary;
  border: 3px solid rgba(255, 255, 255, 1);
  border-radius: 9px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgb(255, 255, 255);
}
input[type=range]::-moz-range-track {
  background: rgb(255, 255, 255);
  border: 0.1px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 6px;
  border-radius: 3px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: $darkPrimary;
  border: 3px solid rgba(255, 255, 255, 1);
  border-radius: 9px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: rgb(255, 255, 255);
  border: 0.1px solid rgba(0, 0, 0, 0);
}
input[type=range]::-ms-fill-upper {
  background: rgb(255, 255, 255);
  border: 0.1px solid rgba(0, 0, 0, 0);
}
input[type=range]::-ms-thumb {
  width: 18px;
  height: 18px;
  background: $darkPrimary;
  border: 3px solid rgba(255, 255, 255, 1);
  border-radius: 9px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: rgb(255, 255, 255);
}
input[type=range]:focus::-ms-fill-upper {
  background: rgb(255, 255, 255);
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

.nearest-aqi {
  position: absolute;
  top: 78px;
  right: 12px;
  z-index: 10;
  display: block;
  transition: top 0.3s ease 0s;

  &.shift-top {
    top: 12px;
  }

  .nearest-aqi-air4thai,
  .nearest-aqi-cn {
    padding: 6px 6px 3px 6px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.75);
    box-sizing: border-box;
    opacity: 0.85;
    margin-bottom: 12px;
    width: 63px;
    height: 63px;

    .head {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 3px;

      .title {
        font-size: 14px;
        font-family: Prompt;
      }

      .icon {
        background-image: url('https://map.longdo.com/mmmap/images/layers/ic_aqi_flag_th.png');
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 6px;
        margin-bottom: 2px;
      }
    }

    .value {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      font-family: Prompt;
    }
  }
}

.traffic-index {
  position: absolute;
  top: 78px;
  left: 12px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease 0s;
  padding: 3px 3px 3px 3px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 50%;
  box-sizing: border-box;
  opacity: 0.85;
  width: 63px;
  height: 63px;
  background-color: rgba(255, 255, 255, 0.95);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &.shift-top {
    top: 12px;
  }

  // .number-image {
  //   width: 100%;
  //   height: 100%;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  // }

  .number {
    font-family: Digital;
    font-size: 28px;
    cursor: pointer;
  }

  label {
    text-align: center;
    font-size: 12px;
    width: 100%;
    font-family: Prompt;
    cursor: pointer;

    &.province {
      font-size: 12px;
      padding-bottom: 10px;
    }
  }
}

#rain-radar-panel {
  position: fixed;
  z-index: 80;
  transform: translate(-50%, 0%);
  bottom: 96px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  pointer-events: none;

  #time-radar {
    pointer-events: none;
    text-align: center;
    padding: 1px 9px;
    background: rgba(0, 0, 0, 0.45);
    margin-bottom: 6px;
    border-radius: 12px;
    font-family: Prompt;
    font-size: 14px;
    color: white;
  }

  .action {
    display: flex;
    flex-direction: row;
    pointer-events: visible;

    button {
      background-color: white;
      border: 1px solid $extraLightGrey;
      border-radius: 0px;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      width: 36px;
      height: 36px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-radius: 6px 0px 0px 6px;
      }

      &:last-child {
        border-radius: 0px 6px 6px 0px;
      }

      i {
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: $primary;
      }
    }
  }
}
</style>
