<template>
  <div class="event-panel" :class="{
    'hide': isHidingEventPanel,
    'expand-less': !isExpand
  }">
    <div class="event-container">
      <div class="top-bar">
        <button class="expand less ripple" v-if="isExpand" @click="clickExpandLess">
          <i class="material-icons-round">expand_more</i>
          <label>{{ $t('eventPanel.expandLess') }}</label>
        </button>
        <button class="expand more ripple" v-if="!isExpand" @click="clickExpandMore">
          <i class="material-icons-round">expand_less</i>
          <label>{{ $t('eventPanel.expandMore') }}</label>
        </button>
      </div>
      <div class="loading-container" v-if="showLoading && tabNo === 2">
        <img src="@/assets/img/loading.gif">
      </div>
      <div class="empty-container" v-if="!showLoading && eventList !== null && eventList.length === 0">
        {{ $t('eventPanel.empty') }}
      </div>
      <div class="event-tab-container">
        <div class="event-tab">
          <input type="radio" @click="eventTab(1)" id="event-now" name="tabs" checked />
          <label class="tab" for="event-now">เหตุการณ์ล่าสุด</label>
          <input type="radio"  @click="eventTab(2)" id="event-all" name="tabs" />
          <label class="tab" for="event-all">ทั้งหมด</label>
          <span class="glider"></span>
        </div>
      </div>
      <div v-show="tabNo === 1" ref="event-list" class="event-list">
        <div v-for="event in eventList" :key="event.eid" class="event ripple" @click="onClickIncident(event)">
          <div class="icon">
            <img :src="event.icon2x" :srcset="event.icon1x + ' 1x' + event.icon2x + ' 2x'" loading="lazy">
          </div>
          <div class="detail">
            <label class="name" :title="$i18n.locale === 'en' ? event.title_en : event.title">
              {{ $i18n.locale === 'en' ? event.title_en : event.title }}
            </label>
            <label class="time">
              {{ event.start_time }} {{ event.end_time }}
            </label>
          </div>
          <a class="info" @click.stop="onClickIncident(event)">
            <i class="material-icons-round">info</i>
          </a>
        </div>
      </div>
      <div v-show="tabNo === 2" ref="event-list-all" class="event-list">
        <div v-for="event in eventListAll" :key="event.eid" class="event ripple" @click="onClickIncident(event)">
          <div class="icon">
            <img :src="event.icon2x" :srcset="event.icon1x + ' 1x' + event.icon2x + ' 2x'" loading="lazy">
          </div>
          <div class="detail">
            <label class="name" :title="$i18n.locale === 'en' ? event.title_en : event.title">
              {{ $i18n.locale === 'en' ? event.title_en : event.title }}
            </label>
            <label class="time">
              {{ event.start_time }} {{ event.end_time }}
            </label>
            <label class="contributor">
              <!-- {{ $t('eventPanel.contributor') }}: {{ event.contributor.join(', ') }} -->
              {{ $t('eventPanel.contributor') }}: {{ event.contributor }}
            </label>
          </div>
          <a class="info" @click.stop="onClickIncident(event)">
            <i class="material-icons-round">info</i>
          </a>
        </div>
        <button v-if="page > 1 && !showLoading" :title="$t('eventPanel.moreTitle')" @click="getEventList(true)"
          class="ripple">
          {{ $t('eventPanel.more') }}
        </button>
      </div>
    </div>
    <div class="event-all"></div>
  </div>
</template>

<script>
export default {
  name: 'EventPanel',
  props: {
    map: {
      type: Object
    }
  },
  data () {
    return {
      isExpand: true,
      eventList: null,
      eventListAll: [],
      showLoading: false,
      isShowingMapOnly: false,
      isHidingEventPanel: true,
      page: 2,
      tabNo: 1,
      isForceGetEventList: false
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.onClickMap)
    self.$parent.$on('showEvent', self.showEvent)
    self.$parent.$on('loadedEvent', self.loadedEvent)
    self.$parent.$on('hideEvent', self.hideEvent)
    if (self.$route.name === 'Main' && self.$route.hash === '#events') {
      self.isHidingEventPanel = false
      self.getTrafficIncident()
      self.getEventList()
      self.page = 1
    }
    self.$root.$emit('changeHidingEventPanel', self.isHidingEventPanel)
    self.$root.$emit('changeExpandEventPanel', self.isExpand)
  },
  methods: {
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isHidingEventPanel = isShowingMapOnly
      if (self.$route.hash !== '#events') {
        self.isHidingEventPanel = true
      }
    },
    onClickIncident (incident) {
      const self = this

      if (self.map.zoom() < 12) {
        self.map.zoom(12, false)
      }
      self.map.location(
        { lat: incident.latitude, lon: incident.longitude },
        true
      )
      self.$emit('fireTrafficIncidentClick', incident)
    },
    clickExpandLess () {
      const self = this
      self.isExpand = false
      self.$root.$emit('changeHidingEventPanel', self.isHidingEventPanel)
      self.$root.$emit('changeExpandEventPanel', self.isExpand)
    },
    clickExpandMore () {
      const self = this
      self.isExpand = true
      self.$root.$emit('changeHidingEventPanel', self.isHidingEventPanel)
      self.$root.$emit('changeExpandEventPanel', self.isExpand)
    },
    showEvent (isForceGetEventList) {
      const self = this
      self.isForceGetEventList = isForceGetEventList
      self.isHidingEventPanel = false
      self.$root.$emit('changeHidingEventPanel', self.isHidingEventPanel)
      self.$root.$emit('changeExpandEventPanel', self.isExpand)
    },
    hideEvent () {
      const self = this
      self.isHidingEventPanel = true
      self.$root.$emit('changeHidingEventPanel', self.isHidingEventPanel)
      self.$root.$emit('changeExpandEventPanel', self.isExpand)
    },
    clickEvent (event) {
      const self = this
      self.$emit('clickEventRow', event)
    },
    clickEventInfo (event) {
      const self = this
      self.$emit('clickEventInfo', event, true)
    },
    eventTab (tabId) {
      const self = this
      self.tabNo = tabId
    },
    async getEventList (isMore = false) {
      const self = this
      const result = await self.api.searchTrafficEvent(0, 0, (self.page * 20))
      if (!isMore) {
        self.page = 1
      }
      self.page++
      const tempList = result.status === 200 ? result.data.item : []
      tempList.forEach(e => {
        e.eventId = 'A' + e.eid.padStart(8, '0')
        e.icon1x = `${self.baseUrl}img/event/event_${e.severity}@2x.png`
        e.icon2x = `${self.baseUrl}img/event/event_${e.severity}@3x.png`
        e.start_time = new Date(e.start_time.replace(' ', 'T')).toLocaleString('th-TH', {
          year: '2-digit',
          month: 'narrow',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit'
        })
      })
      self.eventListAll = tempList
      self.showLoading = false
      self.$nextTick(() => {
        if (!isMore) {
          self.$refs['event-list'].scrollTop = 0
        }
      })
      // self.page++
      // const tempList = result.status === 200 ? result.data.item : []
      // tempList.forEach(e => {
      //   e.eventId = 'A' + e.eid.padStart(8, '0')
      //   e.icon1x = `${self.baseUrl}img/event/event_${e.severity}@2x.png`
      //   e.icon2x = `${self.baseUrl}img/event/event_${e.severity}@3x.png`
      //   const sameDay = e.start_time.substr(0, 10) === e.stop_time.substr(0, 10)
      //   e.start_time = new Date(e.start_time.replace(' ', 'T')).toLocaleString('th-TH', {
      //     year: '2-digit',
      //     month: 'narrow',
      //     day: 'numeric',
      //     hour: 'numeric',
      //     minute: '2-digit'
      //   })
      //   e.stop_time = new Date(e.stop_time.replace(' ', 'T')).toLocaleString('th-TH', sameDay ? {
      //     hour: 'numeric',
      //     minute: '2-digit'
      //   } : {
      //     year: '2-digit',
      //     month: 'narrow',
      //     day: 'numeric',
      //     hour: 'numeric',
      //     minute: '2-digit'
      //   })
      // })
      // self.eventListAll = isMore ? self.eventListAll.concat(tempList) : tempList
      // self.$emit('loadedEventList', tempList, isMore)
      // self.showLoading = false
      // self.$nextTick(() => {
      //   if (!isMore) {
      //     self.$refs['event-list'].scrollTop = 0
      //   }
      // })
    },
    async getTrafficIncident () {
      const self = this
      self.showLoading = true
      var result = await self.api.getTrafficIncident()
      const tempList = result.status === 200 ? result.data.item : []
      tempList.forEach(e => {
        e.eventId = 'A' + e.eid.padStart(8, '0')
        e.icon1x = `${self.baseUrl}img/event/event_${e.severity}@2x.png`
        e.icon2x = `${self.baseUrl}img/event/event_${e.severity}@3x.png`
        e.start_time = new Date(e.start_time.replace(' ', 'T')).toLocaleString('th-TH', {
          year: '2-digit',
          month: 'narrow',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit'
        })
      })
      self.eventList = tempList
      self.showLoading = false
    }
  },
  watch: {
    isHidingEventPanel () {
      const self = this
      self.$nextTick(() => {
        if (!self.isHidingEventPanel && (self.isForceGetEventList || (self.eventList || []).length < 1)) {
          self.getTrafficIncident()
          self.getEventList()
          self.isForceGetEventList = false
          self.page = 1
        }
        self.$root.$emit('changeHidingEventPanel', self.isHidingEventPanel)
      })
    },
    isExpand () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeExpandEventPanel', self.isExpand)
      })
    }
  }
}
</script>

<style scoped lang="scss">
$eventPanelHeight: 40%;
$topBarHeight: 32px;

.event-panel {
  width: 100%;
  height: $eventPanelHeight;
  border-radius: 12px 12px 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0%;
  left: 0px;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

  .event-tab-container {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .event-tab {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 99px;
  }

  input[type=radio] {
    display: none;
  }

  .tab {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 150px;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    color: $primary;
    background-color: #F4FCF9;
    transition: color 0.15s ease-in;
  }

  input[type=radio]:checked+label {
    z-index: 1;
    color: white;
    background-color: $primary;
  }

  input[id=event-now]:checked~.glider {
    transform: translateX(0);
  }

  input[id=event-all]:checked~.glider {
    transform: translateX(100%);
  }

  .glider {
    color: white;
    font-weight: bold;
    position: absolute;
    display: flex;
    height: 30px;
    width: 150px;
    z-index: 0;
    border-radius: 99px;
    transition: 0.25s ease-out;
  }

  @media (max-width: 700px) {
    .tabs {
      transform: scale(0.6);
    }
  }

  >.event-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0px 0px;

    >.top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 3px 0px 3px;
      box-sizing: border-box;

      >button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        >i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 6px 0px 0px;
        }

        >label {
          font-size: 13px;
          color: rgb(168, 168, 168);
          user-select: none;
        }
      }
    }

    >.loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    >.empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }
  }

  .event-list {
    display: block;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    height: calc(100% - #{$topBarHeight});

    >.event {
      $iconWidth: 48px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 9px 0px;
      cursor: pointer;

      >.icon {
        width: $iconWidth;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        padding-top: 3px;

        >img {
          width: 16px;
          height: 16px
        }
      }

      >.detail {
        display: flex;
        flex-direction: column;
        width: calc(100% - #{$iconWidth} - 36px);

        >.name {
          width: 100%;
          cursor: pointer;
          font-size: 16px;
          font-family: Prompt;
        }

        >.time {
          width: 100%;
          font-size: 14px;
          color: $grey;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }

        >.contributor {
          width: 100%;
          font-size: 14px;
          color: $grey;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }
      }

      >.info {
        width: 36px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        >i {
          color: $lightPrimary;
          font-size: 24px;
        }
      }
    }

    >button {
      width: calc(100% - 12px);
      border: none;
      border-radius: 3px;
      font-size: 14px;
      padding: 12px 6px;
      margin: 6px;
      cursor: pointer;
      outline: none;
      color: rgb(255, 255, 255);
      background-color: $primary;
      transition: background-color 0.3s ease 0s;
    }

    >button:hover {
      background-color: $darkPrimary;
    }
  }
}

.event-panel.expand-less {
  bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$eventPanelHeight});
}

.event-panel.hide {
  bottom: -#{$eventPanelHeight};
  box-shadow: none;
}

.event-panel.hide.expand-less {
  bottom: calc(0px - #{$eventPanelHeight} - 12px);
}

@media only screen and (min-width: $mobileMaxSize) {
  .event-panel {
    display: none;
  }
}
</style>
