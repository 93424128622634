<template>
  <div class="main">
    <NavigationBarDesktop :map="map" />
    <ImagesPreview/>
    <div  @drop.prevent="onDropHandler" @dragenter.prevent @dragover.prevent class="longdo-map-content" :class="{
      'only-one': isShowingMapOnly,
      'dark-mode': darkMode,
      'light-mode': lightMode,
      'zoom-more-16': zoom > 16,
      'land-parcel-filter': landParcelFilter,
      'hide-my-places-name': !showMyPlacesName,
      'terrain-filter': useTerrainFilter,
      'showing-explore-panel': !isHidingExplorePanel && isExpandingExplorePanel,
      'collapsing-explore-panel': !isHidingExplorePanel && !isExpandingExplorePanel,
      'showing-search-result-panel': !isHidingSearchResultPanel && isExpandingSearchResultPanel,
      'collapsing-search-result-panel': !isHidingSearchResultPanel && !isExpandingSearchResultPanel,
      'showing-my-place-panel': !isHidingMyPlacePanel && isExpandingMyPlacePanel,
      'collapsing-my-place-panel': !isHidingMyPlacePanel && !isExpandingMyPlacePanel,
      'showing-explore-nearby-panel': !isHidingExploreNearbyPanel && isExpandingExploreNearbyPanel,
      'collapsing-explore-nearby-panel': !isHidingExploreNearbyPanel && !isExpandingExploreNearbyPanel,
      'showing-event-panel': !isHidingEventPanel && isExpandingEventPanel,
      'collapsing-event-panel': !isHidingEventPanel && !isExpandingEventPanel,
      'showing-popup': isShowingPopup,
      'routing': $route.hash === '#routing',
      'place-card-step-full': placeCardStep === 'FULL',
      'place-card-step-mid': placeCardStep === 'MID',
      'place-card-step-mini': placeCardStep === 'MINI'
    }">
      <longdo-map-vector @load="loadedLongdoMap" v-if="isLongdoMapV3" />
      <longdo-map @load="loadedLongdoMap" v-else />
    </div>
    <SearchPanel :map="map" v-if="islongdoMapReady || islongdoMap3Ready" ref="searchPanel" @beforeSearch="onBeforeSearch"
      @searchLocation="onSearchLocation" @searchResult="onSearchResult" @searchTrafficResult="onSearchTrafficResult" @beforeMore="onBeforeMore"
      @moreResult="onMoreResult" @noMoreTrafficEvent="onNoMoreTrafficEvent" @beforeMoreTrafficEvent="onBeforeMoreTrafficEvent" @clearSearch="onClearSearch" @showTag="onShowTag" />
    <MapControlPanel v-if="islongdoMapReady | islongdoMap3Ready" @getCurrentLocation="getCurrentLocation" :map="map" :isTracking="isTracking" :isTrackingDirection="isTrackingDirection"
      :isShowingPopup="isShowingPopup" :isGeolocationMobileAppDisabled="isGeolocationMobileAppDisabled" :placeCardStep="placeCardStep" />
    <SpecialMapControlPanel v-if="islongdoMapReady | islongdoMap3Ready"
      @getCurrentLocation="getCurrentLocation" :isShowingPopup="isShowingPopup" :placeCardStep="placeCardStep"
      :map="map"
       />
    <TrafficControlPanel v-if="islongdoMapReady || islongdoMap3Ready && isTrafficProduct" @getCurrentLocation="getCurrentLocation" :map="map"
      :isShowingPopup="isShowingPopup" :placeCardStep="placeCardStep" @showTag="onShowTag" @hideTag="onHideTag" />
    <SearchResultPanel v-if="islongdoMapReady || islongdoMap3Ready" :searchResultList="searchResultList"
      :showLoading="isSearching" :showLoadingMore="isSearchingMore" :hasMore="hasMoreSearchResult"
      @clickSearchResult="onClickSearchResult" @clickHasMore="onClickHasMore" :userData="user" />
    <MeasurementControlPanel v-if="islongdoMapReady" @clickClearMeasurement="clickClearMeasurement" />
    <ToolBar v-if="islongdoMapReady || islongdoMap3Ready" @clickCheckNearby="onClickCheckNearby"  :isReportPanelOpened="isShowReportEventFormPanel" :isGeolocationMobileAppDisabled="isGeolocationMobileAppDisabled"/>
    <LayerPanel v-if="islongdoMapReady" :map="map" @clickClose="clickCloseLayerPanel" @showTrafficEvent="showMapTrafficEventHandler" @hideTrafficEvent="hideMapTrafficEventHandler"/>
    <Map3LayerPanel :map="map" :baseMap="baseMap" v-if="islongdoMap3Ready" @clickClose="clickCloseLayerPanel" @showTrafficEvent="showMapTrafficEventHandler" @hideTrafficEvent="hideMapTrafficEventHandler"/>
    <MapTypePanel v-if="islongdoMapReady || islongdoMap3Ready" :map="map"
      @clickMapType="(e) => isLongdoMapV3 ? clickMapTypeV3(e) : clickMapType(e)" @clickClose="clickCloseMapTypePanel" />
    <ExplorePanel :map="map" v-if="(islongdoMapReady || islongdoMap3Ready) && !isGeolocationMobileAppDisabled" @beforeExplore="onBeforeExplore"
      @exploreResult="onExploreResult" @beforeExploreMore="onBeforeExploreMore" @exploreMoreResult="onExploreMoreResult"
      @clickExploreResult="onClickExploreResult" :isGeolocationMobileAppDisabled="isGeolocationMobileAppDisabled" />
    <ExploreNearbyPanel v-if="islongdoMapReady || islongdoMap3Ready && exploreNearbyCenter !== null"
      :center="exploreNearbyCenter" @beforeExplore="onBeforeExplore" @exploreResult="onExploreResult"
      @beforeExploreMore="onBeforeExploreMore" @exploreMoreResult="onExploreMoreResult"
      @clickExploreResult="onClickExploreResult" />
    <MyPlacePanel @clickMyPlace="onClickMyPlace" />
    <EventPanel @loadedEventList="onLoadedEventList" @clickEventRow="onClickEventRow"
      @clickEventInfo="onClickEventInfo" @fireTrafficIncidentClick="onFireTrafficIncidentClick" :map="map"/>
    <BadgeInformation v-if="islongdoMapReady || islongdoMap3Ready" :map="map" :isGeolocationMobileAppDisabled="isGeolocationMobileAppDisabled"/>
    <button ref="main-menu" class="main-menu ripple" :class="{
      hide: isShowingMapOnly,
      'ldmap-toggle': isLongdoMapV3
    }" v-show="isShowingMainMenuButton" @click="clickMainMenu">
      <i class="material-icons-round">
        menu
      </i>
    </button>
    <Settings />
    <PdpaManagement />
    <RoutingPanel v-if="islongdoMapReady || islongdoMap3Ready" :map="map" :baseMap="baseMap" :userData="user" :isGeolocationMobileAppDisabled="isGeolocationMobileAppDisabled"/>
    <MobileWarningPopUp v-if="(islongdoMap3Ready || islongdoMapReady) && isOnMobileApp && !isGeolocationMobileAppDisabled" :warningPoint="warningPoint" :map="map"/>
    <NearbyDialog v-model="isShowNearbyDialog" :message="nearbyDataFeature.nearbyMessage"
      @close="isShowNearbyDialog = false" />
    <SelectNearbyTagDialog v-model="isShowSelectNearbyTagDialog" @close="onCloseSelectNearbyTagDialog"
      @selectNearbyTag="checkNearby" />

    <SharePlaceWidget :poiId="share.poiId" :lat="share.lat" :lon="share.lon" :isShareInfoPage="false"
      @close="isShowSharePlaceWidget = false" v-if="isShowSharePlaceWidget" />

    <PlaceCard v-if="islongdoMapReady || islongdoMap3Ready" :poiId="$route.params.poiId" :userData="user" :map="map" @changeStep="onChangePlaceCardStep" @showMessageToast="showMessageToast" :geocodingResult="geocodingResult" />
    <TrafficIncidentPanel v-if="(islongdoMapReady || islongdoMap3Ready) && isTrafficProduct" :trafficIncident="trafficIncident" :trafficEventType="trafficEventType" :map="map"
    @clickHasMoreTrafficEvent="onClickHasMoreTrafficEvent" :isLoadingMore="isSearchingMoreTrafficEvent" :hasMoreTrafficEvent="hasMoreTrafficEvent" @fireTrafficIncidentClick="onFireTrafficIncidentClick" :hasOpenReportEventForm="isShowReportEventFormPanel"/>
    <transition name="fade">
      <div class="copied" v-if="isShowToast">{{ toastMessage }}</div>
    </transition>
    <ReportEventFormPanel v-if="(islongdoMapReady || islongdoMap3Ready) && isShowReportEventFormPanel"
      @successReportEvent="handleSuccessSaveReportEvent" @clickClose="clickCloseReportEventPanel" :map="map"
      :userData="user" :trafficIncident="trafficIncident"  :location=tempLocationByContext :key="showReportEventFormKey" :editMode="eventEditMode" :idEventTypeClick="idEventType"/>
    <ConfirmDeleteEventDialogVue v-if="confirmDeleteEvent" />
    <div class="loading-overlay" v-if="isLoadingDelete">
      <img src="@/assets/img/loading.gif" alt="loading" v-if="!isDeletedEventComplete && !isDeleteEventError">
      <span class="error-message" v-if="isDeleteEventError">{{ $t('reportEventForm.deletedError') }}</span>
      <span class="success-message" v-if="isDeletedEventComplete">{{ $t('reportEventForm.deleted') }}</span>
    </div>
  </div>
</template>

<script>
import MapControlPanel from '@/components/MapControlPanel.vue'
import NavigationBarDesktop from '@/components/NavigationBarDesktop.vue'
import SpecialMapControlPanel from '@/components/SpecialMapControlPanel.vue'
import TrafficControlPanel from '@/components/TrafficControlPanel.vue'
import ToolBar from '@/components/ToolBar.vue'
import LayerPanel from '@/components/LayerPanel.vue'
import Map3LayerPanel from '@/components/Layers/Map3LayerPanel.vue'
import MapTypePanel from '@/components/MapTypePanel.vue'
import SearchPanel from '@/components/SearchPanel.vue'
import SearchResultPanel from '@/components/SearchResultPanel.vue'
import ExplorePanel from '@/components/ExplorePanel.vue'
import ExploreNearbyPanel from '@/components/ExploreNearbyPanel.vue'
import MyPlacePanel from '@/components/MyPlacePanel.vue'
import EventPanel from '@/components/EventPanel.vue'
import Settings from '@/components/Settings.vue'
import PdpaManagement from '@/components/PdpaManagement.vue'
import RoutingPanel from '@/components/RoutingPanel.vue'
import MeasurementControlPanel from '@/components/MeasurementControlPanel.vue'
import NearbyDialog from '@/components/NearbyDialog.vue'
import SelectNearbyTagDialog from '@/components/SelectNearbyTagDialog.vue'
import SharePlaceWidget from '@/components/SharePlaceWidget.vue'
import PlaceCard from '@/components/PlaceCard.vue'
import BadgeInformation from '../components/Layers/BadgeInformation.vue'
import LongdoMapVector from '@/components/LongdoMapVector.vue'
import ImagesPreview from '../components/ImagesPreview.vue'
import ReportEventFormPanel from '../components/ReportEventFormPanel.vue'
import TrafficIncidentPanel from '@/components/TrafficIncidentPanel.vue'
import ConfirmDeleteEventDialogVue from '@/components/ConfirmDeleteEventDialog.vue'
import MobileWarningPopUp from '../components/MobileWarningPopUp.vue'

export default {
  name: 'Main',
  components: {
    MapControlPanel,
    NavigationBarDesktop,
    SpecialMapControlPanel,
    TrafficControlPanel,
    ToolBar,
    LayerPanel,
    Map3LayerPanel,
    MapTypePanel,
    SearchPanel,
    SearchResultPanel,
    ExplorePanel,
    ExploreNearbyPanel,
    MyPlacePanel,
    EventPanel,
    Settings,
    PdpaManagement,
    RoutingPanel,
    MeasurementControlPanel,
    NearbyDialog,
    SelectNearbyTagDialog,
    SharePlaceWidget,
    PlaceCard,
    BadgeInformation,
    LongdoMapVector,
    ImagesPreview,
    ReportEventFormPanel,
    TrafficIncidentPanel,
    ConfirmDeleteEventDialogVue,
    MobileWarningPopUp
  },
  data () {
    return {
      map: null,
      baseMap: null,
      islongdoMapReady: false,
      islongdoMap3Ready: false,
      isShowingMapOnly: false,
      searchLocationMarker: null,
      searchResultList: [],
      hasMoreSearchResult: false,
      searchResultOverlayList: [],
      isShowingPopup: false,
      isSearching: false,
      isSearchingMore: false,
      isSearchingMoreTrafficEvent: false,
      hasMoreTrafficEvent: true,
      user: null,
      width: window.innerWidth,
      myPlaceList: [],
      isFromClickMyPlace: false,
      isFromClickEditPlace: false,
      fromSearch: false, // flag for load object from search only
      darkMode: false,
      lightMode: false,
      lastTagName: null,
      iconOption: (this.$route.query.map || 'v3') === 'v3' ? {
        html: '<img style="display:block;width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px;" src="' + process.env.VUE_APP_LONGDO_MAP_PIN + '">',
        offset: {
          x: 0,
          y: -(Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT) / 2)
        }
      }
        : {
          html: '<img alt="pin" style="width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px;" src="' + process.env.VUE_APP_LONGDO_MAP_PIN + '">',
          offset: {
            x: (Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH) / 2),
            y: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
          },
          size: {
            width: process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH,
            height: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
          }
        },
      isShowingContextmenu: false,
      isClickFromMyPlaceMarker: false,
      isClickOverlayMarker: false,
      currentLocationMarker: null,
      currentLocationBuffer: null,
      lastUpdateCurrentLocation: null,
      isTracking: false,
      isTrackingDirection: false,
      showMyPlacesName: localStorage.showMyPlacesName ? (localStorage.showMyPlacesName === 'true') : true,
      useTerrainFilter: false,
      landParcelOnPoiBaseLayerObjectList: [],
      landParcelFilter: false,
      nearbyDataFeature: {
        nearbyMessage: ''
      },
      isFirstCheckNearby: true,
      isShowNearbyDialog: false,
      isShowSelectNearbyTagDialog: false,
      isHidingExplorePanel: true,
      isExpandingExplorePanel: true,
      isHidingSearchResultPanel: true,
      isExpandingSearchResultPanel: true,
      isHidingMyPlacePanel: true,
      isExpandingMyPlacePanel: true,
      isHidingExploreNearbyPanel: true,
      isExpandingExploreNearbyPanel: true,
      isHidingEventPanel: true,
      isExpandingEventPanel: true,
      exploreNearbyCenter: null,
      isShowSharePlaceWidget: false,
      placeCardStep: 'MID',
      zoom: 16,
      timestamp: Date.now(),
      isShowToast: false,
      trafficEventType: null,
      trafficIncident: null,
      trafficIncidentMarker: [],
      trafficIncidentMakerActive: null,
      isShowReportEventFormPanel: false,
      showReportEventFormKey: 0,
      eventEditMode: null,
      idEventType: null,
      isDeletedEventComplete: false,
      isDeleteEventError: false,
      confirmDeleteEvent: false,
      confirmIdEventDelete: null,
      isLoadingDelete: false,
      toastMessage: '',
      isCheckingSpeedCamera: false,
      isCheckingAccidentBlackSpot: false,
      coorCheckSpeedCameraAndBlackSpot: null,
      isInWarningArea: false,
      reCheckSpeedCameraAndBlackSpotInterval: null,
      speedCameraList: [],
      blackSpotList: [],
      tempLocationByContext: {
        lat: null,
        lon: null
      },
      warningPoint: null,
      isLoadingNearByCam: false,
      warningMarker: null,
      isGeolocationMobileAppDisabled: false,
      isRealLocation: false,
      isLogin: false,
      geocodingResult: null
    }
  },
  computed: {
    isShowingMainMenuButton () {
      const self = this
      return self.$route.hash !== '#search'
    },
    isDisableLoginFeature () {
      const self = this
      if (self.lji === null) {
        return false
      }
      return self.lji.Util.isIosNativeApp()
    }
  },
  created () {
    const self = this
    self.isFirstCheckNearby = self.$route.name !== 'Nearby'
  },
  async mounted () {
    const self = this
    if (!self.isLongdoMapV3 && self.width >= self.mobileMaxSize) {
      window.location = 'https://map.longdo.com/longdomap'
    }
    self.$root.$on('loginFormClose', self.onLoginFormClose)
    self.$root.$on('loginComplete', self.onLoginComplete)
    self.$root.$on('logoutComplete', self.onLogoutComplete)
    self.$root.$on('froceDoNotShowingMapOnly', () => {
      self.isShowingMapOnly = false
      self.$emit('clickMap', self.isShowingMapOnly)
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeShowMyPlaces', self.onChangeShowMyPlaces)
    self.$root.$on('changeShowMyPlacesName', self.onChangeShowMyPlacesName)
    self.$root.$on('MobileAppGeolocationEnabled', self.onMobileAppGeolocationEnabled)
    self.$root.$on('MobileAppGeolocationDisabled', self.onMobileAppGeolocationDisabled)

    if (window.addEventListener) {
      window.addEventListener('message', self.receiveMessage, false)
    } else {
      if (window.attachEvent) {
        window.attachEvent('onmessage', self.receiveMessage, false)
      }
    }
    window.addRouteDestination = (lat, lon) => {
      self.$emit('routeTo', {
        lat: lat,
        lon: lon
      })
    }

    setInterval(() => {
      self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null || window.document.querySelector('.place-card:not(.hide)') !== null
    }, 100)

    self.$root.$on('getMyPlace', () => {
      self.getMyPlace()
    })

    self.$root.$on('changeHidingExplorePanel', value => {
      self.isHidingExplorePanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeExpandExplorePanel', value => {
      self.isExpandingExplorePanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeHidingSearchResultPanel', value => {
      self.isHidingSearchResultPanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeExpandSearchResultPanel', value => {
      self.isExpandingSearchResultPanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeHidingMyPlacePanel', value => {
      self.isHidingMyPlacePanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeExpandMyPlacePanel', value => {
      self.isExpandingMyPlacePanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeHidingExploreNearbyPanel', value => {
      self.isHidingExploreNearbyPanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeExpandExploreNearbyPanel', value => {
      self.isExpandingExploreNearbyPanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeHidingEventPanel', value => {
      self.isHidingEventPanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('changeExpandEventPanel', value => {
      self.isExpandingEventPanel = value
      self.resizeMapHeightAnimate()
    })
    self.$root.$on('showMessageToast', value => {
      self.showMessageToast(value)
    })
    self.$root.$on('reportEvent', value => {
      if (value === 'btn-click') {
        self.tempLocationByContext = {
          lat: null,
          lon: null
        }
      }
      self.reportEvent()
    })
    self.$root.$on('closeReportEventByToolbar', value => {
      self.isShowReportEventFormPanel = false
    })

    self.$root.$on('cancelDeleteEvent', value => {
      self.confirmDeleteEvent = false
    })
    self.$root.$on('confirmDeleteEvent', value => {
      self.confirmDeleteEvent = false
      self.isLoadingDelete = true
      self.deleteEvent()
    })
    self.$root.$on('showLongdoLoginForm', async value => {
      await self.showLongdoLoginForm(value)
    })
    self.$root.$on('setIsRealLocation', value => {
      self.isRealLocation = value
    })
    self.$root.$on('setIsTracking', self.setIsTracking)
    self.$root.$on('setIsTrackingDirection', self.setIsTrackingDirection)
    if (self.isTrafficProduct) {
      const trafficType = await self.api.getTrafficEventType()
      if (trafficType.data) {
        self.trafficEventType = trafficType.data
      }
    }
    if (self.isOnMobileApp) {
      if (window.isGeolocationMobileAppDisabled !== null) {
        self.isGeolocationMobileAppDisabled = window.isGeolocationMobileAppDisabled
      }
      self.$root.$on('setShowSpeedCamera', self.onSetShowSpeedCamera)
      self.$root.$on('setShowAccidentBlackSpot', self.onSetShowAccidentBlackSpot)
      self.$on('closeMobileWarningPopup', self.onCloseMobileWarningPopup)
      self.storePathToNativeAnalytic(self.$route)
    }

    self.$on('placeCardBoundArea', (poiId) => {
      self.boundArea(poiId)
    })
  },
  methods: {
    boundArea (poiId) {
      // method to bound overlay of area poi
      // overlay must exist since we draw overlay when showing poi
      const self = this
      const geomIndex = self.searchResultOverlayList.map(searchResultOverlay => {
        return searchResultOverlay instanceof window.longdo.Overlays.Object && searchResultOverlay.searchObjectData ? searchResultOverlay.searchObjectData.id : null
      }).indexOf(poiId)
      if (geomIndex !== -1) {
        const obj = self.searchResultOverlayList[geomIndex]
        window.test = obj
        let pointList
        // if layer use line array
        if (obj.items[0].objecttype === 'ooi_layer') {
          pointList = obj.items[0].linearray[0].data
        } else {
          // else normal case
          pointList = obj.overlays[0].location()
          if ('type' in pointList && pointList.type === 'MultiLineString') {
            // for v3 multiline string return {type: 'MultiLineString', coordinates: Array[Array[Array[],...],...]}
            pointList = pointList.coordinates.flat(1).map((point) => {
              return { lat: point[1], lon: point[0] }
            })
          }
          pointList = window.longdo.Util.locationBound(pointList)
        }

        if (pointList) {
          if (self.isLongdoMapV3) {
            if (window.innerWidth >= 992) {
              self.map.bound(pointList, { padding: { top: 70, left: 500, right: 70, bottom: 70 } })
            } else {
              self.map.bound(pointList, { padding: { top: 70, left: 20, right: 20, bottom: 250 } })
            }
          } else {
            self.map.bound(pointList)
          }
        }
      }
    },
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    onCloseMobileWarningPopup () {
      const self = this
      if (self.warningMarker) {
        self.map.Overlays.remove(self.warningMarker)
        self.warningMarker = null
      }
    },
    onMobileAppGeolocationEnabled () {
      const self = this
      self.$root.$emit('closeMobilePermissionDialog')
      self.isGeolocationMobileAppDisabled = false
    },
    onMobileAppGeolocationDisabled () {
      const self = this
      self.isGeolocationMobileAppDisabled = true
      if (self.currentLocationBuffer) {
        self.map.Overlays.remove(self.currentLocationBuffer)
        self.currentLocationBuffer = null
      }
      if (self.currentLocationMarker) {
        self.map.Overlays.remove(self.currentLocationMarker)
        self.currentLocationMarker = null
      }
      if (self.reCheckSpeedCameraAndBlackSpotInterval) {
        clearInterval(self.reCheckSpeedCameraAndBlackSpotInterval)
        self.reCheckSpeedCameraAndBlackSpotInterval = null
      }
      if (self.warningMarker) {
        self.map.Overlays.remove(self.warningMarker)
        self.warningMarker = null
      }
      self.warningPoint = null
      self.coorCheckSpeedCameraAndBlackSpot = null
      self.isInWarningArea = false
    },
    async showLongdoLoginForm (callback) {
      const self = this
      if (self.isOnMobileApp) {
        let loginResult
        try {
          loginResult = await self.lji.loginLongdo()

          // {<{username: String, longdoToken: String}>} result
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        if (loginResult?.username && loginResult?.longdoToken) {
          try {
            await self.lji.addStorage({
              key: 'username',
              value: loginResult.username
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
          try {
            await self.lji.addStorage({
              key: 'ldtoken',
              value: loginResult.longdoToken
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
        }
        const user = await self.utility.getUserSession()
        if (user) {
          self.$root.$emit('loginComplete', user)
          if (callback.success) {
            callback.success()
          }
        } else {
          console.log('error getUserSession')
        }
      } else {
        self.longdoAccount.showLongdoLoginForm()
      }
    },
    waitForElm (selector) {
      return new Promise(resolve => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector))
        }

        const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
            observer.disconnect()
            resolve(document.querySelector(selector))
          }
        })

        observer.observe(document.body, {
          childList: true,
          subtree: true
        })
      })
    },
    onNoMoreTrafficEvent () {
      const self = this
      self.hasMoreTrafficEvent = false
      self.isSearchingMoreTrafficEvent = false
    },
    drawTrafficEvent (incidentList) {
      const self = this
      incidentList.forEach(async (incident, index) => {
        var typeIndex = self.trafficEventType.findIndex((element) => {
          return element.id === parseInt(incident.severity)
        })
        // check start time and stop time to display
        const startTime = new Date(incident.start_time)
        const stopTime = new Date(incident.stop_time)
        const isSameDate = startTime.toDateString() === stopTime.toDateString()
        const dateDisplay = isSameDate ? startTime.toLocaleDateString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
          minute: 'numeric',
          hour: 'numeric',
          year: '2-digit',
          month: 'short',
          day: 'numeric'
        }) + ' - ' + stopTime.toLocaleTimeString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
          minute: 'numeric',
          hour: 'numeric'
        }) : startTime.toLocaleDateString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
          year: '2-digit',
          month: 'short',
          day: 'numeric'
        }) + ' - ' + stopTime.toLocaleDateString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
          year: '2-digit',
          month: 'short',
          day: 'numeric'
        })

        const eventPopUpOptions = {
          title: `<div style='font-size:15px; font-weight:800; word-wrap:break-word; display:flex; align-items:center; ${self.isLongdoMapV3 ? 'max-width: 235px' : ''}'>
                      ${self.isLongdoMapV3 ? '' : '<button class="material-icons-round back" style="width:40px">arrow_back</button>'}${self.$i18n.locale === 'th' ? incident.title : incident.title_en}
                    </div>`,
          detail: self.isLongdoMapV3 ? `<div style="overflow:hidden; max-width: 250px; display: flex; flex-direction: column;
                        gap: 9px; padding-top: 9px; word-wrap:break-word; font-size:12px; ">
                        <div style="font-weight:500;">
                          ${self.$i18n.locale === 'th' ? incident.title_en : incident.title}
                        </div>
                        <div style="font-size: 11px">${dateDisplay}</div>
                        <div style="font-size: 11px">${self.$t('main.trafficEventPopUp.by')} <a id="eventContributor" style="font-size: 11px" target='_blank' href="${process.env.VUE_APP_LONGDO_USER_INFO}${incident.uid}">${incident.contributor}</a></div>
                        ${incident.imagenid !== '0' ? `
                        <div style="width: 100%; display:flex; justify-content:center">
                          <a id="incidentImage" target='_blank' href='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}'>
                            <img src='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}/thumbnail'>
                          </a>
                        </div>` : ''}
                        <div>${self.$i18n.locale === 'th' ? incident.comment : incident.comment_en}</div>
                      <div style="display: flex; gap: 3px;">
                        <div id="shareEventButton" style="padding: 5px; color: #16A668; background-color: #F4FCF9; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #16A668;vertical-align: text-bottom; font-size: 16px;">share</i> ${self.$t('main.trafficEventPopUp.share')}</div>
                        <div id="commentEventButton" style="padding: 5px; color: #1B87EB; background-color: #EEF7FF; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #1B87EB; vertical-align: text-bottom; font-size: 16px;">chat_bubble</i> ${self.$t('main.trafficEventPopUp.comment')}</div>
                      </div>
                      <div class="editDelteEvent" style="display: flex; gap: 3px;"><div class="editEvent"></div><div class="deleteEvent"></div></div>
                  </div>
                </div>`
            : `<hr style="border: 0.5px solid #ccc; width:100%">
                  <div class="event-popup-description" style="overflow-y:scroll; overflow-x:hidden; display: flex; flex-direction: column; align-items:start !important; width: calc(100% - 20px);
                  gap: 9px; padding-left: 20px; word-wrap:break-word; font-size:13px; ">
                    <div style="font-weight:500; white-space: nowrap">
                      ${self.$i18n.locale === 'th' ? incident.title_en : incident.title}
                    </div>
                    <div style="font-size: 12px">${dateDisplay}</div>
                    <div style="font-size: 12px">${self.$t('main.trafficEventPopUp.by')} <a style="font-size: 11px" target='_blank' href="${process.env.VUE_APP_LONGDO_USER_INFO}${incident.uid}">${incident.contributor}</a></div>
                    ${incident.imagenid !== '0' ? `
                    <div style="width:100%; max-width:500px;}">
                      <a target='_blank' href='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}' style="width: 50%">
                        <img src='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}/thumbnail'>
                      </a>
                    </div>` : ''}
                    <div style="display: flex; gap: 3px;">
                      <div id="shareEventButton" style="padding: 5px; color: #16A668; background-color: #F4FCF9; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #16A668;vertical-align: text-bottom; font-size: 16px;">share</i> ${self.$t('main.trafficEventPopUp.share')}</div>
                      <div id="commentEventButton" style="padding: 5px; color: #1B87EB; background-color: #EEF7FF; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #1B87EB; vertical-align: text-bottom; font-size: 16px;">chat_bubble</i> ${self.$t('main.trafficEventPopUp.comment')}</div>
                    </div>
                    <div class="editDelteEvent" style="display: flex; gap: 3px;"><div class="editEvent"></div><div class="deleteEvent"></div></div>
                  <div>${self.$i18n.locale === 'th' ? incident.comment : incident.comment_en}</div>
                `,
          size: self.isLongdoMapV3 ? { width: 270 } : {},
          loadDetail: () => {
            let temp = document.getElementById('shareEventButton')
            if (temp) {
              temp.addEventListener('click', () => { self.handleShareEvent(incident.eid) })
            }
            temp = document.getElementById('commentEventButton')
            if (temp) {
              temp.addEventListener('click', () => { self.handleCommentEvent(incident.eid) })
            }
            if (self.isOnMobileApp) {
              temp = document.getElementById('eventContributor')
              if (temp) {
                const href = temp.href
                temp.addEventListener('click', () => { self.onClickUrl(href) })
                temp.removeAttribute('href')
              }
              temp = document.getElementById('incidentImage')
              if (temp) {
                const href = temp.href
                temp.addEventListener('click', () => { self.onClickUrl(href) })
                temp.removeAttribute('href')
              }
            }
          }
        }

        // draw overlay
        const icon = new window.longdo.Marker({ lat: parseFloat(incident.latitude), lon: parseFloat(incident.longitude) },
          {
            icon: {
              html: `<img src='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_TYPE_PATH_ICON.replace('{{icon}}', self.trafficEventType[typeIndex].icon)}'
              style="${incident.severity_level === '10' ? 'width:48px; height:48px;' : 'width:26px; height:26px'}">`
            },
            visibleRange: { min: 12, max: 22 },
            clickable: true,
            popup: eventPopUpOptions
          })
        icon.data = incident
        icon.type = 'e'
        self.trafficIncidentMarker.push(icon)
        incident.marker = icon

        if (incident.severity_level === '10') {
          for (var j = 1; j <= 5; j++) {
            var circle = new window.longdo.Circle(
              {
                lat: parseFloat(incident.latitude), lon: parseFloat(incident.longitude)
              },
              j * 0.01,
              {
                lineWidth: 1,
                lineColor: 'rgba(255, 0, 0, ' + (0.15 - j * 0.02) + ')',
                fillColor: 'rgba(255, 0, 0, ' + (0.15 - j * 0.02) + ')'
              }
            )
            self.trafficIncidentMarker.push(circle)
          }
        }
        const label = new window.longdo.Marker({ lat: parseFloat(incident.latitude), lon: parseFloat(incident.longitude) },
          {
            icon: {
              html: `<div style="position:fixed; display:flex; align-items:center;">
                <div style="position:absolute; border-radius: 3px; padding: 1px 3px; color: #542800; background-color:white; left:18px; min-width:max-content;">
                  ${self.$i18n.locale === 'th' ? incident.title : incident.title_en}
                </div>`,
              size: { height: 24 }
            },
            visibleRange: { min: 13, max: 22 },
            clickable: true,
            popup: eventPopUpOptions
          })
        label.data = incident
        label.type = 'e'
        self.trafficIncidentMarker.push(label)

        // add more attribute to array
        incident.icon = self.trafficEventType[typeIndex]
        incident.isToday = startTime.toDateString() === new Date().toDateString()

        if (['1', '2', '12', '18'].includes(incident.severity)) {
          incident.backgroundColor = '#FFF9E3'
        } else if (['5', '6', '8', '11', '13'].includes(incident.severity)) {
          incident.backgroundColor = '#EEF7FF'
        } else if (['3', '10', '19'].includes(incident.severity)) {
          incident.backgroundColor = '#FFE6E6'
        } else {
          incident.backgroundColor = '#FFE9D5'
        }

        self.$set(self.trafficIncident, index, incident)
      })
    },
    showMapTrafficEventHandler () {
      const self = this
      self.trafficIncidentMarker.forEach((marker) => { self.map.Overlays.add(marker) })
    },
    hideMapTrafficEventHandler () {
      const self = this
      self.trafficIncidentMarker.forEach((marker) => { self.map.Overlays.remove(marker) })
    },
    onDropHandler (event) {
      const self = this
      self.$emit('dropRoutingMarker', event)
    },
    showMessageToast (message) {
      const self = this
      self.toastMessage = message
      self.isShowToast = true
      setTimeout(() => {
        self.isShowToast = false
      }, 2000)
    },
    resizeMapHeightAnimate () {
      const self = this
      const start = new Date().getTime()
      if (!self.islongdoMap3Ready) {
        return false
      }
      const task = () => {
        const now = new Date().getTime()
        const diff = now - start
        if (diff >= 300) {
          self.map.resize()
          setTimeout(() => {
            self.map.resize()
          }, 500)
          // window.requestAnimationFrame(task)
        } else if (diff < 300) {
          window.requestAnimationFrame(task)
        }
      }
      window.requestAnimationFrame(task)
    },
    onClickTag (tag) {
      const self = this
      self.$router.push({
        name: 'Main',
        query: {
          search: `tag: ${tag}`
        }
      })
    },
    copyText (text) {
      const self = this
      const copytext = document.createElement('input')
      copytext.value = text
      document.body.appendChild(copytext)
      copytext.blur()
      copytext.select()
      document.execCommand('Copy')
      document.body.removeChild(copytext)
      self.$root.$emit('showMessageToast', self.$t('sharePlaceWidget.copy'))
    },
    receiveMessage (event) {
      const self = this
      if (typeof event !== 'undefined') {
        if (event.data) {
          if (typeof event.data === 'string' && event.data === 'refreshMyPlaces') {
            self.removeMyPlaceLocationMarker()
            self.removeMyPlaceResultOverlay()
            self.getMyPlace()
          } else if (event.data instanceof Object && event.data.msg === 'routeTo') {
            // window.open('https://maps.google.com?saddr=Current+Location&daddr=' + event.data.lat + ',' + event.data.lon, '_blank')
            self.$emit('routeTo', {
              lat: event.data.lat,
              lon: event.data.lon
            })
          }
        }
      }
    },
    clickMainMenu () {
      const self = this
      self.$root.$emit('showNavigationSideBar')
    },
    onLoginFormClose () {
      const self = this
      if (self.isFromClickMyPlace) {
        self.$router.replace({
          name: 'Main'
        })
      }

      self.isFromClickMyPlace = false
      self.isFromClickEditPlace = false
    },
    onLoginComplete (user) {
      const self = this
      if (user) {
        self.user = user
        self.getMyPlace()
        try {
          self.longdoAccount.closePrettyPhoto()
        } catch (e) {
          console.warn(e)
        }
        if (self.isFromClickMyPlace) {
          self.$emit('showMyPlace')
        }
        if (self.isFromClickEditPlace) {
          self.$router.push({
            name: 'EditPlace',
            params: {
              poiId: self.$route.params.poiId,
              userData: self.user
            }
          })
        }
        self.isFromClickMyPlace = false
        self.isFromClickEditPlace = false
        self.isLogin = true
      }
    },
    onLogoutComplete () {
      const self = this
      self.user = null
      self.removeMyPlaceLocationMarker()
      self.removeMyPlaceResultOverlay()
      if (self.$route.hash === '#my-places') {
        self.$router.replace({
          path: self.$route.path,
          query: self.$route.query
        })
      }
      self.isLogin = false
    },
    clickCloseReportEventPanel () {
      const self = this
      self.isShowReportEventFormPanel = false
      self.eventEditMode = null
    },
    clickCloseLayerPanel () {
      const self = this
      self.$router.replace({
        query: self.$route.query,
        hash: ''
      })
    },
    clickCloseMapTypePanel () {
      const self = this
      self.$router.replace({
        query: self.$route.query,
        hash: ''
      })
    },
    async getMyPlace () {
      const self = this
      if (self.user) {
        const params = {
          ldtoken: self.user.longdousertoken,
          username: self.user.name
        }
        self.$emit('loadingMyPlace')
        const result = await self.api.getMyPlace(params)
        result.data.result.forEach(item => {
          item.markicon = item.icon_url
          item.markicon2x = item.icon_url.replace('/icons/', '/icons_2x/')
        })
        if (self.isOnMobileApp) {
          // store my place for offline
          try {
            const temp = await self.lji.addStorage({
              key: 'myPlace',
              value: JSON.stringify(result.data.result)
            })

            // {Boolean} result
            console.log(temp)
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
        }
        self.myPlaceList = result.data.result
        self.$emit('loadedMyPlace', self.myPlaceList)
        const showFavorites = localStorage.showFavorites ? (localStorage.showFavorites === 'true') : true
        const showCreated = localStorage.showCreated ? (localStorage.showCreated === 'true') : true
        self.removeMyPlaceLocationMarker()
        self.removeMyPlaceResultOverlay()
        self.drawMyPlace(showFavorites || self.$route.hash === '#my-places', showCreated || self.$route.hash === '#my-places')
      }
    },
    drawMyPlace (drawFavorites = true, drawCreated = true) {
      const self = this
      self.myPlaceList.forEach(myPlaceObject => {
        if ((myPlaceObject.marked_favorite && drawFavorites) || (myPlaceObject.place_owner && drawCreated)) {
          const poiMarker = new window.longdo.Marker({
            lat: myPlaceObject.latitude,
            lon: myPlaceObject.longitude
          }, {
            title: myPlaceObject[`title_${self.$i18n.locale}`],
            detail: myPlaceObject[`teaser_${self.$i18n.locale}`],
            // popup: {
            //   loadDetail: (element) => {}
            // },
            icon: {
              html: `<div class="my-place-marker ${myPlaceObject.marked_favorite ? 'favorite' : ''} ${myPlaceObject.place_owner ? 'created' : ''}">
                <div class="icon">
                  ${myPlaceObject.marked_favorite ? '<i class="material-icons-round border">star</i>' : ''}
                  ${myPlaceObject.marked_favorite ? '<i class="material-icons-round body">star</i>' : ''}
                  ${!myPlaceObject.marked_favorite && myPlaceObject.place_owner ? ('<div style="background-image: url(' + myPlaceObject.markicon2x + ')"></div>') : ''}
                </div>
                <label class="hide">${myPlaceObject[`title_${self.$i18n.locale}`]}</label>
              </div>`
            },
            visibleRange: {
              min: myPlaceObject.marked_favorite ? 9 : 12,
              max: 22
            }
          })
          poiMarker.popup = () => { return false }
          poiMarker.searchType = 'MYPLACE'
          poiMarker.myPlaceObjectData = myPlaceObject
          self.searchResultOverlayList.push(poiMarker)
          myPlaceObject.exploreResultOverlayIndex = self.searchResultOverlayList.length - 1
          self.map.Overlays.add(poiMarker)
        }
      })
    },
    onClickMyPlace (myPlace, showPlaceDetail) {
      const self = this
      self.searchResultOverlayList.forEach(myPlaceOverlay => {
        if (myPlaceOverlay instanceof window.longdo.Marker) {
          myPlaceOverlay.element().classList.remove('focus-my-place')
        }
      })
      const location = {
        lat: myPlace.latitude,
        lon: myPlace.longitude
      }
      const bound = self.map.bound()
      const diffLat = bound.maxLat - bound.minLat
      const diffLon = bound.maxLon - bound.minLon
      const locationList = [{
        lat: bound.maxLat - (diffLat * 0.10),
        lon: bound.minLon + (diffLon * 0.05)
      }, {
        lat: bound.maxLat - (diffLat * 0.10),
        lon: bound.maxLon - (diffLon * 0.05)
      }, {
        lat: bound.minLat + (diffLat * 0.40),
        lon: bound.maxLon - (diffLon * 0.05)
      }, {
        lat: bound.minLat + (diffLat * 0.40),
        lon: bound.minLon + (diffLon * 0.05)
      }, {
        lat: bound.maxLat - (diffLat * 0.10),
        lon: bound.minLon + (diffLon * 0.05)
      }]
      const isContain = window.longdo.Util.contains(location, locationList)
      if (!isContain) {
        self.map.location(location)
        self.map.resize()
      }
      self.searchResultOverlayList.forEach(myPlaceOverlay => {
        if (myPlaceOverlay instanceof window.longdo.Marker) {
          if (myPlaceOverlay.myPlaceObjectData.ooiid === myPlace.ooiid) {
            myPlaceOverlay.element().classList.add('focus-my-place')
            if (showPlaceDetail) {
              self.map.Event.fire('overlayClick', myPlaceOverlay)
            }
          }
        }
      })

      if (self.map.zoom() < 16) {
        self.map.zoom(16)
      }
    },
    async loadedLongdoMap (map, isV3 = false) {
      const self = this
      if (isV3) {
        self.islongdoMap3Ready = true
        window.longdo3.googleQuery = 'key=' + process.env.VUE_APP_GOOGLE_MAP_TILE_KEY
      } else {
        self.islongdoMapReady = true
        map.Tags.add('%', { dataset: ' ' })
      }
      self.map = map

      // for debug map
      // if (process.env.VUE_APP_BUILD_SERVER !== 'prod') {
      // fix to also be avaliable in production
      window.map = map
      // }
      if (self.isOnMobileApp) {
        if (localStorage.showSpeedCamera) {
          self.isCheckingSpeedCamera = localStorage.showSpeedCamera === 'true'
        } else {
          self.isCheckingSpeedCamera = true
        }
        if (localStorage.showAccidentBlackSpot) {
          self.isCheckingAccidentBlackSpot = localStorage.showAccidentBlackSpot === 'true'
        } else {
          self.isCheckingAccidentBlackSpot = true
        }
      }

      self.map.Layers.externalOptions({
        googleQuery: 'key=' + process.env.VUE_APP_GOOGLE_MAP_TILE_KEY,
        mapboxKey: process.env.VUE_APP_MAPBOX_KEY
      })
      if (self.isOnMobileApp && self.isRealLocation && window.currentLocation &&
        self.$route.name === 'Main' && self.$route.hash === '' &&
        JSON.stringify(self.$route.query) === '{}' && JSON.stringify(self.$route.params === '{}')) {
        // case load map is slower than getLocation
        self.map.location(window.currentLocation, !isV3)
      } else if ('lat' in self.$route.query && 'lon' in self.$route.query) {
        localStorage.lat = self.$route.query.lat
        localStorage.lon = self.$route.query.lon
        self.map.location({
          lat: Number(localStorage.lat),
          lon: Number(localStorage.lon)
        }, !isV3)
      } else if ('lat' in self.$route.query && 'long' in self.$route.query) {
        localStorage.lat = self.$route.query.lat
        localStorage.lon = self.$route.query.long
        self.map.location({
          lat: Number(localStorage.lat),
          lon: Number(localStorage.lon)
        }, !isV3)
      } else if (localStorage.lat && localStorage.lon) {
        self.map.location({
          lat: Number(localStorage.lat),
          lon: Number(localStorage.lon)
        }, !isV3)
      }
      if (!self.isRealLocation) {
        window.currentLocation = self.map.location()
      }
      if (self.isOnMobileApp && self.isRealLocation && window.currentLocation &&
        self.$route.name === 'Main' && self.$route.hash === '' &&
        JSON.stringify(self.$route.query) === '{}' && JSON.stringify(self.$route.params === '{}')) {
        if (self.map.zoom() < 16) {
          self.map.zoom(16, !isV3)
        }
      } else if ('zoom' in self.$route.query) {
        self.map.zoom(Number(self.$route.query.zoom))
      } else if (localStorage.zoom) {
        let localStorageZoom = Number(localStorage.zoom)
        if (!isV3) {
          localStorageZoom = Math.round(localStorageZoom)
        }
        self.map.zoom(localStorageZoom)
      }
      self.zoom = self.map.zoom()
      if (self.map.placeholder().querySelector('.ldmap_popup_mini') === null) {
        self.map.Ui.popup = new window.longdo.PopupMini(self.map.placeholder(), self.map)
      }
      if (!self.isLongdoMapV3) {
        self.map.Ui.DPad.visible(false)
        self.map.Ui.Geolocation.visible(false)
        self.map.Ui.LayerSelector.visible(false)
        self.map.Ui.Zoombar.visible(false)
        window.longdo.MapTheme.ui.popupMiniMarkerIcon.html = ''
        self.map.Ui.Toolbar.visible(false)
        self.map.Ui.enableTouchMeasure()
      }

      const needShowLayerList = (self.$route.query.layers || '').split(',')

      if (self.product === 'traffic') {
        const currentMonth = new Date().getMonth() + 1
        if (currentMonth >= 8 && currentMonth <= 9 && needShowLayerList.indexOf('flood') < 0) {
          needShowLayerList.push('flood')
        }
        if ((currentMonth >= 11 || currentMonth <= 1) && needShowLayerList.indexOf('aqi') < 0) {
          needShowLayerList.push('aqi')
        }
        if ((currentMonth >= 5 && currentMonth <= 10) && needShowLayerList.indexOf('rainviewer') < 0) {
          needShowLayerList.push('rainviewer')
        }
      }

      if (needShowLayerList.length > 0) {
        self.$nextTick(() => {
          needShowLayerList.forEach(layerName => {
            self.$root.$emit('openLayer', layerName)
          })
        })
      }
      if (self.isOnMobileApp) {
        const attrButtonList = document.getElementsByClassName('maplibregl-ctrl-attrib-button mapboxgl-ctrl-attrib-button')
        for (var item of attrButtonList) {
          item.addEventListener('click', async () => {
            try {
              await self.lji.addStorage({
                key: 'closeAttribution',
                value: 'true'
              })
              // {Boolean} result
            } catch (error) {
              // {<{code: Number, message: String}>} error
              console.log(error)
            }
          })
        }
        try {
          const result = await self.lji.getStorage({
            key: 'closeAttribution'
          })

          if (result === 'true') {
            for (item of attrButtonList) {
              item.click()
            }
          }
          // {Boolean} result
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
      if (self.product === 'traffic') {
        const {
          isActiveRainRadarLayer,
          isActiveCamera,
          isActiveGasStation,
          isActiveAqi,
          isActiveEvent
        } = window.localStorage

        self.$nextTick(() => {
          self.$root.$emit('openLayer', self.layerList[0].name)

          if (isActiveCamera === 'false') {
            self.$root.$emit('hideCameraOnTrafficLayer')
          }

          if (isActiveEvent === 'false') {
            self.$root.$emit('hideEventOnTrafficLayer')
          }

          if ((isActiveGasStation || 'false') !== 'false') {
            self.onShowTag('Gas_station')
          }

          if ((isActiveAqi || 'false') !== 'false' && needShowLayerList.indexOf('aqi') < 0) {
            self.$root.$emit('openLayer', self.layerList[3].name)
          }
          if ((isActiveRainRadarLayer || 'false') !== 'false' && needShowLayerList.indexOf('rainviewer') < 0) {
            self.$root.$emit('openLayer', self.layerList[4].name)
          }
        })
      }

      window.addEventListener('resize', () => {
        self.map.resize()
      })

      self.updateCurrentLocation()

      self.map.Event.bind('location', () => {
        const location = self.map.location()
        self.$emit('location', location)
        localStorage.lat = location.lat
        localStorage.lon = location.lon
      })
      if (isV3) {
        self.map.Renderer.on('pitch', () => {
          if (self.isShowingContextmenu) {
            self.isShowingContextmenu = false
          }
          self.rotateDirectionMarker()
        })
        self.map.Renderer.on('rotate', () => {
          self.rotateDirectionMarker()
        })
      }
      self.map.Event.bind('drag', () => {
        if (self.isShowingContextmenu) {
          self.isShowingContextmenu = false
        }
        self.setIsTracking(false)
        if (self.map.Ui.Mouse.lockPinch) {
          self.map.Ui.Mouse.lockPinch(false)
        }
        self.$emit('dragMap')
      })
      self.useTerrainFilter = self.map.zoom() < 12
      self.map.Event.bind('zoom', () => {
        if (self.isShowingContextmenu) {
          self.isShowingContextmenu = false
        }
        const zoom = self.map.zoom()
        const location = self.map.location()
        self.zoom = zoom
        localStorage.zoom = zoom
        localStorage.lat = location.lat
        localStorage.lon = location.lon
        self.searchResultOverlayList.forEach(myPlaceMarker => {
          if (myPlaceMarker instanceof window.longdo.Marker && 'searchType' in myPlaceMarker) {
            if (myPlaceMarker.searchType === 'MYPLACE') {
              if (zoom < 14) {
                myPlaceMarker.element().querySelector('label').classList.add('hide')
              } else {
                myPlaceMarker.element().querySelector('label').classList.remove('hide')
              }
            }
          }
        })
        self.useTerrainFilter = zoom < 12
      })

      self.map.Event.bind('beforeContextmenu', (e) => {
        if (self.isOnMobileApp) {
          return false
        }
        const moveToLocationContextMenu = window.document.createElement('div')
        window.longdo.Util.addClass(moveToLocationContextMenu, self.isLongdoMapV3 ? 'ldmap-link' : 'ldmap_link')
        moveToLocationContextMenu.innerHTML = `<i class="material-icons" style="font-size:14px;">add</i> <span style="color: #069; vertical-align: text-bottom;">${self.$t('main.moveToLocation')}</span>`
        moveToLocationContextMenu.addEventListener('click', () => {
          self.map.location(e.location)
        })
        e.add(moveToLocationContextMenu)

        const shareThisLocationContextMenu = window.document.createElement('div')
        window.longdo.Util.addClass(shareThisLocationContextMenu, self.isLongdoMapV3 ? 'ldmap-link' : 'ldmap_link')
        shareThisLocationContextMenu.innerHTML = `<i class="material-icons" style="font-size:14px;">share</i> <span style="color: #069; vertical-align: text-bottom;">${self.$t('main.shareThisLocation')}</span>`
        const url = `https://${process.env.VUE_APP_PRODUCT}.longdo.com/?lat=${e.location.lat.toFixed(6)}&lon=${e.location.lon.toFixed(6)}&zoom=${self.map.zoom().toFixed(6)}`
        shareThisLocationContextMenu.addEventListener('click', () => {
          if (typeof window.navigator !== 'undefined') {
            if (window.navigator.share) {
              window.navigator.share({
                url: url,
                title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
              })
            } else {
              self.copyText(url)
            }
          } else {
            self.copyText(url)
          }
        })
        e.add(shareThisLocationContextMenu)

        // Map product
        if (!self.isTrafficProduct && ((self.isOnMobileApp && self.isLogin) || !self.isOnMobileApp)) {
          const createPlaceContextMenu = window.document.createElement('div')
          window.longdo.Util.addClass(createPlaceContextMenu, self.isLongdoMapV3 ? 'ldmap-link' : 'ldmap_link')
          createPlaceContextMenu.innerHTML = self.$t('main.createPlace')
          createPlaceContextMenu.addEventListener('click', () => {
            if (self.isLongdoMapV3 && self.product !== 'traffic') {
              window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${e.location.lat.toFixed(6)}&lon=${e.location.lon.toFixed(6)}`
            } else {
              self.$router.push({
                name: 'CreatePlace',
                params: {},
                query: {
                  lat: Number(e.location.lat.toFixed(6)),
                  lon: Number(e.location.lon.toFixed(6))
                },
                hash: ''
              })
            }
          })
          e.add(createPlaceContextMenu)
        //   Traffic
        } else if (self.isTrafficProduct && ((self.isOnMobileApp && self.isLogin) || !self.isOnMobileApp)) {
          this.tempLocationByContext = e.location
          const reportEventTitle = window.document.createElement('div')
          reportEventTitle.setAttribute('class', 'reportEventZone')
          const textTitle = window.document.createElement('span')
          const line = window.document.createElement('hr')
          line.setAttribute('style', 'height: 1px; background-color: #eee; border: none;')
          textTitle.innerHTML = self.$t('reportEventForm.header')
          reportEventTitle.appendChild(line)
          reportEventTitle.appendChild(textTitle)
          e.add(reportEventTitle)
          const reportEventContext = window.document.createElement('div')
          window.longdo.Util.addClass(reportEventContext, self.isLongdoMapV3 ? 'ldmap-link' : 'ldmap_link')
          if (self.isShowReportEventFormPanel) {
            reportEventContext.style.cursor = 'unset'
            reportEventContext.setAttribute('disabled', '')
          }
          self.trafficEventType.forEach((el) => {
            const url = process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_TYPE_PATH_ICON
            const iconUrl = url.replace('{{icon}}', el.icon)
            const iconEvent = window.document.createElement('img')
            window.longdo.Util.addClass(iconUrl, self.isLongdoMapV3 ? 'ldmap-link' : 'ldmap_link')
            iconEvent.src = iconUrl
            iconEvent.id = `iconID${el.id}`
            iconEvent.setAttribute('loading', 'lazy')
            iconEvent.setAttribute('style', 'height: 22px; padding: 3px;')
            iconEvent.setAttribute('class', 'iconTrafficEventType')
            iconEvent.addEventListener('click', () => { self.reportEvent(el.id) })
            reportEventContext.appendChild(iconEvent)
          })
          e.add(reportEventContext)
        }
      })

      self.map.Event.bind('contextmenu', () => {
        if (self.isOnMobileApp) {
          return false
        }
        self.isShowingContextmenu = true
      })

      self.map.Event.bind('click', () => {
        if (self.isShowingContextmenu) {
          self.$nextTick(() => {
            // prevent stack click from beforetagpopup event
            self.isShowingContextmenu = false
          })
        } else if (self.$route.hash === '#measurement') {
          self.isShowingMapOnly = false
        } else {
          const panelHashList = ['#layers', '#maps', '#search']
          const isShowingPanel = panelHashList.indexOf(self.$route.hash) !== -1
          self.isShowingMapOnly = isShowingPanel ? false : !self.isShowingMapOnly
          if (isShowingPanel) {
            self.$router.push({
              query: self.$route.query,
              hash: ''
            })
          }

          self.$emit('clickMap', self.isShowingMapOnly)
          // for (let time = 0; time <= 300; time += 15) {
          //   setTimeout(() => {
          //     self.map.resize()
          //   }, time)
          // }
          self.resizeMapHeightAnimate()
        }
      })

      // Cluster click
      self.map.Event.bind('overlayClick', async (e) => {
        if (e instanceof window.longdo.Marker) {
          self.setIsTracking(false)
          self.isClickOverlayMarker = true
          if ('searchObjectData' in e) { // marker from search
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            if ((e.searchObjectData.source || '') === 'google') {
              return false
            }

            self.$emit('clickMarker')
            setTimeout(() => {
              let samePlace = false
              if ('poiId' in self.$route.params) {
                samePlace = self.$route.params.poiId === e.searchObjectData.id
              }

              self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null

              const isPoi = 'searchObjectData' in e
                ? (e.searchObjectData.objecttype === 'poi' || e.searchObjectData.objecttype === 'osmp')
                : false
              if (isPoi) {
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isKhet = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'khet') : false
              if (isKhet) {
                self.isShowingPopup = true
                self.fromSearch = true
                // Bug overlay
                // if (!self.isLongdoMapV3) {
                //   self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                // }
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isRoad = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'road') : false
              if (isRoad) {
                self.isShowingPopup = true
                self.fromSearch = true
                self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isGeom = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'geom') : false
              if (isGeom) {
                self.isShowingPopup = true
                self.fromSearch = true
                self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isWaterLine = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'water-line') : false
              if (isWaterLine) {
                self.isShowingPopup = true
                self.fromSearch = true
                self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isWaterArea = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'water-area') : false
              if (isWaterArea) {
                self.isShowingPopup = true
                self.fromSearch = true
                self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isBusRoute = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'bus') : false
              if (isBusRoute) {
                self.isShowingPopup = true
                self.fromSearch = true
                self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }

              const isGeocoding = 'searchObjectData' in e
                ? (e.searchObjectData.objecttype === 'geocoding')
                : false
              if (isGeocoding) {
                self.isShowingPopup = true
                self.fromSearch = true
                if (!samePlace) {
                  const path = {
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {
                      lat: e.searchObjectData.lat,
                      lon: e.searchObjectData.long,
                      name: e.searchObjectData.name
                    }
                  }
                  if ('GCP' in self.$route.query) {
                    self.$router.replace(path)
                  } else {
                    self.$router.push(path)
                  }
                }
              }

              const isReverseGeocoding = 'searchObjectData' in e
                ? (e.searchObjectData.objecttype === 'reverseGeocoding')
                : false
              if (isReverseGeocoding) {
                self.fromSearch = true
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {
                      lat: self.$route.query.lat,
                      lon: self.$route.query.lon
                    }
                  })
                }
              }

              const isOther = 'searchObjectData' in e ? (e.searchObjectData.objecttype === 'other') : false
              if (isOther) {
                self.isShowingPopup = true
                self.fromSearch = true
                self.map.Overlays.load(self.searchResultOverlayList[e.searchObjectData.searchResultGeomIndex])
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.searchObjectData.id
                    },
                    query: {}
                  })
                }
              }
            }, 50)
          } else if ('exploreObjectData' in e) { // marker from explore
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            setTimeout(() => {
              let samePlace = false
              if ('poiId' in self.$route.params) {
                samePlace = self.$route.params.poiId === e.exploreObjectData.id
              }

              self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null

              const isPoi = 'exploreObjectData' in e ? (
                (e.exploreObjectData.id[0] === 'A' ||
                e.exploreObjectData.id.indexOf('H0') === 0 ||
                e.exploreObjectData.id.indexOf('OSM:P') === 0 ||
                e.exploreObjectData.id.indexOf('OSM:L') === 0 ||
                e.exploreObjectData.id.indexOf('OSM:G') === 0 ||
                e.exploreObjectData.id.indexOf('OVM:P') === 0)
              ) : false
              // if (self.isShowingPopup && isPoi) { // ทำไมต้อเช็ค self.isShowingPopup
              if (isPoi) {
                if (!samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.exploreObjectData.id
                    },
                    query: {}
                  })
                }
              }
            }, 50)
          } else if ('myPlaceObjectData' in e) { // marker from my place
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            setTimeout(() => {
              let samePlace = false
              if ('poiId' in self.$route.params) {
                samePlace = self.$route.params.poiId === e.myPlaceObjectData.ooiid
              }
              self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
              // if (self.isShowingPopup) { // ทำไมต้อเช็ค self.isShowingPopup
              if (!samePlace) {
                self.isClickFromMyPlaceMarker = true
                self.$router.push({
                  name: 'Place',
                  params: {
                    poiId: e.myPlaceObjectData.ooiid
                  },
                  query: {}
                })
              }
              // }
            }, 50)
          } else if ('eventObjectData' in e) { // marker from event (event panel)
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            setTimeout(() => {
              let sameEvent = false
              if ('eventId' in self.$route.params) {
                sameEvent = self.$route.params.eventId === e.eventObjectData.eventId
              }
              // if (self.userData) {
              //   if (!document.getElementById('editEventObjc')) {
              //     const desc = document.getElementsByClassName('ldmap_overlay_description')
              //     const test = document.createElement('div')
              //     test.setAttribute('id', 'editEventObjc')
              //     const text = document.createTextNode(self.$t('reportEventForm.edit'))
              //     test.addEventListener('click', () => { document.getElementById('editEventObjc').onclick = self.handleEditEvent(e.eventObjectData.eid) })
              //     test.appendChild(text)
              //     desc[0].appendChild(test)
              //   }
              // }
              // e.popup().title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)
              if (!sameEvent) {
                const eventPageOption = {
                  name: 'Event',
                  params: {
                    eventId: e.eventObjectData.eventId
                  },
                  query: {}
                }
                if (self.$route.name === 'Event') {
                  self.$router.replace(eventPageOption)
                } else {
                  self.$router.push(eventPageOption)
                }
              }
            }, 50)
          } else if ('aqiAir4ThaiData' in e) {
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            self.clearFocusMarker()
            e.element().classList.add('focus-aqi')
            e.popup().title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)

            setTimeout(() => {
              self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
              if (self.isShowingPopup) {
                if (self.$route.name === 'AqiAir4Thai') {
                  let sameAqi = false
                  if ('aqiAir4ThaiId' in self.$route.params) {
                    sameAqi = self.$route.params.aqiAir4ThaiId === e.aqiAir4ThaiData.id
                  }
                  if (!sameAqi) {
                    self.$router.replace({
                      name: 'AqiAir4Thai',
                      params: {
                        aqiAir4ThaiId: e.aqiAir4ThaiData.id
                      },
                      query: {}
                    })
                  }
                } else {
                  self.$router.push({
                    name: 'AqiAir4Thai',
                    params: {
                      aqiAir4ThaiId: e.aqiAir4ThaiData.id
                    },
                    query: {}
                  })
                }
              } else {
                self.clearFocusMarker()
                self.onClickBackPopupMini()
              }
            }, 50)
          } else if ('aqiCNData' in e) {
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            if (!self.isLongdoMapV3) {
              self.clearFocusMarker()
              e.element().classList.add('focus-aqi')
              e.popup().title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)
              setTimeout(() => {
                self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
                if (self.isShowingPopup) {
                  if (self.$route.name === 'AqiCN') {
                    let sameAqi = false
                    if ('aqiCNId' in self.$route.params) {
                      sameAqi = self.$route.params.aqiCNId === e.aqiCNData.id
                    }
                    if (!sameAqi) {
                      self.$router.replace({
                        name: 'AqiCN',
                        params: {
                          aqiCNId: e.aqiCNData.id
                        },
                        query: {}
                      })
                    }
                  } else {
                    self.$router.push({
                      name: 'AqiCN',
                      params: {
                        aqiCNId: e.aqiCNData.id
                      },
                      query: {}
                    })
                  }
                } else {
                  self.clearFocusMarker()
                  self.onClickBackPopupMini()
                }
              }, 50)
            }
          } else if (e.type === 'e') { // events (tag event marker by api)
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            if (self.isLongdoMapV3) {
              // mapv3 has different popup from v2
              await self.waitForElm('.ldmap-popup_title')
              const title = document.querySelector('.ldmap-popup_title')
              if (title) {
                title.style.height = 'auto'
              }
              // return false
            } else {
              // v2
              if (!self.isTrafficProduct) {
                const title = e.data[self.$i18n.locale === 'th' ? 'title' : 'title_en']
                e.popup().title(title)
              }
              e.popup().title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)
            }

            setTimeout(async () => {
              let userSession = await self.utility.getUserSession()
              userSession = userSession || self.user
              let isAdmin = false
              if (userSession) {
                isAdmin = e.data.uid === userSession.uid
                if ('roles' in userSession) {
                  Object.keys(userSession.roles).forEach((key) => {
                    if (userSession.roles[key] === 'webmaster') { isAdmin = true }
                  })
                }
              }
              if (isAdmin) {
                if (!document.getElementById('editEvent')) {
                  const desc = document.getElementsByClassName('editEvent')
                  desc[0].innerHTML += `<div id="editBtn" style="padding: 5px; color: #997907; background-color: #FFF9E3; border-radius: 3px; cursor:pointer;"><i id="iconEditReportEvent" class="material-icons-round" style="color: #997907;vertical-align: text-bottom; font-size: 16px;">edit</i> ${self.$t('reportEventForm.edit')}</div>`
                  desc[0].setAttribute('id', 'editEvent')
                  desc[0].addEventListener('click', async () => { await document.getElementById('editEvent').onclick(self.handleEditEvent(e.data.eid)) })
                  if (self.isShowReportEventFormPanel) desc[0].setAttribute('disabled', '')
                  else desc[0].removeAttribute('disabled')
                }
                if (!document.getElementById('deleteEvent')) {
                  const desc = document.getElementsByClassName('deleteEvent')
                  desc[0].innerHTML += `<div style="padding: 5px; color: #F15151; background-color: #FFE6E6; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #F15151; vertical-align: text-bottom; font-size: 16px;">delete</i> ${self.$t('reportEventForm.delete')}</div>`
                  desc[0].setAttribute('id', 'deleteEvent')
                  desc[0].addEventListener('click', async () => { await document.getElementById('deleteEvent').onclick(self.handleDeleteEvent(e.data.eid)) })
                }
              } else {
                if (document.getElementById('editEvent')) {
                  document.getElementById('editEvent').remove()
                }
                if (document.getElementById('deleteEvent')) {
                  document.getElementById('deleteEvent').remove()
                }
              }
              if (!self.isLongdoMapV3) {
                self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
                if (self.isShowingPopup) {
                  const eventId = 'A' + e.data.eid.padStart(8, '0')
                  if (self.$route.name === 'Event') {
                    let sameEvent = false
                    if ('eventId' in self.$route.params) {
                      sameEvent = self.$route.params.eventId === eventId
                    }
                    if (!sameEvent) {
                      self.$router.replace({
                        name: 'Event',
                        params: {
                          eventId: eventId
                        },
                        query: {}
                      })
                    }
                  } else {
                    self.$router.push({
                      name: 'Event',
                      params: {
                        eventId: eventId
                      },
                      query: {}
                    })
                  }
                } else {
                  self.onClickBackPopupMini()
                }
              }
            }, 200)
          } else if (e.type === 't') { // marker from tag.add()
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            self.$router.push({
              name: 'Place',
              params: {
                poiId: e.data.id
              }
            })
            return false
          } else if ('data' in e) {
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            if ('camid' in e.data) {
              if (self.isLongdoMapV3) {
                const elm = await self.waitForElm('.maplibregl-popup-content')
                await self.waitForElm('.ldmap-popup_detail video')
                var videos = document.querySelectorAll('.ldmap-popup_detail video')
                let footer
                if (document.getElementById('camera_footer') === null) {
                  footer = document.createElement('div')
                  footer.setAttribute('id', 'camera_footer')
                  footer.style.display = 'flex'
                  footer.style.gap = '10px'
                  footer.style.alignItems = 'center'
                } else {
                  footer = document.getElementById('camera_footer')
                }
                setTimeout(() => {
                  let refreshDiv = document.getElementById('camera_refresh')
                  if (videos.length > 0 && videos[0].paused && !refreshDiv) {
                    refreshDiv = document.createElement('div')
                    refreshDiv.setAttribute('id', 'camera_refresh')
                    refreshDiv.setAttribute('class', 'material-icons-round')
                    const textfooter = document.createTextNode('refresh')
                    const button1 = document.createElement('a')
                    button1.addEventListener('click', async () => { await document.querySelectorAll('.ldmap-popup_detail video')[0].play() })
                    const textfooter2 = document.createTextNode('Refresh')
                    refreshDiv.appendChild(textfooter)
                    button1.appendChild(textfooter2)
                    refreshDiv.appendChild(button1)
                    refreshDiv.style.display = 'flex'
                    refreshDiv.style.gap = '10px'
                    refreshDiv.style.alignItems = 'center'
                    footer.insertBefore(refreshDiv, footer.firstChild)
                  }
                  if (!document.getElementById('camera_share')) {
                    const shareDiv = document.createElement('div')
                    shareDiv.setAttribute('id', 'camera_share')
                    shareDiv.setAttribute('class', 'material-icons-round')
                    const textfooter3 = document.createTextNode('share')
                    const button2 = document.createElement('a')
                    button2.addEventListener('click', () => {
                      const url = process.env.VUE_APP_LONGDO_TRAFFIC_CAMERA_BASE_URL + e.data.camid
                      if (typeof window.navigator !== 'undefined') {
                        if (window.navigator.share) {
                          window.navigator.share({
                            url: url,
                            title: self.$t('main.trafficShareCamera')
                          })
                        } else {
                          self.copyText(url)
                        }
                      } else {
                        self.copyText(url)
                      }
                    })
                    const textfooter4 = document.createTextNode('Share')
                    shareDiv.appendChild(textfooter3)
                    button2.appendChild(textfooter4)
                    shareDiv.appendChild(button2)
                    shareDiv.style.display = 'flex'
                    shareDiv.style.gap = '10px'
                    shareDiv.style.alignItems = 'center'
                    footer.appendChild(shareDiv)
                    elm.appendChild(footer)
                  }
                }, 1500)
              } else {
                setTimeout(async () => {
                  self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
                  if (self.isShowingPopup) {
                    const title = '<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">' + e.data.title + '</div>'
                    e.popup().title(title)
                    e.popup().title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)
                    self.$router.push({
                      name: 'Main',
                      params: {},
                      query: {},
                      hash: '#cam'
                    })
                    await self.waitForElm('.ldmap_popup_detail a video')
                    var videos = document.querySelectorAll('.ldmap_popup_detail a video')[0]
                    const detail = e.popup().detail()
                    let footer
                    if (document.getElementById('camera_footer') === null) {
                      footer = document.createElement('div')
                      footer.setAttribute('id', 'camera_footer')
                      footer.style.display = 'flex'
                      footer.style.gap = '10px'
                      footer.style.alignItems = 'center'
                    } else {
                      footer = document.getElementById('camera_footer')
                    }
                    footer.style.display = 'flex'
                    footer.style.justifyContent = 'center'
                    footer.style.alignItems = 'center'
                    footer.style.gap = '10px'
                    if (!document.getElementById('camera_share')) {
                      const shareDiv = document.createElement('div')
                      shareDiv.setAttribute('id', 'camera_share')
                      shareDiv.setAttribute('class', 'material-icons-round')
                      const textfooter3 = document.createTextNode('share')
                      const button2 = document.createElement('a')
                      button2.addEventListener('click', () => {
                        const url = process.env.VUE_APP_LONGDO_TRAFFIC_CAMERA_BASE_URL + e.data.camid
                        if (typeof window.navigator !== 'undefined') {
                          if (window.navigator.share) {
                            window.navigator.share({
                              url: url,
                              title: self.$t('main.trafficShareCamera')
                            })
                          } else {
                            self.copyText(url)
                          }
                        } else {
                          self.copyText(url)
                        }
                      })
                      const textfooter4 = document.createTextNode('Share')
                      shareDiv.appendChild(textfooter3)
                      button2.appendChild(textfooter4)
                      shareDiv.appendChild(button2)
                      shareDiv.style.display = 'flex'
                      shareDiv.style.gap = '10px'
                      shareDiv.style.alignItems = 'center'
                      footer.appendChild(shareDiv)
                      detail.appendChild(footer)
                    }
                    let refreshDiv = document.getElementById('camera_refresh')
                    if (videos.length > 0 && videos[0].paused && !refreshDiv) {
                      refreshDiv = document.createElement('div')
                      refreshDiv.setAttribute('id', 'camera_refresh')
                      refreshDiv.setAttribute('class', 'material-icons-round')
                      const textfooter = document.createTextNode('refresh')
                      const button1 = document.createElement('a')
                      button1.addEventListener('click', async () => { await document.querySelectorAll('.ldmap-popup_detail video')[0].play() })
                      const textfooter2 = document.createTextNode('Refresh')
                      refreshDiv.appendChild(textfooter)
                      button1.appendChild(textfooter2)
                      refreshDiv.appendChild(button1)
                      refreshDiv.style.display = 'flex'
                      refreshDiv.style.gap = '10px'
                      refreshDiv.style.alignItems = 'center'
                      footer.insertBefore(refreshDiv, footer.lastChild)
                    }
                  } else {
                    self.onClickBackPopupMini()
                  }
                }, 50)
              }
            } else { // bus stop marker from loading Longdo bus route object
              setTimeout(() => {
                let samePlace = true
                if ('poiId' in self.$route.params) {
                  samePlace = self.$route.params.poiId !== e.data.id
                }

                self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null

                if (samePlace) {
                  self.$router.push({
                    name: 'Place',
                    params: {
                      poiId: e.data.id
                    },
                    query: {}
                  })
                } else {
                  self.onClickBackPopupMini()
                }
              }, 50)
            }
          }
        }

        if (e instanceof window.longdo.Polygon) {
          if ('rainfallAccumulation2563Data' in e) {
            // prevent tag below overlay popup when click overlay
            self.timestamp = Date.now()
            self.clearFocusMarker()
            e.popup().title().querySelector('button').addEventListener('click', () => {
              const sameRoute = self.$route.name === 'Main' && JSON.stringify(self.$route.params) === JSON.stringify({}) && JSON.stringify(self.$route.query) === JSON.stringify({})
              if (!sameRoute) {
                self.$router.replace({
                  name: 'Main',
                  params: {},
                  query: {}
                })
              }
              self.isShowingPopup = false // slide down map control
              e.pop(false) // close popup of rainfall
            })

            setTimeout(() => {
              self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
              let sameRoute = false
              if (self.isShowingPopup) {
                sameRoute = self.$route.name === 'Rainfall' && JSON.stringify(self.$route.params) === JSON.stringify({}) && JSON.stringify(self.$route.query) === JSON.stringify({})
                if (!sameRoute) {
                  self.$router.replace({
                    name: 'Rainfall',
                    params: {},
                    query: {}
                  })
                }
              } else {
                sameRoute = self.$route.name === 'Main' && JSON.stringify(self.$route.params) === JSON.stringify({}) && JSON.stringify(self.$route.query) === JSON.stringify({})
                if (!sameRoute) {
                  self.$router.replace({
                    name: 'Main',
                    params: {},
                    query: {}
                  })
                }
              }
            }, 50)
          } else {
            self.$emit('clickPolygon')
            setTimeout(() => {
              self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
            }, 50)
          }
        }

        if (e instanceof window.longdo.Polyline) {
          self.$emit('clickPolygon')
          setTimeout(() => {
            self.isShowingPopup = window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null
          }, 50)
        }
      })
      if (self.isLongdoMapV3) {
        self.map.Event.bind(window.longdo3.EventName.BeforeTagPopup, (e) => {
          if (Date.now() - self.timestamp < 500 || self.isShowingContextmenu) {
            // clicking stack icon and text will trigger 2 events, we use only the first one
            return false
          }
          self.timestamp = Date.now()
          setTimeout(() => {
            self.$emit('clickMarker')
            self.isClickOverlayMarker = true
            let samePlace = false
            if ('poiId' in self.$route.params) {
              samePlace = self.$route.params.poiId === e.id
            }
            if (!samePlace) {
              if (self.$route.name === 'Place') {
                self.$router.replace({
                  name: 'Place',
                  params: {
                    poiId: e.id
                  },
                  query: {}
                })
              } else {
                self.$router.push({
                  name: 'Place',
                  params: {
                    poiId: e.id
                  },
                  query: {}
                })
              }
            }
          }, 10)
          return false
        })
      }

      self.map.Event.bind('overlayLoad', self.onOverlayLoad)
      self.map.Event.bind('popupLoadDetail', self.onPopupLoadDetail)
      self.map.Event.bind('popupClose', self.onPopupClose)

      if (self.isLongdoMapV3) {
        window.longdo3.Util.poiPopupOptions = (source, id, name, language) => {
          return {
            loadDetail (e) {
              self.$router.push({
                name: 'Place',
                params: {
                  poiId: id
                },
                query: {}
              })
            },
            html: '<div style="display:none"></div>'
          }
        }
      }

      if (self.$route.name === 'Place') {
        self.$nextTick(() => {
          const isNormalPlace = () => {
            return (self.$route.params.poiId[0] === 'A' ||
          self.$route.params.poiId.indexOf('H0') === 0 ||
          self.$route.params.poiId.indexOf('OSM:P') === 0 ||
          self.$route.params.poiId.indexOf('OSM:L') === 0 ||
          self.$route.params.poiId.indexOf('OSM:G') === 0 ||
          self.$route.params.poiId.indexOf('OVM:P') === 0)
          }
          if (isNormalPlace()) {
            self.drawPoi(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'K') {
            self.drawKhet(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'L') {
            self.drawRoad(self.$route.params.poiId)
          } else if (self.$route.params.poiId.slice(0, 3) === 'GCP') {
            self.drawGeocoding()
          } else if (self.$route.params.poiId.slice(0, 4) === 'RGCP') {
            self.drawReverseGeocoding()
          } else if (self.$route.params.poiId[0] === 'G') {
            self.drawGeom(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'Y') {
            self.drawLayer(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'X') {
            self.drawWaterLine(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'W') {
            self.drawWaterArea(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'S') {
            self.drawBusRoute(self.$route.params.poiId)
          } else if (self.$route.params.poiId[0] === 'B') {
            self.drawB(self.$route.params.poiId)
          }
        })
      }

      if (self.$route.name === 'Main' && self.$route.hash === '#my-places') {
        let userSession = await self.utility.getUserSession()
        userSession = userSession || self.user
        self.isFromClickMyPlace = false
        if (!userSession) {
          self.isFromClickMyPlace = true
          if (self.isOnMobileApp) {
            let loginResult
            try {
              loginResult = await self.lji.loginLongdo()
              // {<{username: String, longdoToken: String}>} result
            } catch (error) {
              // {<{code: Number, message: String}>} error
              console.log(error)
            }
            if (loginResult?.username && loginResult?.longdoToken) {
              try {
                await self.lji.addStorage({
                  key: 'username',
                  value: loginResult.username
                })
                // {Boolean} result
              } catch (error) {
                // {<{code: Number, message: String}>} error
                console.log(error)
              }
              try {
                await self.lji.addStorage({
                  key: 'ldtoken',
                  value: loginResult.longdoToken
                })
                // {Boolean} result
              } catch (error) {
                // {<{code: Number, message: String}>} error
                console.log(error)
              }
            }
            const user = await self.utility.getUserSession()
            if (user) {
              self.$root.$emit('loginComplete', user)
            } else {
              console.log('error getUserSession')
            }
          } else {
            self.$root.$emit('showLongdoLoginForm')
          }
        } else {
          self.$emit('showMyPlace')
        }
      }

      let user = await self.utility.getUserSession()
      user = user || self.user
      if (user) {
        self.user = user
        self.$root.$emit('loginComplete', self.user)
      }

      if (self.$route.name === 'Event') {
        const eid = Number(self.$route.params.eventId.replace('A', ''))
        self.drawEvent(eid)
      }

      if (self.$route.name === 'Cam') {
        const camId = self.$route.params.camId
        setTimeout(() => {
          const camMarker = window.longdo.Overlays.cameras.overlays._markers.filter(o => 'data' in o).filter(o => ((o.data.camid || '') === camId))
          if (camMarker.length > 0) {
            self.map.location(camMarker[0].location(), false)
            self.map.zoom(19, false)
          }
        }, 2000)
      }
    },
    async drawEvent (eid) {
      const self = this
      const result = await self.api.getEventList({
        eid: eid
      })
      const eventList = result.status === 200 ? result.data.item : []
      if (eventList.length > 0) {
        eventList.forEach(eventObject => {
          eventObject.eventId = 'A' + eventObject.eid.padStart(8, '0')
          eventObject.icon1x = `${self.baseUrl}img/event/event_${eventObject.severity}@2x.png`
          eventObject.icon2x = `${self.baseUrl}img/event/event_${eventObject.severity}@3x.png`
          const sameDay = eventObject.start_time.substr(0, 10) === eventObject.stop_time.substr(0, 10)
          eventObject.start_time = new Date(eventObject.start_time).toLocaleString('th-TH', {
            year: '2-digit',
            month: 'narrow',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
          })
          eventObject.stop_time = new Date(eventObject.stop_time).toLocaleString('th-TH', sameDay ? {
            hour: 'numeric',
            minute: '2-digit'
          } : {
            year: '2-digit',
            month: 'narrow',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
          })
        })
        self.onLoadedEventList(eventList, true)
        self.onClickEventInfo(eventList[0])
      } else {
        self.$router.replace({
          name: 'Main',
          query: {},
          hash: '',
          params: {}
        })
      }
    },
    async updateCurrentLocation () {
      const self = this
      if (self.isShowingContextmenu) {
        setTimeout(() => {
          self.updateCurrentLocation()
        }, 1e3)
        return false
      }
      // self.map.Ui.Geolocation.updateCurrentLocation() โดน auto zoom

      const offset = {
        x: 28,
        y: 42
      }

      const now = new Date().getTime()
      let allowUpdate = false
      if (self.lastUpdateCurrentLocation === null) {
        allowUpdate = true
      } else {
        allowUpdate = 600e3
      }

      const afterGetGeolocation = async (e) => {
        self.lastUpdateCurrentLocation = null
        if (!self.isOnMobileApp) {
          window.currentLocation = {
            lat: e.coords.latitude,
            lon: e.coords.longitude
          }
        }
        if (((self.islongdoMap3Ready && self.isLongdoMapV3) ||
          (self.islongdoMapReady && !self.isLongdoMapV3)) &&
          self.isOnMobileApp &&
          !self.isGeolocationMobileAppDisabled &&
          self.isRealLocation) {
          if (!self.isFirstCheckNearby) {
            self.checkNearby(window.currentLocation, true)
          }

          if (self.currentLocationBuffer) {
            self.map.Overlays.remove(self.currentLocationBuffer)
          }
          const d = window.longdo.Util.latitudeLength(window.currentLocation.lat)
          const degree = e.coords.accuracy * 1.0 / d
          self.currentLocationBuffer = new window.longdo.Circle(window.currentLocation, degree, {
            lineWidth: 1,
            lineColor: 'rgba(56, 148, 238, 0.4)',
            fillColor: 'rgba(56, 148, 238, 0.2)'
          })
          self.map.Overlays.add(self.currentLocationBuffer)
          if (self.currentLocationMarker) {
            self.currentLocationMarker.move(window.currentLocation, true)
            window.longdo.Util.removeClass(self.currentLocationMarker.element(), 'offline')
          } else {
            self.currentLocationMarker = new window.longdo.Marker(window.currentLocation, {
              icon: {
                html: `
                  <div style="position: relative;" class="current-location-marker">
                    <div style="background-color: ${process.env.VUE_APP_PRIMARY_COLOR}; border-radius: 50%; 
                    height: 15px; width: 15px; border: 2px solid #fff; 
                    position:absolute; top:50%; left:50%; 
                    transform: translate(-50%,-50%);"></div>
                    <div class="direction" style="position:absolute; bottom:0;">
                      <i class="material-icons-round">navigation</i>
                    </div>
                  </div>
                `
              }
            })
            self.map.Overlays.add(self.currentLocationMarker)
          }

          // if (e.coords.heading) {
          //   self.currentLocationMarker.update({
          //     rotate: e.coords.heading
          //   })
          // }
          self.rotateDirectionMarker()

          let checkDistance = 10000
          if (self.coorCheckSpeedCameraAndBlackSpot) {
            checkDistance = window.longdo.Util.distance([
              self.coorCheckSpeedCameraAndBlackSpot,
              window.currentLocation
            ])
          }
          if ((self.isCheckingSpeedCamera || self.isCheckingAccidentBlackSpot) && checkDistance >= 9000 && !self.isLoadingNearByCam) {
            self.isLoadingNearByCam = true
            self.coorCheckSpeedCameraAndBlackSpot = window.currentLocation
            const params = {
              keyword: 'tag: accident_black_spot',
              span: '10km',
              lat: e.coords.latitude,
              lon: e.coords.longitude
            }
            let result = await self.api.searchAccidentAndSpeedCam(params, false)
            if (result.status === 200 && result.data.data) {
              self.blackSpotList = result.data.data
            } else {
              self.blackSpotList = []
            }

            params.keyword = 'tag: speed_camera'
            result = await self.api.searchAccidentAndSpeedCam(params, false)
            if (result.status === 200 && result.data.data) {
              self.speedCameraList = result.data.data
            } else {
              self.speedCameraList = []
            }
            self.checkNearBySpeedCamAndBlackSpot()
            if (self.reCheckSpeedCameraAndBlackSpotInterval) {
              clearInterval(self.reCheckSpeedCameraAndBlackSpotInterval)
            }
            const reCheckInterval = 5000
            self.reCheckSpeedCameraAndBlackSpotInterval = setInterval(() => { self.checkNearBySpeedCamAndBlackSpot() }, reCheckInterval)
            self.isLoadingNearByCam = false
          }
        }

        if (self.isTracking && self.isLongdoMapV3) {
          if (self.isOnMobileApp) {
            self.track()
          } else {
            self.map.Ui.Geolocation.trigger()
          }
        } else if (self.isTracking) {
          self.$root.$emit('rotateCompass')
          self.track()
        } else {
          // self.map.location(window.currentLocation)
        }

        setTimeout(() => {
          self.updateCurrentLocation()
        }, self.utility.isFacebookApp() ? 300e3 : 1e3)
      }

      if (self.lji.Util.isAndroidNativeApp()) {
        afterGetGeolocation({
          coords: {
            latitude: window.currentLocation.lat,
            longitude: window.currentLocation.lon,
            accuracy: window.accuracy,
            heading: window.heading
          }
        })
      } else if (self.lji.Util.isIosNativeApp()) {
        afterGetGeolocation({
          coords: {
            latitude: window.currentLocation.lat,
            longitude: window.currentLocation.lon,
            accuracy: window.accuracy,
            heading: window.heading
          }
        })
        // try {
        //   const geolocationNativeResult = await self.lji.getGeolocation()
        //   afterGetGeolocation({
        //     coords: {
        //       latitude: geolocationNativeResult.latitude,
        //       longitude: geolocationNativeResult.longitude,
        //       accuracy: 5,
        //       heading: geolocationNativeResult.heading
        //     }
        //   })
        // } catch (e1) {
        //   console.log(e1)
        // }
      } else {
        // Try HTML5 geolocation.
        if (window.navigator.geolocation && allowUpdate) {
          window.navigator.geolocation.getCurrentPosition(
            afterGetGeolocation,
            (error) => {
              if (error.code === 3) {
                self.lastUpdateCurrentLocation = null
              } else {
                self.lastUpdateCurrentLocation = now
              }
              if (!window.currentLocation) {
                window.currentLocation = self.map.location()
              }

              if (!self.isLongdoMapV3) {
                if (self.currentLocationBuffer) {
                  self.map.Overlays.remove(self.currentLocationBuffer)
                }

                const d = window.longdo.Util.latitudeLength(window.currentLocation.lat)
                const degree = 1000.0 / d
                self.currentLocationBuffer = new window.longdo.Circle(window.currentLocation, degree, {
                  lineWidth: 1,
                  lineColor: 'rgba(75, 75, 75, 0.4)',
                  fillColor: 'rgba(75, 75, 75, 0.2)'
                })
                self.map.Overlays.add(self.currentLocationBuffer)

                if (self.currentLocationMarker) {
                  self.currentLocationMarker.move(window.currentLocation, true)
                } else {
                  // self.currentLocationMarker = new window.longdo3.Marker(window.currentLocation)
                  // self.map.Overlays.add(self.currentLocationMarker)
                  self.currentLocationMarker = new window.longdo.Marker(window.currentLocation, {
                    icon: {
                      html: `
                      <div class="current-location-marker">
                        <div class="pin"></div>
                      </div>
                    `,
                      offset
                    }
                  })
                  self.map.Overlays.add(self.currentLocationMarker)
                }
                window.longdo.Util.addClass(self.currentLocationMarker.element(), 'offline')
              }
              setTimeout(() => {
                self.updateCurrentLocation()
              }, self.utility.isFacebookApp() ? 300e3 : 10e3)
            },
            {
              maximumAge: 5e3,
              timeout: 15e3,
              enableHighAccuracy: true
            }
          )
        } else {
          // Browser doesn't support Geolocation
          self.lastUpdateCurrentLocation = now
          if (!window.currentLocation) {
            window.currentLocation = self.map.location()
          }

          if (self.currentLocationBuffer) {
            self.map.Overlays.remove(self.currentLocationBuffer)
          }

          const d = window.longdo.Util.latitudeLength(window.currentLocation.lat)
          const degree = 1000.0 / d
          self.currentLocationBuffer = new window.longdo.Circle(window.currentLocation, degree, {
            lineWidth: 1,
            lineColor: 'rgba(75, 75, 75, 0.4)',
            fillColor: 'rgba(75, 75, 75, 0.2)'
          })
          self.map.Overlays.add(self.currentLocationBuffer)

          if (self.currentLocationMarker) {
            self.currentLocationMarker.move(window.currentLocation, true)
          } else {
            self.currentLocationMarker = new window.longdo.Marker(window.currentLocation, {
              icon: {
                html: `
                  <div class="current-location-marker">
                    <div class="pin"></div>
                  </div>
                `,
                offset
              }
            })
            self.map.Overlays.add(self.currentLocationMarker)
          }
          window.longdo.Util.addClass(self.currentLocationMarker.element(), 'offline')
        }
      }
    },
    async checkNearBySpeedCamAndBlackSpot () {
      // 1. not in any point and enter new point --> !isInWarningArea and warningPoint !== null
      // 2. in any point and enter new point that is closer --> isInWarningArea and warningPoint !== null and oldPoint !== newPoint
      // 3. in any point and still stay in that point --> isInWarningArea and waringPoint !== null and oldPoint === newPoint
      // 4. in any point and in next state doesnt near any point -->  isInWarningArea and warningPoint === null
      // 5. not in any point and continue to not in any point --> !isInWarningArea and warningPoint === null
      // flags -> isInWarningArea = in any point, warningPoint !== null = enter new point,
      // to do --> at the end of flow update new isInWarningArea to be old state in next state
      // to do --> save warningPoint as oldWarningPoint, before start checking reset warningPoint to null and set to new warningPoint that we found (if not it is still null)
      const self = this
      if (self.speedCameraList.length !== 0 || self.blackSpotList.length !== 0) {
        let minDis = 1000
        let oldWarningPointId
        if (self.warningPoint) {
          oldWarningPointId = self.warningPoint.id
        }
        self.warningPoint = null
        if (self.isCheckingSpeedCamera) {
          self.speedCameraList.forEach((poi) => {
            const distance = window.longdo.Util.distance([window.currentLocation, { lat: poi.lat, lon: poi.lon }])

            if (distance < minDis) {
              minDis = distance
              self.warningPoint = poi
            }
          })
        }

        if (self.isCheckingAccidentBlackSpot) {
          self.blackSpotList.forEach((poi) => {
            const distance = window.longdo.Util.distance([window.currentLocation, { lat: poi.lat, lon: poi.lon }])

            if (distance < minDis) {
              minDis = distance
              self.warningPoint = poi
            }
          })
        }

        if (self.warningPoint) {
          self.$set(self.warningPoint, 'currentDistance', Math.round(minDis))
        }
        const isSameWarningPoint = self.warningPoint?.id === oldWarningPointId
        if (!self.isInWarningArea && self.warningPoint) {
          // 1. not in any point and enter new point
          // show popup vibrate sound noti
          if (self.warningMarker) {
            self.map.Overlays.remove(self.warningMarker)
          }
          const loc = { lat: self.warningPoint.lat, lon: self.warningPoint.lon }
          self.warningMarker = new window.longdo.Marker(loc)
          self.map.Overlays.add(self.warningMarker)
          self.$emit('openMobileWarningPopup')
          try {
            await self.lji.vibrate({
              type: 'default'
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
          try {
            await self.lji.sound({
              soundId: 'alarm'
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }

          self.isInWarningArea = true
        } else if (self.isInWarningArea && self.warningPoint && !isSameWarningPoint) {
          // 2. in any point and enter new point that is closer
          // show popup vibrate sound noti
          if (self.warningMarker) {
            self.map.Overlays.remove(self.warningMarker)
          }
          const loc = { lat: self.warningPoint.lat, lon: self.warningPoint.lon }
          self.warningMarker = new window.longdo.Marker(loc)
          self.map.Overlays.add(self.warningMarker)

          self.$emit('openMobileWarningPopup')
          try {
            await self.lji.vibrate({
              type: 'default'
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
          try {
            await self.lji.sound({
              soundId: 'alarm'
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }

          self.isInWarningArea = true
        } else if (self.isInWarningArea && self.warningPoint && isSameWarningPoint) {
          // 3. in any point and still stay in that point
          // update distance in pop up
          // already update at above
          self.isInWarningArea = true
        } else if (self.isInWarningArea && !self.warningPoint) {
          // 4. in any point and in next state doesnt near any point
          // close popup
          self.$emit('closeMobileWarningPopup')
          self.isInWarningArea = false
        } else {
          // 5. not in any point and continue to not in any point
          self.isInWarningArea = false
        }
      }
    },
    onSetShowSpeedCamera (value) {
      const self = this
      if (value) {
        // add tag
        const showSpeedCameraLevel = localStorage.showSpeedCameraLevel
          ? parseInt(localStorage.showSpeedCameraLevel) : 9
        setTimeout(() => {
          self.map.Tags.add('speed_camera', {
            label: true,
            visibleRange: {
              min: showSpeedCameraLevel
            }
          })
        }, 1500)
        self.isCheckingSpeedCamera = true
      } else {
        // clear interval
        if (!self.isCheckingAccidentBlackSpot && self.reCheckSpeedCameraAndBlackSpotInterval) {
          clearInterval(self.reCheckSpeedCameraAndBlackSpotInterval)
          self.reCheckSpeedCameraAndBlackSpotInterval = null
          self.coorCheckSpeedCameraAndBlackSpot = null
        }
        // remove tag
        setTimeout(() => {
          self.map.Tags.remove('speed_camera', {
            label: true
          })
        }, 1500)
        self.isCheckingSpeedCamera = false
        if (self.warningPoint?.tag.includes('speed_camera')) {
          self.$emit('closeMobileWarningPopup')
        }
      }
    },
    onSetShowAccidentBlackSpot (value) {
      const self = this
      if (value) {
        // add tag
        const showAccidentBlackSpotLevel = localStorage.showSpeedCameraLevel
          ? parseInt(localStorage.showSpeedCameraLevel) : 9
        setTimeout(() => {
          self.map.Tags.add('accident_black_spot', {
            label: true,
            visibleRange: {
              min: showAccidentBlackSpotLevel
            }
          })
        }, 1500)
        self.isCheckingAccidentBlackSpot = true
      } else {
        // clear interval when both speed and accident is null
        if (!self.isCheckingSpeedCamera && self.reCheckSpeedCameraAndBlackSpotInterval) {
          clearInterval(self.reCheckSpeedCameraAndBlackSpotInterval)
          self.reCheckSpeedCameraAndBlackSpotInterval = null
          self.coorCheckSpeedCameraAndBlackSpot = null
        }
        // remove tag
        setTimeout(() => {
          self.map.Tags.remove('accident_black_spot', {
            label: true
          })
        }, 1500)
        self.isCheckingAccidentBlackSpot = false
        if (self.warningPoint?.tag.includes('accident_black_spot')) {
          self.$emit('closeMobileWarningPopup')
        }
      }
    },
    async drawPoi (poiId) {
      const self = this
      self.clearFocusMarker()
      self.searchResultOverlayList.forEach(searchResultOverlay => {
        if (searchResultOverlay instanceof window.longdo.Marker) {
          if (searchResultOverlay.searchType === 'POIID') {
            self.map.Overlays.remove(searchResultOverlay)
          }
        }
      })
      self.searchResultOverlayList = self.searchResultOverlayList.filter(searchResultOverlay => searchResultOverlay.searchType !== 'POIID')
      let poiMarker
      const index = self.searchResultOverlayList.map(resultOverlay => {
        if ('searchObjectData' in resultOverlay) {
          return resultOverlay.searchObjectData.id
        }
        if ('exploreObjectData' in resultOverlay) {
          return resultOverlay.exploreObjectData.id
        }
      }).indexOf(poiId)
      if (index !== -1) {
        poiMarker = self.searchResultOverlayList[index]
      } else {
        if (poiId.indexOf('OSM:P') === 0 || poiId.indexOf('OSM:L') === 0 || poiId.indexOf('OSM:G') === 0) {
          const params = {
            id: poiId
          }
          const result = await self.api.getPlaceInfo(params)
          const data = result.data.data
          if (data) {
            try {
              poiMarker = new window.longdo.Marker({
                lat: data.location.latitude,
                lon: data.location.longitude
              }, {
                icon: self.iconOption
              })
            } catch (error) {
              console.warn('Can\'not create marker')
            }
          }

          poiMarker.searchObjectData = {
            id: data.id,
            name: data.name_th,
            lat: data.location.latitude,
            long: data.location.longitude,
            type: data[`place_type_${self.$i18n.locale}`],
            status: null,
            name_en: data.name_en
          }
          data.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
          data.id = poiId
          data.objecttype = 'poi'
        } else if (poiId.indexOf('OVM:P') === 0) {
          const params = {
            id: poiId,
            locale: self.$i18n.locale
          }
          try {
            const result = await self.api.getPoiOtherSourceDetail(params)
            const { data } = result.data
            const placeData = data ? data[0] : false
            if (placeData) {
              if (placeData) {
                poiMarker = new window.longdo.Marker({
                  lat: placeData.lat,
                  lon: placeData.lon
                }, {
                  icon: self.iconOption
                })
                poiMarker.searchObjectData = {
                  id: placeData.id,
                  name: placeData.name,
                  lat: placeData.lat,
                  long: placeData.lon,
                  type: placeData.type,
                  status: null,
                  name_en: placeData.name
                }
                data.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
                data.id = poiId
                data.objecttype = 'poi'

                self.$emit('saveSearchHistory', {
                  id: poiId,
                  name: placeData.name,
                  name_en: placeData.name_en || placeData.name,
                  type: 'poi'
                })
              }
            }
          } catch (error) {
            console.warn('Error get data')
            return
          }
        } else if (poiId.indexOf('H0') === 0) {
          const params = {
            id: poiId,
            locale: self.$i18n.locale
          }
          try {
            const result = await self.api.getPoiOtherSourceDetail(params)
            const { data } = result.data
            const placeData = data[0] || false
            if (placeData) {
              poiMarker = new window.longdo.Marker({
                lat: placeData.lat,
                lon: placeData.lon
              }, {
                icon: self.iconOption
              })
              poiMarker.searchObjectData = {
                ...placeData,
                // to make routing name not empty
                name_en: placeData.name
              }
              data.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
              data.id = poiId
              data.objecttype = 'poi'
            }
          } catch (error) {
            console.warn('Error get data')
            return
          }
        } else {
          // Normal POI
          const now = new Date()
          const timestamp = now.getTime()
          const params = {
            id: encodeURIComponent(poiId.substring(1)),
            timestamp: encodeURIComponent(timestamp)
          }
          const result = await self.api.getPoiData(params)
          if (!result?.data?.lat && !result?.data?.lon) {
            return
          }
          poiMarker = new window.longdo.Marker({
            lat: result.data.lat,
            lon: result.data.long
          }, {
            title: result.data.name,
            detail: '',

            icon: self.iconOption
          })
          poiMarker.popup = () => { return false }
          poiMarker.searchObjectData = result.data
          result.data.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
          result.data.id = poiId
          result.data.objecttype = 'poi'

          self.$emit('saveSearchHistory', {
            id: poiId,
            name: result.data.name,
            name_en: result.data.name_en || result.data.name,
            type: 'poi'
          })

          self.$root.$emit('updateSearchHistory')
        }
        poiMarker.searchType = 'POIID'
        self.searchResultOverlayList.push(poiMarker)
        self.map.Overlays.add(poiMarker)
        const overlayList = self.map.Overlays.list() || []
        overlayList.forEach(o => {
          if ('type' in o) {
            if (o.type === 'p') {
              self.map.Overlays.remove(o)
            }
          }
        })
      }
      const location = poiMarker.location()
      const bound = self.map.bound()
      const diffLat = bound.maxLat - bound.minLat
      const diffLon = bound.maxLon - bound.minLon
      const locationList = [{
        lat: bound.maxLat - (diffLat * 0.10),
        lon: bound.minLon + (diffLon * 0.05)
      }, {
        lat: bound.maxLat - (diffLat * 0.10),
        lon: bound.maxLon - (diffLon * 0.05)
      }, {
        lat: bound.minLat + (diffLat * 0.40),
        lon: bound.maxLon - (diffLon * 0.05)
      }, {
        lat: bound.minLat + (diffLat * 0.40),
        lon: bound.minLon + (diffLon * 0.05)
      }, {
        lat: bound.maxLat - (diffLat * 0.10),
        lon: bound.minLon + (diffLon * 0.05)
      }]
      const isContain = window.longdo.Util.contains(location, locationList)
      if (!self.isClickOverlayMarker || !isContain) {
        self.map.location(poiMarker.location(), false)
        self.map.zoom(18)
        self.isClickOverlayMarker = false
      }
      poiMarker.pop(true)
      poiMarker.element().classList.add('focus')
      self.isShowingPopup = true
      self.isClickFromMyPlaceMarker = false
    },
    drawGeocoding () {
      const self = this
      self.clearFocusMarker()
      if (self.geocodingResult) {
        const geocodingMarker = new window.longdo.Marker({
          lat: self.geocodingResult.lat,
          lon: self.geocodingResult.long
        }, { icon: self.iconOption })

        // let geocode marker use same type as poi since we show place card
        self.map.Overlays.add(geocodingMarker)
        geocodingMarker.element().classList.add('focus')
        geocodingMarker.searchType = 'POIID'
        geocodingMarker.searchObjectData = self.geocodingResult
        self.searchResultOverlayList.push(geocodingMarker)
      }
    },
    drawReverseGeocoding () {
      const self = this
      self.clearFocusMarker()
      self.searchResultOverlayList.forEach(searchResultOverlay => {
        if (searchResultOverlay instanceof window.longdo.Marker) {
          if (searchResultOverlay.searchType === 'POIID') {
            self.map.Overlays.remove(searchResultOverlay)
          }
        }
      })
      if ('lat' in self.$route.query && 'lon' in self.$route.query) {
        const reverseGeocodingMarker = new window.longdo.Marker({
          lat: self.$route.query.lat,
          lon: self.$route.query.lon
        }, { icon: self.iconOption })

        // let geocode marker use same type as poi since we show place card
        self.map.Overlays.add(reverseGeocodingMarker)
        reverseGeocodingMarker.element().classList.add('focus')
        reverseGeocodingMarker.searchType = 'POIID'
        reverseGeocodingMarker.searchObjectData = {
          id: 'RGCP0001',
          lat: self.$route.query.lat,
          lon: self.$route.query.lon,
          icon: 'reddot.png',
          name: `${self.$route.query.lat}, ${self.$route.query.lon}`,
          name_en: `${self.$route.query.lat}, ${self.$route.query.lon}`,
          type: 'reverseGeocoding',
          shortdesc: '',
          shortdesc_en: '',
          source: 'longdo'
        }
        self.searchResultOverlayList.push(reverseGeocodingMarker)
        self.map.location(reverseGeocodingMarker.location())
      }
    },
    findMarkerPoiIndex (poiId) {
      const self = this
      return self.searchResultOverlayList.map(searchResultOverlay => {
        return searchResultOverlay instanceof window.longdo.Marker && searchResultOverlay.searchObjectData ? searchResultOverlay.searchObjectData.id : null
      }).indexOf(poiId)
    },
    findGeomPoiIndex (poiId) {
      const self = this
      return self.searchResultOverlayList.map(searchResultOverlay => {
        return searchResultOverlay instanceof window.longdo.Overlays.Object && searchResultOverlay.searchObjectData ? searchResultOverlay.searchObjectData.id : null
      }).indexOf(poiId)
    },
    drawPoiMarker (marker, poiId, searchType, searchObjectData, objectType) {
      const self = this
      marker.searchType = searchType
      marker.searchObjectData = searchObjectData
      self.searchResultOverlayList.push(marker)
      searchObjectData.searchResultMarkerIndex = self.searchResultOverlayList.length - 1
      searchObjectData.id = poiId
      searchObjectData.objecttype = objectType
      self.map.Overlays.add(marker)
      marker.element().classList.add('focus')
    },
    drawPoiGeom (marker, geomObj, searchType) {
      const self = this
      geomObj.searchType = searchType
      geomObj.searchObjectData = marker.searchObjectData
      self.searchResultOverlayList.push(geomObj)
      geomObj.popup = false
      marker.searchObjectData.searchResultGeomIndex = self.searchResultOverlayList.length - 1
      self.fromSearch = true
      self.map.Overlays.load(geomObj)
    },
    async drawKhet (khetId) {
      const self = this
      self.clearFocusMarker()
      let khetMarker
      let placeCardDetail
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(khetId)
      if (markerIndex !== -1) {
        khetMarker = self.searchResultOverlayList[markerIndex]
        self.$emit('setPlaceCardDetail', khetMarker.searchObjectData.placeCardDetail)
      } else {
        const paramsTH = {
          geocode: encodeURIComponent(Number(khetId.substring(1))),
          locale: 'th'
        }
        const resultTH = await self.api.getAddressFromGeocode(paramsTH)
        const paramsEN = {
          geocode: encodeURIComponent(Number(khetId.substring(1))),
          locale: 'en'
        }
        const resultEN = await self.api.getAddressFromGeocode(paramsEN)
        if (!resultTH.data || !resultEN.data) {
          self.$emit('setPlaceCardDetail', null)

          return
        }
        khetMarker = new window.longdo.Marker({
          lat: resultTH.data.lat,
          lon: resultTH.data.lon
        }, {
          icon: self.iconOption
        })
        placeCardDetail = {
          name_th: resultTH?.data.subdistrict || resultTH?.data.district || resultTH?.data.province,
          name_en: resultEN?.data.subdistrict || resultEN?.data.district || resultEN?.data.province,
          address_th: `${resultTH?.data.subdistrict || ''} ${resultTH?.data.district || ''} ${resultTH?.data.province || ''}`,
          address_en: `${resultEN?.data.subdistrict || ''} ${resultEN?.data.district || ''} ${resultEN?.data.province || ''}`,
          iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
          location: {
            latitude: resultTH.data.lat,
            longitude: resultTH.data.lon
          },
          datasource: {
            logo: 'https://www.numap.co.th/home/images/nu-smartinfo.png',
            source: 'Numap',
            url: 'https://www.numap.co.th/'
          }
        }
        resultTH.data = { ...resultTH.data, placeCardDetail: placeCardDetail }
        self.drawPoiMarker(khetMarker, khetId, 'KHETID', resultTH.data, 'khet')
      }
      self.map.location(khetMarker.location())

      const geomIndex = self.findGeomPoiIndex(khetId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const khetObject = new window.longdo.Overlays.Object(Number(khetId.substring(1)), 'IG')
        self.drawPoiGeom(khetMarker, khetObject, 'KHETID')
      }

      self.$emit('setPlaceCardDetail', placeCardDetail)
    },
    async drawRoad (roadId) {
      const self = this
      self.clearFocusMarker()
      let roadMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(roadId)
      if (markerIndex !== -1) {
        roadMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          id: encodeURIComponent(roadId),
          locale: self.$i18n.locale
        }
        const result = await self.api.getRoadData(params)
        let data = null
        if (result && result.data.length > 0) {
          data = result.data[0].linearray[0]
          roadMarker = new window.longdo.Marker({
            lat: data.centerlat,
            lon: data.centerlong
          }, {
            icon: self.iconOption
          })
          self.$emit('setPlaceCardDetail', {
            ...data,
            name_en: data.name_e,
            name_th: data.name_t,
            iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
            location: {
              latitude: data.centerlat,
              longitude: data.centerlong
            },
            datasource: {
              logo: 'https://www.numap.co.th/home/images/nu-smartinfo.png',
              source: 'Numap',
              url: 'https://www.numap.co.th/'
            }
          })
          self.drawPoiMarker(roadMarker, roadId, 'ROADID', data, 'road')
        } else {
          self.$emit('setPlaceCardDetail', null)

          return
        }
      }

      self.map.location(roadMarker.location())

      const geomIndex = self.findGeomPoiIndex(roadId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const roadObject = new window.longdo.Overlays.Object(roadId, 'RID')
        self.drawPoiGeom(roadMarker, roadObject, 'ROADID')
      }
    },
    async drawGeom (geomId) {
      const self = this
      self.clearFocusMarker()
      let geomMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(geomId)
      if (markerIndex !== -1) {
        geomMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          mode: 'geojson',
          id: encodeURIComponent(geomId),
          locale: self.$i18n.locale
        }

        const { data } = await self.api.getObjectData(params, 'GID')
        if ('features' in data) {
          const properties = data.features.map((feature) => {
            return feature.properties
          }, [])[0]
          if (properties) {
            const _location = {
              lat: properties.centerlat,
              lon: properties.centerlong
            }
            self.map.location(_location)
            geomMarker = new window.longdo.Marker(_location, {
              icon: self.iconOption
            })
            self.$emit('setPlaceCardDetail', {
              ...properties,
              name_th: properties.name_t,
              name_en: properties.name_t, // name_e of this response is unreadable
              iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
              location: {
                latitude: Number(properties.centerlat),
                longitude: Number(properties.centerlong)
              },
              datasource: {
                source: 'Longdo Map User',
                url: '',
                logo: 'https://www.longdo.com/profileimage/uid/0'
              }
            })
            self.drawPoiMarker(geomMarker, geomId, 'GEOMID', data, 'geom-search')
          }
        }
      }

      const geomIndex = self.findGeomPoiIndex(geomId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const geomObject = new window.longdo.Overlays.Object(geomId, 'GID')
        self.drawPoiGeom(geomMarker, geomObject, 'GEOMID')
      }
    },
    async drawLayer (layerId) {
      const self = this
      self.clearFocusMarker()
      let layerMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(layerId)
      if (markerIndex !== -1) {
        layerMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          mode: 'geojson',
          id: encodeURIComponent(layerId),
          locale: self.$i18n.locale
        }

        const { data } = await self.api.getObjectData(params, 'YID')
        if ('linearray' in data[0]) {
          const properties = data[0].linearray[0] || null
          if (properties) {
            const _location = {
              lat: properties.centerlat,
              lon: properties.centerlong
            }
            self.map.location(_location)
            layerMarker = new window.longdo.Marker(_location, {
              icon: self.iconOption
            })
            self.$emit('setPlaceCardDetail', {
              ...properties,
              name_th: properties.name_t,
              name_en: properties.name_e,
              iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
              location: {
                latitude: Number(properties.centerlat),
                longitude: Number(properties.centerlong)
              },
              datasource: {
                source: 'Longdo Map User',
                url: '',
                logo: 'https://www.longdo.com/profileimage/uid/0'
              }
            })
            self.drawPoiMarker(layerMarker, layerId, 'LAYERID', data, 'layer-search')
          } else {
            self.$emit('setPlaceCardDetail', null)

            return
          }
        } else {
          self.$emit('setPlaceCardDetail', null)

          return
        }
      }

      const layerIndex = self.findGeomPoiIndex(layerId)
      if (layerIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[layerIndex])
      } else {
        const layerObject = new window.longdo.Overlays.Object(layerId, 'YID')
        self.drawPoiGeom(layerMarker, layerObject, 'LAYERID')
      }
    },
    async drawWaterLine (waterLineId) {
      const self = this
      self.clearFocusMarker()
      let waterLineMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(waterLineId)
      if (markerIndex !== -1) {
        waterLineMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          mode: 'geojson',
          id: encodeURIComponent(waterLineId),
          locale: self.$i18n.locale
        }

        const { data } = await self.api.getObjectData(params, 'XID')

        if ('features' in data) {
          const properties = data.features.map((feature) => {
            return feature.properties
          }, [])[0]
          if (properties) {
            const _location = {
              lat: properties.centerlat,
              lon: properties.centerlong
            }
            self.map.location(_location)
            waterLineMarker = new window.longdo.Marker(_location, {
              icon: self.iconOption
            })
            self.$emit('setPlaceCardDetail', {
              ...properties,
              name_th: properties.name_t,
              name_en: properties.name_e,
              iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
              location: {
                latitude: Number(properties.centerlat),
                longitude: Number(properties.centerlong)
              },
              datasource: {
                logo: 'https://www.numap.co.th/home/images/nu-smartinfo.png',
                source: 'Numap',
                url: 'https://www.numap.co.th/'
              }
            })
            self.drawPoiMarker(waterLineMarker, waterLineId, 'WATERLINEID', data, 'water-line-search')
          } else {
            self.$emit('setPlaceCardDetail', null)

            return
          }
        } else {
          self.$emit('setPlaceCardDetail', null)

          return
        }
      }

      const geomIndex = self.findGeomPoiIndex(waterLineId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const waterLineObject = new window.longdo.Overlays.Object(waterLineId, 'XID')
        self.drawPoiGeom(waterLineMarker, waterLineObject, 'WATERLINEID')
      }
    },
    async drawWaterArea (waterAreaId) {
      const self = this
      self.clearFocusMarker()
      let waterAreaMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(waterAreaId)
      if (markerIndex !== -1) {
        waterAreaMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          mode: 'geojson',
          id: encodeURIComponent(waterAreaId),
          locale: self.$i18n.locale
        }

        const { data } = await self.api.getObjectData(params, 'WID')

        if ('features' in data) {
          const properties = data.features.map((feature) => {
            return feature.properties
          }, [])[0]
          if (properties) {
            const _location = {
              lat: properties.centerlat,
              lon: properties.centerlong
            }
            self.map.location(_location)
            waterAreaMarker = new window.longdo.Marker(_location, {
              icon: self.iconOption
            })
            self.$emit('setPlaceCardDetail', {
              ...properties,
              name_th: properties.name_t,
              name_en: properties.name_e,
              iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
              location: {
                latitude: Number(properties.centerlat),
                longitude: Number(properties.centerlong)
              },
              datasource: {
                logo: 'https://www.numap.co.th/home/images/nu-smartinfo.png',
                source: 'Numap',
                url: 'https://www.numap.co.th/'
              }
            })
            self.drawPoiMarker(waterAreaMarker, waterAreaId, 'WATERAREAID', data, 'water-area-search')
          } else {
            self.$emit('setPlaceCardDetail', null)

            return
          }
        } else {
          self.$emit('setPlaceCardDetail', null)

          return
        }
      }

      const geomIndex = self.findGeomPoiIndex(waterAreaId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const waterAreaObject = new window.longdo.Overlays.Object(Number(waterAreaId.substring(1)), 'WID')
        self.drawPoiGeom(waterAreaMarker, waterAreaObject, 'WATERAREAID')
      }
    },
    async drawBusRoute (busRouteId) {
      const self = this
      self.clearFocusMarker()
      let busRouteMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(busRouteId)
      if (markerIndex !== -1) {
        busRouteMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          mode: 'geojson',
          id: encodeURIComponent(busRouteId),
          locale: self.$i18n.locale
        }

        const { data } = await self.api.getObjectData(params, 'SID')
        if ('features' in data) {
          const properties = data.features.map((feature) => {
            return feature.properties
          }, [])[0]
          if (properties) {
            const _location = {
              lat: properties.centerlat,
              lon: properties.centerlong
            }
            self.map.location(_location)
            busRouteMarker = new window.longdo.Marker(_location, {
              icon: self.iconOption
            })
            self.$emit('setPlaceCardDetail', {
              ...properties,
              name_th: properties.name_t,
              name_en: properties.name_e,
              iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
              location: {
                latitude: Number(properties.centerlat),
                longitude: Number(properties.centerlong)
              },
              datasource: {
                source: 'Longdo Map User',
                url: '',
                logo: 'https://www.longdo.com/profileimage/uid/0'
              }
            })
            self.drawPoiMarker(busRouteMarker, busRouteId, 'BUSROUTEID', data, 'bus-route-search')
          } else {
            self.$emit('setPlaceCardDetail', null)

            return
          }
        } else {
          self.$emit('setPlaceCardDetail', null)

          return
        }
      }

      const geomIndex = self.findGeomPoiIndex(busRouteId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const busRouteObject = new window.longdo.Overlays.Object(Number(busRouteId.substring(1)), 'SID')
        self.drawPoiGeom(busRouteMarker, busRouteObject, 'BUSROUTEID')
      }
      self.map.Tags.add('__ldmap_bus:' + busRouteId)
    },
    async drawB (bId) {
      const self = this
      self.clearFocusMarker()
      let bMarker
      self.$emit('setPlaceCardIsLoading', true)
      const markerIndex = self.findMarkerPoiIndex(bId)
      if (markerIndex !== -1) {
        bMarker = self.searchResultOverlayList[markerIndex]
      } else {
        const params = {
          mode: 'geojson',
          id: encodeURIComponent(bId),
          locale: self.$i18n.locale
        }

        const { data } = await self.api.getObjectData(params, 'BID')

        if ('features' in data) {
          const properties = data.features.map((feature) => {
            return feature.properties
          }, [])[0]
          if (properties) {
            const _location = {
              lat: properties.centerlat,
              lon: properties.centerlong
            }
            let result = await self.api.getAddress({
              lat: properties.centerlat,
              lon: properties.centerlong,
              locale: 'th'
            })
            let addressTH = null
            if (result.status === 200) {
              addressTH = result.data
            }
            result = await self.api.getAddress({
              lat: properties.centerlat,
              lon: properties.centerlong,
              locale: 'en'
            })
            let addressEN = null
            if (result.status === 200) {
              addressEN = result.data
            }
            self.map.location(_location)
            bMarker = new window.longdo.Marker(_location, {
              icon: self.iconOption
            })
            self.$emit('setPlaceCardDetail', {
              ...properties,
              name_th: properties.desc_t,
              name_en: properties.desc_e,
              address_th: addressTH || '',
              address_en: addressEN || '',
              iconfile: `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`,
              location: {
                latitude: Number(properties.centerlat),
                longitude: Number(properties.centerlong)
              },
              datasource: {
                logo: 'https://www.numap.co.th/home/images/nu-smartinfo.png',
                source: 'Numap',
                url: 'https://www.numap.co.th/'
              }
            })
            self.drawPoiMarker(bMarker, bId, 'BID', data, 'building-search')
          } else {
            self.$emit('setPlaceCardDetail', null)

            return
          }
        } else {
          self.$emit('setPlaceCardDetail', null)

          return
        }
      }

      const geomIndex = self.findGeomPoiIndex(bId)
      if (geomIndex !== -1) {
        self.fromSearch = true
        self.map.Overlays.load(self.searchResultOverlayList[geomIndex])
      } else {
        const bObject = new window.longdo.Overlays.Object(bId, 'BID')
        self.drawPoiGeom(bMarker, bObject, 'BID')
      }
    },
    onOverlayLoad (object) {
      const self = this
      if (object.length > 0) {
        if ('eid' in object[0]) {
          object.forEach((overlay) => {
            overlay.title = '<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">' + overlay.title + '</div>'
            overlay.title_en = '<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">' + overlay.title_en + '</div>'
          })
        }
        if (self.fromSearch) {
          if (object[0].objecttype === 'ooi_layer') {
            const boundingBox = object[0].linearray[0].data
            self.map.bound(boundingBox)
          } else if (object[0].objecttype === 'ooi_geom') {
            const location = {
              lat: Number(object[0].linearray[0].centerlat),
              lon: Number(object[0].linearray[0].centerlong)
            }
            const popup = new window.longdo.Popup(location, {
              title: self.$i18n.locale === 'en' ? object[0].linearray[0].name_e : object[0].linearray[0].name_t,
              detail: self.$i18n.locale === 'en' ? object[0].linearray[0].name_e : object[0].linearray[0].name_t,
              loadDetail: (element) => { }
            })
            popup.searchObjectData = object[0]
            self.map.Overlays.add(popup)
            let locationList = []
            object[0].linearray[0].data.forEach(l => {
              locationList = locationList.concat(l)
            })
            const boundingBox = window.longdo.Util.locationBound(locationList)
            self.map.bound(boundingBox)
          } else {
            self.map.Overlays.list().forEach(o => {
              if ('type' in o) {
                if (o.type === 'p') {
                  self.map.Overlays.remove(o)
                }
              }
            })
            self.map.Overlays.list().forEach((overlay) => {
              if ('data' in overlay && 'linearray' in object[0]) {
                if (overlay.data.myid === object[0].linearray[0].myid) {
                  const title = '<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">' + overlay.popup().title().innerHTML + '</div>'
                  overlay.popup().title(title)
                  overlay.popup().title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)
                  overlay.pop(true)
                  const boundingBox = window.longdo.Util.locationBound(overlay.location())
                  self.map.bound(boundingBox)
                }
              }
            })
          }
          self.fromSearch = false
        }
      }
    },
    async onPopupLoadDetail (object) {
      const self = this
      let title = ''
      let id = ''
      if ('marker' in object) {
        if ('searchObjectData' in object.marker) {
          switch (object.marker.searchObjectData.objecttype) {
            case 'osmp':
            case 'poi': {
              id = object.marker.searchObjectData.id
              if (id.indexOf('OSM:P') === 0 || id.indexOf('OSM:L') === 0 || id.indexOf('OSM:G') === 0) {
                if ('name_th' in object.marker.searchObjectData) {
                  title = object.marker.searchObjectData['name_' + self.$i18n.locale]
                } else {
                  title = object.marker.searchObjectData.name
                }
              } else if (id.indexOf('OVM:P') === 0) {
                title = object.marker.searchObjectData.name
              } else {
                title = object.marker.searchObjectData.name
              }
              break
            }
          }
        } else if ('exploreObjectData' in object.marker) {
          title = object.marker.exploreObjectData.name
          id = object.marker.exploreObjectData.id
        } else if ('myPlaceObjectData' in object.marker) {
          title = object.marker.myPlaceObjectData['title_' + self.$i18n.locale]
          id = object.marker.myPlaceObjectData.ooiid
        } else if ('data' in object.marker) { // click from contextmenu
          if ('name' in object.marker.data) { // click from contextmenu
            title = object.marker.data.name
          } else { // click polyline from viewing fav feom
            title = object.marker.data['name_' + (self.$i18n.locale === 'th' ? 't' : 'e')]
          }
          if ('id' in object.marker.data) { // click from contextmenu
            id = object.marker.data.id
          } else if ('myid' in object.marker.data) { // click polyline from viewing fav feom
            id = object.marker.data.myid
          }
          let samePage = false
          if (self.$route.name === 'Place' && 'poiId' in self.$route.params) {
            if (self.$route.params.poiId === id) {
              samePage = true
            }
          }

          if (!samePage) {
            self.$router.push({
              name: 'Place',
              params: {
                poiId: id
              }
            })
            self.map.Overlays.list().forEach(overlay => {
              if (overlay instanceof window.longdo.Marker) {
                if ('data' in overlay) {
                  if ('distance' in overlay.data) { // from contextmenu
                    if (overlay.data.id === id) {
                      self.map.Overlays.remove(overlay)
                    }
                  }
                }
              }
            })
            return false
          }
        }
      } else if (object instanceof window.longdo.Popup) {
        if ('searchObjectData' in object) {
          // object.searchObjectData.objecttype => ooi_geom, ooi_layer
          title = self.$i18n.locale === 'en' ? object.searchObjectData.linearray[0].name_e : object.searchObjectData.linearray[0].name_t
          id = object.searchObjectData.linearray[0].myid
        } else if ('data' in object) { // open popup of bus stop marker from loading Longdo bus route object
          title = object.name
          id = object.id
        }
      }

      if (id === '') {
        return
      }

      title = '<button class="back"><i class="material-icons-round">arrow_back</i></button><div class="title">' + title + '</div>'
      object.title(title)
      object.title().querySelector('button').addEventListener('click', self.onClickBackPopupMini)
      object.detail(`<div class="loading-container"><img src="${self.utility.getImgPath('loading.gif')}"></div>`)

      if (id.indexOf('OSM:P') === 0 || id.indexOf('OSM:L') === 0 || id.indexOf('OSM:G') === 0) {
        let data = object.marker.searchObjectData
        if (!('name_th' in object.marker.searchObjectData)) {
          const params = {
            id: id
          }
          const result = await self.api.getPlaceInfo(params)
          data = result.data.data
        }

        object.detail(`
          <div class="">
            <div class="secondary-name">${data['name_' + (self.$i18n.locale === 'th' ? 'en' : 'th')]}</div>
            <div class="field">
              <i class="material-icons-round">category</i>
              <div>${data['place_type_' + self.$i18n.locale]}</div>
            </div>
            <div class="field">
              <i class="material-icons-round">place</i>
              <div>${data['address_' + self.$i18n.locale]}</div>
            </div>
            <div class="field" style="display: ${data.telephone !== '' ? 'flex' : 'none'};">
              <i class="material-icons-round">phone</i>
              <div><a id="OSM-tel" href="tel:${data.telephone}">${data.telephone}</a></div>
            </div>
            <div class="field" style="display: ${data.website !== '' ? 'flex' : 'none'};">
              <i class="material-icons-round">language</i>
              <div><a id="OSM-web" href="${data.website}" target="_blank">${data.website}</a></div>
            </div>
            <div class="field" style="display: ${'facebook' in data ? 'flex' : 'none'};">
              <i class="material-icons-round">public</i>
              <div><a id="OSM-facebook" href="${data.facebook}" target="_blank">${data.facebook}</a></div>
            </div>
            <div class="field resource" style="display: ${'datasource' in data ? 'flex' : 'none'};">
              <div>${self.$i18n.locale === 'th' ? 'แหล่งข้อมูล' : 'resource'}</div>
              <div><img src="${data.datasource.logo}"><a id="OSM-source" href="${data.datasource.url}" target="_blank">${data.datasource.source}</a></div>
            </div>
          </div>
        `)
        if (self.isOnMobileApp) {
          let temp = document.getElementById('OSM-tel')
          if (temp) {
            const href = temp.href
            temp.addEventListener('click', () => { self.onClickUrl(href) })
            temp.removeAttribute('href')
          }
          temp = document.getElementById('OSM-web')
          if (temp) {
            const href = temp.href
            temp.addEventListener('click', () => { self.onClickUrl(href) })
            temp.removeAttribute('href')
          }
          temp = document.getElementById('OSM-facebook')
          if (temp) {
            const href = temp.href
            temp.addEventListener('click', () => { self.onClickUrl(href) })
            temp.removeAttribute('href')
          }
          temp = document.getElementById('OSM-source')
          if (temp) {
            const href = temp.href
            temp.addEventListener('click', () => { self.onClickUrl(href) })
            temp.removeAttribute('href')
          }
        }
      } else {
        const now = new Date()
        const timestamp = now.getTime()

        const params = {
          id: encodeURIComponent(id),
          timestamp: encodeURIComponent(timestamp),
          locale: self.$i18n.locale
        }
        const result = await self.api.getPoiDetail(params)
        const detail = result.data.split('|')[1]
        object.detail(detail.replace(/!important/g, ''))
        const editButton = object.detail().querySelector('#edit_0')
        if (editButton) {
          if (self.isDisableLoginFeature && editButton.parentNode !== null) {
            editButton.parentNode.removeChild(editButton)
          } else {
            editButton.href = ''
            editButton.addEventListener('click', async (e) => {
              e.preventDefault()
              e.stopPropagation()
              let userSession = await self.utility.getUserSession()
              userSession = userSession || self.user
              self.isFromClickEditPlace = false
              if (!userSession) {
                self.isFromClickEditPlace = true
                self.$root.$emit('showLongdoLoginForm')
              } else {
                self.$router.push({
                  name: 'EditPlace',
                  params: {
                    poiId: id,
                    userData: self.user
                  }
                })
              }
              return false
            })
          }
        }
        const viewDetailButton = object.detail().querySelector('#detail_0')
        if (viewDetailButton) {
          viewDetailButton.href = ''
          viewDetailButton.addEventListener('click', async (e) => {
            e.preventDefault()
            e.stopPropagation()
            let userSession = await self.utility.getUserSession()
            userSession = userSession || self.user
            if (!userSession) {
              self.$router.push({
                name: 'InfoPlace',
                params: {
                  poiId: id
                }
              })
            } else {
              self.$router.push({
                name: 'InfoPlace',
                params: {
                  poiId: id,
                  userData: self.user
                }
              })
            }
            return false
          })
        }
        const shareButton = object.detail().querySelector('#link_0')
        if (shareButton) {
          shareButton.href = ''
          shareButton.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (typeof window.navigator !== 'undefined') {
              if (window.navigator.share) {
                window.navigator.share({
                  url: `https://${process.env.VUE_APP_PRODUCT}.longdo.com/p/${id}`,
                  title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
                })
              } else {
                self.share = {
                  poiId: id,
                  ...object.location()
                }
                self.isShowSharePlaceWidget = true
              }
            } else {
              self.share = {
                poiId: id,
                ...object.location()
              }
              self.isShowSharePlaceWidget = true
            }
            return false
          })
        }
      }

      self.resizeMapHeightAnimate()
      return false
    },
    onClickBackPopupMini () {
      const self = this
      if (window.history.length <= 3) {
        self.$router.replace({
          name: 'Main',
          hash: '',
          params: {},
          query: {}
        })
      } else {
        this.$router.back()
      }
    },
    onPopupClose (e) {
      const self = this
      self.isShowingPopup = false
      if ('search' in self.$route.query) {
        if (!self.isShowingMapOnly) {
          self.$emit('showSearchResultPanel')
          self.$emit('changeHidingSearchResultPanel', false)
        }
      }
    },
    setIsTracking (value) {
      const self = this
      self.isTracking = value
      if (value === false) {
        self.setIsTrackingDirection(false)
      } else {
        self.track()
      }
    },
    setIsTrackingDirection (value) {
      const self = this
      self.isTrackingDirection = value
    },
    getCurrentLocation () {
      const self = this
      self.lastUpdateCurrentLocation = null
      self.setIsTracking(true)
      if (self.map.Ui.Mouse.lockPinch) {
        self.map.Ui.Mouse.lockPinch(true)
      }
    },
    track () {
      const self = this
      if (self.isTrackingDirection && window.heading) {
        self.rotateDirectionMarker()
        if (self.isLongdoMapV3) {
          self.map.Renderer.easeTo({
            center: window.currentLocation,
            bearing: window.heading,
            duration: 1000
          })
        } else {
          self.map.rotate(window.heading)
        }
      } else {
        self.map.location(window.currentLocation, false)
      }
    },
    rotateDirectionMarker () {
      const self = this
      if (self.currentLocationMarker) {
        const directionElement = self.currentLocationMarker.element().querySelector('.direction')
        if (directionElement && self.isLongdoMapV3) {
          if (window.heading) {
            if (self.isTrackingDirection) {
              directionElement.style.display = 'block'
              directionElement.style.transform = `rotateX(${self.map.Renderer.getPitch()}deg) rotateZ(0deg)`
            } else {
              const degree = (window.heading - self.map.rotate()) % 360
              directionElement.style.transform = `rotateX(${self.map.Renderer.getPitch()}deg) rotateZ(${degree}deg)`
              directionElement.style['-webkit-transform'] = `rotateX(${self.map.Renderer.getPitch()}deg) rotateZ(${degree}deg)`
              directionElement.style['-moz-transform'] = `rotateX(${self.map.Renderer.getPitch()}deg) rotateZ(${degree}deg)`
              directionElement.style['-ms-transform'] = `rotateX(${self.map.Renderer.getPitch()}deg) rotateZ(${degree}deg)`
            }
            directionElement.style.display = 'block'
          } else {
            directionElement.style.display = 'none'
          }
          self.pitchCurrentLocationElement()
        } else if (directionElement && !self.isLongdoMapV3) {
          if (window.heading) {
            if (self.isTrackingDirection) {
              directionElement.style.display = 'block'
              directionElement.style.transform = 'rotateZ(0deg)'
            } else {
              const degree = (window.heading - self.map.rotate()) % 360
              directionElement.style.transform = `rotateZ(${degree}deg)`
              directionElement.style['-webkit-transform'] = `rotateZ(${degree}deg)`
              directionElement.style['-moz-transform'] = `rotateZ(${degree}deg)`
              directionElement.style['-ms-transform'] = `rotateZ(${degree}deg)`
            }
            directionElement.style.display = 'block'
          } else {
            directionElement.style.display = 'none'
          }
        }
      }
    },
    pitchCurrentLocationElement () {
      const self = this
      const currentLocationElement = self.currentLocationMarker.element().querySelector('.current-location-marker')
      currentLocationElement.style.transform = `rotateX(${self.map.Renderer.getPitch()}deg)`
      currentLocationElement.style['-webkit-transform'] = `rotateX(${self.map.Renderer.getPitch()}deg)`
      currentLocationElement.style['-moz-transform'] = `rotateX(${self.map.Renderer.getPitch()}deg)`
      currentLocationElement.style['-ms-transform'] = `rotateX(${self.map.Renderer.getPitch()}deg)`
    },
    clickMapType (selectedMapType) {
      const self = this
      self.darkMode = false
      self.lightMode = false
      self.landParcelFilter = false

      const removeLayList = ['iconstransp', 'iconstransp-en']
      self.map.Layers.list().forEach(l => {
        if (removeLayList.indexOf(l.name()) >= 0) {
          self.map.Layers.remove(l)
        }
      })
      self.landParcelOnPoiBaseLayerObjectList.forEach(l => {
        self.map.Layers.remove(l)
      })

      if (selectedMapType === 'POI' || selectedMapType === 'POI_EN') {
        self.map.Layers.setBase(window.longdo.Layers[selectedMapType])
        self.landParcelOnPoiBaseLayerObjectList = []
        self.landParcelOnPoiBaseLayerObjectList.push(new window.longdo.Layer('', {
          type: window.longdo.LayerType.Custom,
          url: function (projection, tile, zoom, hd) {
            return `https://ms.longdo.com/mmmap/img.php?mode=dol_hd&proj=${projection.longdoName}&zoom=${zoom}&x=${tile.u}&y=${tile.v}`
          },
          zoomRange: {
            min: 17,
            max: 20
          },
          source: {
            max: 19
          },
          opacity: 0.145,
          weight: 50
        }))
        self.landParcelOnPoiBaseLayerObjectList.forEach(l => {
          self.map.Layers.add(l)
        })
        self.landParcelFilter = true
      } else if (selectedMapType === 'DARK_POI') {
        self.darkMode = true
        self.map.Layers.setBase(window.longdo.Layers.POI)
      } else if (selectedMapType === 'LIGHT_NORMAL') {
        self.lightMode = true
        self.map.Layers.setBase(window.longdo.Layers.NORMAL)
      } else if (selectedMapType === 'DARK_POI_EN') {
        self.darkMode = true
        self.map.Layers.setBase(window.longdo.Layers.POI_EN)
      } else if (selectedMapType === 'LIGHT_NORMAL_EN') {
        self.lightMode = true
        self.map.Layers.setBase(window.longdo.Layers.NORMAL_EN)
      } else if (selectedMapType === 'TERRAIN') {
        self.map.Layers.setBase(window.longdo.Layers[selectedMapType])
        const layerName = self.$i18n.locale === 'th' ? 'iconstransp' : 'iconstransp-en'
        const poiLayer = new window.longdo.Layer(layerName, {
          weight: -1,
          zoomRange: {
            min: 1,
            max: 11
          }
        })
        self.map.Layers.add(poiLayer)
      } else if (selectedMapType === 'THAICHOTE') {
        self.map.Layers.setBase(window.longdo.Layers[selectedMapType])
        const layerName = self.$i18n.locale === 'th' ? 'iconstransp' : 'iconstransp-en'
        const poiLayer = new window.longdo.Layer(layerName, {
          weight: -1
        })
        self.map.Layers.add(poiLayer)
      } else if (selectedMapType === 'BING_VIRTUALEARTH') {
        if (!window.longdo.Layers[selectedMapType]) {
          window.longdo.Layers[selectedMapType] = new window.longdo.Layer(
            'bing_virtualearth',
            {
              type: window.longdo.LayerType.WMS,
              url: 'http://msq.longdo.com/service',
              zoomRange: {
                min: 1,
                max: 20
              },
              source: {
                max: 19
              }
            }
          )
        }
        self.map.Layers.setBase(window.longdo.Layers[selectedMapType])
        const layerName = self.$i18n.locale === 'th' ? 'iconstransp' : 'iconstransp-en'
        const poiLayer = new window.longdo.Layer(layerName, {
          weight: -1
        })
        self.map.Layers.add(poiLayer)
      } else {
        self.map.Layers.setBase(window.longdo.Layers[selectedMapType])
      }
    },
    clickMapTypeV3 (selectedMapType) {
      const self = this
      try {
        switch (selectedMapType) {
          case 'POI':
            // self.map.Layers.setBase(window.longdo3.Layers.NORMAL)
            break
          case 'GRAY':
            self.map.Layers.setBase(window.longdo3.Layers.GRAY)
            break
          case 'KLOKANTECH_BASIC':
            self.map.Layers.setBase(window.longdo3.Layers.KLOKANTECH_BASIC)
            break
          default:
            self.map.Layers.setBase(window.longdo3.Layers[selectedMapType])
            self.baseMap = selectedMapType
        }
      } catch (error) {
      }
    },
    onBeforeSearch (keyword) {
      const self = this
      self.isSearching = true
      self.isSearchingMore = false
      self.hasMoreSearchResult = false
      self.searchResultList = []
      let tagName = ''
      if (keyword.indexOf('tag:') !== -1) {
        const splitedKeyword = keyword.split(':')
        tagName = splitedKeyword[1].trim()
      }
      if (self.lastTagName !== null && self.lastTagName !== tagName) {
        self.map.Tags.remove(self.lastTagName)
      }
    },
    onSearchLocation (location) {
      const self = this
      self.isSearching = false
      self.removeSearchLocationMarker()
      self.removeSearchResultOverlay()
      self.searchResultList = null
      self.$emit('searchLocation')
    },
    onSearchTrafficResult (result) {
      const self = this
      self.hideMapTrafficEventHandler()
      self.trafficIncidentMarker = []
      self.trafficIncident = result
      self.drawTrafficEvent(result)
      self.showMapTrafficEventHandler()
      self.hasMoreTrafficEvent = true
      self.isSearchingMoreTrafficEvent = false
    },
    onSearchResult (result, isMore = false, isSearchLocation = false) {
      const self = this
      if (!isMore) {
        self.isSearching = false
        self.geocodingResult = null
        self.removeSearchLocationMarker()
        self.removeSearchResultOverlay()
        self.isShowingPopup = false
      } else {
        self.isSearchingMore = false
      }

      const boundingLocationList = []
      result.searchobject.forEach(searchObject => {
        if ((searchObject.source || '') !== 'google') {
          searchObject.markicon2x = searchObject.markicon.replace('/icons/', '/icons_2x/')
        }
        switch (searchObject.objecttype) {
          case 'osmp': {
            const poiMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            poiMarker.searchType = 'KEYWORD'
            poiMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(poiMarker)
            searchObject.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(poiMarker)
            boundingLocationList.push(poiMarker.location())
            break
          }
          case 'poi': {
            const poiMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              title: searchObject.name,
              detail: searchObject.shortdesc,
              icon: self.iconOption
            })
            poiMarker.popup = () => { return false }
            poiMarker.searchType = 'KEYWORD'
            poiMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(poiMarker)
            searchObject.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(poiMarker)
            boundingLocationList.push(poiMarker.location())
            break
          }
          case 'khet': {
            const poiMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            poiMarker.searchType = 'KEYWORD'
            poiMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(poiMarker)
            searchObject.searchResultMarkerIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(poiMarker)
            boundingLocationList.push(poiMarker.location())

            const khetObject = new window.longdo.Overlays.Object(searchObject.geocode, 'IG')
            khetObject.searchType = 'KEYWORD'
            khetObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(khetObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'road': {
            const poiMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            poiMarker.searchType = 'KEYWORD'
            poiMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(poiMarker)
            searchObject.searchResultMarkerIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(poiMarker)
            boundingLocationList.push(poiMarker.location())

            const roadObject = new window.longdo.Overlays.Object(searchObject.id, 'RID')
            roadObject.searchType = 'KEYWORD'
            roadObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(roadObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'geom': {
            if (searchObject.lat !== 0 && searchObject.long !== 0) {
              const geomMarker = new window.longdo.Marker({
                lat: searchObject.lat,
                lon: searchObject.long
              }, {
                icon: self.iconOption
              })
              geomMarker.searchType = 'KEYWORD'
              geomMarker.searchObjectData = searchObject
              self.searchResultOverlayList.push(geomMarker)
              searchObject.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
              self.map.Overlays.add(geomMarker)
              boundingLocationList.push(geomMarker.location())
            }
            const geomObject = new window.longdo.Overlays.Object(searchObject.id, 'GID')
            geomObject.searchType = 'KEYWORD'
            geomObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(geomObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'layer': {
            // if (searchObject.lat !== 0 && searchObject.long !== 0) {
            //   const layerMarker = new window.longdo.Marker({
            //     lat: searchObject.lat,
            //     lon: searchObject.long
            //   }, {
            //     title: searchObject.name,
            //     detail: searchObject.shortdesc,
            //     popup: {
            //       loadDetail: (element) => {}
            //     },
            //     icon: self.iconOption
            //   })
            //   layerMarker.searchType = 'KEYWORD'
            //   layerMarker.searchObjectData = searchObject
            //   self.searchResultOverlayList.push(layerMarker)
            //   searchObject.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
            //   self.map.Overlays.add(layerMarker)
            //   boundingLocationList.push(layerMarker.location())
            // }
            const layerObject = new window.longdo.Overlays.Object(searchObject.id, 'YID')
            layerObject.searchType = 'KEYWORD'
            layerObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(layerObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'water-line': {
            const waterLineMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            waterLineMarker.searchType = 'KEYWORD'
            waterLineMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(waterLineMarker)
            searchObject.searchResultMarkerIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(waterLineMarker)

            const waterLineObject = new window.longdo.Overlays.Object(searchObject.id, 'XID')
            waterLineObject.searchType = 'KEYWORD'
            waterLineObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(waterLineObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'water-area': {
            if (searchObject.lat !== 0 && searchObject.long !== 0) {
              const waterAreaMarker = new window.longdo.Marker({
                lat: searchObject.lat,
                lon: searchObject.long
              }, {
                icon: self.iconOption
              })
              waterAreaMarker.searchType = 'KEYWORD'
              waterAreaMarker.searchObjectData = searchObject
              self.searchResultOverlayList.push(waterAreaMarker)
              searchObject.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
              self.map.Overlays.add(waterAreaMarker)
            }
            const waterAreaObject = new window.longdo.Overlays.Object(searchObject.id, 'WID')
            waterAreaObject.searchType = 'KEYWORD'
            waterAreaObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(waterAreaObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'bus': {
            const poiMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            poiMarker.searchType = 'KEYWORD'
            poiMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(poiMarker)
            searchObject.searchResultMarkerIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(poiMarker)
            boundingLocationList.push(poiMarker.location())

            const busRouteObject = new window.longdo.Overlays.Object(searchObject.id, 'SID')
            busRouteObject.searchType = 'KEYWORD'
            busRouteObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(busRouteObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
          case 'geocoding': {
            const geocodingMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            geocodingMarker.searchType = 'KEYWORD'
            geocodingMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(geocodingMarker)
            searchObject.searchResultOverlayIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(geocodingMarker)
            boundingLocationList.push(geocodingMarker.location())
            self.geocodingResult = searchObject
            break
          }
          case 'other': {
            const poiMarker = new window.longdo.Marker({
              lat: searchObject.lat,
              lon: searchObject.long
            }, {
              icon: self.iconOption
            })
            poiMarker.searchType = 'KEYWORD'
            poiMarker.searchObjectData = searchObject
            self.searchResultOverlayList.push(poiMarker)
            searchObject.searchResultMarkerIndex = self.searchResultOverlayList.length - 1
            self.map.Overlays.add(poiMarker)
            boundingLocationList.push(poiMarker.location())

            const buildingObject = new window.longdo.Overlays.Object(searchObject.id, 'BID')
            buildingObject.searchType = 'KEYWORD'
            buildingObject.searchObjectData = searchObject
            self.searchResultOverlayList.push(buildingObject)
            searchObject.searchResultGeomIndex = self.searchResultOverlayList.length - 1
            break
          }
        }
      })
      const tempSearchResultList = JSON.parse(JSON.stringify(result.searchobject))
      self.searchResultList = isMore ? self.searchResultList.concat(tempSearchResultList) : tempSearchResultList
      self.hasMoreSearchResult = result.hasmore

      if (!isMore) {
        if ('GCP' in self.$route.query) {
          // GCP is for sharing geocode poi
          // call search and select first result which is always geocoding (if has)
          self.onClickSearchResult(self.searchResultList[0], true)
          return
        } else if (self.searchResultList.length === 1 && self.searchResultList.filter(r => r.source === 'google').length === 0) {
          self.$router.replace({
            name: 'Place',
            params: {
              poiId: self.searchResultList[0].id
            },
            query: self.searchResultList[0].id.slice(0, 4) === 'RGCP' ? {
              lat: self.$route.query.lat,
              lon: self.$route.query.lon
            } : {}
          })
        }
      }

      let location = self.map.location()
      if ('lat' in self.$route.query && 'lon' in self.$route.query) {
        location = {
          lat: self.$route.query.lat,
          lon: self.$route.query.lon
        }
      }
      if (boundingLocationList.length > 0 && !isSearchLocation) {
        if (!isMore && boundingLocationList.length > 5) {
          let latArray = []
          let lonArray = []
          boundingLocationList.forEach((loc) => {
            latArray.push(loc.lat)
            lonArray.push(loc.lon)
          })
          // filter outlier with IQR method
          latArray = latArray.sort(function (a, b) { return a - b })
          const latQ1 = latArray[Math.floor(latArray.length / 4)]
          const latQ3 = latArray[Math.floor(latArray.length * 3 / 4)]
          const latIQR = latQ3 - latQ1
          lonArray = lonArray.sort(function (a, b) { return a - b })
          const lonQ1 = lonArray[Math.floor(lonArray.length / 4)]
          const lonQ3 = lonArray[Math.floor(lonArray.length * 3 / 4)]
          const lonIQR = lonQ3 - lonQ1
          const filterList = boundingLocationList.filter((loc) => {
            return (loc.lat >= latQ1 - latIQR) && (loc.lat <= latQ3 + latIQR) && (loc.lon >= lonQ1 - lonIQR) && (loc.lon <= lonQ3 + lonIQR)
          })
          if (self.isLongdoMapV3) {
            self.map.bound(window.longdo.Util.locationBound(filterList), { padding: self.isOnMobileApp ? 70 : { top: 120, right: 100, left: 50, bottom: 50 } })
          } else {
            self.map.bound(window.longdo.Util.locationBound(filterList), location)
          }
        } else {
          if (self.isLongdoMapV3) {
            self.map.bound(window.longdo.Util.locationBound(boundingLocationList), { padding: self.isOnMobileApp ? 70 : { top: 120, right: 100, left: 50, bottom: 50 } })
          } else {
            self.map.bound(window.longdo.Util.locationBound(boundingLocationList), location)
          }
        }
      }
    },
    onBeforeMore () {
      const self = this
      self.isSearchingMore = true
    },
    onBeforeMoreTrafficEvent () {
      const self = this
      self.isSearchingMoreTrafficEvent = true
    },
    onMoreResult (result) {
      const self = this
      self.onSearchResult(result, true)
    },
    removeMyPlaceLocationMarker (removeFavorites = true, removeCreated = true) {
      const self = this
      if (self.searchLocationMarker !== null) {
        if (self.searchLocationMarker.searchType === 'MYPLACE') {
          if ((self.searchLocationMarker.myPlaceObjectData.marked_favorite && removeFavorites && self.searchLocationMarker.myPlaceObjectData.place_owner && removeCreated) ||
            (self.searchLocationMarker.myPlaceObjectData.marked_favorite && removeFavorites && !self.searchLocationMarker.place_owner()) ||
            (!self.searchLocationMarker.marked_favorite && self.searchLocationMarker.myPlaceObjectData.place_owner && removeCreated)) {
            self.map.Overlays.remove(self.searchLocationMarker)
            self.searchLocationMarker = null
          }
        }
      }
    },
    removeMyPlaceResultOverlay (removeFavorites = true, removeCreated = true) {
      const self = this
      self.searchResultOverlayList.forEach(searchResultOverlay => {
        if (searchResultOverlay instanceof window.longdo.Marker) {
          if (searchResultOverlay.searchType === 'MYPLACE') {
            if ((searchResultOverlay.myPlaceObjectData.marked_favorite && removeFavorites && searchResultOverlay.myPlaceObjectData.place_owner && removeCreated) ||
              (searchResultOverlay.myPlaceObjectData.marked_favorite && removeFavorites && !searchResultOverlay.myPlaceObjectData.place_owner) ||
              (!searchResultOverlay.myPlaceObjectData.marked_favorite && searchResultOverlay.myPlaceObjectData.place_owner && removeCreated)) {
              self.map.Overlays.remove(searchResultOverlay)
            }
          }
        }
      })
      self.searchResultOverlayList = self.searchResultOverlayList.filter(searchResultOverlay => searchResultOverlay.searchType !== 'MYPLACE')
    },
    onChangeShowMyPlaces ({ showFavorites, showCreated }) {
      const self = this
      // if ((!showFavorites || !showCreated) && self.$route.hash !== '#my-places') {
      //   self.removeMyPlaceLocationMarker(!showFavorites, !showCreated)
      //   self.removeMyPlaceResultOverlay(!showFavorites, !showCreated)
      // }
      // if (showFavorites || showCreated) {
      //   self.drawMyPlace(showFavorites, showCreated)
      // }
      self.removeMyPlaceLocationMarker()
      self.removeMyPlaceResultOverlay()
      self.drawMyPlace(showFavorites || self.$route.hash === '#my-places', showCreated || self.$route.hash === '#my-places')
    },
    onChangeShowMyPlacesName (showMyPlacesName) {
      const self = this
      self.showMyPlacesName = showMyPlacesName
    },
    removeSearchLocationMarker () {
      const self = this
      if (self.searchLocationMarker !== null) {
        if (self.searchLocationMarker.searchType !== 'MYPLACE' && self.searchLocationMarker.searchType !== 'EVENT') {
          self.map.Overlays.remove(self.searchLocationMarker)
          self.searchLocationMarker = null
        }
      }
    },
    removeSearchResultOverlay () {
      const self = this
      if (self.isLongdoMapV3) {
        self.searchResultOverlayList.forEach(searchResultOverlay => {
          if (searchResultOverlay instanceof window.longdo3.Marker) {
            if (searchResultOverlay.searchType !== 'MYPLACE' && searchResultOverlay.searchType !== 'EVENT') {
              self.map.Overlays.remove(searchResultOverlay)
            } else {
              // If MapAPI3 Ready Remove overylay
            }
          } else {
            self.map.Overlays.clear()
            // self.map.Overlays.unload(searchResultOverlay)
          }
        })
        self.searchResultOverlayList = self.searchResultOverlayList.filter(searchResultOverlay => {
          return searchResultOverlay.searchType === 'MYPLACE' || searchResultOverlay.searchType === 'EVENT'
        })
      } else {
        self.searchResultOverlayList.forEach(searchResultOverlay => {
          if (searchResultOverlay instanceof window.longdo.Marker) {
            if (searchResultOverlay.searchType !== 'MYPLACE' && searchResultOverlay.searchType !== 'EVENT') {
              self.map.Overlays.remove(searchResultOverlay)
            }
          } else {
            self.map.Overlays.unload(searchResultOverlay)
          }
        })
        self.searchResultOverlayList = self.searchResultOverlayList.filter(searchResultOverlay => {
          return searchResultOverlay.searchType === 'MYPLACE' || searchResultOverlay.searchType === 'EVENT'
        })
      }
    },
    onClearSearch () {
      const self = this
      self.removeSearchLocationMarker()
      self.removeSearchResultOverlay()
      self.removeCheckNearby()
      self.currentLocationBuffer = null
      self.currentLocationMarker = null
      if (self.lastTagName !== null) {
        self.map.Tags.remove(self.lastTagName)
        self.lastTagName = null
      }
      if (self.$route.name === 'Nearby') {
        self.$router.push({
          name: 'Main'
        })
      }
      self.$emit('clearSearch')
      self.resizeMapHeightAnimate()
    },
    onClickSearchResult (searchResult, showPlaceDetail) {
      const self = this
      self.searchResultOverlayList.forEach(searchResultOverlay => {
        if (searchResultOverlay instanceof window.longdo.Marker) {
          searchResultOverlay.element().classList.remove('focus')
        }
      })
      if (searchResult.objecttype === 'tag') {
        const tagName = searchResult.name.split(': ')[1]
        self.onShowTag(tagName)
      } else if (searchResult.objecttype === 'layer') {
        self.$router.push({
          name: 'Place',
          params: {
            poiId: searchResult.id
          },
          query: {}
        })
      } else if (searchResult.objecttype === 'geometry') {
        self.map.location({
          lat: searchResult.lat,
          lon: searchResult.long
        })
        self.$router.push({
          name: 'Place',
          params: {
            poiId: searchResult.id
          }
        })
      } else {
        const location = {
          lat: searchResult.lat,
          lon: searchResult.long
        }
        const bound = self.map.bound()
        const diffLat = bound.maxLat - bound.minLat
        const diffLon = bound.maxLon - bound.minLon
        const locationList = [{
          lat: bound.maxLat - (diffLat * 0.10),
          lon: bound.minLon + (diffLon * 0.05)
        }, {
          lat: bound.maxLat - (diffLat * 0.10),
          lon: bound.maxLon - (diffLon * 0.05)
        }, {
          lat: bound.minLat + (diffLat * 0.40),
          lon: bound.maxLon - (diffLon * 0.05)
        }, {
          lat: bound.minLat + (diffLat * 0.40),
          lon: bound.minLon + (diffLon * 0.05)
        }, {
          lat: bound.maxLat - (diffLat * 0.10),
          lon: bound.minLon + (diffLon * 0.05)
        }]
        const isContain = window.longdo.Util.contains(location, locationList)
        if (!isContain) {
          self.map.location(location)
          self.map.resize()
        }

        // if (self.isLongdoMapV3) {
        //   if (!isContain) {
        //     self.map.resize()
        //     setTimeout(() => {
        //       self.map.location(location)
        //       self.map.zoom(15)
        //     }, 2000)
        //   }
        // } else {
        //   if (!isContain) {
        //     self.map.location(location)
        //   }
        // }
        self.searchResultOverlayList.forEach(searchResultOverlay => {
          if (!self.isLongdoMapV3) {
            if (searchResultOverlay instanceof window.longdo.Marker && 'searchObjectData' in searchResultOverlay) {
              if (searchResultOverlay.searchObjectData.id === searchResult.id) {
                searchResultOverlay.element().classList.add('focus')
                if (showPlaceDetail) {
                  self.map.Event.fire('overlayClick', searchResultOverlay)
                }
              }
            }
          } else {
            if (searchResultOverlay instanceof window.longdo3.Marker && 'searchObjectData' in searchResultOverlay) {
              if (searchResultOverlay.searchObjectData.id === searchResult.id) {
                searchResultOverlay.element().classList.add('focus')
                if (showPlaceDetail) {
                  self.map.Event.fire('overlayClick', searchResultOverlay)
                }
              }
            }
          }
        })
      }
      // }
    },
    onClickHasMore () {
      const self = this
      self.$emit('clickHasMore')
    },
    onFireTrafficIncidentClick (incident) {
      const self = this
      if (self.trafficIncidentMarker) {
        self.trafficIncidentMarker.forEach(overlay => {
          if (overlay.data) {
            if (overlay.data.eid === incident.eid) {
              overlay.pop()
              self.map.Event.fire('overlayClick', overlay)
            }
          } else {
            if (self.trafficIncidentMakerActive !== null) {
              self.map.Overlays.remove(self.trafficIncidentMakerActive)
            }
            if (self.baseUrl === '/') {
              self.baseUrl = 'http://localhost:8080'
            }
            const startTime = new Date(incident.start_time)
            const stopTime = new Date(incident.stop_time)
            const isSameDate = startTime.toDateString() === stopTime.toDateString()
            const dateDisplay = isSameDate ? startTime.toLocaleDateString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
              minute: 'numeric',
              hour: 'numeric',
              year: '2-digit',
              month: 'short',
              day: 'numeric'
            }) + ' - ' + stopTime.toLocaleTimeString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
              minute: 'numeric',
              hour: 'numeric'
            }) : startTime.toLocaleDateString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
              year: '2-digit',
              month: 'short',
              day: 'numeric'
            }) + ' - ' + stopTime.toLocaleDateString(`${self.$i18n.locale === 'th' ? 'th-TH' : 'en-US'}`, {
              year: '2-digit',
              month: 'short',
              day: 'numeric'
            })

            const eventPopUpOptions = {
              title: `<div style='font-size:15px; font-weight:800; word-wrap:break-word; display:flex; align-items:center; ${self.isLongdoMapV3 ? 'max-width: 235px' : ''}'>
                      ${self.isLongdoMapV3 ? '' : '<button class="material-icons-round back" style="width:40px">arrow_back</button>'}${self.$i18n.locale === 'th' ? incident.title : incident.title_en}
                    </div>`,
              detail: self.isLongdoMapV3 ? `<div style="overflow:hidden; max-width: 250px; display: flex; flex-direction: column;
                        gap: 9px; padding-top: 9px; word-wrap:break-word; font-size:12px; ">
                        <div style="font-weight:500;">
                          ${self.$i18n.locale === 'th' ? incident.title_en : incident.title}
                        </div>
                        <div style="font-size: 11px">${dateDisplay}</div>
                        <div style="font-size: 11px">${self.$t('main.trafficEventPopUp.by')} <a id="eventContributor" style="font-size: 11px" target='_blank' href="${process.env.VUE_APP_LONGDO_USER_INFO}${incident.uid}">${incident.contributor}</a></div>
                        ${incident.imagenid !== '0' ? `
                        <div style="width: 100%; display:flex; justify-content:center">
                          <a id="incidentImage" target='_blank' href='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}'>
                            <img src='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}/thumbnail'>
                          </a>
                        </div>` : ''}
                        <div>${self.$i18n.locale === 'th' ? incident.comment : incident.comment_en}</div>
                      <div style="display: flex; gap: 3px;">
                        <div id="shareEventButton" style="padding: 5px; color: #16A668; background-color: #F4FCF9; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #16A668;vertical-align: text-bottom; font-size: 16px;">share</i> ${self.$t('main.trafficEventPopUp.share')}</div>
                        <div id="commentEventButton" style="padding: 5px; color: #1B87EB; background-color: #EEF7FF; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #1B87EB; vertical-align: text-bottom; font-size: 16px;">chat_bubble</i> ${self.$t('main.trafficEventPopUp.comment')}</div>
                      </div>
                      <div class="editDelteEvent" style="display: flex; gap: 3px;"><div class="editEvent"></div><div class="deleteEvent"></div></div>
                  </div>
                </div>`
                : `<hr style="border: 0.5px solid #ccc; width:100%">
                  <div class="event-popup-description" style="overflow-y:scroll; overflow-x:hidden; display: flex; flex-direction: column; align-items:start !important; width: calc(100% - 20px);
                  gap: 9px; padding-left: 20px; word-wrap:break-word; font-size:13px; ">
                    <div style="font-weight:500; white-space: nowrap">
                      ${self.$i18n.locale === 'th' ? incident.title_en : incident.title}
                    </div>
                    <div style="font-size: 12px">${dateDisplay}</div>
                    <div style="font-size: 12px">${self.$t('main.trafficEventPopUp.by')} <a style="font-size: 11px" target='_blank' href="${process.env.VUE_APP_LONGDO_USER_INFO}${incident.uid}">${incident.contributor}</a></div>
                    ${incident.imagenid !== '0' ? `
                    <div style="width:100%; max-width:500px;}">
                      <a target='_blank' href='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}' style="width: 50%">
                        <img src='${process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_IMAGE_BASE_URL}${incident.imagenid}/thumbnail'>
                      </a>
                    </div>` : ''}
                    <div style="display: flex; gap: 3px;">
                      <div id="shareEventButton" style="padding: 5px; color: #16A668; background-color: #F4FCF9; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #16A668;vertical-align: text-bottom; font-size: 16px;">share</i> ${self.$t('main.trafficEventPopUp.share')}</div>
                      <div id="commentEventButton" style="padding: 5px; color: #1B87EB; background-color: #EEF7FF; border-radius: 3px; cursor:pointer;"><i class="material-icons-round" style="color: #1B87EB; vertical-align: text-bottom; font-size: 16px;">chat_bubble</i> ${self.$t('main.trafficEventPopUp.comment')}</div>
                    </div>
                    <div class="editDelteEvent" style="display: flex; gap: 3px;"><div class="editEvent"></div><div class="deleteEvent"></div></div>
                  <div>${self.$i18n.locale === 'th' ? incident.comment : incident.comment_en}</div>
                `,
              size: self.isLongdoMapV3 ? { width: 270 } : {},
              loadDetail: () => {
                let temp = document.getElementById('shareEventButton')
                if (temp) {
                  temp.addEventListener('click', () => { self.handleShareEvent(incident.eid) })
                }
                temp = document.getElementById('commentEventButton')
                if (temp) {
                  temp.addEventListener('click', () => { self.handleCommentEvent(incident.eid) })
                }
                if (self.isOnMobileApp) {
                  temp = document.getElementById('eventContributor')
                  if (temp) {
                    const href = temp.href
                    temp.addEventListener('click', () => { self.onClickUrl(href) })
                    temp.removeAttribute('href')
                  }
                  temp = document.getElementById('incidentImage')
                  if (temp) {
                    const href = temp.href
                    temp.addEventListener('click', () => { self.onClickUrl(href) })
                    temp.removeAttribute('href')
                  }
                }
              }
            }
            var incidentMarker = new window.longdo3.Marker({ lon: Number.parseFloat(incident.longitude), lat: Number.parseFloat(incident.latitude) }, {
              icon: {
                html: `<img src='${self.baseUrl}/${incident.icon1x}'
              style="${incident.severity_level === '10' ? 'width:48px; height:48px;' : 'width:26px; height:26px'}">`
              },
              visibleRange: { min: 12, max: 22 },
              clickable: false,
              popup: eventPopUpOptions
            })
            var incidentPopup = new window.longdo3.Popup({ lon: Number.parseFloat(incident.longitude), lat: Number.parseFloat(incident.latitude) },
              {
                ...eventPopUpOptions
              })
            self.trafficIncidentMakerActive = incidentMarker
            self.map.Overlays.add(incidentMarker)
            self.map.Overlays.add(incidentPopup)
          }
        })
      }
    },
    onClickHasMoreTrafficEvent () {
      const self = this
      self.$emit('clickHasMoreTrafficEvent')
    },
    clearFocusMarker () {
      const self = this
      if (self.isLongdoMapV3) {
        return false
      }

      self.searchResultOverlayList.forEach(searchResultOverlay => {
        if (searchResultOverlay instanceof window.longdo.Marker) {
          searchResultOverlay.element().classList.remove('focus')
          searchResultOverlay.element().classList.remove('focus-my-place')
        }
      })
      self.map.Overlays.list().forEach(o => {
        if (o instanceof window.longdo.Marker && 'aqiAir4ThaiData' in o) {
          o.element().classList.remove('focus-aqi')
        }
        if (o instanceof window.longdo.Marker && 'aqiCNData' in o) {
          o.element().classList.remove('focus-aqi')
        }
      })
    },
    onBeforeExplore () {
      const self = this
      self.setIsTracking(false)
      self.removeSearchLocationMarker()
      self.removeSearchResultOverlay()
      self.isShowingPopup = false
    },
    onExploreResult (result, isMore = false) {
      const self = this
      const boundingLocationList = []
      result.data.forEach((exploreObject, index) => {
        boundingLocationList.push({
          lat: exploreObject.lat,
          lon: exploreObject.lon
        })
        const poiMarker = new window.longdo.Marker({
          lat: exploreObject.lat,
          lon: exploreObject.lon
        }, {
          title: exploreObject.name,
          detail: exploreObject.address,
          // popup: {
          //   loadDetail: (element) => {}
          // },
          icon: {
            html: '<img class="explore-pin-marker" style="width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px;" src="' + process.env.VUE_APP_LONGDO_MAP_PIN + '">',
            offset: (this.$route.query.map || 'v3') === 'v3' ? {
              x: 0,
              y: -(Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT) / 2)
            } : {
              x: process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH / 2,
              y: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
            }
          }
        })
        poiMarker.popup = () => { return false }
        poiMarker.searchType = 'EXPLORE'
        poiMarker.exploreObjectData = exploreObject
        self.searchResultOverlayList.push(poiMarker)
        exploreObject.exploreResultOverlayIndex = self.searchResultOverlayList.length - 1

        // if (index === 0) {
        //   firstLocation = poiMarker.location()
        // }

        const poiIconMarker = new window.longdo.Marker({
          lat: exploreObject.lat,
          lon: exploreObject.lon
        }, {
          popup: null,
          clickable: false,
          // icon: {
          //   url: exploreObject.markicon,
          //   urlHd: exploreObject.markicon2x
          // }
          // offset: {
          //   x: (Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH) / 2),
          //   y: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
          // },
          // size: {
          //   width: process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH,
          //   height: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
          // }
          icon: {
            html: `
              <div class="explore-icon-marker">
                <img
                  src="${exploreObject.markicon2x}"
                  srcset="${exploreObject.markicon} 1x, ${exploreObject.markicon2x} 2x"
                >
              </div>
            `,
            offset: (this.$route.query.map || 'v3') === 'v3' ? {
              x: 0,
              y: 0
            } : {
              x: 13,
              y: 13
            }
          }
        })
        poiIconMarker.searchType = 'EXPLORE'
        poiIconMarker.exploreObjectData = exploreObject
        self.searchResultOverlayList.push(poiIconMarker)
        exploreObject.exploreResultOverlayIndex = self.searchResultOverlayList.length - 1

        // order การ add marker มีผลในการแสดงการซ้อนทับต่างกัน ต่างกันระหว่าง v2 และ 3
        if (!self.isLongdoMapV3) {
          self.map.Overlays.add(poiMarker)
          self.map.Overlays.add(poiIconMarker)
        } else {
          self.map.Overlays.add(poiIconMarker)
          self.map.Overlays.add(poiMarker)
        }
      })
      if (boundingLocationList.length > 0) {
        const explore = document.getElementById('explore-panel')
        const exploreTag = document.getElementById('explore-panel-tag-list')
        const isDesktop = window.innerWidth > 992
        if (self.isLongdoMapV3) {
          self.map.bound(window.longdo.Util.locationBound(boundingLocationList), {
            padding: {
              top: 70, left: isDesktop ? 70 + explore.clientWidth : 70, right: 70, bottom: isDesktop ? 70 : 70 + explore.clientHeight + exploreTag.clientHeight
            }
          })
        } else {
          self.map.bound(window.longdo.Util.locationBound(boundingLocationList))
        }
      }
    },
    onBeforeExploreMore () {
      const self = this
      self.setIsTracking(false)
    },
    onExploreMoreResult (result) {
      const self = this
      self.onExploreResult(result, true)
    },
    onClickExploreResult (exploreResult, showPlaceDetail) {
      const self = this
      self.searchResultOverlayList.forEach(exploreResultOverlay => {
        if (exploreResultOverlay instanceof window.longdo.Marker) {
          if (exploreResultOverlay.highlightExploreMarker) {
            self.map.Overlays.remove(exploreResultOverlay)
          } else {
            exploreResultOverlay.element().classList.remove('focus')
          }
        }
      })

      const explore = document.getElementById('explore-panel')
      const exploreTag = document.getElementById('explore-panel-tag-list')
      const isDesktopV3 = window.innerWidth > 992 && self.isLongdoMapV3
      const location = {
        lat: exploreResult.lat,
        lon: exploreResult.lon
      }
      const currentBoundingBox = self.map.bound()
      const topInDegree = (currentBoundingBox.maxLat - currentBoundingBox.minLat) * (isDesktopV3 ? 50 + 42 : 14 + 42) / self.map.placeholder().clientHeight
      const bottomInDegree = (currentBoundingBox.maxLat - currentBoundingBox.minLat) * (isDesktopV3 ? 30 + 13 : 60 + explore.clientHeight + exploreTag.clientHeight) / self.map.placeholder().clientHeight
      const leftInDegree = (currentBoundingBox.maxLon - currentBoundingBox.minLon) * (isDesktopV3 ? 48 + explore.clientWidth : 48) / self.map.placeholder().clientWidth
      const rightInDegree = (currentBoundingBox.maxLon - currentBoundingBox.minLon) * (isDesktopV3 ? 100 : 48) / self.map.placeholder().clientWidth

      const isContain = window.longdo.Util.contains(location, [
        { lat: currentBoundingBox.maxLat - topInDegree, lon: currentBoundingBox.minLon + leftInDegree },
        { lat: currentBoundingBox.maxLat - topInDegree, lon: currentBoundingBox.maxLon - rightInDegree },
        { lat: currentBoundingBox.minLat + bottomInDegree, lon: currentBoundingBox.maxLon - rightInDegree },
        { lat: currentBoundingBox.minLat + bottomInDegree, lon: currentBoundingBox.minLon + leftInDegree }
      ])

      if (self.$route.name !== 'Nearby' && !isContain) {
        self.map.location(location)
      }
      self.searchResultOverlayList.forEach(exploreResultOverlay => {
        if (exploreResultOverlay instanceof window.longdo.Marker && 'exploreObjectData' in exploreResultOverlay) {
          if (exploreResultOverlay.exploreObjectData.id === exploreResult.id) {
            exploreResultOverlay.element().classList.add('focus', 'no-filter')
            if (showPlaceDetail) {
              self.map.Event.fire('overlayClick', exploreResultOverlay)
            }
          }
        }
      })
      if (self.$route.name === 'Nearby') {
        if (self.nearbyDataFeature.distanctLine) {
          self.map.Overlays.remove(self.nearbyDataFeature.distanctLine)
        }

        const nearbyAndCurrentLocationList = [
          location,
          self.exploreNearbyCenter
        ]
        const displacementResult = window.longdo.Util.distance(nearbyAndCurrentLocationList)
        const displayDisplacement = displacementResult <= 1000
          ? (displacementResult.toLocaleString('th', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) + ` ${self.$t('main.metre')}`)
          : ((displacementResult / 1000.0).toLocaleString('th', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + ` ${self.$t('main.km')}`)

        const distanctLine = new window.longdo.Polyline(nearbyAndCurrentLocationList, {
          label: displayDisplacement,
          lineStyle: window.longdo.LineStyle.Dot,
          lineColor: 'rgb(56, 148, 238)',
          pivot: {
            lat: (nearbyAndCurrentLocationList[0].lat + nearbyAndCurrentLocationList[1].lat) * 0.5,
            lon: (nearbyAndCurrentLocationList[0].lon + nearbyAndCurrentLocationList[1].lon) * 0.5
          }
        })
        self.map.Overlays.add(distanctLine)
        self.nearbyDataFeature.distanctLine = distanctLine
        if (!isContain) {
          self.map.bound(window.longdo.Util.locationBound([
            { lat: nearbyAndCurrentLocationList[0].lat + topInDegree, lon: nearbyAndCurrentLocationList[0].lon + leftInDegree },
            { lat: nearbyAndCurrentLocationList[0].lat - bottomInDegree, lon: nearbyAndCurrentLocationList[0].lon - rightInDegree },
            { lat: nearbyAndCurrentLocationList[1].lat + topInDegree, lon: nearbyAndCurrentLocationList[1].lon + leftInDegree },
            { lat: nearbyAndCurrentLocationList[1].lat - bottomInDegree, lon: nearbyAndCurrentLocationList[1].lon - rightInDegree }
          ]), nearbyAndCurrentLocationList[1])
        }
      }
    },
    onShowTag (tagName) {
      const self = this
      if (self.lastTagName !== null && self.lastTagName !== tagName) {
        self.map.Tags.remove(self.lastTagName)
      }
      self.map.Tags.add(tagName)
      self.lastTagName = tagName
      if ('tag' in self.$route.query && 'lat' in self.$route.query && 'lon' in self.$route.query) {
        self.map.location({
          lat: self.$route.query.lat,
          lon: self.$route.query.lon
        })
      }
    },
    onHideTag (tagName) {
      const self = this
      self.map.Tags.remove(tagName)
    },
    clickClearMeasurement () {
      const self = this
      self.map.Ui.Toolbar.measureList().forEach(m => {
        self.map.Overlays.remove(m)
      })
      self.map.Event.fire('clearMeasure')
    },
    onLoadedEventList (eventList, isMore = false) {
      const self = this
      eventList.forEach(eventObject => {
        const poiMarker = new window.longdo.Marker({
          lat: eventObject.latitude,
          lon: eventObject.longitude
        }, {
          title: self.$i18n.locale === 'en' ? eventObject.title_en : eventObject.title,
          detail: self.$i18n.locale === 'en' ? eventObject.comment_en : eventObject.comment,
          popup: {
            title: `${self.$i18n.locale === 'en' ? eventObject.title_en : eventObject.title}`,
            detail: `
              <div class="ldmap_popup_detail">
                <span class="ldmap_overlay_info">${eventObject.start_time} - ${eventObject.stop_time} ${self.$t('eventPanel.contributor')} ${eventObject.contributor.join(',')}</span>
                <div class="ldmap_overlay_description">
                  <div class="ldmap_overlay_image" style="display: ${eventObject.images.length > 0 ? 'unset' : 'none'};">
                    <a href="${eventObject.images.length > 0 ? eventObject.images[0] : ''}" target="_blank">
                      <img src="${eventObject.images.length > 0 ? (eventObject.images[0] + '/thumbnail') : ''}">
                    </a>
                  </div>
                  ${self.$i18n.locale === 'en' ? eventObject.comment_en : eventObject.comment}
                </div>
              </div>
            `
          },
          icon: {
            html: `<img
              src="${eventObject.icon2x}"
              srcset="${eventObject.icon1x + ' 1x' + eventObject.icon2x + ' 2x'}" loading="lazy"
              style="width: 26px; height: 26px;"
            >`,
            offset: {
              x: 13,
              y: 13
            },
            size: {
              width: 26,
              height: 26
            }
          }
        })
        poiMarker.searchType = 'EVENT'
        poiMarker.eventObjectData = eventObject
        self.searchResultOverlayList.push(poiMarker)
        eventObject.eventResultOverlayIndex = self.searchResultOverlayList.length - 1
        self.map.Overlays.add(poiMarker)
      })
    },
    handleShareEvent (EventId) {
      const self = this
      const url = `${process.env.VUE_APP_LONGDO_TRAFFIC_BASE_EVENT_URL}A${EventId.padStart(8, '0')}`
      if (typeof window.navigator !== 'undefined') {
        if (window.navigator.share) {
          window.navigator.share({
            url: url,
            title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
          })
        } else {
          self.copyText(url)
        }
      } else {
        self.copyText(url)
      }
    },
    async handleCommentEvent (EventId) {
      const self = this
      const url = `${process.env.VUE_APP_LONGDO_TRAFFIC_COMMENT_EVENT_URL}#A${EventId.padStart(8, '0')}`
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank')
      }
    },
    handleEditEvent (EventId) {
      const self = this
      self.isShowReportEventFormPanel = true
      // window.open(`${process.env.VUE_APP_LONGDO_TRAFFIC_PATH_OLD_EVENT_REPORT_FORM}?id=${EventId}`, '_blank')
      self.eventEditMode = EventId
    },
    handleDeleteEvent (EventId) {
      const self = this
      self.confirmDeleteEvent = true
      self.confirmIdEventDelete = EventId
    },
    async deleteEvent () {
      const self = this
      const params = {
        eid: self.confirmIdEventDelete,
        format: 'json',
        token: self.user.longdousertoken,
        username: self.user.mail
      }
      const responseDelete = await self.api.deleteEventTraffic(params)
      if (responseDelete.status === 200) {
        self.isDeletedEventComplete = true
        self.$root.$emit('deleteEventSuccess')
      } else {
        self.isDeletedEventError = true
      }
      setTimeout(() => {
        self.isLoadingDelete = false
      }, 2 * 1000)
    },
    async reportEvent (elId = null) {
      const self = this
      if (!self.user) {
        if (self.isOnMobileApp) {
          let loginResult
          try {
            loginResult = await self.lji.loginLongdo()
          } catch (error) {
            console.log(error)
          }
          if (loginResult?.username && loginResult?.longdoToken) {
            try {
              await self.lji.addStorage({
                key: 'username',
                value: loginResult.username
              })
            } catch (error) {
              console.log(error)
            }
            try {
              await self.lji.addStorage({
                key: 'ldtoken',
                value: loginResult.longdoToken
              })
            } catch (error) {
              console.log(error)
            }
          }
          const user = await self.utility.getUserSession()
          if (user) {
            self.$root.$emit('loginComplete', user)
          } else {
            console.log('error getUserSession')
          }
        } else {
          self.$root.$emit('showLongdoLoginForm')
        }

        self.$root.$on('loginComplete', () => {
          self.isShowReportEventFormPanel = true
          self.showReportEventFormKey += 1
        })
      } else {
        self.isShowReportEventFormPanel = true
        self.showReportEventFormKey += 1
        self.idEventType = elId
      }
    },
    handleSuccessSaveReportEvent () {
      const self = this
      self.isShowReportEventFormPanel = false
    },
    onClickEventRow (event) {
      const self = this
      self.map.location({
        lat: event.latitude,
        lon: event.longitude
      })
    },
    onClickEventInfo (event) {
      const self = this
      self.map.location({
        lat: event.latitude,
        lon: event.longitude
      }, false)
      const marker = self.searchResultOverlayList.filter(o => 'eventObjectData' in o).filter(o => o.eventObjectData.eventId === event.eventId)
      if (marker.length > 0) {
        marker[0].pop(true)
        self.map.Event.fire('overlayClick', marker[0])
      } else {
        self.$router.push({
          name: 'Event',
          params: {
            eventId: event.eventId
          },
          query: {}
        })
      }
    },
    removeEventLocationMarker () {
      const self = this
      if (self.searchLocationMarker !== null) {
        if (self.searchLocationMarker.searchType === 'EVENT') {
          self.map.Overlays.remove(self.searchLocationMarker)
          self.searchLocationMarker = null
        }
      }
    },
    removeEventResultOverlay () {
      const self = this
      self.searchResultOverlayList.forEach(searchResultOverlay => {
        if (searchResultOverlay instanceof window.longdo.Marker) {
          if (searchResultOverlay.searchType === 'EVENT') {
            self.map.Overlays.remove(searchResultOverlay)
          }
        }
      })
      self.searchResultOverlayList = self.searchResultOverlayList.filter(searchResultOverlay => {
        return searchResultOverlay.searchType !== 'EVENT'
      })
    },
    onClickCheckNearby () {
      const self = this
      self.isShowSelectNearbyTagDialog = true
      self.$router.push({
        hash: '#nearbytagdialog'
      })
    },
    onCloseSelectNearbyTagDialog () {
      const self = this
      self.isShowSelectNearbyTagDialog = false
      self.$router.back()
    },
    checkNearby (center, isShowNearbyDialog) {
      const self = this
      self.isFirstCheckNearby = true
      self.removeCheckNearby()
      self.showCheckNearby({
        center: center,
        isShowNearbyDialog: isShowNearbyDialog
      })
      self.exploreNearbyCenter = center
    },
    removeCheckNearby () {
      const self = this
      if (self.nearbyDataFeature.centerMarker) {
        self.map.Overlays.remove(self.nearbyDataFeature.centerMarker)
      }
      if (self.nearbyDataFeature.nearbyPlace) {
        self.map.Overlays.remove(self.nearbyDataFeature.nearbyPlace.marker)
      }
      if (self.nearbyDataFeature.circle1km) {
        self.map.Overlays.remove(self.nearbyDataFeature.circle1km)
      }
      if (self.nearbyDataFeature.circle5km) {
        self.map.Overlays.remove(self.nearbyDataFeature.circle5km)
      }
      if (self.nearbyDataFeature.distanctLine) {
        self.map.Overlays.remove(self.nearbyDataFeature.distanctLine)
      }
      self.nearbyDataFeature = {
        nearbyMessage: ''
      }
    },
    async showCheckNearby ({ center, isShowNearbyDialog }) {
      const self = this
      const tagName = self.$route.params.tagName || ''
      if (tagName === '') {
        return false
      }

      let tagList = []
      self.tagCategoryList.forEach(c => {
        tagList = tagList.concat(c.icons)
      })
      const tagIndex = self.tagList.map(t => t.tag.toLowerCase()).indexOf(tagName.toLowerCase())
      if (tagIndex === -1) {
        return false
      }
      const tagNameDisplay = self.tagList[tagIndex][self.$i18n.locale === 'en' ? 'name_en' : 'name']

      const params = {
        lat: center.lat,
        lon: center.lon,
        offset: 0,
        limit: 1,
        locale: self.$i18n.locale,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p',
        tag: tagName
      }
      const nearbyResult = await self.api.searchNearbyPlace(params)
      if (((nearbyResult.data || {}).data || []).length > 0) {
        const nearbyPlace = nearbyResult.data.data[0]
        const nearbyAndCurrentLocationList = [
          {
            lat: nearbyPlace.lat,
            lon: nearbyPlace.lon
          },
          {
            lat: center.lat,
            lon: center.lon
          }
        ]
        self.map.bound(window.longdo.Util.locationBound(nearbyAndCurrentLocationList), nearbyAndCurrentLocationList[1])

        const radiusInMetre = 1000 // 2500m or 2.5km
        const metrePerLatInDegree = window.longdo.Util.latitudeLength(nearbyAndCurrentLocationList[1].lat)
        const radiusInDegree = (radiusInMetre * 1.0) / metrePerLatInDegree

        const radiusInMetre10km = 5000 // 2500m or 2.5km
        const metrePerLatInDegree10km = window.longdo.Util.latitudeLength(nearbyAndCurrentLocationList[1].lat)
        const radiusInDegree10km = (radiusInMetre10km * 1.0) / metrePerLatInDegree10km

        const circle1km = new window.longdo.Circle(nearbyAndCurrentLocationList[1], radiusInDegree, {
          title: `รัศมี 1 ${self.$t('main.km')} จาก${tagNameDisplay}`,
          // label: `1 ${self.$t('main.km')}`,
          detail: '',
          lineWidth: 1,
          lineColor: 'rgba(255, 165, 0, 0.6)',
          fillColor: 'rgba(255, 165, 0, 0.3)',
          pivot: {
            lat: nearbyAndCurrentLocationList[1].lat,
            lon: nearbyAndCurrentLocationList[1].lon + 1000.0 / window.longdo.Util.longitudeLength(nearbyAndCurrentLocationList[1].lat)
          }
        })

        const circle5km = new window.longdo.Circle(nearbyAndCurrentLocationList[1], radiusInDegree10km, {
          title: `รัศมี 1-5 ${self.$t('main.km')} จาก${tagNameDisplay}`,
          // label: `1-5 ${self.$t('main.km')}`,
          detail: '',
          lineWidth: 1,
          lineColor: 'rgba(255, 165, 0, 0.4)',
          fillColor: 'rgba(255, 165, 0, 0.2)',
          pivot: {
            lat: nearbyAndCurrentLocationList[1].lat,
            lon: nearbyAndCurrentLocationList[1].lon + 5000.0 / window.longdo.Util.longitudeLength(nearbyAndCurrentLocationList[1].lat)
          }
        })

        self.map.Overlays.add(circle1km)
        self.map.Overlays.add(circle5km)

        nearbyPlace.marker = new window.longdo.Marker(
          nearbyAndCurrentLocationList[0],
          {
            icon: {
              html: `
                <div class="nearby-place-marker">
                  <img
                    src="${self.tagList[tagIndex].imagefile}"
                    srcset="${self.tagList[tagIndex].imagefile.replace('/icons_2x/', '/icons/')} 1x, ${self.tagList[tagIndex].imagefile} 2x"
                  >
                </div>
              `,
              offset: {
                x: 13,
                y: 13
              }
            }
          }
        )
        self.map.Overlays.add(nearbyPlace.marker)

        const displacementResult = window.longdo.Util.distance(nearbyAndCurrentLocationList)
        const displayDisplacement = displacementResult <= 1000
          ? (displacementResult.toLocaleString('th', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) + ` ${self.$t('main.metre')}`)
          : ((displacementResult / 1000.0).toLocaleString('th', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + ` ${self.$t('main.km')}`)

        const distanctLine = new window.longdo.Polyline(nearbyAndCurrentLocationList, {
          label: displayDisplacement,
          lineStyle: window.longdo.LineStyle.Dot,
          lineColor: 'rgb(56, 148, 238)',
          pivot: {
            lat: (nearbyAndCurrentLocationList[0].lat + nearbyAndCurrentLocationList[1].lat) * 0.5,
            lon: (nearbyAndCurrentLocationList[0].lon + nearbyAndCurrentLocationList[1].lon) * 0.5
          }
        })
        self.map.Overlays.add(distanctLine)

        self.nearbyDataFeature = {
          nearbyPlace,
          circle1km,
          circle5km,
          distanctLine,
          nearbyMessage: `คุณอยู่ห่างจาก${tagNameDisplay}<br>${displayDisplacement}`
        }

        if (!window.longdo.Util.sameLocation(center, window.currentLocation)) {
          const centerMarker = new window.longdo.Marker(center)
          self.map.Overlays.add(centerMarker)
          self.nearbyDataFeature.centerMarker = centerMarker
        }

        if (isShowNearbyDialog === null || isShowNearbyDialog === undefined) {
          isShowNearbyDialog = true
        }
        self.isShowNearbyDialog = isShowNearbyDialog
      }
    },
    onChangePlaceCardStep (step) {
      const self = this
      self.placeCardStep = step
      self.resizeMapHeightAnimate()
    }
  },
  watch: {
    '$route' (to, from) {
      const self = this
      self.$nextTick(async () => {
        if (to.name === 'Main' && JSON.stringify(to.query) === '{}' && JSON.stringify(to.params) === '{}') {
          self.removeSearchLocationMarker()
          self.removeSearchResultOverlay()
          self.$emit('clearSearch')
        }
        if (to.name === 'Main' && !('search' in to.query)) {
          self.removeSearchLocationMarker()
          self.removeSearchResultOverlay()
          self.isShowingPopup = false
          self.searchResultList = null
          self.$emit('clearSearch')
        }
        if (from.hash === '#nearbytagdialog') {
          self.isShowSelectNearbyTagDialog = false
        }
        if (from.name === 'Main' && from.hash === '#explore') {
          self.removeSearchLocationMarker()
          self.removeSearchResultOverlay()
          self.$emit('clearSearch')
        }
        if (from.name === 'Main' && from.hash === '#events' && to.name !== 'Event') {
          self.removeEventLocationMarker()
          self.removeEventResultOverlay()
          self.$emit('clearSearch')
        }
        const showFavorites = localStorage.showFavorites ? (localStorage.showFavorites === 'true') : true
        const showCreated = localStorage.showCreated ? (localStorage.showCreated === 'true') : true
        if (to.name === 'Main' && to.hash === '#my-places') {
          let userSession = await self.utility.getUserSession()
          userSession = userSession || self.user
          self.isFromClickMyPlace = false
          if (!userSession) {
            self.isFromClickMyPlace = true
            if (self.isOnMobileApp) {
              let loginResult
              try {
                loginResult = await self.lji.loginLongdo()
                // {<{username: String, longdoToken: String}>} result
              } catch (error) {
                // {<{code: Number, message: String}>} error
                console.log(error)
              }
              if (loginResult?.username && loginResult?.longdoToken) {
                try {
                  await self.lji.addStorage({
                    key: 'username',
                    value: loginResult.username
                  })
                  // {Boolean} result
                } catch (error) {
                  // {<{code: Number, message: String}>} error
                  console.log(error)
                }
                try {
                  await self.lji.addStorage({
                    key: 'ldtoken',
                    value: loginResult.longdoToken
                  })
                  // {Boolean} result
                } catch (error) {
                  // {<{code: Number, message: String}>} error
                  console.log(error)
                }
              }
              const user = await self.utility.getUserSession()
              if (user) {
                self.$root.$emit('loginComplete', user)
              } else {
                console.log('error getUserSession')
              }
            } else {
              self.$root.$emit('showLongdoLoginForm')
            }
          } else {
            self.$emit('showMyPlace')
            if ((!showFavorites && showCreated) || (showFavorites && !showCreated) || (!showFavorites && !showCreated)) { // froce show fav pin on #my-places url path
              self.removeMyPlaceLocationMarker()
              self.removeMyPlaceResultOverlay()
              self.drawMyPlace()
            }
          }
          const miniPopup = self.map.placeholder().querySelector('.ldmap_popup_mini')
          if (miniPopup) {
            miniPopup.style.display = 'none'
          }
          self.isShowingPopup = false
        }
        if (from.hash === '#events') {
          self.$emit('hideEvent')
        }
        if (from.hash === '#my-places') {
          self.$emit('hideMyPlace')
          self.clearFocusMarker()
          self.removeMyPlaceLocationMarker()
          self.removeMyPlaceResultOverlay()
          self.drawMyPlace(showFavorites, showCreated)
        }
        if (from.hash === '#cam') {
          self.map.Overlays.list().forEach(o => {
            if (o instanceof window.longdo.Marker && 'data' in o) {
              if ('camid' in o.data) {
                o.popup().closeButton.click()
              }
            }
          })
        }
        if (to.name === 'Main' && ('search' in to.query) && from.name === 'Place') {
          if (window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null) {
            window.document.querySelector('.ldmap_popup.ldmap_selectable > .ldmap_popup_close').click()
          }
        }
        if (to.name === 'Main' && from.name === 'AqiAir4Thai') {
          if (window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null) {
            window.document.querySelector('.ldmap_popup.ldmap_selectable > .ldmap_popup_close').click()
            self.clearFocusMarker()
          }
        }
        if (to.name === 'Main' && from.name === 'AqiCN') {
          if (window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null) {
            window.document.querySelector('.ldmap_popup.ldmap_selectable > .ldmap_popup_close').click()
            self.clearFocusMarker()
          }
        }
        if (to.name === 'Main' && from.name === 'Rainfall') {
          if (window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null) {
            window.document.querySelector('.ldmap_popup.ldmap_selectable > .ldmap_popup_close').click()
            self.clearFocusMarker()
          }
        }
        if (to.name === 'Main' && from.name === 'Event') {
          if (window.document.querySelector('.ldmap_popup.ldmap_selectable') !== null) {
            window.document.querySelector('.ldmap_popup.ldmap_selectable > .ldmap_popup_close').click()
          }
        }
        if (to.name === 'Main' && ('search' in to.query)) {
          self.isShowingMapOnly = false
          self.$emit('clickMap', self.isShowingMapOnly)
        }
        if (to.hash === '#explore') {
          self.isShowingMapOnly = false
          self.$emit('clickMap', self.isShowingMapOnly)
        }
        if (to.hash === '#events') {
          self.isShowingMapOnly = false
          self.$emit('clickMap', self.isShowingMapOnly)
          self.$emit('showEvent', from.name !== 'Event')
        }
        if (to.name === 'Nearby' && (to.params.tagName || '') !== (from.params.tagName || '')) {
          self.checkNearby(
            from.name === 'Place' ? self.exploreNearbyCenter : self.map.location(),
            from.name !== 'Place'
          )
        }
        if (to.name !== 'Nearby') {
          self.removeCheckNearby()
        }
        if (to.name === 'Place') {
          // use nexttick to prevent race condition of placecard
          self.$nextTick(() => {
            // CLICK BY Cluster
            // if reverse geocoding we draw every time
            if (self.$route.params.poiId.slice(0, 4) === 'RGCP') {
              self.removeSearchResultOverlay()
              self.drawReverseGeocoding()
            } else if (from.params.poiId !== to.params.poiId) {
              self.removeSearchResultOverlay()
              const isPlaceNormal = () => {
                return (self.$route.params.poiId[0] === 'A' ||
              self.$route.params.poiId.indexOf('H0') === 0 ||
              self.$route.params.poiId.indexOf('OSM:P') === 0 ||
              self.$route.params.poiId.indexOf('OSM:L') === 0 ||
              self.$route.params.poiId.indexOf('OSM:G') === 0 ||
              self.$route.params.poiId.indexOf('OVM:P') === 0)
              }
              if (isPlaceNormal()) {
                self.drawPoi(self.$route.params.poiId)
                if (self.map.Ui.popup) {
                  self.map.Ui.popup.visible(false)
                }
              }
              if (self.$route.params.poiId[0] === 'K') {
                self.drawKhet(self.$route.params.poiId)
              } else if (self.$route.params.poiId[0] === 'L') {
                self.drawRoad(self.$route.params.poiId)
              } else if (self.$route.params.poiId.slice(0, 3) === 'GCP') {
                self.drawGeocoding()
              } else if (self.$route.params.poiId[0] === 'G') {
                self.drawGeom(self.$route.params.poiId)
              } else if (self.$route.params.poiId[0] === 'Y') {
                self.drawLayer(self.$route.params.poiId)
              } else if (self.$route.params.poiId[0] === 'X') {
                self.drawWaterLine(self.$route.params.poiId)
              } else if (self.$route.params.poiId[0] === 'W') {
                self.drawWaterArea(self.$route.params.poiId)
              } else if (self.$route.params.poiId[0] === 'S') {
                self.drawBusRoute(self.$route.params.poiId)
              } else if (self.$route.params.poiId[0] === 'B') {
                self.drawB(self.$route.params.poiId)
              }
            }
          })
        }
      })
    },
    '$i18n.locale' () {
      const self = this
      self.$nextTick(() => {
        if (!self.isLongdoMapV3 && self.map) {
          self.map.language(self.$i18n.locale)
        }
      })
    },
    isRealLocation (value) {
      const self = this
      // case get location slower than loading map
      if (value && (self.islongdoMap3Ready || self.islongdoMapReady)) {
        if (self.isOnMobileApp && self.isRealLocation && window.currentLocation &&
        self.$route.name === 'Main' && self.$route.hash === '' &&
        JSON.stringify(self.$route.query) === '{}' && JSON.stringify(self.$route.params === '{}')) {
          self.map.location(window.currentLocation, !self.isLongdoMapV3)
          if (self.map.zoom() < 16) {
            self.map.zoom(16, !self.isLongdoMapV3)
          }
        }
      }
    },
    isCheckingAccidentBlackSpot (newVal) {
      const self = this
      if (newVal) {
        const zoom = localStorage.showSpeedCameraLevel ? parseInt(localStorage.showSpeedCameraLevel) : 9
        self.map.Tags.add('accident_black_spot', {
          label: true,
          visibleRange: {
            min: zoom
          }
        })
      } else {
        self.map.Tags.remove('accident_black_spot')
      }
    },
    isCheckingSpeedCamera (newVal) {
      const self = this
      if (newVal) {
        const zoom = localStorage.showSpeedCameraLevel ? parseInt(localStorage.showSpeedCameraLevel) : 9
        self.map.Tags.add('speed_camera', {
          label: true,
          visibleRange: {
            min: zoom
          }
        })
      } else {
        self.map.Tags.remove('speed_camera')
      }
    },
    isShowingPopup (newIsShowingPopup) {
      const self = this
      if (self.$route.hash !== '#cam') {
        self.$nextTick(self.resizeMapHeightAnimate)
      }
    },
    isGeolocationMobileAppDisabled (value) {
      const self = this
      if (value) {
        if (self.isTracking) {
          self.setIsTracking(false)
        }
        if (self.$route.hash === '#explore') {
          self.$root.$emit('changeHidingExplorePanel', true)
          self.$router.replace({
            hash: ''
          })
        }
      }
    }
  },
  beforeDestroy () {
    const self = this
    self.$root.$off('loginFormClose')
    self.$root.$off('loginComplete')
    self.$root.$off('logoutComplete')
    self.$root.$off('froceDoNotShowingMapOnly')
    self.$root.$off('changeShowMyPlaces')
    self.$root.$off('changeShowMyPlacesName')
    self.$root.$off('changeHidingExplorePanel')
    self.$root.$off('changeExpandExplorePanel')
    self.$root.$off('changeHidingSearchResultPanel')
    self.$root.$off('changeExpandSearchResultPanel')
    self.$root.$off('changeHidingMyPlacePanel')
    self.$root.$off('changeExpandMyPlacePanel')
    self.$root.$off('changeHidingExploreNearbyPanel')
    self.$root.$off('changeExpandExploreNearbyPanel')
    self.$root.$off('changeHidingEventPanel')
    self.$root.$off('changeExpandEventPanel')
  }
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;

  @media only screen and (min-width: $mobileMaxSize) {
    a {
      cursor: pointer !important;
    }
  }

  .maplibregl-ctrl-top-left .maplibregl-ctrl {
    margin: 0 !important;
  }

  >.longdo-map-content {
    width: 100%;
    // height: calc(100% - #{$toolBarHeight});
    height: 100%;
    transition: height 0.3s ease 0s;

    &::v-deep .ldmap_placeholder .ldmap_bottomright {
      transition: bottom 0.3s ease 0s,
        padding-right 0.3s ease 0s;
      bottom: calc(#{$toolBarHeight} + 6px) !important;
    }

    &::v-deep .ldmap_placeholder .ldmap_bottomleft {
      transition: bottom 0.3s ease 0s;
      bottom: calc(#{$toolBarHeight} + 6px) !important;
    }

    &.showing-explore-panel {
      height: 60%;

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: calc(38px + 36px + 6px + 6px) !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.collapsing-explore-panel {
      height: calc(100% - 34px - #{$toolBarHeight});

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.showing-search-result-panel {
      height: 60%;

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.collapsing-search-result-panel {
      height: calc(100% - 34px - #{$toolBarHeight});

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.showing-my-place-panel {
      height: 60%;

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.collapsing-my-place-panel {
      height: calc(100% - 34px - #{$toolBarHeight});

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.showing-explore-nearby-panel {
      height: 60%;

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: calc(38px + 6px) !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.collapsing-explore-nearby-panel {
      height: calc(100% - 34px - #{$toolBarHeight});

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.showing-event-panel {
      height: 60%;

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.collapsing-event-panel {
      height: calc(100% - 34px - #{$toolBarHeight});

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.showing-popup {
      height: calc(70% - #{$toolBarHeight});

      &.place-card-step-mini {
        height: calc(100% - 72px - #{$toolBarHeight});
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.only-one {
      height: 100%;

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }

      &::v-deep .maplibregl-ctrl-bottom-left.mapboxgl-ctrl-bottom-left,
      &::v-deep .maplibregl-ctrl-bottom-left {
        bottom: 0px !important;
      }

      &::v-deep .maplibregl-ctrl-bottom-right.mapboxgl-ctrl-bottom-right,
      &::v-deep .maplibregl-ctrl-bottom-right {
        bottom: 0px !important;
      }
    }

    &.only-one.showing-popup {
      height: 70%;

      &.place-card-step-mini {
        height: calc(100% - 72px - #{$toolBarHeight});
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: 6px !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: 6px !important;
      }
    }

    &.routing {
      &::v-deep .ldmap_placeholder .ldmap_bottomright {
        bottom: calc(#{$toolBarHeight} + 6px) !important;
      }

      &::v-deep .ldmap_placeholder .ldmap_bottomleft {
        bottom: calc(#{$toolBarHeight} + 6px) !important;
      }
    }
  }

  >button.main-menu {
    position: fixed;
    top: 12px;
    left: 12px;
    z-index: 150;
    border: none;
    background-color: transparent;
    width: calc(54px - 6px);
    height: $searchInput;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    outline: none;
    transition: top 0.3s ease 0s;
    box-sizing: border-box;
    padding: 0px;

    >i {
      color: $grey;
      font-size: 24px;
    }
  }

  @media only screen and (min-width: $mobileMaxSize) {
    button.ldmap-toggle {
      top: 50px !important;
    }
  }

  // > button.ldmap-toggle.hide {
  //   // top: calc(0px - #{$searchInput} - 12px) !important;
  // }

  >button.main-menu.hide {
    top: calc(0px - #{$searchInput} - 12px);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .copied {
      position: absolute;
      color: white;
      background-color: rgba(0, 0, 0, 0.75);
      width: fit-content;
      border-radius: 16px;
      // width: 75px;
      width: max-content;
      padding: 0px 10px;
      height: 32px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 50%;
      bottom: 65px;
      transform: translate(-50%, 0%);
      font-family: 'Prompt';
      z-index: 200;
    }
  .deleted-event-complete {
    width: auto;
    padding: 10px;
  }
}
.traffic {
  .main {
    >.longdo-map-content {
      &.collapsing-explore-panel {
        &::v-deep .ldmap_placeholder .ldmap_bottomright {
          padding-right: 64px !important;
        }
      }

      &.collapsing-search-result-panel {
        &::v-deep .ldmap_placeholder .ldmap_bottomright {
          padding-right: 64px !important;
        }
      }

      &.showing-popup:not(.only-one) {
        &::v-deep .ldmap_placeholder .ldmap_bottomright {
          padding-right: 64px !important;
        }
      }
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 250;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);

  .error-message,
  .success-message {
    font-family: 'Prompt';
    font-size: 16px;
    padding: 12px;
    text-align: center;
    color: rgb(255, 255, 255);
    max-width: 230px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
  }
}

</style>
