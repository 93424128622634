<template>
  <div class="explore-nearby-panel"
    :class="{
      'hide': isHidingExploreNearbyPanel,
      'expand-less': !isExpand
    }"
  >
    <div class="tag-list flash">
      <template v-for="flashExplore in flashExploreList">
        <button :key="'flash-tag-explore-' + flashExplore.name"
          @click="selectTagFilter(flashExplore.name)"
          class="ripple"
          :class="{active: tagFilterList.map(t => t.toLowerCase()).indexOf(flashExplore.name.toLowerCase()) !== -1}"
        >
          <img :src="flashExplore.icon.x2"
            :srcset="flashExplore.icon.x1 + ' 1x, ' + flashExplore.icon.x2 + ' 2x'"
          >
          {{ flashExplore.displayName[$i18n.locale] }}
        </button>
      </template>
      <button class="ripple" @click="clickMoreTag">{{ $t('explorePanel.other') }}</button>
    </div>
    <button class="filter-tag-name ripple"
      v-if="isShowingMoreTagList"
      :class="{active: isActiveFilterTagName}"
      @click="onClickFilterTagNameIcon"
    >
      <i class="material-icons-round">search</i>
    </button>
    <div class="filter-tag-name-input-container"
      v-if="isShowingMoreTagList"
      :class="{active: isActiveFilterTagName}"
    >
      <button class="clear ripple"
        v-if="filterTagName !== '' && isActiveFilterTagName"
        @click.stop="filterTagName = ''"
      >
        <i class="material-icons-round">clear</i>
      </button>
      <input ref="filter-tag-name-input" type="text" v-model="filterTagName" :placeholder="$t('explorePanel.filterTagName')">
    </div>
    <button class="close-tag-category-list ripple"
      v-if="isShowingMoreTagList"
      @click.stop="isShowingMoreTagList = false"
    >
      <i class="material-icons-round">clear</i>
    </button>
    <div class="tag-category-list"
      v-show="isShowingMoreTagList"
      :class="{'active-filter-tag-name': isActiveFilterTagName}"
    >
      <template v-for="tagCategory in tagCategoryList">
        <div class="tag-category"
          :key="'more-tag-category-' + tagCategory.category_id"
          v-show="tagCategory.icons.filter(tag => {
            return tag.name.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0 || tag.name_en.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0
          }).length > 0"
        >
          <label>
            {{ tagCategory['category_name_' + $i18n.locale] }}
          </label>
          <div class="tag-list">
            <template v-for="tag in tagCategory.icons">
              <button class="tag ripple"
                :key="'more-tag-' + tag.id"
                :class="{active: tagFilterList.map(t => t.toLowerCase()).indexOf(tag.tag.toLowerCase()) !== -1}"
                @click="selectTagFilter(tag.tag)"
                v-show="tag.name.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0 || tag.name_en.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0"
                v-if="tag[$i18n.locale === 'th' ? 'name' : 'name_en'] !== ''"
              >
                <img :src="tag.imagefile"
                  :srcset="tag.imagefile.replace('/icons_2x/', '/icons/') + ' 1x, ' + tag.imagefile + ' 2x'"
                  loading="lazy"
                >
                {{ tag[$i18n.locale === 'th' ? 'name' : 'name_en'] }}
              </button>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="explore-container">
      <div class="top-bar">
        <button class="expand less ripple"
          v-if="isExpand"
          @click="clickExpandLess"
        >
          <i class="material-icons-round">expand_more</i>
        </button>
        <button class="expand more ripple"
          v-if="!isExpand"
          @click="clickExpandMore"
        >
          <i class="material-icons-round">expand_less</i>
        </button>
      </div>
      <div class="loading-container" v-if="showLoading">
        <img src="@/assets/img/loading.gif">
      </div>
      <div class="empty-container" v-if="!showLoading && exploreResultList !== null && exploreResultList.length === 0">
        {{ $t('explorePanel.empty') }}
      </div>
      <div ref="explore-result-list"
        class="explore-result-list"
      >
        <template v-for="(exploreResult, index) in exploreResultList">
        <div :key="index + '-' + exploreResult.id"
          class="explore-result ripple"
          @click="clickExploreResult(exploreResult)"
        >
          <div class="icon">
            <img :src="exploreResult.markicon2x" :srcset="exploreResult.markicon + ' 1x, ' + exploreResult.markicon2x + ' 2x'" loading="lazy">
          </div>
          <div class="detail">
            <label class="name" :title="exploreResult.name">{{ exploreResult.name }}</label>
            <label class="short-description" :title="exploreResult.shortdesc">{{ exploreResult.address }}</label>
          </div>
          <!-- <label class="distance">{{ exploreResult.distance }}</label> -->
          <a class="info"
            @click.stop="clickExploreResultInfo(exploreResult)"
          >
            <i class="material-icons-round">info</i>
          </a>
        </div>
        </template>
        <div class="loading-more-container" v-if="showLoadingMore">
          <img src="@/assets/img/loading.gif">
        </div>
        <button v-if="hasMore && !showLoadingMore"
          :title="$t('explorePanel.moreTitle')"
          @click="clickHasMore"
          class="ripple"
        >
          {{ $t('explorePanel.more') }}
        </button>
        <a
          @click="clickCreatePlace"
          class="add-place"
          v-if="product === 'map' && !showLoading && !showLoadingMore && !(!showLoading && exploreResultList !== null && exploreResultList.length === 0) && !isDisableLoginFeature"
        >
          {{ $t('explorePanel.addNewPlace') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExploreNearbyPanel',
  props: {
    center: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      isExpand: true,
      exploreResultList: null,
      showLoading: false,
      showLoadingMore: false,
      hasMore: false,
      exploreLocation: window.currentLocation,
      offset: 0,
      limit: 20,
      cacheData: [],
      tagFilterList: [],
      isShowingMapOnly: false,
      isShowingMoreTagList: false,
      flashExploreList: [],
      isActiveFilterTagName: false,
      filterTagName: ''
    }
  },
  computed: {
    isHidingExploreNearbyPanel () {
      const self = this
      return self.$route.name !== 'Nearby' || (self.$route.name === 'Nearby' && self.$route.hash !== '') || self.isShowingMapOnly
    },
    isDisableLoginFeature () {
      const self = this
      if (self.lji === null) {
        return false
      }
      return self.lji.Util.isIosNativeApp()
    }
  },
  async created () {
    const self = this
    const tagName = self.$route.params.tagName || ''
    self.flashExploreList = (await self.api.getFlashExploreNearbyList()).data
    if (tagName !== '') {
      self.selectTagFilter(tagName.toLowerCase())
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.onClickMap)
    self.$root.$emit('changeHidingExploreNearbyPanel', self.isHidingExploreNearbyPanel)
    self.$root.$emit('changeExpandExploreNearbyPanel', self.isExpand)
  },
  methods: {
    clickCreatePlace () {
      const self = this
      if (self.isLongdoMapV3 && self.product !== 'traffic') {
        window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${localStorage.lat}&lon=${localStorage.lon}`
      } else {
        self.$router.push({
          name: 'CreatePlace',
          params: {},
          query: {
            lat: Number(localStorage.lat),
            lon: Number(localStorage.lon)
          },
          hash: ''
        })
      }
    },
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isShowingMapOnly = isShowingMapOnly
    },
    clickExpandLess () {
      const self = this
      self.isExpand = false
    },
    clickExpandMore () {
      const self = this
      self.isExpand = true
    },
    clickExploreResult (exploreResult) {
      const self = this
      self.$emit('clickExploreResult', exploreResult, false)
    },
    clickExploreResultInfo (exploreResult) {
      const self = this
      self.$emit('clickExploreResult', exploreResult, true)
    },
    clickHasMore () {
      const self = this
      self.exploreMore()
    },
    async explore () {
      const self = this
      if (self.center === null) {
        return false
      }
      self.$emit('beforeExplore')
      self.showLoading = true
      self.exploreLocation = self.center
      self.offset = 0
      const params = {
        lat: self.exploreLocation.lat,
        lon: self.exploreLocation.lon,
        offset: self.offset,
        limit: self.limit,
        locale: self.$i18n.locale,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p'
      }
      if (self.tagFilterList.length > 0) {
        params.tag = self.tagFilterList.join(',')
      }
      const cacheKey = JSON.stringify(params)
      const cacheIndex = self.cacheData.map(c => c.key).indexOf(cacheKey)
      let result
      if (cacheIndex !== -1) {
        result = self.cacheData[cacheIndex].value
      } else {
        result = await self.api.searchNearbyPlace(params)
        result.data.data.forEach(item => {
          item.markicon = process.env.VUE_APP_LONGDO_MAP_ICON + item.icon
          item.markicon2x = process.env.VUE_APP_LONGDO_MAP_HD_ICON + item.icon
          item.distance = window.longdo.Util.distance([
            {
              lat: item.lat,
              lon: item.lon
            },
            self.exploreLocation
          ], self.$i18n.locale)
        })
        self.cacheData.push({
          key: cacheKey,
          value: result
        })
      }
      self.exploreResultList = result.data.data
      self.hasMore = result.data.meta.hasmore
      self.showLoading = false
      self.$refs['explore-result-list'].scrollTop = 0
      self.$emit('exploreResult', result.data)
    },
    async exploreMore () {
      const self = this
      self.$emit('beforeExploreMore')
      self.showLoadingMore = true
      self.offset += self.limit
      const params = {
        lat: self.exploreLocation.lat,
        lon: self.exploreLocation.lon,
        offset: self.offset,
        limit: self.limit,
        locale: self.$i18n.locale,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p'
      }
      if (self.tagFilterList.length > 0) {
        params.tag = self.tagFilterList.join(',')
      }
      const cacheKey = JSON.stringify(params)
      const cacheIndex = self.cacheData.map(c => c.key).indexOf(cacheKey)
      let result
      if (cacheIndex !== -1) {
        result = self.cacheData[cacheIndex].value
      } else {
        result = await self.api.searchNearbyPlace(params)
        result.data.data.forEach(item => {
          item.markicon = process.env.VUE_APP_LONGDO_MAP_ICON + item.icon
          item.markicon2x = process.env.VUE_APP_LONGDO_MAP_HD_ICON + item.icon
          item.distance = window.longdo.Util.distance([
            {
              lat: item.lat,
              lon: item.lon
            },
            self.exploreLocation
          ], self.$i18n.locale)
        })
        self.cacheData.push({
          key: cacheKey,
          value: result
        })
      }
      self.exploreResultList = self.exploreResultList.concat(result.data.data)
      self.hasMore = result.data.meta.hasmore
      self.showLoadingMore = false
      self.$emit('exploreMoreResult', result.data)
    },
    selectTagFilter (tagName) {
      const self = this
      if (!(self.$route.name === 'Nearby' && (self.$route.params.tagName || '') === tagName)) {
        self.$router.push({
          name: 'Nearby',
          params: {
            tagName: tagName
          }
        })
      } else {
        const alreadyHasTag = self.flashExploreList.map(f => f.name.toLowerCase()).indexOf(tagName.toLowerCase()) >= 0
        if (!alreadyHasTag) {
          self.tagCategoryList.forEach(c => {
            c.icons.forEach(t => {
              if (t.tag.toLowerCase() === tagName.toLowerCase()) {
                self.flashExploreList.splice(0, 0, {
                  displayName: {
                    th: t.name,
                    en: t.name_en
                  },
                  icon: {
                    x1: t.imagefile,
                    x2: t.imagefile
                  },
                  name: t.tag
                })
              }
            })
          })
        }
        self.tagFilterList = [tagName]
        self.explore()
      }
    },
    clickMoreTag () {
      const self = this
      self.isShowingMoreTagList = !self.isShowingMoreTagList
    },
    onClickFilterTagNameIcon () {
      const self = this
      self.isActiveFilterTagName = !self.isActiveFilterTagName
      if (self.isActiveFilterTagName) {
        self.$refs['filter-tag-name-input'].focus()
      }
    }
  },
  watch: {
    isHidingExploreNearbyPanel () {
      const self = this
      self.$nextTick(() => {
        if (!self.isHidingExploreNearbyPanel) {
          self.explore()
        } else {
          self.isShowingMoreTagList = false
        }
        self.$root.$emit('changeHidingExploreNearbyPanel', self.isHidingExploreNearbyPanel)
        if (!self.isHidingExploreNearbyPanel && self.isExpand) {
          const el = document.querySelector('.tag-list > button.active')
          if (el) {
            el.scrollIntoView(false)
          }
        }
      })
    },
    isExpand () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeExpandExploreNearbyPanel', self.isExpand)
        if (!self.isHidingExploreNearbyPanel && self.isExpand) {
          const el = document.querySelector('.tag-list > button.active')
          if (el) {
            el.scrollIntoView(false)
          }
        }
      })
    },
    tagFilterList () {
      const self = this
      self.$nextTick(() => {
        const el = document.querySelector('.tag-list > button.active')
        if (el) {
          el.scrollIntoView(false)
        }
      })
    },
    '$route' (to) {
      const self = this
      const tagName = to.params.tagName
      if (to.name === 'Nearby') {
        self.selectTagFilter(tagName)
        self.clickExpandMore()
        self.isShowingMoreTagList = false
      }
    },
    center () {
      const self = this
      self.explore()
    }
  }
}
</script>

<style scoped lang="scss">
$explorePanelHeight: 40%;
$topBarHeight: 32px;

.explore-nearby-panel {
  width: 100%;
  height: $explorePanelHeight;
  border-radius: 12px 12px 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0%;
  left: 0px;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

  > .tag-list {
    position: absolute;
    top: -38px;
    left: 0px;
    width: 100%;
    height: 38px;
    padding: 2px 3px 6px 3px;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    white-space: nowrap;
    transition: top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    > button {
      height: 30px;
      padding: 0px 12px;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      margin: 0px 3px;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      background-color: rgb(255, 255, 255);
      cursor: pointer;
      outline: none;
      user-select: none;
      filter: grayscale(0.85);
      color: rgba(40, 40, 40, 0.7);
      transition: box-shadow 0.3s ease 0s,
        background-color 0.3s ease 0s,
        border 0.3s ease 0s;
      font-family: Prompt;

      > img {
        height: 16px;
        padding: 0px;
        box-sizing: border-box;
        margin-right: 6px;
        opacity: 0.7;
      }
    }

    > button.active {
      color: white;
      filter: grayscale(0);
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
      background-color: $darkPrimary;
      border: 2px solid $primary;

      > img {
        opacity: 1;
        filter: drop-shadow(0px 0px 2px white);
      }
    }
  }

  > .tag-list::-webkit-scrollbar {
    display: none;
  }

  > button.filter-tag-name {
    position: absolute;
    top: calc(12px - 38px);
    right: calc(12px + 38px + 6px);
    width: 36px;
    height: 36px;
    padding: 0px;
    border-radius: 50%;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 4;
    transform: translate(0%, 0%);
    transition: right 0.3s ease 0s;

    > i {
      font-size: 24px;
      color: $grey;
    }

    &.active {
      right: calc(100% - 12px - 36px);
      box-shadow: none;

      i {
        color: $primary;
      }
    }
  }

  > .filter-tag-name-input-container {
    position: absolute;
    top: calc(12px - 38px - 1px);
    right: calc(12px + 38px + 6px);
    width: 0px;
    height: 38px;
    padding: 0px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 3;
    transition: width 0.3s ease 0s;

    &.active {
      width: calc(100% - 12px - 12px - 38px - 6px);

      input {
        padding: 0px 38px 0px 38px;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      }
    }

    input {
      width: 100%;
      height: 100%;
      padding: 0px 0px;
      outline: none;
      font-size: 16px;
      line-height: 36px;
      border: none;
      box-sizing: border-box;
      border-radius: 18px;
    }

    button.clear {
      position: absolute;
      top: 0px;
      right: 0px;
      border: none;
      width: 38px;
      height: 38px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $grey;
      }
    }
  }

  > button.close-tag-category-list {
    position: absolute;
    top: calc(12px - 38px);
    right: 12px;
    width: 36px;
    height: 36px;
    padding: 0px;
    border-radius: 50%;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 4;

    i {
      color: $grey;
    }
  }

  > .tag-category-list {
    width: 100%;
    height: calc(100% - 54px - 3px + 38px + 3px);
    background-color: rgb(255, 255, 255);
    padding: 12px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0px 0px;
    position: absolute;
    top: -38px;
    left: 0px;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    transition: padding-top 0.3s ease 0s;

    &.active-filter-tag-name {
      padding-top: calc(12px + 38px);
    }

    > .tag-category {
      padding: 12px;

      > label {
        font-weight: bold;
        font-family: Prompt;
        font-size: 16px;
      }

      > .tag-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 12px;

        > button.tag {
          height: 30px;
          padding: 6px 12px;
          box-sizing: border-box;
          border: none;
          border-radius: 15px;
          flex: 0 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          margin: 6px;
          box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
          background-color: rgb(255, 255, 255);
          cursor: pointer;
          outline: none;
          user-select: none;
          filter: grayscale(0.85);
          color: rgba(40, 40, 40, 0.7);

          > img {
            max-height: 16px;
            max-width: 16px;
            padding: 0px;
            box-sizing: border-box;
            margin-right: 6px;
            opacity: 0.7;
          }
        }

        > button.tag.active {
          color: white;
          filter: grayscale(0);
          box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
          background-color: $darkPrimary;
          border: 2px solid $primary;

          > img {
            opacity: 1;
            filter: drop-shadow(0px 0px 2px white);
          }
        }
      }
    }
  }

  > .explore-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0px 0px;
    z-index: 1;

    > .top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 3px 0px 3px;
      box-sizing: border-box;
      position: relative;

      > button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        > i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    > .loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    > .empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
      font-size: 16px;
    }

    > .explore-result-list {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight});

      > .explore-result {
        $iconWidth: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 9px 0px;
        cursor: pointer;

        &:first-child {
          padding-top: 0px;
        }

        > .icon {
          width: $iconWidth;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          padding-top: 3px;

          > img {
            max-width: 36px;
            min-width: 16px;
          }
        }

        > .detail {
          display: flex;
          flex-direction: column;
          width: calc(100% - #{$iconWidth} - 36px);

          > .name {
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            font-family: Prompt;
          }

          > .short-description {
            width: 100%;
            font-size: 14px;
            color: $grey;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
          }
        }

        > .distance {
          width: 72px;
          font-size: 14px;
          color: $darkGrey;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
          padding: 0px 6px 0px 6px;
          box-sizing: border-box;
          text-align: right;
          font-family: Prompt;
        }

        > .info {
          width: 36px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          > i {
            color: $lightPrimary;
            font-size: 24px;
          }
        }
      }

      > .loading-more-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 6px;
      }

      a.add-place {
        color: $primary;
        text-decoration: none;
        font-family: Prompt;
        text-align: center;
        margin: 12px auto;
        display: block;
        font-size: 14px;
      }

      > button {
        width: calc(100% - 12px);
        border: none;
        border-radius: 3px;
        font-size: 14px;
        padding: 12px 6px;
        margin: 6px;
        cursor: pointer;
        outline: none;
        color: rgb(255, 255, 255);
        background-color: $primary;
        transition: background-color 0.3s ease 0s;
      }

      > button:hover {
        background-color: $darkPrimary;
      }
    }
  }
}

.explore-nearby-panel.expand-less {
  bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$explorePanelHeight});

  > button.more-tag {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  > .tag-list {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }
}

.explore-nearby-panel.hide {
  bottom: -#{$explorePanelHeight};
  box-shadow: none;

  > button.more-tag {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  > .tag-list {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }
}

.explore-nearby-panel.hide.expand-less {
  bottom: calc(0px - #{$explorePanelHeight} - 12px);
}
</style>
