<template>
    <div class="mobile-enable-permission" :class="{hide: isHiding}">
        <div class="background"></div>
        <div class="card">
            <div class="label">
                <h1>{{ $t('mobilePermissionDialog.title') }}</h1>
                <div>{{ $t('mobilePermissionDialog.description') }}</div>
            </div>
            <div class="button-list">
                <button class="open-setting" @click="onOpenPermissionSetting">{{ $t('mobilePermissionDialog.openSetting') }}</button>
                <button class="cancel" @click="onClose">{{ $t('mobilePermissionDialog.cancel') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MobileEnablePermission',
  data () {
    return {
      isHiding: true
    }
  },
  mounted () {
    const self = this
    self.$root.$on('openMobilePermissionDialog', self.onOpenMobilePermissionDialog)
    self.$root.$on('closeMobilePermissionDialog', self.onCloseMobilePermissionDialog)
  },
  methods: {
    async onOpenMobilePermissionDialog () {
      const self = this
      self.storeEventToNativeAnalytic('open_mobile_permission_dialog')
      if (self.lji.Util.isAndroidNativeApp()) {
        if (window.isGeolocationError402) {
          window.isGeolocationError402 = false
          try {
            const result = await self.lji.openSetting()
            // {Bool} result
            console.log(result)
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
        } else {
          self.$root.$emit('setOnWatchGeolocation')
        }
      } else if (self.lji.Util.isIosNativeApp()) {
        self.isHiding = false
      }
    },
    onCloseMobilePermissionDialog () {
      const self = this
      self.isHiding = true
    },
    async onOpenPermissionSetting () {
      const self = this
      self.storeEventToNativeAnalytic('open_permission_setting')
      if (self.lji.Util.isIosNativeApp()) {
        try {
          const result = await self.lji.openSetting()

          // {Bool} result
          console.log(result)
        } catch (error) {
        // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
      self.isHiding = true
    },
    onClose () {
      const self = this
      self.storeEventToNativeAnalytic('cancel_permission_dialog')
      self.isHiding = true
    }
  }
}
</script>

<style lang="scss" scoped>
    .mobile-enable-permission {
        &.hide {
            display: none;
        }
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999999999;
        width: 100%;
        height: 100%;
        > .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $grey;
            opacity: 0.5;

        }
        > .card {
            border-radius: 24px;
            background: white;
            z-index: 20;
            max-width: 75vw;

            > .label {
                padding: 24px;
                text-align: center;
            }
            > .button-list {
                width: 100%;
                display: flex;
                align-items: center;
                > button {
                    padding: 12px;
                    width: 100%;
                    background-color: white;
                    border-color: transparent;
                    color: $primary;
                    min-width: max-content;

                    &.open-setting {
                        border-end-start-radius: 24px;
                        border-top: 2px solid $extraLightGrey;
                        border-right: 1px solid $extraLightGrey;
                    }
                    &.cancel {
                        border-top: 2px solid $extraLightGrey;
                        border-left: 1px solid $extraLightGrey;
                        border-end-end-radius: 24px;
                    }
                }
            }
        }
    }
</style>
