<template>
  <div>
    <div class="tool-bar" :class="{
      'hide-tool-bar': isLongdoMapV3,
      'hide': isHidingToolBar
    }">
      <template v-for="tool in toolList.filter(t => !t.disable)">
        <router-link v-if="tool.hash" @click.native="onClickTool(tool)" tag="a" :to="tool.onMainOnly ? {
          name: 'Main',
          query: {},
          hash: $route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
        } : {
          query: $route.query,
          hash: $route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
        }" :key="tool.name" :class="'ripple '+(tool.className || '')"
          :style="{ width: `calc(100% / ${numberOfEnableToolMenu})` }"
          :replace="$route.hash === '#layers' || $route.hash === '#maps'">
          <i class="material-icons-round">
            {{ tool.iconName }}
          </i>
          <label  v-if="!tool.disableLabel">
            {{ $t('toolBar.' + tool.name) }}
          </label>
          <div v-if="tool.name === 'layers' && numberOfLayer > 0" class="count">{{ numberOfLayerFormat }}</div>
        </router-link>
        <button v-else :key="'tool-' + tool.name" class="ripple" :class="{ active: tool.active }"
          :style="{ width: `calc(100% / ${numberOfEnableToolMenu})` }" @click="tool.click">
          <i class="material-icons-round">
            {{ tool.iconName }}
          </i>
          <label>
            {{ $t('toolBar.' + tool.name) }}
          </label>
        </button>
      </template>
    </div>
    <div v-if="isLongdoMapV3" class="tool-bar-desktop" :class="{
      'active': onToggle
    }">
      <div class="ldmap-btn-group">
        <div v-for="tool in toolListDesktop.filter(t => !t.disable)" :key="tool.name" class="ldmap-btn ripple" :class="{
          'ldmap-btn-active': $route.hash === `#${tool.name}`
        }">
          <router-link v-if="tool.hash" tag="a" :to="tool.onMainOnly ? {
            name: 'Main',
            query: {},
            hash: $route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
          } : {
            query: $route.query,
            hash: $route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
          }" :key="tool.name" :replace="$route.hash === '#layers' || $route.hash === '#maps'">
            <label>
              <i class="material-icons-round">
                {{ tool.iconName }}
              </i>
              {{ $t('toolBar.' + tool.name) }}
            </label>
            <div v-if="tool.name === 'layers' && numberOfLayer > 0" class="count">{{ numberOfLayerFormat }}</div>
          </router-link>
          <button v-else class="ldmap-btn ripple" :class="{ active: tool.active }" @click="tool.click">
            <label>
              <i class="material-icons-round">
                {{ tool.iconName }}
              </i>
              {{ $t('toolBar.' + tool.name) }}
            </label>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolBar',
  props: {
    isReportPanelOpened: {
      type: Boolean,
      default: false
    },
    isGeolocationMobileAppDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      toolList: [
        {
          name: 'layers',
          hash: 'layers',
          iconName: 'layers',
          onMainOnly: false,
          disable: false
        },
        {
          name: 'maps',
          hash: 'maps',
          iconName: 'map',
          onMainOnly: false,
          disableLabel: false,
          disable: false
        },
        {
          name: 'explore',
          click: () => {
            if (!this.toolList[2].active) {
              this.$router.push({
                name: 'Main',
                hash: '#explore',
                query: {
                  lat: localStorage.lat ? Number(localStorage.lat) : Number(process.env.VUE_APP_DEFAULT_LAT),
                  lon: localStorage.lon ? Number(localStorage.lon) : Number(process.env.VUE_APP_DEFAULT_LON)
                }
              })
            } else {
              this.$router.push({
                name: 'Main',
                hash: '',
                query: {}
              })
            }
          },
          iconName: 'wifi_tethering',
          onMainOnly: true,
          disable: process.env.VUE_APP_PRODUCT === 'traffic',
          active: this.$route.hash === '#explore'
        },
        {
          name: 'myPlaces',
          hash: 'my-places',
          iconName: 'star',
          onMainOnly: true,
          disableLabel: false,
          disable: true
        },
        {
          name: 'event',
          hash: 'events',
          className: 'event-btn',
          iconName: 'rss_feed',
          disableLabel: true,
          onMainOnly: true,
          disable: process.env.VUE_APP_PRODUCT === 'map'
        },
        {
          name: 'createIncident',
          iconName: 'campaign',
          onMainOnly: true,
          disableLabel: false,
          disable: process.env.VUE_APP_PRODUCT === 'map',
          click: () => {
            const self = this
            if (!self.isReportPanelOpened) {
              self.$root.$emit('reportEvent')
            } else {
              self.$root.$emit('closeReportEventByToolbar')
            }
          },
          active: self.isReportPanelOpened
        },
        {
          name: 'routing',
          hash: 'routing',
          iconName: 'commute',
          onMainOnly: true,
          disableLabel: false,
          disable: false
        },
        {
          name: 'nearby',
          click: () => {
            this.toolList[7].active = !this.toolList[7].active
            if (this.toolList[7].active) {
              this.$emit('clickCheckNearby')
            } else {
              // window.document.querySelector('.search-panel button.clear').click()
            }
          },
          iconName: 'radar',
          onMainOnly: false,
          disable: process.env.VUE_APP_PRODUCT === 'traffic',
          disableLabel: false,
          active: false
        }
        // {
        //   name: 'createPlace',
        //   hash: 'create-place',
        //   iconName: 'add_location_alt',
        //   onMainOnly: true,
        //   disable: process.env.VUE_APP_PRODUCT === 'traffic'
        // },
      ],
      toolListDesktop: [
        {
          name: 'maps',
          hash: 'maps',
          iconName: 'map',
          onMainOnly: false,
          disable: false
        },
        {
          name: 'layers',
          hash: 'layers',
          iconName: 'layers',
          onMainOnly: false,
          disable: false
        },
        // {
        //   name: 'nearby',
        //   click: () => {
        //     this.toolList[2].active = !this.toolList[2].active
        //     if (this.toolList[2].active) {
        //       this.$emit('clickCheckNearby')
        //     } else {
        //       window.document.querySelector('.search-panel button.clear').click()
        //     }
        //   },
        //   iconName: 'radar',
        //   onMainOnly: false,
        //   disable: process.env.VUE_APP_PRODUCT === 'traffic',
        //   active: this.$route.name === 'Nearby'
        // },
        {
          name: 'routing',
          hash: 'routing',
          iconName: 'commute',
          onMainOnly: false,
          disable: false
        },
        {
          name: 'explore',
          click: () => {
            if (!this.toolListDesktop[3].active) {
              this.$router.push({
                name: 'Main',
                hash: '#explore',
                query: {
                  lat: localStorage.lat ? Number(localStorage.lat) : Number(process.env.VUE_APP_DEFAULT_LAT),
                  lon: localStorage.lon ? Number(localStorage.lon) : Number(process.env.VUE_APP_DEFAULT_LON)
                }
              })
            } else {
              this.$router.push({
                name: 'Main',
                hash: '',
                query: {}
              })
            }
          },
          iconName: 'wifi_tethering',
          onMainOnly: true,
          disable: false,
          active: this.$route.hash === '#explore'
        }
      ],
      onToggle: false,
      isHidingToolBar: false,
      numberOfLayer: 0
    }
  },
  computed: {
    numberOfEnableToolMenu () {
      const self = this
      return self.toolList.filter(t => !t.disable).length
    },
    numberOfLayerFormat () {
      const self = this
      return self.numberOfLayer > 9 ? '9+' : self.numberOfLayer
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.toggleDisplayToolBar)
    self.$root.$on('updateSelectedLayer', self.onUpdateSelectedLayer)
    self.$root.$on('closeSelectNearbyTagDialog', self.onCloseSelectNearbyTagDialog)
  },
  methods: {
    onClickTool (tool) {
      const self = this
      // active explore tool
      if (tool.hash === 'explore' && self.$route.hash === '#explore' && self.isGeolocationMobileAppDisabled) {
        self.$root.$emit('openMobilePermissionDialog')
      }
    },
    toggleDisplayToolBar (isShowingMapOnly) {
      const self = this
      self.isHidingToolBar = isShowingMapOnly
    },
    onUpdateSelectedLayer (selectedLayer) {
      const self = this
      self.numberOfLayer = selectedLayer.length
    },
    onCloseSelectNearbyTagDialog () {
      const self = this
      self.toolList[7].click()
    }
  },
  watch: {
    '$route' (to, from) {
      const self = this
      // self.toolList[7].active = to.name === 'Nearby'
      self.onToggle = to.hash !== ''
      self.toolList[2].active = to.hash === '#explore'
      self.toolListDesktop[3].active = to.hash === '#explore'
    },
    isReportPanelOpened () {
      const self = this
      self.toolList[5].active = self.isReportPanelOpened
    }
  }
}
</script>

<style scoped lang="scss">
// Desktop MODE
@media only screen and (min-width: $mobileMaxSize) {
  .tool-bar-desktop {
    max-width: 74px;
    top: 50px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    z-index: 80;
    position: absolute;
    right: 10px;

    // transition: bottom 0.3s ease 0s;
    .count {
      position: absolute;
      left: 50%;
      top: 0px;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $primary;
      color: white;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      font-family: Prompt;
      font-size: 12px;
      -webkit-transform: translate(calc(24px - 50%), 25%);
      transform: translate(calc(24px - 50%), 25%);
    }
  }

  // .tool-bar-desktop.active{
  //   // -webkit-transition: right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  //   // transition: right 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  //   // right: 400px;
  // }

  button{
    background-color: rgba(0, 0, 0, 0.0);
    border: none;
  }

  .ldmap-btn:hover {
    cursor: pointer;

    i {
      font-weight: bold;
    }

    label {
      font-weight: bold;
    }
  }

  .ldmap-btn {

    cursor: pointer;
    color: $grey;

    a {
      text-decoration: none;
    }

    i {
      cursor: pointer;
      width: 100%;
      color: $defaultFontColor;
      font-size: 18px;
      transition: color 0.2s ease 0s;
    }

    label {
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      display: block;
      text-align: center;
      color: $defaultFontColor;
      font-family: Prompt;
      font-size: 14px;
      transition: color 0.2s ease 0s;
    }
  }

  .ldmap-btn-active {
    background-color: $darkPrimary;
    border-radius: 10px;

    i {
      font-weight: bold;
      color: white;
    }

    label {
      font-weight: bold;
      color: white;
    }
  }

  .hide-tool-bar {
    display: none !important;
  }
}

.tool-bar {
  width: 100%;
  height: $toolBarHeight;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  position: fixed;
  bottom: 0px;
  left: 0px;
  transition: bottom 0.3s ease 0s;

  .event-btn{
    // background-color: $primary;
    width: calc(55px) !important;
    margin-right: calc(12px);
    margin-left: calc(12px);
    background: white;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 50%;
    margin-bottom: 20px;
    i {
      font-size: 30px;
      margin-bottom: 3px;
      margin-left: 3px;
    }
  }

  .event-btn.router-link-active{
    background-color: $primary;
    i {
      font-size: 30px;
      color: white;
    }
  }

  >a,
  button {
    width: $toolBarHeight;
    height: $toolBarHeight;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    background-color: transparent;

    >i {
      color: $grey;
      font-size: 24px;
      transition: color 0.2s ease 0s;
    }

    >label {
      color: $grey;
      font-family: Prompt;
      font-size: 14px;
      transition: color 0.2s ease 0s;
      cursor: pointer;
    }

    >.count {
      position: absolute;
      left: 50%;
      top: 0px;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $primary;
      color: rgb(255, 255, 255);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      font-family: Prompt;
      font-size: 12px;
      transform: translate(calc(24px - 50%), 25%);
    }
  }

  >a.router-link-active,
  button.active {
    position: relative;

    >i {
      color: $primary;
    }

    >label {
      color: $primary;
    }
  }
}

.tool-bar.hide {
  bottom: calc(0px - #{$toolBarHeight} - 12px);
  box-shadow: none;
}
</style>

<style lang="scss">

  @media only screen and (max-width: $mobileMaxSize) {
    .maplibregl-ctrl-bottom-left.mapboxgl-ctrl-bottom-left,
    .maplibregl-ctrl-bottom-left,
    .maplibregl-ctrl-bottom-right.mapboxgl-ctrl-bottom-right,
    .maplibregl-ctrl-bottom-right,
    .ldmap-externalmap .gm-style > div:last-child > div {
      transition: bottom 0.3s ease 0s;
      bottom: 54px !important;
    }

    .only-one .ldmap-externalmap .gm-style > div:last-child > div {
      bottom: 0px !important;
    }
  }
</style>
