<template>
  <div class="my-place-panel"
    :class="{
      'hide': isHidingMyPlacePanel,
      'expand-less': !isExpand
    }"
  >
    <div class="my-place-container">
      <div class="top-bar">
        <button class="expand less ripple"
          v-if="isExpand"
          @click="clickExpandLess"
        >
          <i class="material-icons-round">expand_more</i>
        </button>
        <button class="expand more ripple"
          v-if="!isExpand"
          @click="clickExpandMore"
        >
          <i class="material-icons-round">expand_less</i>
        </button>
      </div>
      <div class="loading-container" v-if="showLoading">
        <img src="@/assets/img/loading.gif">
      </div>
      <div class="empty-container" v-if="!showLoading && myPlaceList !== null && myPlaceList.length === 0 ">
        {{ $t('myPlacePanel.empty') }}
      </div>
      <div ref="my-place-list"
        class="my-place-list"
      >
        <template v-for="myPlace in myPlaceList">
        <div :key="myPlace.ooiid"
          class="my-place ripple"
        >
          <div class="icon"
            @click="clickMyPlace(myPlace)"
          >
            <img :src="myPlace.markicon2x" :srcset="myPlace.markicon + ' 1x, ' + myPlace.markicon2x + ' 2x'" loading="lazy">
          </div>
          <div class="detail"
            @click="clickMyPlace(myPlace)"
          >
            <label class="name" :title="myPlace[`title_${$i18n.locale}`]">{{ myPlace[`title_${$i18n.locale}`] }}</label>
            <label class="short-description" :title="myPlace[`teaser_${$i18n.locale}`]">{{ myPlace[`teaser_${$i18n.locale}`] }}</label>
          </div>
          <!-- <a class="info"
            :href="'https://map.longdo.com/p/' + myPlace.ooiid + '/info/'"
            target="_blank"
          >
            <i class="material-icons-round">info</i>
          </a> -->
          <a class="info"
            @click="clickMyPlaceInfo(myPlace)"
          >
            <i class="material-icons-round">info</i>
          </a>
        </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPlacePanel',
  props: {
  },
  data () {
    return {
      isExpand: true,
      myPlaceList: null,
      showLoading: false,
      isShowingMapOnly: false,
      isHidingMyPlacePanel: true
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.onClickMap)
    self.$parent.$on('showMyPlace', self.showMyPlace)
    self.$parent.$on('loadingMyPlace', self.loadingMyPlace)
    self.$parent.$on('loadedMyPlace', self.loadedMyPlace)
    self.$parent.$on('hideMyPlace', self.hideMyPlace)
    self.$root.$emit('changeHidingMyPlacePanel', self.isHidingMyPlacePanel)
    self.$root.$emit('changeExpandMyPlacePanel', self.isExpand)
  },
  methods: {
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isHidingMyPlacePanel = isShowingMapOnly
      if (self.$route.hash !== '#my-places') {
        self.isHidingMyPlacePanel = true
      }
    },
    clickExpandLess () {
      const self = this
      self.isExpand = false
      self.$root.$emit('changeHidingMyPlacePanel', self.isHidingMyPlacePanel)
      self.$root.$emit('changeExpandMyPlacePanel', self.isExpand)
    },
    clickExpandMore () {
      const self = this
      self.isExpand = true
      self.$root.$emit('changeHidingMyPlacePanel', self.isHidingMyPlacePanel)
      self.$root.$emit('changeExpandMyPlacePanel', self.isExpand)
    },
    showMyPlace () {
      const self = this
      self.isHidingMyPlacePanel = false
      self.$root.$emit('changeHidingMyPlacePanel', self.isHidingMyPlacePanel)
      self.$root.$emit('changeExpandMyPlacePanel', self.isExpand)
    },
    loadingMyPlace () {
      const self = this
      self.showLoading = true
    },
    loadedMyPlace (myPlaceList) {
      const self = this
      self.showLoading = false
      self.myPlaceList = myPlaceList
      self.$refs['my-place-list'].scrollTop = 0
    },
    hideMyPlace () {
      const self = this
      self.isHidingMyPlacePanel = true
      self.$root.$emit('changeHidingMyPlacePanel', self.isHidingMyPlacePanel)
      self.$root.$emit('changeExpandMyPlacePanel', self.isExpand)
    },
    clickMyPlace (myPlace) {
      const self = this
      self.$emit('clickMyPlace', myPlace, false)
    },
    clickMyPlaceInfo (myPlace) {
      const self = this
      self.$emit('clickMyPlace', myPlace, true)
    }
  },
  watch: {
    isHidingMyPlacePanel () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeHidingMyPlacePanel', self.isHidingMyPlacePanel)
      })
    },
    isExpand () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeExpandMyPlacePanel', self.isExpand)
      })
    }
  }
}
</script>

<style scoped lang="scss">
$myPlacePanelHeight: 40%;
$topBarHeight: 32px;

.my-place-panel {
  width: 100%;
  height: $myPlacePanelHeight;
  border-radius: 12px 12px 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0%;
  left: 0px;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

  > .my-place-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0px 0px;

    > .top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 3px 0px 3px;
      box-sizing: border-box;

      > button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        > i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    > .loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    > .empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    > .my-place-list {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight});

      > .my-place {
        $iconWidth: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 9px 0px;
        cursor: pointer;

        > .icon {
          width: $iconWidth;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            max-width: 36px;
            min-width: 16px;
          }
        }

        > .detail {
          display: flex;
          flex-direction: column;
          width: calc(100% - #{$iconWidth} - 36px);

          > .name {
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            font-family: Prompt;
          }

          > .short-description {
            width: 100%;
            font-size: 14px;
            color: $grey;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
          }
        }

        > .info {
          width: 36px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          > i {
            color: $lightPrimary;
            font-size: 24px;
          }
        }
      }

      > button {
        width: calc(100% - 12px);
        border: none;
        border-radius: 3px;
        font-size: 14px;
        padding: 12px 6px;
        margin: 6px;
        cursor: pointer;
        outline: none;
        color: rgb(255, 255, 255);
        background-color: $primary;
        transition: background-color 0.3s ease 0s;
      }

      > button:hover {
        background-color: $darkPrimary;
      }
    }
  }
}

.my-place-panel.expand-less {
  bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$myPlacePanelHeight});
}

.my-place-panel.hide {
  bottom: -#{$myPlacePanelHeight};
  box-shadow: none;
}

.my-place-panel.hide.expand-less {
  bottom: calc(0px - #{$myPlacePanelHeight} - 12px);
}
</style>
