var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image",style:({
    width: _vm.width,
    height: _vm.height,
    backgroundImage: ("url('" + _vm.previewSrc + "')"),
  }),on:{"click":function($event){$event.stopPropagation();return _vm.onClickView.apply(null, arguments)}}},[(_vm.previewSrc === '')?_c('i',{staticClass:"material-icons-round"},[_vm._v("photo")]):_vm._e(),(_vm.canDelete)?_c('button',{staticClass:"delete ripple",attrs:{"disabled":_vm.isProcessing},on:{"click":function($event){$event.stopPropagation();return _vm.onClickDelete.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("delete")])]):_vm._e(),(_vm.canEdit)?_c('button',{staticClass:"edit ripple",attrs:{"disabled":_vm.isProcessing},on:{"click":function($event){$event.stopPropagation();return _vm.onClickEdit.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("edit")])]):_vm._e(),(_vm.showFullscreen)?_c('div',{staticClass:"fullscreen",class:{
      edit: _vm.canEdit
    },style:({
      backgroundImage: ("url('" + _vm.originalSrc + "')")
    })},[_c('button',{staticClass:"close ripple",attrs:{"disabled":_vm.isProcessing},on:{"click":function($event){$event.stopPropagation();return _vm.onClickClose.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("close")])]),(_vm.isProcessing)?_c('div',{staticClass:"loading-overlay"},[_c('img',{attrs:{"src":require("@/assets/img/loading.gif")}})]):_vm._e(),(_vm.showPrevButton)?_c('button',{staticClass:"prev ripple",on:{"click":function($event){$event.stopPropagation();return _vm.onClickPrev.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("chevron_left")])]):_vm._e(),(_vm.showNextButton)?_c('button',{staticClass:"next ripple",on:{"click":function($event){$event.stopPropagation();return _vm.onClickNext.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("chevron_right")])]):_vm._e()]):_vm._e(),(_vm.showFullscreen && _vm.canView && _vm.image.comment)?_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.image.comment))]):_vm._e(),(_vm.showFullscreen && _vm.canEdit)?_c('div',{staticClass:"action"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.image.comment),expression:"image.comment"}],attrs:{"type":"text","placeholder":_vm.$t('imageWidget.commentPlaceholder')},domProps:{"value":(_vm.image.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.image, "comment", $event.target.value)}}}),_c('button',{staticClass:"h-flip ripple",attrs:{"disabled":_vm.isProcessing},on:{"click":_vm.hFlip}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("flip")])]),_c('button',{staticClass:"v-flip ripple",attrs:{"disabled":_vm.isProcessing},on:{"click":_vm.vFlip}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("flip")])]),_c('button',{staticClass:"rotate ripple",attrs:{"disabled":_vm.isProcessing},on:{"click":_vm.rotate}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("rotate_90_degrees_ccw")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }