<template>
  <div class="back-drop" v-if="showDialog" @click.stop="onClickClose()">
    <div class="dialog-card" @click.stop="() => {}">
      <button class="close ripple" @click.stop="onClickClose()">
        <i class="material-icons">close</i>
      </button>
      <div class="identify" v-if="location">
        <div class="text">{{ kmDisplay }} {{ addressDisplay }}</div>
        <div class="text">{{ location.lat.toFixed(6) }}, {{ location.lon.toFixed(6) }}</div>
        <div class="text">{{ demDisplay }}</div>
        <div class="action">
          <input id="input-for-copy" ref="input-for-copy" type="link" :value="copyText" readonly>
          <button class="ripple" @click="copyLatLon(false)">
            <i class="material-icons">content_copy</i>
            <span>Lat, Lon</span>
          </button>
          <button class="ripple" @click="copyLatLon(true)">
            <i class="material-icons">content_copy</i>
            <span>Lon, Lat</span>
          </button>
          <button class="ripple" @click="share()">
            <i class="material-icons">share</i>
            <span>Share</span>
          </button>
          <transition name="copyText">
            <div class="copied" v-if="isShowCopied">{{ $t('sharePlaceWidget.copy') }}</div>
          </transition>
        </div>
      </div>
      <!-- <div class="title">
        {{ $t('selectNearbyTagDialog.title') }}
      </div>
      <div class="content">
        <button
          v-for="tag in choiceTagList"
          :key="tag.tagName"
          @click="onClickTag(tag.tagName)"
        >
          {{ $i18n.locale === 'en' ? tag.nameEn : tag.name }}
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectNearbyTagDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    choiceTagList () {
      return [
        {
          name: 'โรงงาน',
          nameEn: 'Factory',
          tagName: 'factory'
        },
        {
          name: 'โรงพยาบาล',
          nameEn: 'Hospital',
          tagName: 'hospital'
        },
        {
          name: 'ป้ายรถเมล์',
          nameEn: 'Bus stop',
          tagName: 'bus_stop'
        },
        {
          name: 'ร้านสะดวกซื้อ',
          nameEn: 'Convenience Store',
          tagName: 'convenience_store'
        }
      ]
    },
    kmDisplay () {
      const self = this
      if (self.km === null) {
        return ''
      }
      return `${self.$t('selectNearbyTagDialog.km')} ${self.km}`
    },
    addressDisplay () {
      const self = this
      if (self.address === null) {
        return ''
      }
      const temp = []
      if (self.address.road) {
        temp.push(self.address.road)
      }
      if (self.address.subdistrict) {
        temp.push(self.address.subdistrict)
      }
      if (self.address.district) {
        temp.push(self.address.district)
      }
      if (self.address.province) {
        temp.push(self.address.province)
      }
      if (self.address.postcode) {
        temp.push(self.address.postcode)
      }
      if (self.address.country) {
        temp.push(self.address.country)
      }
      return temp.join(self.$i18n.locale === 'th' ? ' ' : ', ')
    },
    demDisplay () {
      const self = this
      if (self.dem === null) {
        return ''
      }
      return `${self.$t('selectNearbyTagDialog.dem')} ${Number(self.dem).toLocaleString('th')} ${self.$t('selectNearbyTagDialog.demUnit')}`
    }
  },
  data () {
    return {
      showDialog: false,
      address: null,
      location: null,
      copyText: null,
      dem: null,
      km: null,
      isShowCopied: false
    }
  },
  methods: {
    onClickClose () {
      const self = this
      self.$emit('close')
      self.$root.$emit('closeSelectNearbyTagDialog')
      self.showDialog = false
    },
    onClickTag (tagName) {
      const self = this
      if (!(self.$route.name === 'Nearby' && (self.$route.params.tagName || '') === tagName)) {
        self.$router.push({
          name: 'Nearby',
          params: {
            tagName: tagName
          }
        })
      }
      self.$emit('close')
      self.showDialog = false
    },
    async getKm () {
      const self = this
      const result = await self.api.getRouteByLocation({
        ...self.location,
        locale: self.$i18n.locale
      })
      if (result.status === 200) {
        self.km = result.data.data.length > 0 ? result.data.data[0].km : null
      } else {
        self.km = null
      }
    },
    async getAddress () {
      const self = this
      const result = await self.api.getAddress({
        ...self.location,
        locale: self.$i18n.locale
      })
      if (result.status === 200) {
        self.address = result.data
        self.dem = result.data.elevation || null
      } else {
        self.address = null
        self.dem = null
      }
    },
    copyLatLon (isSwap) {
      const self = this
      self.copyText = isSwap ? `${self.location.lon.toFixed(6)}, ${self.location.lat.toFixed(6)}` : `${self.location.lat.toFixed(6)}, ${self.location.lon.toFixed(6)}`
      self.$nextTick(() => {
        var copyText = self.$refs['input-for-copy']
        copyText.select()
        document.execCommand('Copy')
        self.isShowCopied = true
        self.storeEventToNativeAnalytic('nearby_tag_copy_lat_lon')
        setTimeout(() => {
          self.isShowCopied = false
        }, 3000)
      })
    },
    share () {
      const self = this
      const url = `https://${process.env.VUE_APP_PRODUCT}.longdo.com/?lat=${self.location.lat}&lon=${self.location.lon}&zoom=${localStorage.zoom}`
      if (window.navigator.share) {
        window.navigator.share({
          url: url,
          title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
        })
      } else {
        self.copyText = url
        self.$nextTick(() => {
          var copyText = self.$refs['input-for-copy']
          copyText.select()
          document.execCommand('Copy')
          self.isShowCopied = true
          setTimeout(() => {
            self.isShowCopied = false
          }, 3000)
        })
      }
      self.storeEventToNativeAnalytic('nearby_tag_share_lat_lon')
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        const self = this
        self.$nextTick(() => {
          self.location = {
            lat: Number(localStorage.lat),
            lon: Number(localStorage.lon)
          }
          self.showDialog = self.value
        })
      }
    },
    showDialog (newValue) {
      const self = this
      if (newValue) {
        self.address = null
        self.km = null
        self.dem = null
      }
      self.$nextTick(() => {
        if (self.showDialog && self.location) {
          self.getKm()
          self.getAddress()
        }
        self.$emit('input', self.showDialog)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.back-drop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 200;
  top: 0px;
  left: 0px;
}

.dialog-card {
  padding: 0px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);

  button.close {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    padding: 0px;

    i {
      color: rgba(255, 255, 255, 0.9);
      font-size: 20px;
    }
  }
  .identify {
    background-color: rgba(54, 150, 242, 0.9);
    padding: 24px 6px 20px 6px;
    border-bottom: 4px solid rgb(41, 136, 240);
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;

    .text {
      color: white;
      line-height: 1.4;
      font-size: 16px;
      width: calc(100% - 48px);
    }

    .action {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin: 9px 0px 0px 0px;

      #input-for-copy {
        position: fixed;
        top: -9999px;
        left: -9999px;
      }

      button {
        border: 1px solid white;
        border-radius: 6px;
        background-color: rgb(41, 136, 240);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 6px 7px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);

        i {
          font-size: 18px;
          color: white;
        }

        span {
          margin-left: 6px;
          color: white;
          font-family: 'Prompt';
          font-size: 14px;
        }
      }

      .copyText-enter-active, .copyText-leave-active {
        transition: opacity 1s;
      }

      .copyText-enter, .copyText-leave-to {
        opacity: 0;
      }

      .copied {
        position: absolute;
        color: white;
        background-color: rgba(0, 0, 0, 0.75);
        width: fit-content;
        border-radius: 16px;
        width: 75px;
        height: 32px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Prompt';
      }
    }
  }

  .title {
    font-family: Prompt;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 12px 6px 12px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .content {
    font-family: Prompt;
    font-size: 20px;
    color: #101010;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 12px 12px 12px;
    background-color: rgba(255, 255, 255, 0.9);

    button {
      height: 32px;
      background-color: white;
      padding: 0 12px;
      border-radius: 18px;
      color: #101010;
      border: 1px solid rgb(41, 136, 240);
      font-family: Prompt;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 6px 0px;
      width: calc(50% - 6px);
    }
  }
}
</style>
