<template>
  <div
    class="image"
    :style="{
      width: width,
      height: height,
      backgroundImage: `url(\'${previewSrc}\')`,
    }"
    @click.stop="onClickView"
  >
    <i class="material-icons-round" v-if="previewSrc === ''">photo</i>
    <button class="delete ripple" v-if="canDelete" @click.stop="onClickDelete" :disabled="isProcessing">
      <i class="material-icons-round">delete</i>
    </button>
    <button class="edit ripple" v-if="canEdit" @click.stop="onClickEdit" :disabled="isProcessing">
      <i class="material-icons-round">edit</i>
    </button>

    <div class="fullscreen"
      :class="{
        edit: canEdit
      }"
      v-if="showFullscreen"
      :style="{
        backgroundImage: `url(\'${originalSrc}\')`
      }"
    >
      <button class="close ripple" @click.stop="onClickClose" :disabled="isProcessing">
        <i class="material-icons-round">close</i>
      </button>

      <div class="loading-overlay" v-if="isProcessing">
        <img src="@/assets/img/loading.gif">
      </div>

      <button class="prev ripple" @click.stop="onClickPrev" v-if="showPrevButton">
        <i class="material-icons-round">chevron_left</i>
      </button>

      <button class="next ripple" @click.stop="onClickNext" v-if="showNextButton">
        <i class="material-icons-round">chevron_right</i>
      </button>

    </div>
    <div class="caption" v-if="showFullscreen && canView && image.comment">{{ image.comment }}</div>
    <div class="action" v-if="showFullscreen && canEdit">
      <input type="text" :placeholder="$t('imageWidget.commentPlaceholder')" v-model="image.comment">
      <button class="h-flip ripple" @click="hFlip" :disabled="isProcessing">
        <i class="material-icons-round">flip</i>
      </button>
      <button class="v-flip ripple" @click="vFlip" :disabled="isProcessing">
        <i class="material-icons-round">flip</i>
      </button>
      <button class="rotate ripple" @click="rotate" :disabled="isProcessing">
        <i class="material-icons-round">rotate_90_degrees_ccw</i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      default: () => {
        return {
          file: null,
          original: '',
          preview: '',
          comment: '',
          orientation: -1
        }
      }
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: 'auto'
    },
    enableFullScreen: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canView: {
      type: Boolean,
      default: false
    },
    showPrevButton: {
      type: Boolean,
      default: false
    },
    showNextButton: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    }
  },
  computed: {
    previewSrc () {
      const self = this
      return self.image.preview || ''
    },
    originalSrc () {
      const self = this
      return self.image.original || ''
    }
  },
  data () {
    return {
      showFullscreen: false,
      isProcessing: false
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('showFullscreenImage', (index) => {
      if (index === self.index) {
        self.showFullscreen = true
      }
    })
  },
  methods: {
    onClickDelete () {
      const self = this
      self.$emit('delete')
    },
    onClickView () {
      const self = this
      if (self.enableFullScreen) {
        self.showFullscreen = true
      }
    },
    onClickEdit () {
      const self = this
      self.showFullscreen = true
    },
    onClickClose () {
      const self = this
      self.showFullscreen = false
    },
    rotate () {
      const self = this
      self.isProcessing = true
      self.utility.resizeImage(self.image.original, -1, -1, 8, (originalUrl) => {
        self.image.original = originalUrl
        self.isProcessing = false
      })
      self.utility.resizeImage(self.image.original, 640, 640, 8, (previewUrl) => {
        self.image.preview = previewUrl
      })
    },
    hFlip (index) {
      var self = this
      self.isProcessing = true
      self.utility.resizeImage(self.image.original, -1, -1, 2, (originalUrl) => {
        self.image.original = originalUrl
        self.isProcessing = false
      })
      self.utility.resizeImage(self.image.original, 640, 640, 2, (previewUrl) => {
        self.image.preview = previewUrl
      })
    },
    vFlip (index) {
      var self = this
      self.isProcessing = true
      self.utility.resizeImage(self.image.original, -1, -1, 4, (originalUrl) => {
        self.image.original = originalUrl
        self.isProcessing = false
      })
      self.utility.resizeImage(self.image.original, 640, 640, 4, (previewUrl) => {
        self.image.preview = previewUrl
      })
    },
    onClickPrev () {
      const self = this
      self.$emit('clickPrev')
      self.showFullscreen = false
    },
    onClickNext () {
      const self = this
      self.$emit('clickNext')
      self.showFullscreen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: $extraLightGrey;
  position: relative;

  i {
    font-size: 32px;
    color: $grey;
  }
}

button.delete {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  border: 1px solid white;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.25);

  i {
    font-size: 18px;
    color: white;
  }
}

button.edit {
  position: absolute;
  top: 40px;
  right: 6px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  border: 1px solid white;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.25);

  i {
    font-size: 18px;
    color: white;
  }
}

.fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(0, 0, 0, 1);
  z-index: 200;

  &.edit {
    height: calc(100% - 60px);
  }

  button.close {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0px;
    border: none;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;

    i {
      color: white;
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  button.next {
    position: absolute;
    top: 50%;
    right: 12px;
    padding: 0px;
    border: none;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    transform: translate(0%, -50%);

    i {
      color: white;
    }
  }

  button.prev {
    position: absolute;
    top: 50%;
    left: 12px;
    padding: 0px;
    border: none;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    transform: translate(0%, -50%);

    i {
      color: white;
    }
  }
}

.caption {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 12px;
  color: white;
  z-index: 210;
}

.action {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 1);
  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;

  input {
    outline: none;
    font-size: 16px;
    padding: 0px 12px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    width: calc(100% - 126px);
    height: 48px;
    line-height: 48px;
    appearance: none;
    user-select: none;

    &::placeholder {
      color: #afafaf;
      font-family: Prompt;
    }
  }

  button {
    width: 36px;
    height: 36px;
    padding: 0px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-left: 6px;

    i {
      font-size: 32px;
      color: white;
    }

    &.v-flip {
      transform: rotate(-90deg);
    }
  }
}
</style>
