<template>
  <div class="measurement-control-panel"
    :class="{
      'hide': isHiding
    }"
  >
    <div class="head">
      <label>
        {{ $t('measurementControlPanel.title') }}
      </label>
      <button class="close ripple"
        @click="clickClose"
      >
        <i class="material-icons-round">close</i>
      </button>
    </div>
    <div
      class="toolbar"
    >
      <button
        class="ripple"
        @click="clickClear"
      >
        <i class="material-icons-round">delete</i>
        <label>{{ $t('measurementControlPanel.clear') }}</label>
      </button>
      <button
        class="ripple"
        @click="clickUndo"
      >
        <i class="material-icons-round">undo</i>
        <label>{{ $t('measurementControlPanel.undo') }}</label>
      </button>
      <button
        class="ripple"
        @click="clickDone"
      >
        <i class="material-icons-round">done</i>
        <label>{{ $t('measurementControlPanel.done') }}</label>
      </button>
      <button
        class="ripple"
        @click="clickAdd"
      >
        <i class="material-icons-round">add</i>
        <label>{{ $t('measurementControlPanel.add') }}</label>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolBar',
  data () {
    return {
      isHiding: true
    }
  },
  mounted () {
    const self = this
    self.isHiding = self.$route.hash !== '#measurement'
  },
  methods: {
    clickAdd () {
      const button = window.document.querySelectorAll('.ldmap_measurement_tool_panel > button')
      button[0].click()
    },
    clickUndo () {
      const button = window.document.querySelectorAll('.ldmap_measurement_tool_panel > button')
      button[1].click()
    },
    clickDone () {
      const button = window.document.querySelectorAll('.ldmap_measurement_tool_panel > button')
      button[2].click()
    },
    clickClear () {
      const self = this
      const button = window.document.querySelectorAll('.ldmap_measurement_tool_panel > button')
      button[3].click()
      self.$emit('clickClearMeasurement')
    },
    clickClose () {
      const self = this
      self.clickClear()
      if (window.history.length <= 2) {
        self.$router.replace({
          name: 'Main',
          hash: '',
          params: {},
          query: {}
        })
      } else {
        this.$router.back()
      }
    }
  },
  watch: {
    '$route.hash' () {
      const self = this
      self.$nextTick(() => {
        self.isHiding = self.$route.hash !== '#measurement'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.measurement-control-panel {
  > .head {
    height: 66px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $primary;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 250;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 6px;
    box-sizing: border-box;
    transition: top 0.3s ease 0s;

    > button {
      width: 42px;
      height: 42px;
      background-color: transparent;
      padding: 9px;
      border: none;
      color: rgb(255, 255, 255);
      position: absolute;
      top: 12px;
      right: 12px;

      > i {
        font-size: 24px;
        color: rgb(255, 255, 255);
      }
    }

    > label {
      text-align: center;
      width: 100%;
      color: rgb(255, 255, 255);
      font-family: Prompt;
    }
  }

  .toolbar {
    width: 100%;
    height: $toolBarHeight;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 250;
    transition: bottom 0.3s ease 0s;

    > button {
      width: calc(100% / 4);
      height: $toolBarHeight;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      border: none;
      background-color: transparent;

      > i {
        color: $grey;
        font-size: 24px;
        transition: color 0.2s ease 0s;
      }

      > label {
        color: $grey;
        font-family: Prompt;
        font-size: 14px;
        transition: color 0.2s ease 0s;
        cursor: pointer;
      }
    }
  }

  &.hide {
    box-shadow: none;

    .head {
      top: -66px;
    }

    .toolbar {
      bottom: calc(0px - #{$toolBarHeight} - 12px);
    }
  }
}
</style>
