<template>
  <div class="warning-pop-up" :class="{hide: isHiding || isShowingMapOnly}">
    <div class="right-corner">
      <button class='jump ripple' @click="onClickFlyTo">
        <i class="material-icons-round">info</i>
      </button>
      <button class="close ripple" @click="onClickClose">
        <i class="material-icons-round">close</i>
      </button>
    </div>
    <div>
      <div class="header">
        <div class="distance number"> {{ warningPoint ? warningPoint.currentDistance : '-.-'}}</div>
        <div class="distance unit">{{ $t('mobileWarningPopup.meter') }}</div>
        <img v-if="warningPoint" :src="imgPathUrl" />
      </div>
      <div class="vertical-line"></div>
      <div class="content">
        <div class="tag">
          <div>{{ tagName !== '-.-' ? $t(`mobileWarningPopup.${tagName}`) : tagName }}</div>
        </div>
        <div class="name">{{ warningPoint ? warningPoint.name : '-.-'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileWarningPopUp',
  data () {
    return {
      isHiding: true,
      isShowingMapOnly: false
    }
  },
  props: {
    warningPoint: {
      type: Object,
      default: null
    },
    map: {
      type: Object,
      default: null
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('openMobileWarningPopup', () => {
      self.isHiding = false
    })
    self.$parent.$on('closeMobileWarningPopup', () => {
      self.isHiding = true
    })
    self.$parent.$on('clickMap', self.onClickMap)
  },
  methods: {
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isShowingMapOnly = isShowingMapOnly
    },
    onClickFlyTo () {
      const self = this
      self.$root.$emit('setIsTracking', false)
      self.map.location({ lat: self.warningPoint.lat, lon: self.warningPoint.lon })
    },
    onClickClose () {
      const self = this
      self.isHiding = true
      self.$parent.$emit('closeMobileWarningPopup')
    }
  },
  computed: {
    imgPathUrl () {
      const self = this
      if (self.warningPoint?.icon) {
        return `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}${self.warningPoint.icon}`
      }
      return `${process.env.VUE_APP_LONGDO_MAP_HD_ICON}blank.png`
    },
    tagName () {
      const self = this
      if (self.warningPoint?.tag) {
        if (self.warningPoint.tag.includes('accident_black_spot')) {
          return 'accident_black_spot'
        } else if (self.warningPoint.tag.includes('speed_camera')) {
          return 'speed_camera'
        }
      }
      return '-.-'
    }
  }
}
</script>

<style lang="scss" scoped>
.warning-pop-up {
  &.hide {
    bottom: -210px;
  }
  position: fixed;
  display: flex;
  align-items: center;
  width: calc(100% - 36px);
  height: 180px;
  z-index: 80;
  bottom: 54px;
  background-color: white;
  padding: 12px 18px;
  border-radius: 24px 24px 0px 0px;
  transition: bottom ease 0.3s;

  .right-corner {
      padding: 0px;
      position: absolute;
      top: 12px;
      right: 12px;
      gap: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: auto;

      >button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.jump {
          i {
            color: $primary;
            font-size: 30px;
          }
        }

        &.close {
          i {
            color: $lightGrey;
            font-size: 30px;
          }
        }
      }
    }

  > div {
    display: flex;
    align-items: center;
    column-gap: 18px;
    width: 100%;
    height: 100%;
    > .header {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;

      > img {
        margin-top: 6px;
      }
      > .distance {
        &.number {
          font-size: 48px;
          line-height: 48px;
        }
        &.unit {
          font-size: 36px;
          line-height: 36px;
        }

        color: red;
      }
    }
    > .vertical-line {
      border: 1px solid $lightGrey;
      border-radius: 9999px;
      height: 120px;
    }

    > .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      word-wrap: break-word;

      > .tag {
        > div {
          font-size: 20px;
          font-weight: 900;
        }

        display: flex;
        align-items: center;
        column-gap: 6px;
      }

      > .name {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}
</style>
