<template>
  <div class="pdpa-management"
    :class="{hide: $route.hash !== '#pdpa-management' || !isLogin}"
  >
    <div class="title-bar">
      <button class="back ripple"
        @click="clickBack()"
      >
        <i class="material-icons-round">
          arrow_back
        </i>
      </button>
      <div class="title">
        {{ $t('pdpaManagement.title') }}
      </div>
    </div>
    <div class="content">
      <div class="banner"
        :style="{backgroundImage: 'url(\'' + baseUrl + 'img/pdpa-banner.svg?version=' + appVersion + '\')'}"
      ></div>
      <div class="info">
        <div>{{ $t('pdpaManagement.consentedDate') }}</div>
        <div>{{ consentedDate }}</div>
      </div>
      <a href="https://longdo.com/privacy">
        <button class="view-privacy-policy button ripple">{{ $t('pdpaManagement.viewPrivacy') }}</button>
      </a>
      <button class="button withdraw ripple" @click="withdraw">{{ $t('pdpaManagement.withdraw') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PdpaManagement',
  computed: {
    consentedDate () {
      const self = this
      return self.utility.getDateTimeStringFromUnixTimestamp(self.pdpaInfo.consented, self.$i18n.locale)
    }
  },
  data () {
    return {
      isLogin: false,
      pdpaInfo: {
        allowed: false,
        consented: null,
        withdrew: null
      }
    }
  },
  mounted () {
    const self = this
    self.$root.$on('loginComplete', self.onLoginComplete)

    if (self.$route.hash === '#pdpa-management') {
      if (self.isLogin) {
        self.pdpaInfo = self.longdoAccount.pdpa.getPdpaInfo() || self.pdpaInfo
      } else {
        self.$router.push({
          path: self.$route.path,
          params: self.$route.params,
          query: self.$route.query
        })
      }
    }
  },
  methods: {
    clickBack () {
      const self = this
      if (window.history.length <= 2) {
        self.$router.replace({
          name: 'Main',
          hash: '',
          params: {},
          query: {}
        })
      } else {
        this.$router.back()
      }
    },
    onLoginComplete () {
      const self = this
      self.isLogin = true
    },
    withdraw () {
      const self = this
      self.longdoAccount.pdpa.setPDPA(false)
      self.isLogin = false
      self.$root.$emit('logoutComplete')
      self.longdoAccount.logout()
    }
  },
  watch: {
    '$route.hash' (newHash) {
      const self = this
      if (newHash === '#pdpa-management') {
        if (self.isLogin) {
          self.pdpaInfo = self.longdoAccount.pdpa.getPdpaInfo() || self.pdpaInfo
        } else {
          self.$router.push({
            path: self.$route.path,
            params: self.$route.params,
            query: self.$route.query
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
// @import '@/assets/style/variables.scss';

.pdpa-management {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 200;
  transition: left 0.3s ease 0s;

  > .title-bar {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(0deg, $primary, $darkPrimary);
    border-bottom: 4px solid $lightPrimary;
    box-sizing: border-box;

    > button.back {
      border: none;
      background-color: transparent;
      padding: 0px;
      width: 36px;
      height: 36px;
      margin: 0px 6px 0px 6px;
      outline: none;
      appearance: none;

      > i {
        color: rgb(255, 255, 255);
        font-size: 24px;
      }
    }

    > .title {
      font-size: 18px;
      font-family: Prompt;
      color: rgb(255, 255, 255);
    }
  }

  > .content {
    width: 100%;
    height: calc(100% - 54px);

    .banner {
      width: 100%;
      height: calc(100% - 84px - 62px - 62px);
      max-height: 45%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 24px 12px;
    }

    .button {
      width: calc(100% - 24px);
      margin: 0px 12px 12px 12px;
      padding: 12px;
      font-family: 'Prompt';
      font-size: 16px;

      &.view-privacy-policy {
        border: none;
        background-color: $darkPrimary;
        color: rgb(255, 255, 255);
      }

      &.withdraw {
        border: 1px solid $darkPrimary;
        background-color: rgb(255, 255, 255);
        color: $darkPrimary;
      }
    }
  }
}

.pdpa-management.hide {
  left: 100%
}
</style>
