<template>
  <div class="navigation-bar">
  </div>
</template>

<script>
export default {
  name: 'NavigationBar'
}
</script>

<style scoped lang="scss">
// @import '@/assets/style/variables.scss';

.navigation-bar {
  width: 100%;
  height: $navigationBarHeight;
}
</style>
