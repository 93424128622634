import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'

export class Api {
  constructor () {
    this.axios = axios
    this.jsonpAdapter = jsonpAdapter
  }

  async getOfflineFileList () {
    const self = this
    try {
      const result = await self.axios.get(process.env.VUE_APP_OFFLINE_FILE_LIST, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      return result.data
    } catch (error) {
      if (error.code !== 1) {
        console.warn(error)
      }
      console.log(error)
      return false
    }
  }

  async getLongdoMapScript () {
    const self = this
    try {
      const result = await self.axios.get(`${process.env.VUE_APP_LONGDO_MAP_API3_LONGDO3}?key=${process.env.VUE_APP_LONGDO_MAP_KEY}`, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      return result.data
    } catch (error) {
      if (error.code !== 1) {
        console.warn(error)
      }
      console.log(error)
      return false
    }
  }

  async getLongdoMapStyle () {
    const self = this
    try {
      const result = await self.axios.get('https://api-test.longdo.com/map3/js/maplibre-gl.css', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      return result.data
    } catch (error) {
      if (error.code !== 1) {
        console.warn(error)
      }
      console.log(error)
      return false
    }
  }

  async getIconList (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_ICON_LIST_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getAutoCompleteTag (tagKeyword) {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_MAP_TAG_AUTOCOMPLETE_REST_API}${encodeURIComponent(tagKeyword)}`)
      return response
    } catch (error) {
      return error.response
    }
  }

  async getParentPlace (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_PARENT_PLACE_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async checkPhoneNoStatus (params) {
    const self = this
    try {
      const response = await self.axios.get('verify/checkstatus', {
        baseURL: process.env.VUE_APP_LONGDO_REST_API_BASE_URL,
        params: params
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async sendSmsForPhoneNoVerification (params) {
    const self = this
    params.client = 'map'
    try {
      const response = await self.axios.get('verify/sendsms', {
        baseURL: process.env.VUE_APP_LONGDO_REST_API_BASE_URL,
        params: params
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async verifyPhoneNo (params) {
    const self = this
    try {
      const response = await self.axios.get('verify/code', {
        baseURL: process.env.VUE_APP_LONGDO_REST_API_BASE_URL,
        params: params
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async savePlace (data) {
    const self = this
    let response = null
    const isEditMode = data.ooiid || false
    const imageList = JSON.parse(JSON.stringify(data.image || []))
    const imageCommentList = JSON.parse(JSON.stringify(data.imagecomment || []))
    if (imageList.length <= 0 || isEditMode) {
      delete data.image
      delete data.imagecomment
    } else {
      data.image = imageList[0]
      data.imagecomment = imageCommentList.length > 0 ? imageCommentList[0] : ''
    }

    try {
      response = await self.axios.post(
        data.ooiid ? process.env.VUE_APP_LONGDO_MAP_UPDATE_PLACE_REST_API : process.env.VUE_APP_LONGDO_MAP_CREATE_PLACE_REST_API,
        new URLSearchParams(data),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
      const resultMessage = ((response.data || {}).result || {}).rs_msg || ''
      if (resultMessage === 'Success') {
        if ((imageList.length > 1 && !isEditMode) || (imageList.length > 0 && isEditMode)) {
          data.ooiid = isEditMode ? data.ooiid : response.data.result.ooiid
          for (let i = (isEditMode ? 0 : 1); i < imageList.length; i++) {
            data.image = imageList[i]
            data.imagecomment = imageCommentList.length > i ? imageCommentList[i] : ''
            await self.axios.post(
              process.env.VUE_APP_LONGDO_MAP_CREATE_PLACE_REST_API,
              new URLSearchParams(data),
              {
                headers: {
                  'Content-type': 'application/x-www-form-urlencoded'
                }
              }
            )
          }
        }
      }
      return response
    } catch (error) {
      return response
    }
  }

  async uploadImagePlace (data) {
    const self = this
    try {
      // for (let i = 0; i < data.image.length; i++) {
      const temp = {
        username: data.username,
        ldtoken: data.ldtoken,
        ooiid: data.ooiid,
        image: data.image.original,
        imagecomment: data.image.comment
      }
      const result = await self.axios.post(
        process.env.VUE_APP_LONGDO_MAP_CREATE_PLACE_REST_API,
        new URLSearchParams(temp),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
      // }
      return result
    } catch (error) {
      return false
    }
  }

  async reportPlace (data) {
    const self = this
    try {
      const response = await self.axios.post(
        process.env.VUE_APP_LONGDO_MAP_REPORT_PLACE_REST_API,
        new URLSearchParams(data),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
      return response
    } catch (error) {
      return error.response
    }
  }

  async deletePlace (data) {
    const self = this
    try {
      const response = await self.axios.post(
        process.env.VUE_APP_LONGDO_MAP_DELETE_PLACE_REST_API,
        new URLSearchParams(data),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
      return response
    } catch (error) {
      return error.response
    }
  }

  async searchPlace (params) {
    const self = this
    params.key = 'showmsg'
    params.action = 'searchjson'
    params.map = 'epsg3857'
    params.forcezoom = 0
    try {
      const response = await self.axios.get(process.env.VUE_APP_MMMAP_SERVICE_URL, {
        params: params,
        auth: {
          username: process.env.VUE_APP_MMMAP_SERVICE_AUTH_USERNAME,
          password: process.env.VUE_APP_MMMAP_SERVICE_AUTH_PASSWORD
        }
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getPoiOtherSourceDetail (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_PLACE_POI_DETAIL_REST_API, {
        params: {
          key: process.env.VUE_APP_LONGDO_MAP_KEY,
          ...params
        }
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getPoiDetail (params) {
    const self = this
    params.key = 'showmsg'
    params.action = 'showpoidetails'
    params.mmroute = true
    params.map = 'epsg3857'
    params.service = 'longdo'
    params.popup_idx = 0
    try {
      const response = await self.axios.get(process.env.VUE_APP_MMMAP_SERVICE_URL, {
        params: params,
        auth: {
          username: process.env.VUE_APP_MMMAP_SERVICE_AUTH_USERNAME,
          password: process.env.VUE_APP_MMMAP_SERVICE_AUTH_PASSWORD
        }
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getPlaceInfo (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_PLACE_INFO_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getPlaceDetail (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_PLACE_DETAIL_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async suggestPlace ({ keyword, limit = 10, isUseMwaAndNso = false }) {
    const self = this
    var dataset = 'poi,poi_p,poi_a,poi_r,poi_b,poi_loi,poi_c,poi2,poi3,s_pg,nw_s,m2h_s,bus_s,s_osmpnt,s_osmline,s_osmpol,overture_p'
    if (isUseMwaAndNso) {
      dataset = dataset + (dataset !== '' ? ',' : '') + 'mwahh_s'
    }
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_SUGGEST_REST_API, {
        params: {
          key: process.env.VUE_APP_LONGDO_MAP_KEY,
          dataset,
          keyword,
          limit
        }
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getPoiData (params) {
    const self = this
    params.action = 'showpoi'
    params.json = true
    try {
      const response = await self.axios.get(process.env.VUE_APP_MMMAP_SERVICE_URL, {
        params: params,
        auth: {
          username: process.env.VUE_APP_MMMAP_SERVICE_AUTH_USERNAME,
          password: process.env.VUE_APP_MMMAP_SERVICE_AUTH_PASSWORD
        }
      })
      const { data } = response
      if (!data.lat || !data.long) {
        // Customize
        params.id = 'A' + params.id
        const resonse = await self.getPlaceDetail(params)
        const { data } = resonse
        return {
          data: {
            id: data.data.id,
            name: data.data.name_th,
            lat: data.data.location.latitude,
            long: data.data.location.longitude,
            type: null,
            status: data.data.status,
            name_en: data.data.name_en
          }
        }
      }
      return response
    } catch (error) {
      return false
    }
  }

  async getAddress (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_ADDRESS_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getAddressFromGeocode (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_ADDRESS_FROM_GEOCODE_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getObjectData (params, dataset) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    params.dataset = dataset
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_ROAD_OBJECT_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getRoadData (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    params.dataset = 'RID'
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_ROAD_OBJECT_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getBusRouteData (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    params.dataset = 'SID'
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_BUS_ROUTE_OBJECT_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async extractAddressRestApi (params) {
    const self = this
    if (!params.text) {
      return false
    }
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_MAP_EXTRACT_ADDRESS_REST_API}?key=${params.key}&text=${params.text}`)
      return response
    } catch (error) {
      return false
    }
  }

  async searchPlaceRestApi (params, isUseMwaAndNso = false) {
    const self = this
    if (process.env.VUE_APP_BUILD_SERVER === 'localdev') {
      params.key = process.env.VUE_APP_LONGDO_MAP_SMART_SEARCH_KEY
    } else {
      params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    }
    params.isSmart = params.isSmart || false
    if (params.isSmart) {
      params.isgeo = 1
      if (params.isgeo === 1 && isUseMwaAndNso) {
        params.geodataset = 'other'
      }
      // params.extendedsearch = 1
      // params.extendedlimit = 3
      // params.extendedkey = process.env.VUE_APP_GOOGLE_MAP_JS_KEY
      params.extendedsearch = 0
    }
    if (params.keyword === '') {
      delete params.keyword
    }
    if (params.limit === '') {
      delete params.limit
    }
    params.dataset = params.dataset || 'poi,data2p,data2a,data2r,data2b,data2loi,change,con,tag,pg,nw,m2h,bus,osmpnt,osmline,osmpol,overture2p'
    if (isUseMwaAndNso) {
      params.dataset = params.dataset + (params.dataset !== '' ? ',' : '') + 'mwahh'
    }
    try {
      const response = await self.axios.get(params.isSmart
        ? process.env.VUE_APP_LONGDO_MAP_SMART_SEARCH_REST_API
        : process.env.VUE_APP_LONGDO_MAP_SEARCH_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async searchAccidentAndSpeedCam (params, isUseMwaAndNso = false) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    if (params.isSmart) {
      params.isgeo = 1
      if (params.isgeo === 1 && isUseMwaAndNso) {
        params.geodataset = 'other'
      }
      // params.extendedsearch = 1
      // params.extendedlimit = 3
      // params.extendedkey = process.env.VUE_APP_GOOGLE_MAP_JS_KEY
      params.extendedsearch = 0
    }
    if (params.keyword === '') {
      delete params.keyword
    }
    if (params.limit === '') {
      delete params.limit
    }
    params.dataset = params.dataset || 'poi,data2p,data2a,data2r,data2b,data2loi,change,con,tag,pg,nw,m2h,bus,osmpnt,osmline,osmpol,overture2p'
    if (isUseMwaAndNso) {
      params.dataset = params.dataset + (params.dataset !== '' ? ',' : '') + 'mwahh'
    }
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_ACCIDENT_SPEEDCAM_REST_API,
        {
          params: params
        })
      return response
    } catch (error) {
      return false
    }
  }

  async getExtraAddressGeoService (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      const res = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_EXTRA_ADDRESS_REST_API, {
        params: params
      })
      return res
    } catch (error) {
      return false
    }
  }

  async searchNearbyPlace (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_NEARBY_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getMyPlace (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_MY_PLACE_REST_API, {
        params: params,
        adapter: self.jsonpAdapter
      })
      return response
    } catch (error) {
      return false
    }
  }

  async updateFav (data) {
    const self = this
    try {
      const response = await self.axios.post(
        process.env.VUE_APP_LONGDO_MAP_UPDATE_FAV_REST_API,
        new URLSearchParams(data),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
      return response
    } catch (error) {
      return error.response
    }
  }

  async getTagCategoryList () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_TAG_CATEGORY_LIST_REST_API)
      return response
    } catch (error) {
      return false
    }
  }

  async getTagList () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_TAG_LIST_REST_API)
      return response
    } catch (error) {
      return false
    }
  }

  async getCountryList () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_BASE_URL + 'country.json?version=' + process.env.VUE_APP_VERSION)
      return response
    } catch (error) {
      return false
    }
  }

  async getLayerList () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_BASE_URL + 'layerList.json?version=' + process.env.VUE_APP_VERSION)
      return response
    } catch (error) {
      return false
    }
  }

  async getFlashExploreList () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_BASE_URL + 'flashExploreList.json?version=' + process.env.VUE_APP_VERSION)
      return response
    } catch (error) {
      return false
    }
  }

  async getFlashExploreNearbyList () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_BASE_URL + 'flashExploreNearbyList.json?version=' + process.env.VUE_APP_VERSION)
      return response
    } catch (error) {
      return false
    }
  }

  getRouteGuide (params, cancelToken) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    params.guide = 'n'
    try {
      return self.axios.get(process.env.VUE_APP_LONGDO_MAP_ROUTE_GUIDE_REST_API, {
        cancelToken: cancelToken,
        params: params
      }).catch((thrown) => {
        if (axios.isCancel(thrown)) {
        } else {
        }
      })
    } catch (error) {
      return false
    }
  }

  getRoutePath (params, cancelToken) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      return self.axios.get(process.env.VUE_APP_LONGDO_MAP_ROUTE_PATH_REST_API, {
        cancelToken: cancelToken,
        params: params
      }).catch((thrown) => {
        if (axios.isCancel(thrown)) {
        } else {
        }
      })
    } catch (error) {
      return false
    }
  }

  async getRouteByLocation (params) {
    const self = this
    params.key = process.env.VUE_APP_LONGDO_MAP_KEY
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_ROUTE_BY_LOCATION_REST_API, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getAqiAir4Thai (params) {
    const self = this
    if (!(params instanceof Object)) {
      params = {}
    }
    if (!('locale' in params)) {
      params.locale = 'th'
    }
    if (!('timestamp' in params)) {
      params.timestamp = ~~(Date.now() / 1000 / 3600)
    }
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_AQI4THAI_REST_API + '&locale=' + params.locale + '&timestamp=' + params.timestamp)
      return response
    } catch (error) {
      return false
    }
  }

  async getAqiCN (params) {
    const self = this
    if (!(params instanceof Object)) {
      params = {}
    }
    if (!('locale' in params)) {
      params.locale = 'th'
    }
    if (!('minLat' in params) || !('minLon' in params) || !('maxLat' in params) || !('maxLon' in params)) {
      return false
    }
    if (!('timestamp' in params)) {
      params.timestamp = ~~(Date.now() / 1000 / 3600)
    }
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_AQICN_REST_API + '?locale=' + params.locale + '&minlat=' + Number(params.minLat).toFixed(5) + '&minlon=' + Number(params.minLon).toFixed(5) + '&maxlat=' + Number(params.maxLat).toFixed(5) + '&maxlon=' + Number(params.maxLon).toFixed(5) + '&timestamp=' + params.timestamp)
      return response
    } catch (error) {
      return false
    }
  }

  async getAqiCNInfo (params) {
    const self = this
    if (!(params instanceof Object)) {
      params = {}
    }
    if (!('id' in params)) {
      return false
    }
    if (!('timestamp' in params)) {
      params.timestamp = ~~(Date.now() / 1000 / 3600)
    }
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_AQICN_INFO_REST_API + '&id=' + params.id + '&timestamp=' + params.timestamp)
      return response
    } catch (error) {
      return false
    }
  }

  async getAqiGistda (params) {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_MAP_GISTDA_PM25_REST_API}?lat=${params.lat}&lng=${params.lon}`)
      return response
    } catch (error) {
      return false
    }
  }

  async getEVCharger (params) {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_OPENCHARGEMAP_API}?key=${process.env.VUE_APP_OPENCHARGEMAP_KEY}&verbose=false&output=json&includecomments=true&maxresults=10&compact=true&boundingbox=${params.boundingBox}`)
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async getRainfallAccumulation2563 () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_RAINFALL_ACCUMULATION_2563_GEOJSON)
      return response
    } catch (error) {
      return false
    }
  }

  async getStationTransitMap () {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_BASE_URL + 'station-transit-map.json?version=' + process.env.VUE_APP_VERSION)
      return response
    } catch (error) {
      return false
    }
  }

  /**
   * Transit API
   * https://docs.google.com/spreadsheets/d/1Rtx4bOSvjXxzySZKF9mLSdz-3x49cNpUwH0AFRhby74
   */

  async getTransitGuide (params, cancelToken) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_TRANSIT + 'guide', {
        cancelToken: cancelToken,
        params: params
      }).catch((thrown) => {
        if (axios.isCancel(thrown)) {
        } else {
        }
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getTransitPath (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_TRANSIT + 'path', {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getTripDetail (tripId, params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_MAP_TRANSIT + 'trip/' + tripId, {
        params: params
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getTrafficIncident () {
    const self = this
    const date = new Date() // Create a Date object
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const formattedDate = `${year}${month}${day}${hours}${minutes}`
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_TRAFFIC_INCIDENT_REST_API}?now=${formattedDate}`)
      return response
    } catch (error) {
      return false
    }
  }

  async getTrafficIndex () {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_TRAFFIC_INDEX_REST_API}?now=${new Date().getTime()}`)
      return response
    } catch (error) {
      return false
    }
  }

  async getTrafficEventType () {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_EVENT_TYPE_REST_API}`)
      return response
    } catch (error) {
      return false
    }
  }

  async searchTrafficEvent (keyword, offset = 0, limit = 20) {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_TRAFFIC_INCIDENT_SEARCH_REST_API}${keyword}/${offset}/${limit}/false`, {
        crossDomain: true
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getNumOfProbe () {
    const self = this
    try {
      const response = await self.axios.get(`${process.env.VUE_APP_LONGDO_TRAFFIC_PROBE_AMOUNT}`)
      return response
    } catch (error) {
      return false
    }
  }

  async getEventList (params) {
    const self = this
    try {
      const response = await self.axios.get(process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_LIST_REST_API, {
        params: params,
        crossDomain: true
      })
      return response
    } catch (error) {
      return false
    }
  }

  async getUserInfo (params) {
    const self = this
    try {
      if (process.env.VUE_APP_PRODUCT === 'traffic' && params.username && params.ldtoken) {
        return await self.axios.post(process.env.VUE_APP_LONGDO_ACCOUNT_USER_INFO, new URLSearchParams(params),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
      } else {
        return await self.axios.get(process.env.VUE_APP_LONGDO_ACCOUNT_USER_INFO, {
          params: params
        })
      }
    } catch (error) {
      return false
    }
  }

  async saveEventTraffic (params) {
    const self = this
    try {
      const response = await self.axios.post(process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_SAVE,
        params,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async deleteEventTraffic (params) {
    const self = this
    try {
      const response = await self.axios.post(process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_DEL, new URLSearchParams(params),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        })
      return response
    } catch (error) {
      return error
    }
  }

  async translateEventReport (params) {
    const self = this
    try {
      const response = await self.axios.post(process.env.VUE_APP_LONGDO_TRAFFIC_TRANSLATE_ITP, new URLSearchParams(params),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        })
      return response
    } catch (error) {
      return error
    }
  }

  async routeColorChange (params) {
    const self = this
    try {
      const response = await self.axios.post(process.env.VUE_APP_LONGDO_TRAFFIC_ROUTE_COLOR_CHANGE, new URLSearchParams(params),
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        })
      return response
    } catch (error) {
      return error
    }
  }
}
