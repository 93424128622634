<template>
  <div :class="{
    'special-control-map-panel-map3': isLongdoMapV3,
    'special-control-map-panel': !isLongdoMapV3,
    'hide': isHidingControlMapPanel || $route.hash === '#cam' || $route.hash === '#measurement' || $route.hash === '#routing' || $route.hash === '#events' || isShowingPopup ||
      (!isHidingExplorePanel && isExpandingExplorePanel) ||
      (!isHidingSearchResultPanel && isExpandingSearchResultPanel) ||
      (!isHidingMyPlacePanel && isExpandingMyPlacePanel) ||
      (!isHidingExploreNearbyPanel && isExpandingExploreNearbyPanel) ||
      (!isHidingEventPanel && isExpandingEventPanel),
    'traffic-product':isTrafficProduct,
    'showing-popup': isShowingPopup,
    'place-card-step-full': placeCardStep === 'FULL',
    'place-card-step-mid': placeCardStep === 'MID',
    'place-card-step-mini': placeCardStep === 'MINI',
    'showing-explore-panel': !isHidingExplorePanel && isExpandingExplorePanel,
    'collapsing-explore-panel': !isHidingExplorePanel && !isExpandingExplorePanel,
    'showing-search-result-panel': !isHidingSearchResultPanel && isExpandingSearchResultPanel,
    'collapsing-search-result-panel': !isHidingSearchResultPanel && !isExpandingSearchResultPanel,
    'showing-my-place-panel': !isHidingMyPlacePanel && isExpandingMyPlacePanel,
    'collapsing-my-place-panel': !isHidingMyPlacePanel && !isExpandingMyPlacePanel,
    'showing-explore-nearby-panel': !isHidingExploreNearbyPanel && isExpandingExploreNearbyPanel,
    'collapsing-explore-nearby-panel': !isHidingExploreNearbyPanel && !isExpandingExploreNearbyPanel,
    'showing-event-panel': !isHidingEventPanel && isExpandingEventPanel,
    'collapsing-event-panel': !isHidingEventPanel && !isExpandingEventPanel
  }">
    <button @click="clickCreatePlace()" :title="$t('specialMapControlPanel.createPlace')" class="ripple"
      v-if="!isTrafficProduct && !isDisableLoginFeature && $route.hash !== '#routing'">
      <i class="material-icons">
        add_location_alt
      </i>
    </button>
    <button @click="clickCreateTrafficIncident()" :title="$t('specialMapControlPanel.createTrafficIncident')" class="ripple report-event-btn"
      v-if="isTrafficProduct && !isDisableLoginFeature && $route.hash !== '#routing'">
      <i class="material-icons">
        campaign
      </i>
    </button>
    <button @click="clickMeasurement()" :title="$t('specialMapControlPanel.measurement')" class="ripple"
      v-if="enableMeasurementToolButton" v-show="!isLongdoMapV3 && !isTrafficProduct">
      <i class="material-icons">
        straighten
      </i>
    </button>
    <button
      @click="clickCheckNearby()"
      :title="$t('specialMapControlPanel.checkNearby')"
      class="ripple check-nearby"
      v-show="!isLongdoMapV3 && !isTrafficProduct"
      v-if="isCheckNearby"
    >
      <i
        class="material-icons"
      >
        radar
      </i>
    </button>
    <button :class="{ inactive: !isActiveCityPlanThailand }" @click="onClickCityPlanThailandLayer" v-if="isCheckNearby && !isLongdoMapV3 && !isTrafficProduct">
      <i class="material-icons-round">
        view_quilt
      </i>
    </button>
    <div v-if="isActiveCityPlanThailand  && cityplanThailandAreaName !== ''" class="city-plan-thailand-area-name">
      {{ cityplanThailandAreaName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialControlMapPanel',
  props: {
    isShowingPopup: {
      type: Boolean,
      default: false
    },
    placeCardStep: {
      type: String,
      default: 'MID'
    },
    map: {
      type: Object
    }
  },
  computed: {
    colorList () {
      return [
        {
          0: 255,
          1: 255,
          2: 102,
          name: 'พื้นที่ที่อยู่อาศัยหนาแน่นน้อย'
        },
        {
          0: 254,
          1: 254,
          2: 0,
          name: 'พื้นที่ที่อยู่อาศัยหนาแน่นน้อย'
        },
        {
          0: 255,
          1: 193,
          2: 131,
          name: 'พื้นที่ที่อยู่อาศัยหนาแน่นปานกลาง'
        },
        {
          0: 254,
          1: 126,
          2: 0,
          name: 'พื้นที่ที่อยู่อาศัยหนาแน่นปานกลาง'
        },
        {
          0: 167,
          1: 112,
          2: 0,
          name: 'พื้นที่ที่อยู่อาศัยหนาแน่นมาก'
        },
        {
          0: 208,
          1: 155,
          2: 102,
          name: 'พื้นที่พาณิชยกรรมและที่อยู่อาศัยหนาแน่นมาก'
        },
        {
          0: 253,
          1: 0,
          2: 0,
          name: 'พื้นที่พาณิชยกรรมและที่อยู่อาศัยหนาแน่นมาก'
        },
        {
          0: 255,
          1: 122,
          2: 122,
          name: 'พื้นที่ชุมชน'
        },
        {
          0: 244,
          1: 124,
          2: 149,
          name: 'พื้นที่ชุมชน'
        },
        {
          0: 190,
          1: 0,
          2: 254,
          name: 'พื้นที่อุตสาหกรรมและคลังสินค้า'
        },
        {
          0: 252,
          1: 0,
          2: 252,
          name: 'พื้นที่คลังสินค้า'
        },
        {
          0: 209,
          1: 160,
          2: 255,
          name: 'พื้นที่อุตสาหกรรมเฉพาะกิจ'
        },
        {
          0: 255,
          1: 208,
          2: 255,
          name: 'พื้นที่อุตสาหกรรมเฉพาะกิจ'
        },
        {
          0: 102,
          1: 169,
          2: 255,
          name: 'พื้นที่สถาบันราชการ การสาธารณูปโภคและสาธารณูปการ'
        },
        {
          0: 0,
          1: 125,
          2: 252,
          name: 'พื้นที่สถาบันราชการ การสาธารณูปโภคและสาธารณูปการ'
        },
        {
          0: 102,
          1: 179,
          2: 102,
          name: 'พื้นที่ชนบทและเกษตรกรรม'
        },
        {
          0: 55,
          1: 167,
          2: 0,
          name: 'พื้นที่ชนบทและเกษตรกรรม'
        },
        {
          0: 207,
          1: 253,
          2: 153,
          name: 'พื้นที่โล่งเพื่อนันทนาการและการรักษาคุณภาพสิ่งแวดล้อม'
        },
        {
          0: 84,
          1: 254,
          2: 0,
          name: 'พื้นที่โล่งเพื่อนันทนาการและการรักษาคุณภาพสิ่งแวดล้อม'
        },
        {
          0: 171,
          1: 171,
          2: 171,
          name: 'พื้นที่สถาบันศาสนา'
        },
        {
          0: 84,
          1: 114,
          2: 0,
          name: 'พื้นที่สถาบันการศึกษา'
        },
        {
          0: 253,
          1: 253,
          2: 253,
          name: 'พื้นที่เขตทหาร'
        }
      ]
    },
    isDisableLoginFeature () {
      const self = this
      if (self.lji === null) {
        return false
      }
      return !self.isLogin && (self.lji.Util.isIosNativeApp() || self.lji.Util.isAndroidNativeApp())
    }
  },
  data () {
    return {
      width: window.innerWidth,
      isHidingControlMapPanel: false,
      enableMeasurementToolButton: localStorage.enableMeasurementToolButton ? (localStorage.enableMeasurementToolButton === 'true') : true,
      isActiveCityPlanThailand: false,
      cityplanThailandAreaName: '',
      isHidingExplorePanel: true,
      isExpandingExplorePanel: true,
      isHidingSearchResultPanel: true,
      isExpandingSearchResultPanel: true,
      isHidingMyPlacePanel: true,
      isExpandingMyPlacePanel: true,
      isHidingExploreNearbyPanel: true,
      isExpandingExploreNearbyPanel: true,
      isHidingEventPanel: true,
      isExpandingEventPanel: true,
      isLogin: false
    }
  },
  mounted () {
    const self = this
    self.$root.$on('loginComplete', self.onLoginComplete)
    self.$root.$on('logoutComplete', self.onLogoutComplete)

    self.$parent.$on('clickMap', self.toggleDisplayMapContorlPanel)
    self.$root.$on('afterOpenLayer', self.onAfterOpenLayer)
    self.$root.$on('afterHideLayer', self.onAfterHideLayer)
    self.$root.$on('changeEnableMeasurementToolButton', self.onChangeEnableMeasurementToolButton)

    self.$root.$on('changeHidingExplorePanel', value => {
      self.isHidingExplorePanel = value
    })
    self.$root.$on('changeExpandExplorePanel', value => {
      self.isExpandingExplorePanel = value
    })
    self.$root.$on('changeHidingSearchResultPanel', value => {
      self.isHidingSearchResultPanel = value
    })
    self.$root.$on('changeExpandSearchResultPanel', value => {
      self.isExpandingSearchResultPanel = value
    })
    self.$root.$on('changeHidingMyPlacePanel', value => {
      self.isHidingMyPlacePanel = value
    })
    self.$root.$on('changeExpandMyPlacePanel', value => {
      self.isExpandingMyPlacePanel = value
    })
    self.$root.$on('changeHidingExploreNearbyPanel', value => {
      self.isHidingExploreNearbyPanel = value
    })
    self.$root.$on('changeExpandExploreNearbyPanel', value => {
      self.isExpandingExploreNearbyPanel = value
    })
    self.$root.$on('changeHidingEventPanel', value => {
      self.isHidingEventPanel = value
    })
    self.$root.$on('changeExpandEventPanel', value => {
      self.isExpandingEventPanel = value
    })

    self.$root.$on('resize', (size) => {
      self.width = size.width
    })

    if (!self.isLongdoMapV3) {
      self.map.Event.bind('drop', () => {
        const location = self.map.location()
        const projection = self.map.projection()
        const zoom = self.map.zoom()
        const p = window.longdo.Util.locationToPoint(projection, location)
        const r = 1 << (projection.maxZoom - zoom)
        const tile = {
          x: parseInt(p.x / r / 256),
          y: parseInt(p.y / r / 256)
        }
        const pixel = {
          x: parseInt(p.x / r % 256),
          y: parseInt(p.y / r % 256)
        }
        const img = document.querySelector(`img[src*="mode=cityplan_thailand"][src*="x=${tile.x}"][src*="y=${tile.y}"][src*="zoom=${zoom}"]`)
        if (img !== null) {
          const image = new Image()
          image.crossOrigin = ''
          image.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height)
            const pixelData = canvas.getContext('2d').getImageData(pixel.x, pixel.y, 1, 1).data
            if (pixelData) {
              const offset = 5
              for (let i = 0; i < self.colorList.length; i++) {
                if (
                  (self.colorList[i][0] - offset) <= pixelData[0] && pixelData[0] <= (self.colorList[i][0] + offset) &&
                (self.colorList[i][1] - offset) <= pixelData[1] && pixelData[1] <= (self.colorList[i][1] + offset) &&
                (self.colorList[i][2] - offset) <= pixelData[2] && pixelData[2] <= (self.colorList[i][2] + offset)
                ) {
                  self.cityplanThailandAreaName = self.colorList[i].name
                  break
                } else {
                  self.cityplanThailandAreaName = ''
                }
              }
            }
          }
          image.src = img.src.replace('simplethai.net', 'longdo.com')
        }
      })
    }
  },
  methods: {
    getCurrentLocation () {
      const self = this
      self.isTracking = true
      self.$emit('getCurrentLocation')
    },
    clickCreateTrafficIncident () {
      const self = this
      self.$root.$emit('reportEvent', 'btn-click')
    },
    clickCreatePlace () {
      const self = this
      if (self.isLongdoMapV3) {
        window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${localStorage.lat}&lon=${localStorage.lon}`
      } else {
        self.$router.push({
          name: 'CreatePlace',
          params: {},
          query: {
            lat: Number(localStorage.lat),
            lon: Number(localStorage.lon)
          },
          hash: ''
        })
      }
      self.storeEventToNativeAnalytic('special_map_control_panel_create_place')
    },
    clickMeasurement () {
      const self = this
      self.$router.push({
        name: 'Main',
        hash: '#measurement'
      })
    },
    toggleDisplayMapContorlPanel (isShowingMapOnly) {
      const self = this
      self.isHidingControlMapPanel = isShowingMapOnly
    },
    onChangeEnableMeasurementToolButton (enableMeasurementToolButton) {
      const self = this
      self.enableMeasurementToolButton = enableMeasurementToolButton
    },
    clickCheckNearby () {
      const self = this
      self.$emit('clickCheckNearby')
    },
    onClickCityPlanThailandLayer () {
      const self = this
      self.isActiveCityPlanThailand = !self.isActiveCityPlanThailand
      if (self.isActiveCityPlanThailand) {
        self.$root.$emit('openLayer', 'cityplan_thailand')
        setTimeout(() => {
          self.map.Event.fire('drop')
        }, 1500)
      } else {
        self.$root.$emit('hideLayer', 'cityplan_thailand')
      }
    },
    onAfterOpenLayer (layerName, options) {
      const self = this
      if (layerName === 'cityplan_thailand') {
        self.isActiveCityPlanThailand = true
        setTimeout(() => {
          self.map.Event.fire('drop')
        }, 1500)
      }
    },
    onAfterHideLayer (layerName) {
      const self = this
      if (layerName === 'cityplan_thailand') {
        self.isActiveCityPlanThailand = false
      }
    },
    async onLoginComplete () {
      const self = this
      self.isLogin = true
    },
    async onLogoutComplete () {
      const self = this
      self.isLogin = false
    }
  }
}
</script>

<style scoped lang="scss">
.special-control-map-panel-map3 {
  width: 64px;
  height: max-content;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 80;
  position: fixed;
  right: 0px;
  transition: right 0.3s ease 0s, bottom 0.3s ease 0s;

  @media only screen and (max-height: 660px) {
    bottom: calc(#{$toolBarHeight} + 24px) !important;
  }
  @media only screen and (min-width: $mobileMaxSize) {
    bottom: calc(#{$toolBarHeight} + 60px + 180px);
  }

  @media only screen and (max-width: $mobileMaxSize) {
    bottom: calc(#{$toolBarHeight} + 60px);

    &.traffic-product {
      right: 64px;
    }

    &.hide {
      right: -128px;

      .city-plan-thailand-area-name {
        top: 12px;
      }
    }

    .report-event-btn {
      display: none;
    }

  }

  >button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    margin-top: 12px;
    outline: none;
    cursor: pointer;
    user-select: none;

    >i {
      color: $primary;
      font-size: 20px;
      user-select: none;
      pointer-events: none;
    }

    &.inactive {
      i {
        color: $lightGrey;
      }
    }
  }

  .city-plan-thailand-area-name {
    pointer-events: none;
    position: fixed;
    top: calc(54px + 12px + 12px);
    left: calc(12px + 64px);
    width: calc(100vw - 12px - 64px - 64px - 12px);
    padding: 0px 6px;
    font-size: 18px;
    font-family: Prompt;
    z-index: -1;
    text-align: center;
    box-sizing: border-box;
    filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(-1px -1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(-1px 1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(1px -1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(1px 0px 0px rgba(255, 255, 255, 0.5)) drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(-1px 0px 0px rgba(255, 255, 255, 0.5)) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.5));
    transition: top 0.3s ease 0s;
  }
}

.special-control-map-panel {
  width: 64px;
  height: max-content;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 80;
  position: fixed;
  bottom: calc(#{$toolBarHeight} + 44px);
  right: 0px;
  transition: right 0.3s ease 0s,
    bottom 0.3s ease 0s;

  @media only screen and (max-width: $mobileMaxSize) {
    &.traffic-product {
      right: 64px;
    }
    &.hide {
      right: -128px;
    }
  }

  >button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    margin-top: 12px;
    outline: none;
    cursor: pointer;
    user-select: none;

    // &.check-nearby {}

    >i {
      color: $primary;
      font-size: 20px;
      user-select: none;
      pointer-events: none;
    }

    &.inactive {
      i {
        color: $lightGrey;
      }
    }
  }

  &.hide {
    right: -64px;

    .city-plan-thailand-area-name {
      top: 12px;
    }
  }

  &.showing-popup {
    bottom: calc(#{$toolBarHeight} + 30%);
  }

  &.hide.showing-popup {
    bottom: calc(#{$toolBarHeight} + 30%);
  }

  .city-plan-thailand-area-name {
    pointer-events: none;
    position: fixed;
    top: calc(54px + 12px + 12px);
    left: calc(12px + 64px);
    width: calc(100vw - 12px - 64px - 64px - 12px);
    padding: 0px 6px;
    font-size: 18px;
    font-family: Prompt;
    z-index: -1;
    text-align: center;
    box-sizing: border-box;
    filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(-1px -1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(-1px 1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(1px -1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(1px 0px 0px rgba(255, 255, 255, 0.5)) drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.5)) drop-shadow(-1px 0px 0px rgba(255, 255, 255, 0.5)) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.5));
    transition: top 0.3s ease 0s;
  }
}
</style>
