<template>
    <div v-if="previewImage && isLongdoMapV3" class="images-preview-container">
        <button @click="closeImagePreview()"  class="close ripple"><i  class="material-icons-round">close</i></button>
        <div class="slideshow-container">
            <div v-for="(image, i) in images" :key="i" :class="{
                'longdo-slide-hide': i !== currentImage,
                'longdo-slide-active': i === currentImage
            }">
                <div class="numbertext">{{ (i + 1)  }}/ {{ images.length }}</div>
                <img :src="image.original" style="width:100%; max-height:100vh">
                <!-- <div class="text">{{ image }}</div> -->
            </div>
        </div>
        <br>
        <a class="prev" @click="changeImageStage(-1)">&#10094;</a>
        <a class="next" @click="changeImageStage(1)">&#10095;</a>
    </div>
</template>

<script>
export default {
  name: 'ImagesPreview',
  data () {
    return {
      currentImage: null,
      previewImage: false,
      images: []
    }
  },
  mounted () {
    const self = this
    self.$root.$on('onPreviewDisplay', (val) => {
      self.previewImage = true
      self.images = val.images
      self.currentImage = val.imageIndex
    })
  },
  methods: {
    onStage (index) {
      let i
      const self = this
      const slides = document.getElementsByClassName('longdo-slide-hide')
      if (index > slides.length) { self.currentImage = 1 }
      if (index < 1) { self.self = slides.length }
      for (i = 0; i < slides.length; i++) {
        slides[i].className = 'longdo-slide-hide'
      }
    },
    closeImagePreview () {
      const self = this
      self.previewImage = false
    },
    changeImageStage (n) {
      const self = this
      self.currentImage = self.currentImage += n
      if (self.currentImage <= 0) {
        self.currentImage = 0
      }
      self.onStage(self.currentImage)
    }
  }
}
</script>

<style lang="scss" scoped>
.images-preview-container {
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 9999;
    height: 100vh;
    background-color: #333333de;

    button.close{
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 0;
        border: none;
        background-color: white;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        i{
            font-size: 32px;
        }
    }

    .slideshow-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .longdo-slide-hide {
        display: none;
    }
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        width: auto;
        top: 50%;
        padding: 16px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        background-color: $darkPrimary;
    }
    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }
    .prev:hover,
    .next:hover {
        background-color: $lightPrimary;
    }

    /* Caption text */
    .text {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        bottom: 8px;
        width: 100%;
        text-align: center;
    }
    .numbertext {
        color: white;
        font-weight: bold;
        font-size: 14px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    .dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }
    .fade {
        animation-name: fade;
        animation-duration: 0.5s;
    }

    @keyframes fade {
        from {
            opacity: .4
        }

        to {
            opacity: 1
        }
    }
}
</style>
