<template>
  <div
    class="settings"
    :class="{
      hide:
        $route.hash !== '#settings' &&
        $route.hash !== '#language-setting' &&
        !(
          ($route.hash === '#show-velocity-setting' ||
            $route.hash === '#velocity-unit-setting') &&
          isOnMobileApp
        ),
    }"
  >
    <transition name="fade">
      <div class="restart-toast" v-if="isShowToastRestartApplication">
        <div>{{ $t("settings.plsRestartApplication") }}</div>
      </div>
    </transition>
    <div class="title-bar">
      <button class="back ripple" @click="clickBack()">
        <i class="material-icons-round"> arrow_back </i>
      </button>
      <div class="title" v-if="$route.hash === '#settings'">
        {{ $t("settings.title") }}
      </div>
      <div class="title" v-if="$route.hash === '#language-setting'">
        {{ $t("settings.language") }}
      </div>
      <div
        class="title"
        v-if="$route.hash === '#show-velocity-setting' && isOnMobileApp"
      >
        {{ $t("settings.displayVelocity") }}
      </div>
      <div
        class="title"
        v-if="$route.hash === '#velocity-unit-setting' && isOnMobileApp"
      >
        {{ $t("settings.velocityUnit") }}
      </div>
    </div>
    <div class="setting-list" v-if="$route.hash === '#settings'">
      <button
        class="setting-field ripple"
        @click="openSelect('#language-setting')"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.language") }}
          </div>
          <div class="detail">
            {{ $i18n.locale === "th" ? "ไทย" : "English" }}
          </div>
        </div>
      </button>
      <button
        class="setting-field zoom-enable ripple"
        @click="toggleEnableZoomButton"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.enableZoomButton") }}
          </div>
          <div class="detail">
            {{ $t("settings.detailEnableZoomButton") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{ enableZoomButton ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        @click="toggleEnableMeasurementToolButton"
        v-if="!isTrafficProduct && !isLongdoMapV3"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.enableMeasurementToolButton") }}
          </div>
          <div class="detail">
            {{ $t("settings.detailEnableMeasurementToolButton") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{
              enableMeasurementToolButton
                ? "check_box"
                : "check_box_outline_blank"
            }}
          </i>
        </div>
      </button>
      <button class="setting-field ripple" @click="toggleShowFavorites">
        <div class="description">
          <div class="name">
            {{ $t("settings.showFavoritesButton") }}
          </div>
          <div class="detail">
            {{ $t("settings.detailShowFavoritesButton") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{ showFavorites ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </button>
      <button class="setting-field ripple" @click="toggleShowCreated">
        <div class="description">
          <div class="name">
            {{ $t("settings.showCreatedButton") }}
          </div>
          <div class="detail">
            {{ $t("settings.detailShowCreatedButton") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{ showCreated ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </button>
      <button class="setting-field ripple" @click="toggleShowMyPlacesName">
        <div class="description">
          <div class="name">
            {{ $t("settings.showMyPlacesNameButton") }}
          </div>
          <div class="detail">
            {{ $t("settings.detailShowMyPlacesNameButton") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{ showMyPlacesName ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        @click="toggleSpeedCamera"
        v-if="isOnMobileApp"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.speedCameraWarning") }}
          </div>
          <div class="detail">
            {{ $t("settings.speedCameraWarningDetail") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{ showSpeedCamera ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        @click="toggleAccidentBlackSpot"
        v-if="isOnMobileApp"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.highRiskWarning") }}
          </div>
          <div class="detail">
            {{ $t("settings.highRiskWarningDetail") }}
          </div>
        </div>
        <div class="toggle">
          <i class="material-icons-round">
            {{ showAccidentBlackSpot ? "check_box" : "check_box_outline_blank" }}
          </i>
        </div>
      </button>
      <div class="setting-field ripple" v-if="isOnMobileApp">
        <div class="name">
           {{ $t("settings.showSpeedCamLevel") }} {{showSpeedCameraLevel}}
        </div>
        <input type="range" min="1" max="22" v-model="showSpeedCameraLevel" step="1" @touchend="setSpeedCamShowLevel" />
      </div>
      <button
        class="setting-field ripple"
        @click="openSelect('#show-velocity-setting')"
        v-if="isOnMobileApp"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.displayVelocity") }}
          </div>
          <div class="detail">
            {{ $t(`settings.displayVelocityOptions.${showVelocity}`) }}
          </div>
        </div>
      </button>
      <button
        class="setting-field ripple"
        @click="openSelect('#velocity-unit-setting')"
        v-if="isOnMobileApp"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.velocityUnit") }}
          </div>
          <div class="detail">
            {{ $t(`settings.velocityUnitOptions.${velocityUnit}`) }}
          </div>
        </div>
      </button>
    </div>
    <div
      class="language-setting setting-list"
      v-if="$route.hash === '#language-setting'"
    >
      <button
        class="setting-field ripple"
        :class="{ active: $i18n.locale === 'th' }"
        @click="selectLanguage('th')"
      >
        <div class="description">
          <div class="name">ไทย</div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        :class="{ active: $i18n.locale === 'en' }"
        @click="selectLanguage('en')"
      >
        <div class="description">
          <div class="name">English</div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
    </div>
    <div
      class="language-setting setting-list"
      v-if="$route.hash === '#show-velocity-setting' && isOnMobileApp"
    >
      <button
        class="setting-field ripple"
        :class="{ active: showVelocity === 'always' }"
        @click="selectShowVelocity('always')"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.displayVelocityOptions.always") }}
          </div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        :class="{ active: showVelocity === 'layers' }"
        @click="selectShowVelocity('layers')"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.displayVelocityOptions.layers") }}
          </div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        :class="{ active: showVelocity === 'none' }"
        @click="selectShowVelocity('none')"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.displayVelocityOptions.none") }}
          </div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
    </div>
    <div
      class="language-setting setting-list"
      v-if="$route.hash === '#velocity-unit-setting' && isOnMobileApp"
    >
      <button
        class="setting-field ripple"
        :class="{ active: velocityUnit === 'km' }"
        @click="selectVelocityUnit('km')"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.velocityUnitOptions.km") }}
          </div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
      <button
        class="setting-field ripple"
        :class="{ active: velocityUnit === 'mile' }"
        @click="selectVelocityUnit('mile')"
      >
        <div class="description">
          <div class="name">
            {{ $t("settings.velocityUnitOptions.mile") }}
          </div>
        </div>
        <div class="check">
          <i class="material-icons-round"> check </i>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Settings } from 'luxon'

export default {
  name: 'Settings',
  data () {
    return {
      enableZoomButton: localStorage.enableZoomButton
        ? localStorage.enableZoomButton === 'true'
        : true,
      enableMeasurementToolButton: localStorage.enableMeasurementToolButton
        ? localStorage.enableMeasurementToolButton === 'true'
        : true,
      showFavorites: localStorage.showFavorites
        ? localStorage.showFavorites === 'true'
        : true,
      showMyPlacesName: localStorage.showMyPlacesName
        ? localStorage.showMyPlacesName === 'true'
        : true,
      showCreated: localStorage.showCreated
        ? localStorage.showCreated === 'true'
        : true,
      showSpeedCamera: localStorage.showSpeedCamera
        ? localStorage.showSpeedCamera === 'true'
        : true,
      showAccidentBlackSpot: localStorage.showAccidentBlackSpot
        ? localStorage.showAccidentBlackSpot === 'true'
        : true,
      showVelocity: localStorage.showVelocity
        ? localStorage.showVelocity
        : 'always',
      velocityUnit: localStorage.velocityUnit
        ? localStorage.velocityUnit
        : 'km',
      isShowToastRestartApplication: false,
      showSpeedCameraLevel: localStorage.showSpeedCameraLevel
        ? parseInt(localStorage.showSpeedCameraLevel) : 9
    }
  },
  methods: {
    clickBack () {
      const self = this
      if (window.history.length <= 2) {
        self.$router.replace({
          name: 'Main',
          hash: '',
          params: {},
          query: {}
        })
      } else {
        this.$router.back()
      }
    },
    openSelect (settingHash) {
      const self = this
      self.$router.push({
        path: self.$route.path,
        params: self.$route.params,
        query: self.$route.query,
        hash: settingHash
      })
    },
    async selectLanguage (language) {
      const self = this
      self.$i18n.locale = language
      localStorage.lang = language
      Settings.defaultLocale = language
      if (self.isOnMobileApp) {
        try {
          const result = await self.lji.setLanguage({
            lang: language
          })

          // {Bool} result
          if (result) {
            self.isShowToastRestartApplication = true
            setTimeout(() => {
              self.isShowToastRestartApplication = false
            }, 2000)
          }
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
    },
    selectShowVelocity (showVelocity) {
      const self = this
      self.showVelocity = showVelocity
      localStorage.showVelocity = showVelocity
      self.$root.$emit('change_show_velocity_badge_setting', showVelocity)
    },
    selectVelocityUnit (velocityUnit) {
      const self = this
      self.velocityUnit = velocityUnit
      localStorage.velocityUnit = velocityUnit
      self.$root.$emit('change_velocity_unit_setting', velocityUnit)
    },
    toggleEnableZoomButton () {
      const self = this
      self.enableZoomButton = !self.enableZoomButton
      localStorage.enableZoomButton = self.enableZoomButton
      self.$root.$emit('changeEnableZoomButton', self.enableZoomButton)
    },
    toggleEnableMeasurementToolButton () {
      const self = this
      self.enableMeasurementToolButton = !self.enableMeasurementToolButton
      localStorage.enableMeasurementToolButton =
        self.enableMeasurementToolButton
      self.$root.$emit(
        'changeEnableMeasurementToolButton',
        self.enableMeasurementToolButton
      )
    },
    toggleShowFavorites () {
      const self = this
      self.showFavorites = !self.showFavorites
      localStorage.showFavorites = self.showFavorites
      self.$root.$emit('changeShowMyPlaces', {
        showFavorites: self.showFavorites,
        showCreated: self.showCreated
      })
    },
    toggleShowMyPlacesName () {
      const self = this
      self.showMyPlacesName = !self.showMyPlacesName
      localStorage.showMyPlacesName = self.showMyPlacesName
      self.$root.$emit('changeShowMyPlacesName', self.showMyPlacesName)
    },
    toggleShowCreated () {
      const self = this
      self.showCreated = !self.showCreated
      localStorage.showCreated = self.showCreated
      self.$root.$emit('changeShowMyPlaces', {
        showFavorites: self.showFavorites,
        showCreated: self.showCreated
      })
    },
    toggleSpeedCamera () {
      const self = this
      self.showSpeedCamera = !self.showSpeedCamera
      localStorage.showSpeedCamera = self.showSpeedCamera
      self.$root.$emit('setShowSpeedCamera', self.showSpeedCamera)
    },
    toggleAccidentBlackSpot () {
      const self = this
      self.showAccidentBlackSpot = !self.showAccidentBlackSpot
      localStorage.showAccidentBlackSpot = self.showAccidentBlackSpot
      self.$root.$emit('setShowAccidentBlackSpot', self.showAccidentBlackSpot)
    },
    setSpeedCamShowLevel () {
      const self = this
      localStorage.showSpeedCameraLevel = self.showSpeedCameraLevel
      if (self.showSpeedCamera) {
        // remove tag before
        self.$root.$emit('setShowSpeedCamera', false)
        // add new tag to change visible range
        self.$root.$emit('setShowSpeedCamera', self.showSpeedCamera)
      }
      if (self.showAccidentBlackSpot) {
        self.$root.$emit('setShowAccidentBlackSpot', false)
        self.$root.$emit('setShowAccidentBlackSpot', self.showAccidentBlackSpot)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// @import '@/assets/style/variables.scss';

.settings {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 200;
  transition: left 0.3s ease 0s;

  > .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s;
  }

  > .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  > .restart-toast {
    position: fixed;
    top: 0;
    left: 50%;
    translate: (-50%);
    width: max-content;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      padding: 12px 24px;
      margin: 3px;
      border-radius: 12px;
      background-color: white;
    }
  }
  > .title-bar {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(0deg, $primary, $darkPrimary);
    border-bottom: 4px solid $lightPrimary;
    box-sizing: border-box;

    > button.back {
      border: none;
      background-color: transparent;
      padding: 0px;
      width: 36px;
      height: 36px;
      margin: 0px 6px 0px 6px;
      outline: none;
      appearance: none;
      display: flex;
      justify-content: center;
      align-items: center;

      > i {
        color: rgb(255, 255, 255);
        font-size: 24px;
      }
    }

    > .title {
      font-size: 18px;
      font-family: Prompt;
      color: rgb(255, 255, 255);
    }
  }

  > .setting-list {
    width: 100%;
    height: calc(100% - 54px);
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (min-width: $mobileMaxSize) {
      .zoom-enable {
        display: none !important;
      }
    }

    > button.setting-field {
      width: 100%;
      min-height: 54px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border: none;
      padding: 12px;
      background-color: transparent;
      border-bottom: 1px solid $extraLightGrey;
      outline: none;
      appearance: none;

      > .description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > .name {
          font-size: 16px;
          line-height: 1.5;
          text-align: left;
        }

        > .detail {
          color: $grey;
          text-align: left;
        }
      }

      > .check {
        margin: 0px 0px 0px auto;

        > i {
          color: $primary;
          opacity: 0;
          transition: opacity 0.3s ease 0s;
        }
      }

      > .toggle {
        margin: 0px 0px 0px auto;

        > i {
          color: $primary;
        }
      }
    }

    > button.setting-field.active {
      > .check {
        > i {
          opacity: 1;
        }
      }
    }

    > div.setting-field {
      width: 100%;
      min-height: 54px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      border: none;
      padding: 12px;
      background-color: transparent;
      border-bottom: 1px solid $extraLightGrey;
      outline: none;
      appearance: none;
      box-sizing: border-box;

      >.name {
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
      }

      >input[type="range"] {
        width: calc(100vw - 24px);
        height: 54px;
        pointer-events: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          pointer-events: auto;
          appearance: none;
          border: 1px solid $lightPrimary;
          border-radius: 26px;
          height: 26px;
          width: 26px;
        }
      }
    }
  }
}

.settings.hide {
  left: 100%;
}
</style>
