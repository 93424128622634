<template>
  <div :class="{
    'map-type-panel': !isLongdoMapV3,
    'map-type-panel-map3': isLongdoMapV3,
    'hide': isHidingMapTypePanel
  }">
    <label class="panel-name">
      {{ $t('mapTypePanel.name') }}
    </label>
    <div class="divider-h"></div>
    <button class="close ripple" @click="clickClose">
      <i class="material-icons-round">close</i>
    </button>
    <div class="panel-content" v-if="!isHidingMapTypePanel">
      <button v-if="isLongdoMapV3" class="toggle-input"
      @click.stop="isShowingPoi = !isShowingPoi"
      :class="{active: isShowingPoi && !isGoogleBaseMap}"
    >
      <div class="toggle">
        <div class="track"></div>
        <div class="thumb"></div>
      </div>
      <label>{{ $t('mapTypePanel.showPoi') }}</label>
    </button>
    <button v-if="isLongdoMapV3" class="toggle-input"
      @click.stop="isShowingShade = !isShowingShade"
      :class="{active: isShowingShade}"
    >
      <div class="toggle">
        <div class="track"></div>
        <div class="thumb"></div>
      </div>
      <label>{{ $t('mapTypePanel.showShade') }}</label>
    </button>
      <div class="map-type-group" v-for="group in mapTypeList" :key="group.groupTitle">
        <label class="title">
          {{ $t((isLongdoMapV3 ? 'mapTypePanel.v3.group.' : '') + group.groupTitle) }}
        </label>
        <div class="map-type-list">
          <button v-for="mapType in group.items" class="map-type ripple" :class="{
            'map-type-v2': !isLongdoMapV3,
            'active': mapType === selectedMapType
          }" :key="mapType" @click="clickMapType(mapType)">
            <img class="thumbnail" :src="utility.getImgPath(`mapType/${isLongdoMapV3 ? 'v3/' : 'v2/'}${mapType}@3x.png`)"
              :srcset="utility.getImgPathSet(`mapType/${isLongdoMapV3 ? 'v3/' : 'v2/'}${mapType}`)" loading="lazy">
            <label class="title">
              {{ $t('mapTypePanel.' + (isLongdoMapV3 ? 'v3.' : '') + mapType) }}
            </label>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShadeMap from 'mapbox-gl-shadow-simulator'
export default {
  name: 'MapTypePanel',
  props: {
    map: {
      type: Object
    }
  },
  data () {
    return {
      selectedMapType: process.env.VUE_APP_PRODUCT === 'traffic' ? 'GRAY' : 'POI',
      isShowingPoi: process.env.VUE_APP_PRODUCT !== 'traffic',
      isShowingShade: false,
      shadeMap: null,
      mapTypeList: [
        {
          groupTitle: 'Longdo',
          items: ['POI', 'NORMAL', 'DARK_POI', 'LIGHT_NORMAL', 'GRAY', 'THAICHOTE', 'POLITICAL', 'TERRAIN', 'LONGDO_OSM']
        },
        {
          groupTitle: 'OpenStreetMap',
          items: ['OSM', 'MAPBOX_LONGDO_OSM']
        },
        {
          groupTitle: 'GISTDA sphere',
          items: ['SPHERE_STREETS', 'SPHERE_IMAGES', 'SPHERE_HYBRID']
        },
        {
          groupTitle: 'TomTom',
          items: ['TOMTOM_POI', 'TOMTOM_NORMAL']
        },
        {
          groupTitle: 'Mapbox',
          items: ['MAPBOX_STREETS']
        },
        {
          groupTitle: 'Google',
          items: ['GOOGLE_ROADMAP', 'GOOGLE_TRAFFIC', 'GOOGLE_SATELLITE']
        },
        {
          groupTitle: 'ESRI',
          items: ['ARCGIS_WORLD_TOPO_MAP', 'ARCGIS_WORLD_IMAGERY']
        },
        {
          groupTitle: 'Bing VirtualEarth',
          items: ['BING_VIRTUALEARTH']
        }
      ],
      enMapTypeList: ['POI', 'NORMAL', 'DARK_POI', 'LIGHT_NORMAL', 'GRAY', 'POLITICAL', 'SPHERE_STREETS', 'SPHERE_HYBRID']
    }
  },
  computed: {
    isHidingMapTypePanel () {
      const self = this
      return self.$route.hash !== '#maps'
    },
    isGoogleBaseMap () {
      const self = this
      return self.selectedMapType.indexOf('GOOGLE_') >= 0
    }
  },
  mounted () {
    const self = this
    self.clickMapType(self.selectedMapType)
  },
  beforeMount () {
    if (this.isLongdoMapV3) {
      this.mapTypeList = [
        {
          groupTitle: 'Longdo',
          items: ['NORMAL', 'LIGHT', 'GRAY', 'NIGHT', 'DARK', 'THAICHOTE', 'POLITICAL']
        },
        {
          groupTitle: 'sphere',
          items: ['SPHERE_STREETS', 'SPHERE_IMAGES', 'SPHERE_HYBRID']
        },
        {
          groupTitle: 'Google',
          items: ['GOOGLE_ROADMAP', 'GOOGLE_TRAFFIC', 'GOOGLE_HYBRID', 'GOOGLE_SATELLITE']
        },
        // {
        //   groupTitle: 'TomTom',
        //   items: ['TOMTOM_POI', 'TOMTOM_NORMAL']
        // },
        {
          groupTitle: 'Others',
          items: ['OSM']
        }
      ]
      this.selectedMapType = process.env.VUE_APP_PRODUCT === 'traffic' ? 'GRAY' : 'NORMAL'
    }
  },
  methods: {
    clickMapType (mapType) {
      const self = this
      self.selectedMapType = mapType
      if (self.enMapTypeList.indexOf(self.selectedMapType) !== -1 && !self.isLongdoMapV3) {
        self.$emit('clickMapType', self.selectedMapType + (self.$i18n.locale === 'th' ? '' : '_EN'))
      } else {
        self.$emit('clickMapType', self.selectedMapType)
      }
      // if (self.isGoogleBaseMap) {
      //   self.map.Tags.remove('%')
      //   self.$root.$emit('hideLayer', self.layerList[0].name)
      // }
    },
    clickClose () {
      const self = this
      self.$emit('clickClose')
    },
    updateShowTag () {
      const self = this
      const showTag = self.isShowingPoi && !self.isGoogleBaseMap
      showTag ? self.map.Tags.add('%', {
        label: true,
        dataset: ' '
      }) : self.map.Tags.remove('%')
    }
  },
  watch: {
    '$i18n.locale' () {
      const self = this
      self.$nextTick(() => {
        self.clickMapType(self.selectedMapType)
        if (self.isLongdoMapV3) {
          self.map.Tags.remove('%')
          setTimeout(() => {
            self.updateShowTag()
          }, 2000)
        }
      })
    },
    isShowingPoi () {
      const self = this
      self.$nextTick(() => {
        self.updateShowTag()
      })
    },
    selectedMapType () {
      const self = this
      self.$nextTick(() => {
        setTimeout(() => {
          self.updateShowTag()
          if (self.isGoogleBaseMap) {
            self.$root.$emit('hideLayer', self.layerList[0].name)
          }
        }, 1000)
      })
    },
    isShowingShade () {
      const self = this
      self.$nextTick(async () => {
        if (self.isShowingShade) {
          self.map.Renderer.setMaxPitch(45)
          if (!self.shadeMap) {
            self.shadeMap = new ShadeMap({
              apiKey: process.env.VUE_APP_SHADE_API_KEY,
              date: new Date(),
              color: '#01112f',
              opacity: 0.7,
              terrainSource: {
                tileSize: 514,
                maxZoom: 14,
                getSourceUrl: ({ x, y, z }) => `https://${'abcd'[(x + y) % 4]}.tiles.mapbox.com/raster/v1/mapbox.mapbox-terrain-dem-v1/${z}/${x}/${y}.webp?sku=101wuwGrczDtH&access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`,
                getElevation: ({ r, g, b, a }) => -10000 + ((r * 256 * 256 + g * 256 + b) * 0.1),
                _overzoom: 18
              },
              getFeatures: async () => new Promise((resolve) => {
                const build = () => resolve(self.map.Renderer.querySourceFeatures('longdo', { sourceLayer: 'building' }))
                if (self.map.Renderer.loaded()) {
                  build()
                } else {
                  self.map.Renderer.once('idle', build)
                }
              })
            })
          }
          self.shadeMap.addTo(self.map.Renderer)
        } else {
          if (self.shadeMap) {
            self.shadeMap.remove(self.map.Renderer)
          }
          self.map.Renderer.setMaxPitch(60)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
$thumbnailSize: 128px;

// Map 2 Mobile
.map-type-panel {
  width: 100%;
  height: 75%;
  border-radius: 12px 12px 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 12px 12px $toolBarHeight 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0%;
  left: 0px;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

  >label.panel-name {
    font-family: Prompt;
    font-size: 18px;
    color: $primary;
  }

  >button.close {
    padding: 0px;
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    i {
      color: $grey;
    }
  }
}

.map-type-panel.hide {
  bottom: calc(0px - 75% - 12px);
  box-shadow: none;
}

// End Map 2 Mobile

// Mobile
@media only screen and (max-width: $mobileMaxSize) {
  .map-type-panel-map3 {
    width: 100%;
    height: 75%;
    border-radius: 12px 12px 0px 0px;
    background-color: rgb(255, 255, 255);
    padding: 12px 12px $toolBarHeight 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    bottom: 0%;
    left: 0px;
    transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    >label.panel-name {
      font-family: Prompt;
      font-size: 18px;
      color: $primary;
    }

    >button.close {
      padding: 0px;
      position: absolute;
      top: 12px;
      right: 12px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      i {
        color: $grey;
      }
    }
  }

  .map-type-panel-map3.hide {
    bottom: calc(0px - 75% - 12px);
    box-shadow: none;
  }
}

// Desktop
@media only screen and (min-width: $mobileMaxSize) {
  .map-type-panel-map3 {
    width: 385px;
    height: 70%;
    border-radius: 12px 12px 12px 12px;
    background-color: white;
    padding: 12px 0px 10px 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    position: fixed;
    top: 50px;
    right: 105px;
    opacity: 1;
    transition: right 0.2s cubic-bezier(0.50, 0.90, 0.90, 1) 0s, opacity 0.2s cubic-bezier(0.50, 0.90, 0.90, 1) 0s;

    >label.panel-name {
      font-family: Prompt;
      font-size: 18px;
      color: $primary;
    }

    >button.close {
      padding: 0px;
      position: absolute;
      top: 12px;
      right: 12px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      i {
        color: $grey;
      }
    }
  }

  .map-type-panel-map3.hide {
    opacity: 0;
    pointer-events: none;
    right: calc(120px - 0%);
    box-shadow: none;
  }
}

// END MAP 3

.panel-content {
  width: 100%;
  height: calc(100% - 28px);
  overflow: hidden;
  overflow-y: auto;
  padding: 0px 0px 12px 0px;

  >.map-type-group {
    padding: 12px 0px;

    >.title {
      margin-left: 10px;
      font-weight: bold;
    }

    >.map-type-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      >.map-type {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 12px 12px 0px 12px;
        outline: none;

        >.thumbnail {
          width: $thumbnailSize;
          height: $thumbnailSize;
          background-image: linear-gradient(to right, rgb(250, 250, 250), rgb(240, 240, 240), rgb(240, 240, 240), rgb(250, 250, 250));
          background-size: $thumbnailSize $thumbnailSize;
          border-radius: 6px;
          display: block;
          overflow: hidden;
          animation: move 2s infinite ease;
          border: 2px solid rgb(255, 255, 255);
        }

        @keyframes move {
          from {
            background-position: - calc(#{$thumbnailSize} / 1) center;
          }

          to {
            background-position: calc(#{$thumbnailSize} / 1) center;
          }
        }

        >.title {
          margin: 6px 0px;
        }
      }

      .map-type {
        width: calc(100% / 2);
      }

      @media (max-width: $mobileMaxSize) {
        .map-type {
          width: calc(100% / 2);
        }
      }

      @media (min-width: 1024px) {
        .map-type-v2 {
          width: calc(100% / 6) !important;
        }
      }

      >.map-type.active {
        >.thumbnail {
          border: 2px solid $darkPrimary;
        }

        >.title {
          font-weight: bold;
        }
      }
    }
  }
}

.toggle-input {
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  padding: 0px;
  border: none;
  margin: 0px auto 0px 10px;

  > .toggle {
    position: relative;
    width: 30px;
    height: 100%;

    > .track {
      background-color: $lightGrey;
      width: 100%;
      height: 18px;
      border-radius: 9px;
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 0.3s ease 0s;
    }

    > .thumb {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: rgb(255, 255, 255);
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      border: 3px solid $lightGrey;
      box-sizing: border-box;
      transition: left 0.3s ease 0s,
        background-color 0.3s ease 0s,
        border 0.3s ease 0s;
    }
  }

  > label {
    font-weight: bold;
    margin-left: 10px;
  }
}

.toggle-input.active {
  > .toggle {
    > .track {
      background-color: $darkPrimary;
    }
    > .thumb {
      background-color: rgb(255, 255, 255);
      border: 3px solid $darkPrimary;
      left: calc(100% - 18px);
    }
  }
}
</style>
