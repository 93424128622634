import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    beforeEnter (to, from, next) {
      if (to.query.ooi) {
        next({
          name: 'Place',
          params: {
            poiId: to.query.ooi
          }
        })
      } else {
        next(true)
      }
    },
    children: [
      {
        path: 'p/:poiId/',
        name: 'Place'
      },
      {
        path: 'e/:eventId/',
        name: 'Event'
      },
      {
        path: 'e/:eventId/editEvent',
        name: 'editEvent'
      },
      {
        path: 'c/:camId/',
        name: 'Cam'
      },
      {
        path: 'aqiair4thai/:aqiAir4ThaiId/',
        name: 'AqiAir4Thai'
      },
      {
        path: 'aqicn/:aqiCNId/',
        name: 'AqiCN'
      },
      {
        path: '/rainfall/',
        name: 'Rainfall'
      },
      {
        path: 'tag/:tagName/',
        name: 'Tag',
        beforeEnter: (to, from, next) => {
          next({
            name: 'Main',
            query: {
              tag: to.params.tagName
            }
          })
        }
      },
      {
        path: '/go/',
        name: 'Go'
      },
      {
        path: 'nearby/:tagName',
        name: 'Nearby'
      }
    ]
  }, {
    path: '/transit',
    name: 'Transit',
    component: () => import('@/views/Transit.vue')
  }, {
    path: '/p/:poiId/info',
    name: 'InfoPlace',
    component: () => import('@/views/InfoPlace.vue'),
    props: route => {
      let userData = null

      if (route.params.userData) {
        if (route.params.userData.name && route.params.userData.longdousertoken) {
          userData = route.params.userData
        }
      }

      return {
        userData
      }
    }
  }, {
    path: '/p/:poiId/edit',
    name: 'EditPlace',
    component: () => import('@/views/EditPlace.vue'),
    props: route => {
      let userData = null
      const platform = route.query.platform || ''

      if (route.params.userData) {
        if (route.params.userData.name && route.params.userData.longdousertoken) {
          userData = route.params.userData
        }
      }
      if ((route.query.username || '') !== '' && (route.query.longdousertoken || '') !== '') {
        userData = {
          username: route.query.username,
          name: route.query.username,
          longdousertoken: route.query.longdousertoken
        }
      }

      return {
        userData: userData,
        platform: platform,
        isReport: false
      }
    }
  }, {
    path: '/p/:poiId/report',
    name: 'ReportPlace',
    component: () => import('@/views/EditPlace.vue'),
    props: route => {
      let userData = null
      const platform = route.query.platform || ''

      if (route.params.userData) {
        if (route.params.userData.name && route.params.userData.longdousertoken) {
          userData = route.params.userData
        }
      }
      if ((route.query.username || '') !== '' && (route.query.longdousertoken || '') !== '') {
        userData = {
          username: route.query.username,
          name: route.query.username,
          longdousertoken: route.query.longdousertoken
        }
      }

      return {
        userData: userData,
        platform: platform,
        isReport: true
      }
    }
  }, {
    path: '/create',
    name: 'CreatePlace',
    component: () => import('@/views/EditPlace.vue'),
    props: route => {
      let userData = null
      const platform = route.query.platform || ''
      const location = {
        lat: Number(route.params.lat) || Number(route.query.lat) || 13.729319976238605,
        lon: Number(route.params.lon) || Number(route.query.lon) || 100.53589001297951
      }
      const zoom = Number(route.query.zoom) || null

      if (route.params.userData) {
        if (route.params.userData.name && route.params.userData.longdousertoken) {
          userData = route.params.userData
        }
      }
      if ((route.query.username || '') !== '' && (route.query.longdousertoken || '') !== '') {
        userData = {
          username: route.query.username,
          name: route.query.username,
          longdousertoken: route.query.longdousertoken
        }
      }

      return {
        userData,
        platform,
        location,
        zoom
      }
    }
  }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE || 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
