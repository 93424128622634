import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Api } from './assets/js/api.js'
import { Utility } from './assets/js/utility.js'
import i18n from './i18n'
import LongdoMap from 'longdo-map-vue'
import './registerServiceWorker'
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import { Settings } from 'luxon'
import LongdoJsInterface from '@/longdo-js-interface/index.js'

async function addCacheScript (lji, url, cacheName, name) {
  try {
    const result = await lji.addCache({
      cacheName: cacheName,
      url: url,
      name: name || null
    })
    console.log(`add cache script ${cacheName}`)
    console.log(result)
  } catch (error) {
    console.log(`error add cache script ${cacheName}`)
    console.log(error)
  }
}

export let longdoAccount = null;
(async function () {
  window.doubleTouchStartTimestamp = 0
  document.addEventListener('touchstart', function (event) {
    const now = new Date().getTime()
    if (window.doubleTouchStartTimestamp + 500 > now) {
      event.preventDefault()
    }
    window.doubleTouchStartTimestamp = now
  }, { passive: false })

  document.addEventListener('touchmove', (event) => {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  }, { passive: false })

  Vue.config.productionTip = false
  Settings.defaultLocale = process.env.VUE_APP_I18N_LOCALE
  Vue.use(Datetime)
  Vue.use(LongdoMap, {
    load: {
      apiKey: process.env.VUE_APP_LONGDO_MAP_KEY,
      src: process.env.VUE_APP_LONGDO_MAP_API,
      checkApiKey: false
    }
  })

  const api = new Api()
  const utility = new Utility()
  let longdoComAPI = null

  const lji = new LongdoJsInterface()
  if (process.env.VUE_APP_ENABLE_OFFLINE === 'true') {
    if (lji.Util.isIosNativeApp() || lji.Util.isAndroidNativeApp()) {
      const cacheName = 'mapOffline'
      const mapKey = lji.Util.isIosNativeApp() ? process.env.VUE_APP_IOS_MAP_KEY : process.env.VUE_APP_ANDROID_MAP_KEY
      const externalResources = [
        {
          url: 'https://api.longdo.com/map3/js/deck-gl.js'
        },
        {
          url: 'https://api.longdo.com/map3/js/maplibre-gl.css'
        },
        {
          url: 'https://api.longdo.com/map3/js/maplibre-gl.js'
        },
        {
          url: `${process.env.VUE_APP_LONGDO_MAP_API3}/?key=${mapKey}`,
          name: 'longdomapapi.js'
        }
      ]
      const data = await api.getOfflineFileList()
      if (data) {
        await lji.setOffline(data)
      }

      const scriptString = await api.getLongdoMapScript()
      try {
        const result = await lji.addStorage({
          key: 'map_api_script',
          value: scriptString
        })

        // {Boolean} result
        console.log('add script to storage')
        console.log(result)
      } catch (error) {
        // {<{code: Number, message: String}>} error
        console.log('add script to storage error')
        console.log(error)
      }

      for (const resource of externalResources) {
        addCacheScript(lji, resource.url, cacheName, resource.name)
      }
    }
  }

  if (typeof window.LongdoAccount !== 'undefined') {
    longdoAccount = new window.LongdoAccount()
    let lang = 'th'
    if (lji.Util.isIosNativeApp() || lji.Util.isAndroidNativeApp()) {
      try {
        const tmp = await lji.getLanguage()
        // {String} lang
        console.log(tmp)
        if (lji.Util.isIosNativeApp()) {
          lang = tmp
        } else {
          if (tmp.includes('th')) {
            lang = 'th'
          } else {
            self.$i18n.locale = 'en'
            lang = 'en'
          }
        }
      } catch (error) {
        // {<{code: Number, message: String}>} error
        console.log(error)
      }
    } else if (localStorage.lang) {
      lang = localStorage.lang
    }
    const params = new URLSearchParams(window.location.search)
    const platform = params.get('platform')
    const isForceHidePDPADialog = ['android', 'ios', 'desktop'].includes(platform)
    longdoAccount.setPdpaConfig({
      isForceHidePDPADialog
    })
    longdoAccount.initLongdoLogin({
      client: process.env.VUE_APP_PRODUCT,
      locale: lang
    })
  }

  if (typeof window.LongdoComAPI !== 'undefined') {
    longdoComAPI = await new window.LongdoComAPI()
    longdoComAPI.initJQuery()
  }

  const tagCategoryList = await api.getTagCategoryList()
  const tagList = await api.getTagList()
  const layerList = await api.getLayerList()

  if (!tagCategoryList || !tagList || !layerList) {
    return false
  }

  async function storePathToNativeAnalytic (to, from = false) {
    if (lji.Util.isIosNativeApp()) {
      if ((from?.name || '') !== to.name) {
        try {
          const result = await lji.screenView({
            name: to.name
          })

          // {Boolean} result
          if (result) {
            console.log('screen view success - path:', to.name)
          }
          console.log('screen view', result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log('screen view error', error)
        }
      }
      try {
        const result = await lji.logEvent({
          name: 'path_change',
          parameters: {
            from: {
              name: from.name,
              path: from.path,
              hash: from.hash,
              query: from.query,
              params: from.params
            },
            to: {
              name: to.name,
              path: to.path,
              hash: to.hash,
              query: to.query,
              params: to.params
            }
          }
        })

        // {Boolean} result
        console.log('log path change: ', result)
      } catch (error) {
        // {<{code: Number, message: String}>} error
        console.log('log path change error', error)
      }
    }
  }

  async function storeEventToNativeAnalytic (name, params = {}) {
    if (lji.Util.isIosNativeApp()) {
      try {
        const result = await lji.logEvent({
          name: name,
          parameters: params
        })

        // {Boolean} result
        console.log('log event: ', result)
        console.log('log ', name, params)
      } catch (error) {
        // {<{code: Number, message: String}>} error
        console.log('log path change error', error)
      }
    }
  }

  if (lji.Util.isIosNativeApp()) {
    window.gtag = function (type, name, params) {
      storeEventToNativeAnalytic(name, params)
    }
  }

  var checkPdpaInterval = setInterval(async function () {
    var isAcceptPdpa = false
    var match = document.cookie.match(new RegExp('(^| )' + 'longdo_pdpa_longdo' + '=([^;]+)'))
    if (match) {
      if (match[2] === 'true') {
        isAcceptPdpa = true
      }
    }
    if (isAcceptPdpa) {
      clearInterval(checkPdpaInterval)
      if (process.env.VUE_APP_PRODUCT === 'traffic' && isAcceptPdpa) {
        try {
          const result = await lji.sendToProbe({
            on: true
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
    }
  }, 1000)

  var check = setInterval(() => {
    longdoComAPI.jq = window.jQuery
    if (window.longdo && window.jQuery) {
      clearInterval(check)

      window.longdo.Layers.SPHERE_HYBRID = new window.longdo.Layer('SPHERE_HYBRID', {
        type: window.longdo.LayerType.Custom,
        url: function (projection, tile, zoom, hd) {
          return 'https://basemap.sphere.gistda.or.th/tiles/sphere_hybrid/EPSG3857/' + zoom + '/' + tile.u + '/' + tile.v + '.jpeg?key=test2022'
        }
      })

      window.longdo.Layers.SPHERE_HYBRID_EN = new window.longdo.Layer('SPHERE_HYBRID_EN', {
        type: window.longdo.LayerType.Custom,
        url: function (projection, tile, zoom, hd) {
          return 'https://basemap.sphere.gistda.or.th/tiles/sphere_hybrid_en/EPSG3857/' + zoom + '/' + tile.u + '/' + tile.v + '.jpeg?key=test2022'
        }
      })

      window.longdo.Layers.SPHERE_STREETS_EN = new window.longdo.Layer('SPHERE_STREETS_EN', {
        type: window.longdo.LayerType.Custom,
        url: function (projection, tile, zoom, hd) {
          return 'https://basemap.sphere.gistda.or.th/tiles/sphere_streets_en/EPSG3857/' + zoom + '/' + tile.u + '/' + tile.v + '.png?key=test2022'
        }
      })

      setTimeout(async () => {
        document.querySelector('#loading-initial').style.display = 'none'
        document.querySelector('body').style.backgroundColor = 'rgb(255, 255, 255)'
        document.querySelector('#splash-screen-logo').style.opacity = '0'
        document.querySelector('#splash-screen-logo').style.zIndex = '-99'
        document.querySelector('#splash-screen-bg').style.opacity = '0'
        document.querySelector('#splash-screen-bg').style.zIndex = '-99'
        document.querySelector('#splash-screen-bg').style.animation = 'unset'
        if (lji.Util.isIosNativeApp() || lji.Util.isAndroidNativeApp()) {
          try {
            const result = await lji.hideLaunchscreen()

            // {Bool} result
            console.log(result)
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
          try {
            const result = await lji.updateToken({
              on: true
            })

            // {Boolean} result
            console.log(result)
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
        }
      }, 400)

      setTimeout(() => {
        Vue.mixin({
          computed: {
            isTrafficProduct () {
              return process.env.VUE_APP_PRODUCT === 'traffic'
            },
            isMapProduct () {
              return process.env.VUE_APP_PRODUCT === 'map'
            },
            isOnMobileApp () {
              if (lji) {
                return (lji.Util.isIosNativeApp() || lji.Util.isAndroidNativeApp())
              }
              return false
            },
            isIosNativeApp () {
              if (lji) {
                return lji.Util.isIosNativeApp()
              }
              return false
            }
          },
          data () {
            return {
              api,
              utility,
              longdoAccount,
              longdoComAPI,
              lji: lji,
              longdoMapIconPath: process.env.VUE_APP_LONGDO_MAP_ICON,
              longdoMapHdIconPath: process.env.VUE_APP_LONGDO_MAP_HD_ICON,
              longdoMapLogo1X: process.env.VUE_APP_LONGDO_MAP_LOGO_1X,
              longdoMapLogo2X: process.env.VUE_APP_LONGDO_MAP_LOGO_2X,
              tagCategoryList: tagCategoryList.data.data,
              tagList: tagList.data.data,
              layerList: layerList.data,
              appVersion: process.env.VUE_APP_VERSION,
              baseUrl: process.env.VUE_APP_BASE_URL,
              product: process.env.VUE_APP_PRODUCT,
              isCheckNearby: this.$route.name === 'Nearby' && process.env.VUE_APP_PRODUCT === 'map',
              isLongdoMapV3: (this.$route.name === 'CreatePlace' || this.$route.name === 'EditPlace') ? false : ((this.$route.query.map || 'v3') === 'v3'),
              isUseMwaAndNso: (this.$route.query.others || 'false') === 'true',
              mobileMaxSize: 992,
              isOffline: false,
              storePathToNativeAnalytic,
              storeEventToNativeAnalytic,
              enableFloorPlan: process.env.VUE_APP_ENABLE_FLOOR_PLAN === 'true'
            }
          }
        })
        new Vue({
          router,
          i18n,
          render: h => h(App)
        }).$mount('#app')
      }, 400)
    }
  }, 200)
})()
