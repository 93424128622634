<template>
  <div>
    <button
      class="open-traffic-event-panel ripple"
      :class="{ hide: isHidingOpenButton }"
      @click="toggleIsHidingPanel"
    >
      <i class="material-icons-round arrow">arrow_forward</i>
    </button>
    <div
      :class="{
        hide: isHidingPanel,
        'traffic-event-panel': !isLongdoMapV3,
        'traffic-event-panel-map3': isLongdoMapV3,
      }"
    >
      <div class="panel-upper">
        <button class="close ripple" @click="toggleIsHidingPanel">
          <i class="material-icons-round">close</i>
        </button>
        <div class="panel-header">
          <div class="panel-name">
            {{
              isShowingSearchResult
                ? $t("trafficIncidentPanel.searchResult")
                : $t("trafficIncidentPanel.recentIncident")
            }}
          </div>
          <div class="panel-button">
            <button class="see-all">
              <a
                @click="onClickUrl(allEventUrl)"
                >{{ $t("trafficIncidentPanel.seeAllRecentIncident") }}</a
              >
            </button>
          </div>
        </div>
        <div class="panel-content" v-if="trafficIncident !== null">
          <div
            :title="`${incident.contributor}\n${incident.createtime}`"
            class="panel-incident"
            v-for="incident in trafficIncidentList"
            :key="incident.eid"
            :style="{ backgroundColor: incident.backgroundColor }"
            @click="onClickIncident(incident)"
          >
            <div class="front-incident-card">
              <img
                class="icon"
                :src="`${baseIconUrl.replace('{{icon}}', incident.icon.icon)}`"
              />
              <div class="name">
                {{ $i18n.locale === "th" ? incident.title : incident.title_en }}
              </div>
            </div>
            <div class="end-incident-card">
              <img
                class="picture"
                src="https://traffic.longdo.com/sites/all/themes/traffic_theme/images/front/ic_image@2x.png"
                v-if="incident.imagenid !== '0'"
              />
              <div class="date">
                {{
                  incident.isToday
                    ? new Date(incident.start_time).toLocaleTimeString(
                        "th-TH",
                        {
                          minute: "numeric",
                          hour: "numeric",
                        }
                      )
                    : new Date(incident.start_time).toLocaleDateString(
                        `${$i18n.locale === "th" ? "th-TH" : "en-US"}`,
                        {
                          year: "2-digit",
                          month: "short",
                          day: "numeric",
                        }
                      )
                }}
              </div>
            </div>
          </div>
          <button
            :title="$t('trafficIncidentPanel.moreTitle')"
            v-if="
              isShowingSearchResult &&
              trafficIncidentList.length !== 0 &&
              trafficIncidentList.length >= 20 &&
              hasMoreTrafficEvent
            "
            @click="clickHasMore"
            class="has-more"
            :disabled="isLoadingMore || !hasMoreTrafficEvent"
          >
            {{ isLoadingMore ? "" : $t("trafficIncidentPanel.more") }}
            <img v-if="isLoadingMore" src="@/assets/img/loading.gif" />
          </button>
          <div
            v-if="
              isShowingSearchResult &&
              (trafficIncidentList.length === 0 || !hasMoreTrafficEvent)
            "
            class="no-result"
          >
            {{
              trafficIncidentList.length === 0
                ? $t("trafficIncidentPanel.noSearchResult")
                : $t("trafficIncidentPanel.noMore")
            }}
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <a @click="onClickUrl(itsUrl)">
          <img :src="itsLogoUrl" />
        </a>
        <a @click="onClickUrl(iticUrl)">
          <img :src="iticLogoUrl" />
        </a>
        <a @click="onClickUrl(truehitUrl)">
          <img :src="truehitLogoUrl" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrafficIncidentPanel',
  data () {
    return {
      baseIconUrl: process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_TYPE_PATH_ICON,
      isHidingPanel: false,
      isShowingMapOnly: false
    }
  },
  mounted () {
    const self = this
    self.isHidingPanel =
      self.$route.hash === '#search' ||
      self.$route.hash === '#explore' ||
      self.$route.hash === '#routing' ||
      'search' in self.$route.query ||
      self.$route.name === 'Place' ||
        ((self.isShowingMapOnly ||
          self.$route.hash === '#maps' ||
          self.$route.hash === '#layers') &&
          window.innerWidth <= self.mobileMaxSize)
    self.$parent.$on('openTrafficIncidentPanel', () => {
      self.isHidingPanel = false
    })
    self.$parent.$on('clickMap', self.onClickMap)
  },
  watch: {
    $route (to, from) {
      const self = this
      if (
        to.hash === '#search' ||
        to.hash === '#routing' ||
        to.hash === '#explore' ||
        'search' in to.query ||
        to.name === 'Place' ||
        ((self.isShowingMapOnly ||
          to.hash === '#maps' ||
          to.hash === '#layers') &&
          window.innerWidth <= self.mobileMaxSize)
      ) {
        self.isHidingPanel = true
      }
    },
    // when open report event form panel by right click while this panel is showing then hide this panel to not cover
    hasOpenReportEventForm () {
      const self = this
      if (self.hasOpenReportEventForm) self.isHidingPanel = true
    }
  },
  props: {
    trafficIncident: {
      type: Array,
      default: null
    },
    trafficEventType: {
      type: Array
    },
    map: {
      type: Object
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    },
    hasMoreTrafficEvent: {
      type: Boolean,
      default: true
    },
    hasOpenReportEventForm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isHidingOpenButton () {
      const self = this
      return (
        !self.isHidingPanel ||
        self.$route.hash === '#search' ||
        self.$route.hash === '#explore' ||
        self.$route.hash === '#routing' ||
        self.$route.name === 'Place' ||
        ((self.isShowingMapOnly ||
          self.$route.hash === '#maps' ||
          self.$route.hash === '#layers') &&
          window.innerWidth <= self.mobileMaxSize)
      )
    },
    trafficIncidentList () {
      // sort stick first then by latest start time
      const self = this
      if ('searchEvent' in self.$route.query) {
        // if find or filter not consider sticky
        return self.trafficIncident.sort(function (a, b) {
          if (new Date(a.start_time) > new Date(b.start_time)) {
            return -1
          } else {
            return 1
          }
        })
      } else {
        return self.trafficIncident.sort(function (a, b) {
          if (a.sticky > b.sticky) {
            // a should be in front of b
            return -1
          } else if (a.sticky < b.sticky) {
            // b shoule be in front of a
            return 1
          } else {
            if (new Date(a.start_time) > new Date(b.start_time)) {
              return -1
            } else {
              return 1
            }
          }
        })
      }
    },
    isShowingSearchResult () {
      const self = this
      return 'searchEvent' in self.$route.query
    },
    allEventUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_ALL_EVENT
    },
    itsLogoUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_CREDIT_ITS_LOGO_URL
    },
    itsUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_CREDIT_ITS_URL
    },
    iticLogoUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_CREDIT_ITIC_LOGO_URL
    },
    iticUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_CREDIT_ITIC_URL
    },
    truehitLogoUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_CREDIT_TRUE_HIT_LOGO_URL
    },
    truehitUrl () {
      return process.env.VUE_APP_LONGDO_TRAFFIC_CREDIT_TRUE_HIT_URL
    }
  },
  methods: {
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank').focus()
      }
    },
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isShowingMapOnly = isShowingMapOnly
    },
    onClickInformationButton () {
      const self = this
      self.$router.replace({
        path: self.$route.path,
        query: {
          searchEvent: 'event:ประกาศ'
        }
      })
    },
    clickHasMore () {
      const self = this
      self.$emit('clickHasMoreTrafficEvent')
    },
    toggleIsHidingPanel () {
      const self = this
      self.isHidingPanel = !self.isHidingPanel
    },
    onClickIncident (incident) {
      const self = this
      if (self.map.zoom() < 12) {
        self.map.zoom(12, false)
      }
      self.map.location(
        { lat: incident.latitude, lon: incident.longitude },
        true
      )
      self.$emit('fireTrafficIncidentClick', incident)
    }
  }
}
</script>

<style lang="scss" scoped>
.open-traffic-event-panel {
  position: fixed;
  left: 0px;
  z-index: 120;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  border-color: transparent;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease 0s;
  &.hide {
    left: -64px;
  }

  .arrow {
    color: $primary;
  }

  @media screen and (max-width: $mobileMaxSize) {
    display: none;
    top: unset;
    bottom: 330px;
    min-width: fit-content;
    width: 30px;
    height: 50px;
  }

  @media screen and (min-width: $mobileMaxSize) {
    top: calc(50vh - 20px);
    min-width: fit-content;
    width: 40px;
    height: 80px;

    @media screen and (max-height: 820px) {
      display: none;
    }
  }
}

// v3
.traffic-event-panel-map3 {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 12px;
  top: 110px;
  border-radius: 6px;
  z-index: 195;
  background-color: white;

  &.hide {
    display: none;
  }
  @media screen and (max-width: $mobileMaxSize) {
    display: none;
    position: fixed;
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 75%;
    z-index: 1000;
    border-radius: 12px 12px 0px 0px;
  }

  @media screen and (min-width: $mobileMaxSize) {
    width: calc(100% - 24px);
    max-width: 384px;
  }

  .panel-upper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    > button.close {
      padding: 0px;
      position: absolute;
      top: 16px;
      right: 12px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      i {
        color: $grey;
      }
    }
    .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 48px 12px 12px;
      > .panel-name {
        font-size: 16px;
        color: #282828;
      }
      > .panel-button {
        display: flex;
        column-gap: 12px;
        > button {
          &.see-all {
            color: #f4fcf9;
            background-color: $darkPrimary;

            > a {
              text-decoration: none;
              color: #f4fcf9;
            }
          }
          padding: 4px 12px;
          border-radius: 6px;
          border-color: transparent;
          cursor: pointer;
          width: max-content;
        }
      }
    }
    .panel-content {
      display: flex;
      flex-direction: column;
      padding: 0px 12px;
      gap: 3px;
      overflow-x: hidden;
      overflow-y: scroll;
      @media screen and (min-width: $mobileMaxSize) {
        max-height: 530px;
      }

      .no-result {
        text-align: center;
        padding: 12px 6px;
      }

      > button.has-more {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        padding: 12px 6px;
        cursor: pointer;
        outline: none;
        color: $darkPrimary;
        background-color: #f4fcf9;
        -webkit-transition: background-color 0.3s ease 0s;
        transition: background-color 0.3s ease 0s;

        > img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .panel-footer {
    padding: 12px;
    display: flex;
    align-items: center;
    column-gap: 36px;
    justify-content: center;

    > a {
      height: 24px;
      > img {
        height: 100%;
      }
    }
  }

  .panel-incident {
    padding: 11px 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .front-incident-card {
      display: flex;
      width: 100%;
      align-items: center;
      > .icon {
        width: 16px;
        height: 16px;
        margin-right: 14px;
      }
      > .name {
        width: 100%;
        @media screen and (min-width: $mobileMaxSize) {
          max-width: 200px;
        }
        word-wrap: break-word;
        margin-right: 12px;
      }
    }
    .end-incident-card {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 90px;
      height: 100%;

      .picture {
        width: 12px;
        height: 12px;
        margin-right: 14px;
      }

      .date {
        min-width: max-content;
      }
    }
  }

  > * {
    font-family: Prompt;
  }
}

// v2
.traffic-event-panel {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 12px;
  top: 170px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 195;
  background-color: white;

  &.hide {
    display: none;
  }
  @media screen and (max-width: $mobileMaxSize) {
    position: fixed;
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 75%;
    z-index: 1000;
    border-radius: 12px 12px 0px 0px;
  }

  @media screen and (min-width: $mobileMaxSize) {
    width: calc(100% - 24px);
    max-width: 384px;
  }

  .panel-name {
    font-size: 16px;
    color: #282828;
    padding: 12px;
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    padding: 0px 12px 12px 12px;
    gap: 3px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 400px;

    > button.has-more {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 3px;
      font-size: 14px;
      padding: 12px 6px;
      cursor: pointer;
      outline: none;
      color: #fff;
      background-color: $darkPrimary;
      -webkit-transition: background-color 0.3s ease 0s;
      transition: background-color 0.3s ease 0s;
    }
  }

  .panel-incident {
    padding: 11px 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .front-incident-card {
      display: flex;
      width: 100%;
      > .icon {
        width: 16px;
        height: 16px;
        margin-right: 14px;
      }
      > .name {
        width: 100%;
        @media screen and (min-width: $mobileMaxSize) {
          max-width: 200px;
        }
        word-wrap: break-word;
        margin-right: 12px;
      }
    }
    .end-incident-card {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 90px;
      height: 100%;

      .picture {
        width: 12px;
        height: 12px;
        margin-right: 14px;
      }

      .date {
        min-width: max-content;
      }
    }
  }

  > * {
    font-family: Prompt;
  }
  > a.all-event {
    background-color: $darkPrimary;
    padding: 4px 12px;
    position: absolute;
    top: 10px;
    right: 48px;
    border-radius: 12px;
    border-color: transparent;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  > button.close {
    padding: 0px;
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    i {
      color: $grey;
    }
  }
}
</style>
