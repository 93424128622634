<template>
  <div class="alert-before-save">
    <div class="dialog-card">
      <div class="msg">
        <i class="material-icons-round">warning</i>
        <br>
        <div>{{ $t('confirmDeleteEventDialog.msg') }}</div>
      </div>
      <div class="action">
        <button class="ripple" @click="cancelDelete">
          {{ $t('confirmDeleteEventDialog.cancel') }}
        </button>
        <button class="ripple outline" @click="confirmDelete">
          {{ $t('confirmDeleteEventDialog.accept') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDeleteEventDialog',
  methods: {
    confirmDelete () {
      this.$root.$emit('confirmDeleteEvent')
    },
    cancelDelete () {
      this.$root.$emit('cancelDeleteEvent')
    }
  }

}
</script>

<style lang="scss" scoped>
  .alert-before-save {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-card {
        padding: 24px;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
        display: flex;
        flex-direction: column;
        min-width: 300px;

        .msg {
            font-family: Prompt;
            font-size: 16px;
            color: $red;

            i {
              font-size: 36px;
              color: $tangerine;
            }
        }

        .action {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 12px;

          button {
            height: 36px;
            background-color: $primary;
            padding: 15px 9px;
            flex-grow: 1;
            border-radius: 18px;
            color: white;
            border: 1px solid $primary;
            font-family: Prompt;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0px 6px;
            transition: background-color 0.3s ease 0s,
              color 0.3s ease 0s;

            &.outline {
              border-color: $red;
              color: $red;
              background-color: white;
            }
          }
        }
     }
  }
</style>
