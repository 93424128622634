<template>
  <div class="back-drop" v-if="showDialog">
    <div class="dialog-card">
      <div class="title">
        {{ $t('nearbyDialog.title') }}
      </div>
      <div class="content" v-html="message"></div>
      <div class="action">
        <button class="ripple" @click="onClickClose">
          {{ $t('nearbyDialog.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NearbyDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showDialog: false
    }
  },
  methods: {
    onClickClose () {
      const self = this
      self.$emit('close')
      self.showDialog = false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        const self = this
        self.$nextTick(() => {
          self.showDialog = self.value
        })
      }
    },
    showDialog () {
      const self = this
      self.$nextTick(() => {
        self.$emit('input', self.showDialog)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.back-drop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  top: 0px;
  left: 0px;
}

.dialog-card {
  padding: 18px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  .title {
    font-family: Prompt;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .content {
    font-family: Prompt;
    font-size: 20px;
    color: #101010;
  }

  .action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    button {
      height: 42px;
      width: 100%;
      background-color: $darkPrimary;
      padding: 0 24px;
      border-radius: 12px;
      color: white;
      border: none;
      font-family: Prompt;
      font-size: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px;
      transition: background-color 0.3s ease 0s,
        color 0.3s ease 0s;

      &:disabled {
        border: 1px solid $lightGrey;
        cursor: default;
        color: $lightGrey;
        background-color: white;
      }
    }
  }
}
</style>
