var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"layer-panel",class:{
    hide: _vm.isHidingLayerPanel,
  }},[_c('label',{staticClass:"panel-name"},[_vm._v(" "+_vm._s(_vm.$t("layerPanel.name"))+" ")]),_c('button',{staticClass:"close ripple",on:{"click":_vm.clickClose}},[_c('i',{staticClass:"material-icons-round"},[_vm._v("close")])]),_c('div',{staticClass:"divider-h"}),_c('div',{staticClass:"layer-list"},_vm._l((_vm.layerList.filter(function (layer) {
        return layer.v3 === true;
      })),function(layer){return _c('div',{key:layer.name,staticClass:"layer-container"},[_c('button',{staticClass:"layer",class:{ active: _vm.selectedLayer.indexOf(layer.name) !== -1 },on:{"click":function($event){return _vm.addLayer(layer)}}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(layer.displayName[_vm.$i18n.locale])+" "),(layer.type !== 'link' &&
                ('description' in layer ||
                  'legend' in layer ||
                  'source' in layer)
                )?_c('i',{staticClass:"material-icons-round",on:{"click":function($event){$event.stopPropagation();return _vm.toggleInfo(layer)}}},[_vm._v(" help_outline ")]):_vm._e()]),_c('i',{staticClass:"material-icons-round close"},[_vm._v(" close ")]),(layer.type === 'link')?_c('i',{staticClass:"material-icons-round link"},[_vm._v(" open_in_new ")]):_vm._e()]),(layer.type === 'traffic' &&
            _vm.selectedLayer.indexOf(layer.name) !== -1
            )?_c('div',{staticClass:"control-traffic"},[_c('button',{staticClass:"toggle-input",class:{ active: _vm.isShowingCamera },on:{"click":function($event){$event.stopPropagation();_vm.isShowingCamera = !_vm.isShowingCamera}}},[_vm._m(0,true),_c('label',[_vm._v(_vm._s(_vm.$t("layerPanel.camera")))])]),_c('button',{staticClass:"toggle-input",class:{ active: _vm.isShowingEvents },on:{"click":function($event){$event.stopPropagation();_vm.isShowingEvents = !_vm.isShowingEvents}}},[_vm._m(1,true),_c('label',[_vm._v(_vm._s(_vm.$t("layerPanel.events")))])])]):_vm._e(),(layer.type !== 'link' && 'opacity' in layer)?_c('div',{staticClass:"input-container",on:{"click":function($event){$event.stopPropagation();return (function () { }).apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(layer.opacity),expression:"layer.opacity"}],attrs:{"type":"range","min":"0","max":"1","step":"0.1"},domProps:{"value":(layer.opacity)},on:{"input":function($event){return _vm.changeOpacity(layer)},"__r":function($event){return _vm.$set(layer, "opacity", $event.target.value)}}})]):_vm._e(),_c('img',{attrs:{"src":_vm.baseUrl +
            'img/layer/' +
            layer.name.replace(':', '') +
            '.jpg?version=' +
            _vm.appVersion,"loading":"lazy"}})]),(layer.isShowingInfo &&
          layer.type !== 'link' &&
          _vm.selectedLayer.indexOf(layer.name) !== -1 &&
          ('description' in layer || 'legend' in layer || 'source' in layer)
          )?_c('div',{staticClass:"layer-info"},[('description' in layer)?_c('div',{staticClass:"field description"},[_c('label',[_vm._v(_vm._s(_vm.$t("layerPanel.description")))]),_c('div',{domProps:{"innerHTML":_vm._s(layer.description[_vm.$i18n.locale])}})]):_vm._e(),('legend' in layer)?_c('div',{staticClass:"field legend"},[_c('img',{style:({ maxWidth: layer.legend.maxWidth }),attrs:{"src":layer.legend[_vm.$i18n.locale]},on:{"click":function($event){return _vm.clickLegend(layer.legend[_vm.$i18n.locale])}}})]):_vm._e(),('source' in layer)?_c('div',{staticClass:"field source"},[_c('label',[_vm._v(_vm._s(_vm.$t("layerPanel.source")))]),_c('div',{domProps:{"innerHTML":_vm._s(layer.source[_vm.$i18n.locale])}})]):_vm._e()]):_vm._e()])}),0)]),(_vm.selectedLayer.indexOf('rainviewer') != -1)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"id":"rain-radar-panel"}},[_vm._m(2),_c('div',{staticClass:"action"},[_c('button',{staticClass:"ripple"},[_c('i',{staticClass:"material-icons-round",on:{"click":function($event){return _vm.rainRadarV3.rainBack()}}},[_vm._v("skip_previous")])]),_c('button',{staticClass:"ripple",on:{"click":function($event){return _vm.playRainRadar()}}},[(!_vm.playAnimation)?_c('i',{staticClass:"material-icons-round"},[_vm._v("play_arrow")]):_vm._e(),(_vm.playAnimation)?_c('i',{staticClass:"material-icons-round"},[_vm._v("stop")]):_vm._e()]),_c('button',{staticClass:"ripple"},[_c('i',{staticClass:"material-icons-round",on:{"click":function($event){return _vm.rainRadarV3.rainNext()}}},[_vm._v("skip_next")])])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle"},[_c('div',{staticClass:"track"}),_c('div',{staticClass:"thumb"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle"},[_c('div',{staticClass:"track"}),_c('div',{staticClass:"thumb"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"time-radar"}})])}]

export { render, staticRenderFns }