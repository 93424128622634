<template>
  <div class="dialog-card" v-if="isHide">
    <div class="report-panel"  v-if="isAuthenticated">
      <div class="content">
        <label>{{ $t('reportEventForm.authen') }}</label>
      </div>
    </div>
    <div class="report-panel" v-else>
      <div class="panel-name">
        <label>
          {{ $t('reportEventForm.header') }}
        </label>
        <div>
          <button class="close ripple" @click="clickClose">
            <i class="material-icons-round">close</i>
          </button>
        </div>
      </div>
      <div class="divider-h"></div>
      <div class="content">
        <button class="close ripple" @click="clickClose">
        <i class="material-icons-round">close</i>
      </button>
      <label>{{ $t('reportEventForm.type') }} <label class="red-star">* </label></label><br />
      <div class="grid eventTypeContent">
        <div v-for="(eventType, eventTypeIndex) in eventTypeList"  :ref="'eventTypeBtn' + eventTypeIndex" :key="'event-type-' + eventTypeIndex">
          <div class="eventType-btn" :class="{'selectedEventType': idEventTypeClick === eventType.id, 'selectedEventType': idSelectedEventType === eventType.id}" :id="eventType.id" @click="handleEventTypeClick(eventType.id, eventType.name, eventType.name_en, eventType.severity)">
            <img class="eventType-icon" alt="image" :src="eventType.icon" loading="lazy">
            <label>{{ $i18n.locale === 'en' ? eventType.name_en : eventType.name }}</label>
          </div>
        </div>
      </div>
      <p class="alret-fill-input" id="alert-event-type">{{ $t('reportEventForm.alert.eventType') }}</p>
      <div class="grid">
        <label class="input-title">{{ $t('reportEventForm.title') }} (TH) <label class="red-star">* </label></label>
        <input class="input-style" v-model="titleInput_TH" :placeholder="$t('reportEventForm.title')" :isRequired="true">
        <div class="spacer"></div>
        <p class="alret-fill-input" id="alert-title-th">{{ $t('reportEventForm.alert.titleTH') }}</p>
      </div>
      <div class="grid">
        <label class="input-title">{{ $t('reportEventForm.title') }} (EN) <label class="red-star">* </label></label>
        <input class="input-style" v-model="titleInput_EN" :placeholder="$t('reportEventForm.title')" :isRequired="true">
        <div class="spacer"></div>
        <p class="alret-fill-input" id="alert-title-en">{{ $t('reportEventForm.alert.titleEN') }}</p>
      </div>
      <div class="image-btn">
        <label class="add-image ripple">
          {{ $t('reportEventForm.image') }}
        </label>
        <label class="custom-file-upload" @click="onClickAddImage">
          <i class="material-icons-round icon-camera">camera_alt</i> Choose File
        </label>
        <input ref="add-image-input" class="add-image-input" type="file" accept="image/png, image/jpeg" @change="uploadImage">
      </div>
      <div class="grid">
        <div class="spacer"></div>
        <div class="file-name" id="file-name-chosen">No file chosen</div>
      </div>
      <div v-if="imageList">
        <div class="img-wrap" v-for="(img, index) in imageList" :key="'img-'+index">
          <div class="grid">
            <div class="spacer"></div>
            <button class="closeImage" @click="stackRemoveSelectedImage(img.id)">
              <i class="material-icons-round">close</i>
            </button>
            <img :id="`image-${img.id}`" :src="img.src" alt="event image" width="200" v-show="isImagePreviewEditMode"/>
          </div>
        </div>
      </div>
      <div class="img-wrap" v-show="isImagePreview">
        <div class="grid">
            <div class="spacer"></div>
              <button class="closeImage" @click="clearImage">
                <i class="material-icons-round">close</i>
              </button>
            <img id="eventImage" alt="event image" width="200"/>
          </div>
      </div>
      <div class="grid" v-if="editMode">
        <div class="spacer"></div>
        <button class="editLocationBtn" @click="editLocation">{{ $t('reportEventForm.editLocation') }}</button>
      </div>
      <div class="loading-overlay-translate">
        <div class="grid">
          <label class="title-textarea">{{ $t('reportEventForm.detail') }} (TH) </label>
          <textarea :disabled="isTranslateLoading" :class="{'bg-fade-loading': isTranslateLoading}" v-model="detailInput" :placeholder="$t('reportEventForm.detail')"></textarea>
        </div>
      </div>
      <div class="grid translate">
        <div class="spacer"></div>
        <div>
          <i class="material-icons-round icon-import-export">import_export</i>
          <button class="translate-btn" @click="translate" :disabled="isTranslateLoading || isNullTextTranslate" :class="{'unset-cursor': isTranslateLoading || isNullTextTranslate}">
            <i class="material-icons-round icon-translate">translate</i>
            <label :class="{'unset-cursor': isTranslateLoading || isNullTextTranslate}">{{ $t('reportEventForm.translate') }}</label>
          </button>
          <label class="label-translate-by">{{ $i18n.locale === 'th' ? '(โดย ': '(by '}} <a @click="onClickUrl($t('reportEventForm.itpLink'))">ITP</a>)</label>
        </div>
      </div>
      <div class="loading-overlay-translate">
        <div class="grid">
          <label>{{ $t('reportEventForm.detail') }} (EN) </label>
          <textarea :disabled="isTranslateLoading" :class="{'bg-fade-loading': isTranslateLoading}" v-model="detailInput_en" :placeholder="$t('reportEventForm.detail')"></textarea>
          <img class="img-loading" v-if="isTranslateLoading" src="@/assets/img/loading.gif" alt="loading" >
        </div>
      </div>
      <div class="grid">
        <label>{{ $t('reportEventForm.status') }} <label class="red-star">* </label></label>
        <div class="inline">
          <input type="radio" id="processing" value="1" v-model="picked_status"/>
          <label for="processing">{{ $t('reportEventForm.statusStage.processing') }}</label>
          <input type="radio" id="done" value="0" v-model="picked_status"/>
          <label for="done">{{ $t('reportEventForm.statusStage.done') }}</label>
        </div>
      </div>
      <div class="depart-datetime grid">
        <label>{{ $t('reportEventForm.dateEventStart') }} <label class="red-star">* </label></label>
        <div class="vue-datetime-container datetime-start">
          <VueDatetime v-model="departDatetimeStartEvent" :type="typeCalendar" input-id="dapart-datetime-input-start"
            input-class="datetime-input" title="" :min-datetime="new Date().toISOString()" :phrases="{
                ok: $t('routingPanel.okDepartDatetime'),
                cancel: $t('routingPanel.cancelDepartDatetime')
              }"/>
          <i class="ripple material-icons-round" @click="clickDepartDatetimeIcon('start')">schedule</i>
          <button class="reset ripple" v-if="departDatetimeStartEvent !== ''" @click="departDatetimeStartEvent = ''">
            <i class="ripple material-icons-round" :style="$i18n.locale === 'en'? 'margin-left: 15px;': ''">close</i>
          </button>
        </div>
      </div>
      <p class="alret-fill-input" id="alert-start-event">{{ $t('reportEventForm.alert.dateStartEvent') }}</p>
      <div class="grid depart-datetime">
        <label>{{ $t('reportEventForm.dateEventEnd') }} <label class="red-star">* </label></label>
        <div class="vue-datetime-container" :style="$i18n.locale === 'en'? 'margin-left: 23px;': ''">
          <VueDatetime v-model="departDatetimeEndEvent" :type="typeCalendar" input-id="dapart-datetime-input-end"
            input-class="datetime-input" title="" :min-datetime="new Date().toISOString()" :phrases="{
                ok: $t('routingPanel.okDepartDatetime'),
                cancel: $t('routingPanel.cancelDepartDatetime')
              }" />
          <i class="ripple material-icons-round" @click="clickDepartDatetimeIcon('end')">schedule</i>
          <button class="reset ripple end-datetime-close" v-if="departDatetimeEndEvent !== ''" @click="departDatetimeEndEvent = ''">
            <i class="ripple material-icons-round">close</i>
          </button>
        </div>
      </div>
      <p class="alret-fill-input" id="alert-end-event">{{ $t('reportEventForm.alert.dateStartEvent') }}</p>
      <p class="alret-fill-input" id="alert-start-end-event-corrected">* กรุณาเลือกวันที่เริ่มและสิ้นสุดเหตุการณ์ให้ถูกต้อง</p>
      <p class="alret-fill-input" id="alert-start-event-time-corrected">* กรุณาเลือกเวลาเริ่มต้นและสิ้นสุดเหตุการณ์ให้ถูกต้อง</p>
      <div class="grid">
        <div class="spacer"></div>
        <div class="inline">
          <input type="checkbox" id="checkbox-allday" v-model="allday"/>
          <label for="checkbox-allday">{{ $t('reportEventForm.allday') }}</label>
          <input v-if="isHasClearMemcachePermission" type="checkbox" class="sticky-display" id="checkbox-sticky" v-model="sticky"/>
          <label v-if="isHasClearMemcachePermission" for="checkbox-sticky" :class="isHasClearMemcachePermission ? 'sticky-display':''">{{ $t('reportEventForm.stricky') }}</label>
        </div>
      </div>
      <div class="grid">
        <label>{{ $t('reportEventForm.place') }} </label>
        <select id="dropdownPlace" v-model="placeInput"></select>
      </div>
      <div class="grid" v-show="isHasSetSeveriyPermission">
        <label>{{ $t('reportEventForm.severity') }} </label>
        <select :disabled="!isHasSetSeveriyPermission" id="dropdownSeverity" v-model="severity2" @change="handleSeverityLevel"></select>
      </div>
      <div class="dropdownMore">
        <div class="titleMore" @click="expandMoreLess">
          <i v-if="isExpandLess" class="icon-dropdown material-icons-round">expand_more</i>
          <i v-else class="icon-dropdown material-icons-round">expand_less</i>
          <label>{{ $t('reportEventForm.optional.title') }}</label>
        </div>
        <div id="DropdownContent" class="dropdown-content" :class="{'Expand': isExpandLess}">
          <label>{{ $t('reportEventForm.optional.coordinate') }}</label>
          <div class="grid-dropdownMore">
            <label>{{ $t('reportEventForm.optional.lat') }} </label>
            <label>{{ $t('reportEventForm.optional.lon') }} </label>
          </div>
          <div class="grid-dropdownMore">
            <input type="numner" onKeyPress="if(this.value.length==9) return false;" class="input-style" v-model="lat" @change="handleLocationChange">
            <input type="number" onKeyPress="if(this.value.length==9) return false;" class="input-style" v-model="lon" @change="handleLocationChange">
          </div>
        </div>
      </div>
      <div class="btn-submit-clear">
        <button class="submitForm" @click="checkFillInput">
          <label>{{ $t('reportEventForm.save') }}</label>
        </button>
        <button class="clearForm" @click="clearReport">
          <label>{{ $t('reportEventForm.clear') }}</label>
        </button>
      </div>
      <div class="loading-overlay" v-if="isSavingReport">
        <img src="@/assets/img/loading.gif" alt="loading" v-if="createErrorMessage === '' && createSuccessMessage === ''">
        <span class="error-message" v-if="createErrorMessage !== ''">{{ createErrorMessage }}</span>
        <span class="success-message" v-if="createSuccessMessage !== ''">{{ createSuccessMessage }}</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
export default {
  name: 'ReportEventFormPanel',
  components: {
    VueDatetime: Datetime
  },
  props: {
    map: {
      type: Object
    },
    userData: {
      type: Object,
      default: null
    },
    editMode: {
      type: String,
      default: null
    },
    idEventTypeClick: {
      type: Number,
      default: null
    },
    trafficIncident: {
      type: Array,
      default: null
    },
    location: {
      lat: Number,
      lon: Number
    }
  },
  data () {
    return {
      eid: null,
      typeInput: null,
      severity: null,
      titleInput_TH: null,
      titleInput_EN: null,
      severity2: null,
      imageList: [],
      eventTypeList: null,
      detailInput: null,
      detailInput_en: null,
      statusInput: null,
      picked_status: 1, // processing : 1, done: 0
      placeInput: null,
      placeList: '',
      lat: null,
      lon: null,
      allday: false,
      sticky: false,
      severityInput: null,
      isImagePreview: false,
      isImagePreviewEditMode: false,
      departDatetimeStartEvent: '',
      departDatetimeEndEvent: '',
      location_TH: null,
      location_EN: null,
      idSelectedEventType: null,
      isSavingReport: false,
      createErrorMessage: '',
      createSuccessMessage: '',
      imageId: null,
      isExpandLess: true,
      dateStartEvent: null,
      dateEndEvent: null,
      timeEndEvent: null,
      timeStartEvent: null,
      editLatLon: false,
      isTranslateLoading: false,
      handleLatLonChange: false,
      isNullTextTranslate: false,
      stackRemoveImageId: [],
      permissions: [],
      typeCalendar: 'datetime'
    }
  },
  computed: {
    isAuthenticated () {
      const self = this
      return !self.userData
    },
    severityList () {
      const self = this
      const value = self.$i18n.locale === 'th' ? 'ค่าเริ่มต้น' : 'Default'
      let options = `<option value="null">${value}</option>`
      for (let i = 1; i <= 10; i++) {
        options += `<option>${i}</option>`
      }
      return options
    },
    isHide () {
      const self = this
      return self.$route.hash !== '#search'
    },
    isHasClearMemcachePermission () {
      const self = this
      if (self.permissions.includes('clear memcache')) {
        return true
      } else return false
    },
    isHasSetSeveriyPermission () {
      const self = this
      if (self.permissions.includes('set severity')) {
        return true
      } else return false
    }
  },
  watch: {
    isAuthenticated () {
      const self = this
      self.$nextTick(() => {
        self.getTrafficEventTypeList()
        self.getAddress()
        self.getNearByPlace()
        self.isAuthenticated = !self.isAuthenticated
      })
    },
    isHide () {
      const self = this
      self.clickClose()
    },
    lat () {
      const self = this
      if (self.lat.length >= 6) {
        self.lat = parseFloat(Number(self.lat).toFixed(6))
      }
    },
    lon () {
      const self = this
      if (self.lon.length >= 6) {
        self.lon = parseFloat(Number(self.lon).toFixed(6))
      }
    },
    detailInput () {
      const self = this
      if (!self.detailInput || self.detailInput === ' ') {
        self.isNullTextTranslate = true
      } else self.isNullTextTranslate = false
    },
    allday () {
      const self = this
      self.allday ? self.typeCalendar = 'date' : self.typeCalendar = 'datetime'
    },
    departDatetimeStartEvent () {
      const self = this
      const startDate = self.departDatetimeStartEvent
      if (self.departDatetimeEndEvent === '' && startDate !== '') {
        const dateData = self.getDateTimeForParams(startDate)
        const date = dateData.date.split('-')
        if (self.allday) {
          self.departDatetimeEndEvent = new Date(date[0], date[1] - 1, date[2]).toISOString()
        } else {
          self.departDatetimeEndEvent = new Date(date[0], date[1] - 1, date[2], dateData.hr + 1, dateData.min, dateData.sec).toISOString()
        }
      }
      const endDate = self.departDatetimeEndEvent
      const dateTimeStartObj = self.getDateTimeForParams(startDate)
      const dateTimeEndObj = self.getDateTimeForParams(endDate)
      // check day
      if (endDate && startDate) {
        if (dateTimeStartObj.date > dateTimeEndObj.date) {
          self.displayAlert(true, 'alert-start-end-event-corrected')
        } else self.displayAlert(false, 'alert-start-end-event-corrected')
      } else self.displayAlert(false, 'alert-start-end-event-corrected')
      // hours check if day equal
      if (dateTimeStartObj.date === dateTimeEndObj.date) {
        if (!self.allday) {
          if (dateTimeStartObj.hr > dateTimeEndObj.hr) {
            self.displayAlert(true, 'alert-start-event-time-corrected')
          } else if (dateTimeStartObj.hr === dateTimeEndObj.hr) {
            // minute check
            if (dateTimeStartObj.min > dateTimeEndObj.min) {
              self.displayAlert(true, 'alert-start-event-time-corrected')
            } else self.displayAlert(false, 'alert-start-event-time-corrected')
          } else self.displayAlert(false, 'alert-start-event-time-corrected')
        }
      }
    },
    departDatetimeEndEvent () {
      const self = this
      const startDate = self.departDatetimeStartEvent
      const endDate = self.departDatetimeEndEvent
      const dateTimeStartObj = self.getDateTimeForParams(startDate)
      const dateTimeEndObj = self.getDateTimeForParams(endDate)
      // check day
      if (startDate && endDate) {
        if (dateTimeStartObj.date > dateTimeEndObj.date) {
          self.displayAlert(true, 'alert-start-end-event-corrected')
        } else self.displayAlert(false, 'alert-start-end-event-corrected')
      } else self.displayAlert(false, 'alert-start-end-event-corrected')
      // hours check if day equal
      if (dateTimeStartObj.date === dateTimeEndObj.date) {
        if (!self.allday) {
          if (dateTimeStartObj.hr > dateTimeEndObj.hr) {
            self.displayAlert(true, 'alert-start-event-time-corrected')
          } else if (dateTimeStartObj.hr === dateTimeEndObj.hr) {
            // minute check
            if (dateTimeStartObj.min > dateTimeEndObj.min) {
              self.displayAlert(true, 'alert-start-event-time-corrected')
            } else self.displayAlert(false, 'alert-start-event-time-corrected')
          } else self.displayAlert(false, 'alert-start-event-time-corrected')
        }
      }
    }
  },
  async mounted () {
    const self = this
    if (self.location.lat && self.location.lon) {
      self.lat = parseFloat(self.location.lat.toFixed(6))
      self.lon = parseFloat(self.location.lon.toFixed(6))
    } else {
      self.lat = parseFloat(self.map.location().lat.toFixed(6))
      self.lon = parseFloat(self.map.location().lon.toFixed(6))
    }
    await self.getTrafficEventTypeList()
    self.getAddress()
    self.getPermission()
    self.editMode ? self.getEventByEid() : self.getNearByPlace()
  },
  methods: {
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank').focus()
      }
    },
    async getPermission () {
      const self = this
      const permission = await self.api.getUserInfo({ username: self.userData.name, ldtoken: self.userData.longdousertoken })
      self.permissions = permission.data.data.permissions
    },
    editLocation () {
      const self = this
      window.open(`${process.env.VUE_APP_LONGDO_TRAFFIC_PATH_OLD_EVENT_REPORT_FORM}?lat=${self.lat}&longt=${self.lon}&sev=${self.severity} `, '_blank')
    },
    handleLocationChange () {
      const self = this
      self.handleLatLonChange = true
      self.getNearByPlace()
    },
    expandMoreLess () {
      const self = this
      self.isExpandLess = !self.isExpandLess
    },
    onClickAddImage () {
      const self = this
      self.$refs['add-image-input'].click()
    },
    uploadImage (imgInput) {
      const self = this
      const img = document.getElementById('eventImage')
      const name = document.getElementById('file-name-chosen')
      img.src = URL.createObjectURL(imgInput.target.files[0])
      name.innerHTML = imgInput.target.files[0].name
      self.isImagePreview = true
    },
    async stackRemoveSelectedImage (imgId) {
      const self = this
      self.stackRemoveImageId.push(imgId)
      self.imageList = self.imageList.filter(item => item.id !== imgId)
    },
    async translate () {
      const self = this
      const params = {
        text: self.detailInput,
        eid: self.eid ? self.eid : '',
        token: self.userData.longdousertoken,
        username: self.userData.mail
      }
      self.isTranslateLoading = true
      const response = await self.api.translateEventReport(params)
      if (response.data.status) self.detailInput_en = response.data.message
      self.isTranslateLoading = false
    },
    clearImage () {
      const self = this
      document.getElementsByClassName('add-image-input')[0].value = ''
      self.isImagePreview = false
    },
    clickClose () {
      const self = this
      self.$emit('clickClose')
    },
    clearReport () {
      const self = this
      self.severity = null // id Event Type
      self.titleInput_TH = null
      self.titleInput_EN = null
      self.detailInput = null
      self.detailInput_en = null
      self.picked_status = 1
      self.departDatetimeStartEvent = ''
      self.departDatetimeEndEvent = ''
      self.placeInput = null
      self.severity2 = null
      self.allday = false
      self.sticky = false
      const reset = document.getElementById(self.idSelectedEventType)
      reset.classList.remove('selectedEventType')
      self.idSelectedEventType = null
      self.clearImage()
    },
    handleSeverityLevel () {
      const self = this
      var dropdown = document.getElementById('dropdownSeverity')
      self.severity2 = dropdown.value
    },
    getDateTimeForParams (dateTime) {
      const dateTimeStart = new Date(dateTime)
      const dateMonth = dateTimeStart.getMonth() + 1
      const dateYear = dateTimeStart.getFullYear()
      const dateDay = dateTimeStart.getDate()

      return {
        date: `${dateYear}-${dateMonth}-${dateDay}`,
        hr: dateTimeStart.getHours(),
        min: dateTimeStart.getMinutes(),
        sec: dateTimeStart.getSeconds()
      }
    },
    checkFillInput () {
      const self = this
      self.severity ? self.displayAlert(false, 'alert-event-type') : self.displayAlert(true, 'alert-event-type')
      self.departDatetimeStartEvent ? self.displayAlert(false, 'alert-start-event') : self.displayAlert(true, 'alert-start-event')
      self.departDatetimeEndEvent ? self.displayAlert(false, 'alert-end-event') : self.displayAlert(true, 'alert-end-event')
      self.titleInput_EN ? self.displayAlert(false, 'alert-title-en') : self.displayAlert(true, 'alert-title-en')
      self.titleInput_TH ? self.displayAlert(false, 'alert-title-th') : self.displayAlert(true, 'alert-title-th')
      const checkAlertTime = document.getElementById('alert-start-event-time-corrected').style.display === 'none'
      const checkAlertDate = document.getElementById('alert-start-end-event-corrected').style.display === 'none'
      if (self.severity && self.departDatetimeStartEvent && self.departDatetimeEndEvent && self.titleInput_EN && self.titleInput_TH && checkAlertTime && checkAlertDate) self.submitReportEvent()
    },
    displayAlert (isDisplay, id) {
      if (isDisplay) document.getElementById(id).style.display = 'block'
      else document.getElementById(id).style.display = 'none'
    },
    async submitReportEvent () {
      const self = this
      if (self.isSavingReport) return false
      self.isSavingReport = true
      self.createErrorMessage = ''
      const dateTimeStart = self.getDateTimeForParams(self.departDatetimeStartEvent)
      const dateTimeEnd = self.getDateTimeForParams(self.departDatetimeEndEvent)
      const params = {
        eid: self.eid ? self.eid : '',
        severity: self.severity, // id Event Type
        title: self.titleInput_TH,
        title_en: self.titleInput_EN,
        detail: self.detailInput,
        detail_en: self.detailInput_en,
        status: self.picked_status,
        start_time: dateTimeStart.date,
        start_time_hr: dateTimeStart.hr,
        start_time_min: dateTimeStart.min,
        start_time_sec: dateTimeStart.sec,
        stop_time: dateTimeEnd.date,
        stop_time_hr: dateTimeEnd.hr,
        stop_time_min: dateTimeEnd.min,
        stop_time_sec: dateTimeEnd.sec,
        nearplace: self.placeInput,
        severity2: self.severity2, // severity level
        latitude: self.lat,
        longitude: self.lon,
        format: 'json',
        token: self.userData.longdousertoken,
        username: self.userData.mail
      }

      if (self.allday) {
        Object.assign(params, { allday: 1 })
      }
      if (self.sticky) {
        Object.assign(params, { sticky: 1 })
      }

      const data = new FormData()
      for (const property in params) {
        data.append(`${property}`, `${params[property]}`)
      }
      if (self.$refs['add-image-input'].files[0]) {
        data.append('image', self.$refs['add-image-input'].files[0])
      }

      // delete image
      if (self.stackRemoveImageId.length !== 0) {
        for (const el of self.stackRemoveImageId) {
          const params = {
            type: 'image',
            id: el,
            token: self.userData.longdousertoken,
            username: self.userData.mail
          }
          await self.api.deleteEventTraffic(params)
        }
      }
      // submit
      const response = await self.api.saveEventTraffic(data)

      if (response !== null) {
        if (response.data.code === 200) {
          self.editMode ? self.$root.$emit('editEventSuccess') : self.$root.$emit('createEventSuccess')
          self.editMode ? self.createSuccessMessage = 'แก้ไขข้อมูลสำเร็จ' : self.createSuccessMessage = response.data.message
          setTimeout(() => {
            self.isSavingReport = false
            self.createSuccessMessage = ''
            self.clickClose()
          }, 2 * 1000)
        } else {
          self.createErrorMessage = response.data.message
          setTimeout(() => {
            self.isSavingReport = false
            self.createErrorMessage = ''
          }, 2 * 1000)
        }
      } else {
        self.createErrorMessage = self.$t('createPlace.defaultCreateErrorMessage')
        setTimeout(() => {
          self.isSavingReport = false
          self.createErrorMessage = ''
        }, 2 * 1000)
      }
    },
    clickDepartDatetimeIcon (eventTime) {
      if (eventTime === 'start') {
        window.document.querySelector('#dapart-datetime-input-start').click()
      } else {
        window.document.querySelector('#dapart-datetime-input-end').click()
      }
    },
    turnIcon (icon) {
      const url = process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_TYPE_PATH_ICON
      return url.replace('{{icon}}', icon)
    },
    handleEventTypeClick (id, eventType, eventTypeEN, severity) {
      const self = this
      self.typeInput = eventType
      self.severity2 = severity
      self.severity = id
      const location = self.location_TH.aoi === null || self.location_TH.aoi === undefined ? self.location_TH.subdistrict : self.location_TH.aoi
      const locationEN = self.location_EN.aoi === null || self.location_EN.aoi === undefined ? self.location_EN.subdistrict : self.location_EN.aoi
      self.titleInput_TH = `${eventType} ${location}`
      self.titleInput_EN = `${eventTypeEN} ${locationEN}`
      // for style
      if (self.idSelectedEventType !== id) {
        const newSelected = document.getElementById(id)
        if (self.idSelectedEventType) {
          const old = document.getElementById(self.idSelectedEventType)
          old.classList.remove('selectedEventType')
        }
        if (newSelected) {
          newSelected.classList.add('selectedEventType')
          self.idSelectedEventType = id
        }
      }

      document.getElementById('dropdownSeverity').value = severity
    },
    getParamsAddress (lang) {
      const self = this
      return {
        lat: self.lat,
        lon: self.lon,
        locale: lang,
        noelevation: 1,
        noaoi: 1,
        nowater: 1
      }
    },
    concatenateAddress (obj) {
      const soi = obj.aoi ? obj.aoi : ''
      const road = obj.road ? obj.road : ''
      const subdistrict = obj.subdistrict ? obj.subdistrict : ''
      const district = obj.district ? obj.district : ''
      const province = obj.province ? obj.province : ''
      const postcode = obj.postcode ? obj.postcode : ''
      return `${soi} ${road} ${subdistrict} ${district} ${province} ${postcode}`
    },
    getPlaceListDropDown (array) {
      const self = this
      let lang = ''
      self.$i18n.locale === 'th' ? lang = 'ไม่มี' : lang = 'none'
      self.placeList = `<option value="null">${lang}</option>`
      array.forEach((item) => {
        self.placeList += `<option value="${item.id}">${item.name}</option>`
      })
    },
    async getTrafficEventTypeList () {
      const self = this
      const result = await self.api.getTrafficEventType()
      result.data.forEach(item => {
        item.icon = self.turnIcon(item.icon)
        if (item.id === self.idEventTypeClick) {
          self.idSelectedEventType = item.id
          self.severity = item.id
        }
      })
      self.eventTypeList = result.data
    },
    async getAddress () {
      const self = this
      const paramsTH = self.getParamsAddress('th')
      const paramsEN = self.getParamsAddress('en')
      const detailTH = await self.api.getAddress(paramsTH)
      const detailEN = await self.api.getAddress(paramsEN)
      const locationEN = detailEN.data
      const locationTH = detailTH.data
      const locationTHtitle = locationTH.aoi ? locationTH.aoi : locationTH.road ? locationTH.road : locationTH.subdistrict
      const locationENtitle = locationEN.aoi ? locationEN.aoi : locationEN.road ? locationEN.road : locationEN.subdistrict
      const eventTypeSelected = self.eventTypeList.find((item) => item.id === self.idSelectedEventType)
      self.location_TH = detailTH.data
      self.location_EN = detailEN.data
      self.detailInput = self.concatenateAddress(detailTH.data)
      self.detailInput_en = self.concatenateAddress(detailEN.data)
      self.titleInput_TH = eventTypeSelected ? `${eventTypeSelected.name || ''} ${locationTHtitle}` : ''
      self.titleInput_EN = eventTypeSelected ? `${eventTypeSelected.name_en || ''} ${locationENtitle}` : ''
    },
    async getNearByPlace (editModeIdplace) {
      const self = this
      const paramsGetNearPlace = {
        lat: self.lat,
        lon: self.lon,
        locale: self.$i18n.locale,
        span: 0.01,
        dataset: 'main2p,poi-contrib'
      }
      const response = await self.api.searchNearbyPlace(paramsGetNearPlace)
      self.getPlaceListDropDown(response.data.data)
      document.getElementById('dropdownSeverity').innerHTML = self.severityList
      document.getElementById('dropdownPlace').innerHTML = self.placeList

      if (self.editMode) {
        if (self.handleLatLonChange) {
          document.getElementById('dropdownPlace').value = null
          self.handleLatLonChange = false
        } else if (editModeIdplace && editModeIdplace !== 'null' && editModeIdplace !== 'undefined') {
          const nearplace = response.data.data.find((idPlace) => idPlace.id === editModeIdplace)
          if (!nearplace) {
            const params = {
              id: editModeIdplace,
              locale: self.$i18n.locale
            }
            const neaplaceOtherSource = await self.api.getPoiOtherSourceDetail(params)
            self.placeList += `<option value="${neaplaceOtherSource.data.id}">${neaplaceOtherSource.data.name}</option>`
            document.getElementById('dropdownPlace').innerHTML = self.placeList
            self.placeInput = neaplaceOtherSource.data.id
          } else {
            self.placeInput = nearplace.id ? nearplace.id : null
          }
          document.getElementById('dropdownPlace').value = self.placeInput
        }
      } else self.placeInput = null
    },
    async getEventByEid () {
      const self = this
      self.isSavingReport = true

      const eidEvent = self.editMode
      const event = self.trafficIncident.find((arr) => arr.eid === eidEvent)
      // filled input
      self.eid = event.eid
      // event type
      self.severity = event.severity
      self.idSelectedEventType = Number(event.severity)

      self.severity2 = event.severity_level
      self.titleInput_TH = event.title
      self.titleInput_EN = event.title_en

      const detailCheck = event.comment.includes('<p>') ? event.comment.replace('<p>', '') : event.comment
      self.detailInput = detailCheck.includes('</p>') ? detailCheck.replace('</p>', '') : detailCheck
      const detailCheckEN = event.comment_en.includes('<p>') ? event.comment_en.replace('<p>', '') : event.commen_en
      self.detailInput_en = detailCheckEN.includes('</p>') ? detailCheckEN.replace('</p>', '') : detailCheckEN
      self.picked_status = event.status
      self.lat = event.latitude
      self.lon = event.longitude
      self.departDatetimeStartEvent = new Date(event.start_time).toISOString()
      self.allday = event.allday === '1'
      event.stop_time === '0000-00-00 00:00:00' ? self.departDatetimeEndEvent = '' : self.departDatetimeEndEvent = new Date(event.stop_time).toISOString()
      event.allday === 1 ? self.typeCalendar = 'date' : self.typeCalendar = 'datetime'
      self.sticky = event.sticky
      await self.getNearByPlace(event.ooi_id)
      if (event.imagenid !== '0') {
        const imageIdList = event.imagenid.split('_')
        imageIdList.forEach((img) => {
          self.imageList.push({
            src: `${process.env.VUE_APP_LONGDO_EVENT_IMAGE_PATH}${img}/thumbnail`,
            id: img
          })
        })
        self.isImagePreviewEditMode = true
      }
      self.isSavingReport = false
    }
  }
}
</script>

<style scoped lang="scss">

label {
  font-family: 'Prompt',serif;
  white-space: nowrap;
}
::v-deep .vdatetime-overlay {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
::v-deep .vdatetime-popup {
  width: 250px !important;
  left: unset !important;
  top: 50vh !important;
  margin-left: 110px;
  .vdatetime-popup__header {
    padding: 15px 30px !important;
  }
  .vdatetime-calendar__month {
    min-height: auto !important;
  }
  .vdatetime-time-picker {
    height: 250px !important;
  }
}
::v-deep .vdatetime {
  @media only screen and (max-width: 347px) {
    .datetime-input {
      max-width: 140px;
    }
  }
  @media only screen and (max-width: 295px) {
    .datetime-input {
      max-width: 85px;
    }
  }
}
.inline {
  display: inline-flex;
}
.dialog-card {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 12px;
  top: 110px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 120;
  background-color: white;

  @media screen and (max-width: $mobileMaxSize) {
    position: fixed;
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 75%;
    z-index: 85;
    border-radius: 12px 12px 0px 0px;
  }

  @media screen and (min-width: $mobileMaxSize) {
    width: calc(100% - 24px);
    max-width: 384px;
  }
}
.report-panel {
  border-radius: 10px;
  background-color: white;
  padding: 12px 0 10px 0;

  .panel-name {
    text-align: center;
  }

  .panel-name label {
    font-family: "Prompt",serif;
    font-size: 18px;
    color: #16A668;
  }

  button.close {
    position: absolute;
    padding: 0;
    top: 12px;
    right: 12px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: gray;
      font-size: 24px;
    }
  }
}
.content {
  padding: 20px 10px 32px 22px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (min-width: $mobileMaxSize) {
    height: calc(65vh - 100px);
  }

  @media screen and (max-width: $mobileMaxSize) {
   margin-bottom: 50px;
  }

  height: 100%;
  .report-event-form {
    margin-bottom: 5px;
  }
  .title-label{
    font-size: 16px;
    color: #282828;
  }
}
.loading-overlay-translate {
  position: relative;
  .img-loading {
    position: absolute;
    top: 40%;
    left: 60%;
  }
  .bg-fade-loading {
    background-color: #f8f8f8;
  }
}
.unset-cursor {
  cursor: unset !important;
}
.image-btn {
  display: grid;
  grid-template-columns: 1fr 2fr;
  .add-image-input {
    display: none;
  }
  .add-image {
    padding: 5px;
  }
  .custom-file-upload {
    cursor: pointer;
    border-radius: 99px;
    padding: 7px 20px 7px 16px;
    background-color: #F4F4F4;
    width: fit-content;
    .icon-camera {
      vertical-align: text-bottom;
      font-size: 20px;
    }
  }
}
.file-name {
  padding: 10px 7px 0px 10px;
  color: #BCBCBC;
  word-wrap: break-word;
  word-break: break-all;
}
.btn-submit-clear {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
  .submitForm {
    border: none;
    border-radius: 99px;
    width: 100px;
    height: 35px;
    background-color: $primary;
    padding: 7px 22px 7px 22px;
    cursor: pointer;
    label {
      color: white;
    }
  }
  .clearForm {
    cursor: pointer;
    border: none;
    border-radius: 99px;
    width: 100px;
    height: 35px;
    background-color: #F4FCF9;
    padding: 7px 22px 7px 22px;
    label {
      color: $primary;
    }
  }
}
.editLocationBtn {
  border: none;
  border-radius: 99px;
  background-color: #F4F4F4;
  padding: 7px 20px 7px 16px;
}

.translate {
  margin-right: 20px;
  .translate-btn {
    padding: 7px 20px 7px 16px;
    border-radius: 99px;
    background-color: #F4F4F4;
    border: none;
    cursor: pointer;
    .icon-translate {
      font-size: 20px;
    }
    label {
      padding-left: 5px;
      vertical-align: super;
    }
  }
  .icon-import-export {
      vertical-align: sub;
      margin-right: 5px;
    }
  .label-translate-by {
    vertical-align: super;
    margin-left: 5px;
  }
}

.grid{
  padding: 5px;
  // display: flex;
  display: grid;
  grid-template-columns: 1fr 2fr;
  .input-style {
    border-radius: 6px;
    border: 0.5px solid #BCBCBC;
    height: 32px;
    padding: 0 10px 0 10px;
  }
  @media only screen and (max-width: 295px) {
    .input-style {
      max-width: 95px;
    }
  }
  .input-title {
    margin: 5px 16px 0 0;
  }
  textarea {
    border: 0.5px solid #BCBCBC;
    border-radius: 6px;
    height: 59px;
    padding: 10px 16px 10px 16px;
  }
  .title-textarea {
    margin: 5px 8px 0 0;
  }
}
.red-star {
  color: red;
}
.alret-fill-input {
  color: red;
  display: none;
  margin: 10px 0 0 0;
}
.img-wrap {
  position: relative;
  .closeImage {
    cursor: pointer;
    position: absolute;
    right: 9%;
    top: 5px;
    z-index: 100;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: none;
    i {
      font-size: 16px;
      display: flex;
      justify-content: center;
    }
  }
}
.dropdownMore {
  background-color: #F4F4F4;
  border-radius: 12px;
  padding: 9px;
  margin-top: 16px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  .Expand {
    display: none;
  }
  .grid-dropdownMore {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 6px;
  }
  .input-style {
    margin-top: 10px;
    border-radius: 6px;
    border: 0.5px solid #BCBCBC;
    height: 32px;
    padding: 0px 10px 0px 10px;
  }
  .icon-dropdown {
    vertical-align: middle;
    margin-right: 13px;
  }
  @media only screen and (max-width: 374px){
    .input-style {
      max-width: 120px;
    }
  }
  @media only screen and (max-width: 347px) {
    .input-style {
      max-width: 100px;
    }
  }
  @media only screen and (max-width: 295px) {
    .input-style {
      max-width: 85px;
    }
  }
}

#dropdownPlace, #dropdownSeverity {
  width: 100%;
  height: 32px;
  padding: 0 10px 0 10px;
  border-radius: 6px;
  border: 0.5px solid #BCBCBC;
}

input[type="radio"], input[type="checkbox"] {
  accent-color: green;
}
input[type="date"], input[type="time"] {
  accent-color: green;
  padding: 8px 16px 8px 16px;
  border: 0.5px solid #BCBCBC;
  border-radius: 6px;
}
.eventTypeContent{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .eventType-btn {
    padding: 8px 0;
    text-align: center;
    border: 0.5px solid #DEDEDE;
    border-radius: 99px;
    margin: 8px 8px 0 0;
    cursor: pointer;
    .eventType-icon {
      height: 14px;
      padding-right: 5px;
      padding-top: 3px
    }
    label {
      font-size: 14px;
      vertical-align: top;
      cursor: pointer;
    }
  }
  .selectedEventType {
    border-color: $primary;
    background-color: #F4FCF9;
    cursor: pointer;
  }
}
.loading-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);

    .error-message,
    .success-message {
      font-family: 'Prompt',serif;
      font-size: 16px;
      padding: 12px;
      text-align: center;
      color: rgb(255, 255, 255);
      max-width: 230px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
    }
  }
.depart-datetime {
  label {
    margin: 8px 8px 0px 0px;
  }
  .title {
    font-family: "Prompt",serif;
    color: $primary;
  }

  .vue-datetime-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    i {
      cursor: pointer;
      padding: 6px;
      background-color: $primary;
      border-radius: 0 8px 8px 0;
      color: rgb(255, 255, 255);
      border: 1px solid $extraLightGrey;
      border-left: none;
      box-sizing: border-box;
      font-size: 18px;
    }

    button.reset {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 30%;
      cursor: pointer;
      border: none;
      background-color: transparent;
      padding: 0;

      i {
        cursor: pointer;
        font-size: 18px;
        padding: 7px;
        box-sizing: border-box;
        background-color: transparent;
        border: none;
        color: $darkGrey;
      }
    }
    @media only screen and (min-width: $mobileMaxSize){
      button.end-datetime-close {
        padding-left: 7px;
      }
    }
  }
}
.datetime-start {
  @media only screen and (min-width: $mobileMaxSize){
    margin-left: 7px;
  }
}
</style>
