var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tool-bar",class:{
    'hide-tool-bar': _vm.isLongdoMapV3,
    'hide': _vm.isHidingToolBar
  }},[_vm._l((_vm.toolList.filter(function (t) { return !t.disable; })),function(tool){return [(tool.hash)?_c('router-link',{key:tool.name,class:'ripple '+(tool.className || ''),style:({ width: ("calc(100% / " + _vm.numberOfEnableToolMenu + ")") }),attrs:{"tag":"a","to":tool.onMainOnly ? {
        name: 'Main',
        query: {},
        hash: _vm.$route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
      } : {
        query: _vm.$route.query,
        hash: _vm.$route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
      },"replace":_vm.$route.hash === '#layers' || _vm.$route.hash === '#maps'},nativeOn:{"click":function($event){return _vm.onClickTool(tool)}}},[_c('i',{staticClass:"material-icons-round"},[_vm._v(" "+_vm._s(tool.iconName)+" ")]),(!tool.disableLabel)?_c('label',[_vm._v(" "+_vm._s(_vm.$t('toolBar.' + tool.name))+" ")]):_vm._e(),(tool.name === 'layers' && _vm.numberOfLayer > 0)?_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.numberOfLayerFormat))]):_vm._e()]):_c('button',{key:'tool-' + tool.name,staticClass:"ripple",class:{ active: tool.active },style:({ width: ("calc(100% / " + _vm.numberOfEnableToolMenu + ")") }),on:{"click":tool.click}},[_c('i',{staticClass:"material-icons-round"},[_vm._v(" "+_vm._s(tool.iconName)+" ")]),_c('label',[_vm._v(" "+_vm._s(_vm.$t('toolBar.' + tool.name))+" ")])])]})],2),(_vm.isLongdoMapV3)?_c('div',{staticClass:"tool-bar-desktop",class:{
    'active': _vm.onToggle
  }},[_c('div',{staticClass:"ldmap-btn-group"},_vm._l((_vm.toolListDesktop.filter(function (t) { return !t.disable; })),function(tool){return _c('div',{key:tool.name,staticClass:"ldmap-btn ripple",class:{
        'ldmap-btn-active': _vm.$route.hash === ("#" + (tool.name))
      }},[(tool.hash)?_c('router-link',{key:tool.name,attrs:{"tag":"a","to":tool.onMainOnly ? {
          name: 'Main',
          query: {},
          hash: _vm.$route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
        } : {
          query: _vm.$route.query,
          hash: _vm.$route.hash === ('#' + tool.hash) ? '' : ('#' + tool.hash)
        },"replace":_vm.$route.hash === '#layers' || _vm.$route.hash === '#maps'}},[_c('label',[_c('i',{staticClass:"material-icons-round"},[_vm._v(" "+_vm._s(tool.iconName)+" ")]),_vm._v(" "+_vm._s(_vm.$t('toolBar.' + tool.name))+" ")]),(tool.name === 'layers' && _vm.numberOfLayer > 0)?_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.numberOfLayerFormat))]):_vm._e()]):_c('button',{staticClass:"ldmap-btn ripple",class:{ active: tool.active },on:{"click":tool.click}},[_c('label',[_c('i',{staticClass:"material-icons-round"},[_vm._v(" "+_vm._s(tool.iconName)+" ")]),_vm._v(" "+_vm._s(_vm.$t('toolBar.' + tool.name))+" ")])])],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }