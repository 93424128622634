<template>
  <div
    class="search-panel"
    :class="{
      hide: isHidingSearchPanel,
      'routing-mode': !isHidingInputPanel,
    }"
  >
    <div
      class="search-on-map"
      :class="{
        active: !isHidingBackButton,
        'search-map3': isLongdoMapV3,
      }"
    >
      <input
        ref="search-input"
        type="text"
        :placeholder="$t('searchPanel.searchInputPlaceholder')"
        @focus="focusSearchInput"
        @keyup.enter="
          isSearchTrafficEventMode ? searchTrafficEvent() : search(true, true)
        "
        v-on:keydown="onKeydown"
        :value="keywordInput"
        @input="changeSearchInput"
      />
      <button
        class="back ripple"
        @click="clickBack()"
        v-if="!isHidingBackButton"
      >
        <i class="material-icons-round"> arrow_back </i>
      </button>
      <button
        class="clear ripple"
        @click="clickClear()"
        v-if="!isHidingClearButton"
      >
        <i class="material-icons-round"> clear </i>
      </button>
      <button
        @click="
          isSearchTrafficEventMode ? searchTrafficEvent() : search(true, true)
        "
        class="ldmap-search ripple"
        :class="{
          active: !isHidingBackButton,
        }"
      >
        <i class="material-icons-round"> search </i>
      </button>
      <button class="ldmap-direction ripple" @click="onClickDirection">
        <i class="material-icons-round">  commute  </i>
      </button>
    </div>
    <div
      class="list-suggest"
      :class="{
        'traffic-product': isTrafficProduct,
        'list-suggest-map3': isLongdoMapV3,
      }"
    >
      <select v-if="isTrafficProduct" @change="jumpToPlace">
        <option value="" selected disabled hidden>
          {{ $t("searchPanel.flashPlaceList.selectArea") }}
        </option>
        <option v-for="place in flashPlaceList" :key="place" :value="place">
          {{ $t(`searchPanel.flashPlaceList.${place}`) }}
        </option>
      </select>
      <div class="tag-list-suggest-bar">
        <button
          class="btn-scroll-left"
          @click="scroll('left')"
          :class="{ hide: !isHidingBackButton || $route.hash === '#explore' }"
          v-if="suggestTagScrollPosition > 24"
        >
          &laquo;
        </button>
        <!-- map -->
        <div
          ref="suggestTags"
          @scroll="searchFromTagScrollHandler"
          class="tag-list-suggest flash"
          :class="{ hide: !isHidingBackButton || $route.hash === '#explore' }"
          v-if="isLongdoMapV3 && !isTrafficProduct"
        >
          <button
            @click="
              searchFromTag({
                tag: flashExplore.name,
              })
            "
            v-for="flashExplore in flashExploreList"
            :key="'flash-tag-explore-' + flashExplore.name"
            class="ripple taglist"
          >
            <img
              :src="flashExplore.icon.x2"
              :srcset="
                flashExplore.icon.x1 + ' 1x, ' + flashExplore.icon.x2 + ' 2x'
              "
            />
            {{ flashExplore.displayName[$i18n.locale] }}
          </button>
        </div>
        <!-- traffic -->
        <div
          ref="suggestTags"
          @scroll="searchFromTagScrollHandler"
          class="tag-list-suggest flash"
          :class="{ hide: !isHidingBackButton }"
          v-if="isLongdoMapV3 && isTrafficProduct"
        >
          <button @click="clickRecentIncident" class="ripple">
            {{ $t("trafficIncidentPanel.recentIncident") }}
          </button>
          <button
            @click="clickTrafficTag(eventType)"
            :ref="`button${index}`"
            v-for="(eventType, index) in flashTrafficEventType"
            :key="eventType.id"
            class="ripple taglist"
          >
            <img
              :src="`${baseTrafficIconUrl.replace('{{icon}}', eventType.icon)}`"
              style="width: 18px; height: 18px"
            />
            {{ $i18n.locale === "th" ? eventType.name : eventType.name_en }}
          </button>
        </div>
        <button
          class="btn-scroll-right"
          @click="scroll('right')"
          :class="{ hide: !isHidingBackButton || $route.hash === '#explore' }"
          v-if="
            suggestListWidth + suggestTagScrollPosition <
              suggestTagButtonListWidth + 24 && isLongdoMapV3
          "
        >
          &raquo;
        </button>
      </div>
    </div>
    <button
      class="selected-tag-map3 ripple"
      v-if="selectedTag && isLongdoMapV3 && $route.hash === ''"
      @click="searchFromTag(selectedTag)"
    >
      <span> ค้นหา </span>
      <span>
        <img
          :src="selectedTag.imagefile"
          :srcset="
            selectedTag.imagefile.replace('/icons_2x/', '/icons/') +
            ' 1x, ' +
            selectedTag.imagefile +
            ' 2x'
          "
          loading="lazy"
        />
      </span>
      <b>{{ selectedTag[$i18n.locale === "th" ? "name" : "name_en"] }}</b>
      <span> บริเวณนี้ </span>
    </button>
    <button
      class="selected-tag ripple"
      v-if="selectedTag && !isLongdoMapV3 && $route.hash === ''"
      @click="searchFromTag(selectedTag)"
    >
      <img
        :src="selectedTag.imagefile"
        :srcset="
          selectedTag.imagefile.replace('/icons_2x/', '/icons/') +
          ' 1x, ' +
          selectedTag.imagefile +
          ' 2x'
        "
        loading="lazy"
      />
      {{ selectedTag[$i18n.locale === "th" ? "name" : "name_en"] }}
    </button>
    <!-- Mobile -->
    <div
      :class="{
        'recommend-container': !isLongdoMapV3,
        'recommend-container-map3': isLongdoMapV3,
        hide: isHidingRecommendContainer,
      }"
      @click="closeSuggest"
    >
    <div v-if="showSearchHistory && !isTrafficProduct" class="search-history-container" :class="{
         hide: (keywordInput.length != 0) & (suggestList.length > 0)
      }">
        <div class="topic">
          {{
              $t("searchPanel.searchHistory")
          }}
        </div>
        <div ref="search-history-list" class="search-panel-history" v-if="!isTrafficProduct">
          <div
            class="history-list"
            v-for="(history, index) in searchHistory"
            :key="index"
            @click="clickHistory(history)"
          >
            <i class="material-icons-round">
              {{ history.type === "poi" ? "history" : "keyboard" }}
            </i>
            <span>
              {{ history.name }}
            </span>
            <i
              @click.stop="removeHistory(history)"
              class="material-icons-round remove ripple"
              >close</i
            >
          </div>
        </div>
      </div>
      <div class="example-container">
        <div v-if="isTrafficProduct" class="toggle-mode-container">
          <button
            :class="{ active: isSearchTrafficEventMode }"
            @click="toggleSearchMode"
            :disabled="isSearchTrafficEventMode"
          >
            {{ $t("searchPanel.trafficEventMode") }}
          </button>
          <button
            :class="{ active: !isSearchTrafficEventMode }"
            @click="toggleSearchMode"
            :disabled="!isSearchTrafficEventMode"
          >
            {{ $t("searchPanel.placeMode") }}
          </button>
        </div>
        <div class="topic">
          {{ $t("searchPanel.example") }}
        </div>
        <div class="example" v-if="!isSearchTrafficEventMode">
          <div class="topic">
            {{ $t("searchPanel.placeName") }}
          </div>
          <div class="value">
            <span @click="searchExample('บริษัท เมตามีเดีย เทคโนโลยี จำกัด')">
              {{ $t("searchPanel.placeNameExample[0]") }}
            </span>
          </div>
        </div>
        <div class="example" v-else>
          <div class="topic">
            {{ $t("searchPanel.trafficEventName") }}
          </div>
          <div class="value">
            <span @click="searchExample('รถบรรทุก')">
              {{ $t("searchPanel.trafficEventNameExample[0]") }}
            </span>
          </div>
        </div>
        <div class="example" v-if="!isSearchTrafficEventMode">
          <div class="topic">
            {{ $t("searchPanel.adminBoundary") }}
          </div>
          <div class="value">
            <span @click="searchExample('แขวงสีลม เขตบางรัก กรุงเทพมหานคร')">
              {{ $t("searchPanel.adminBoundaryExample[0]") }}
            </span>
          </div>
        </div>
        <div class="example">
          <div class="topic">
            {{
              $t(
                `searchPanel.${
                  isSearchTrafficEventMode ? "trafficCategory" : "category"
                }`
              )
            }}
          </div>
          <div class="value">
            <span
              @click="
                searchExample(
                  isSearchTrafficEventMode
                    ? 'event:อุบัติเหตุ'
                    : $t('searchPanel.categoryExample[0]')
                )
              "
            >
              {{
                $t(
                  `searchPanel.${
                    isSearchTrafficEventMode
                      ? "trafficCategoryExample[0]"
                      : "categoryExample[0]"
                  }`
                )
              }} </span
            >,
            <span
              @click="
                searchExample(
                  isSearchTrafficEventMode
                    ? 'event:ก่อสร้าง'
                    : $t('searchPanel.categoryExample[1]')
                )
              "
            >
              {{
                $t(
                  `searchPanel.${
                    isSearchTrafficEventMode
                      ? "trafficCategoryExample[1]"
                      : "categoryExample[1]"
                  }`
                )
              }}
            </span>
          </div>
        </div>
        <div class="example" v-if="!isSearchTrafficEventMode">
          <div class="topic">
            {{ $t("searchPanel.coordinate") }}
          </div>
          <div class="value">
            <span @click="searchExample('13.72264,100.52931')">
              13.72264,100.52931
            </span>
            {{ $t("searchPanel.or") }}
            <span @click="searchExample('665365,1517575,47N')">
              665365,1517575,47N
            </span>
          </div>
        </div>
        <div class="example" v-if="!isSearchTrafficEventMode">
          <div class="topic">
            {{ $t("searchPanel.layer") }}
          </div>
          <div class="value">
            <span
              @click="
                clickSuggest({
                  w: 'layer: traffic',
                })
              "
            >
              {{ layerList[0].displayName[$i18n.locale] }} </span
            >,
            <span
              @click="
                clickSuggest({
                  w: 'layer: evstation',
                })
              "
            >
              {{ layerList[8].displayName[$i18n.locale] }}
            </span>
          </div>
        </div>
      </div>
      <div class="tag-category-container" v-if="!isSearchTrafficEventMode">
        <div class="topic">
          {{ $t("searchPanel.searchByIcon") }}
        </div>
        <div
          v-for="tagCategory in tagCategoryList"
          :key="'tag-category-id-' + tagCategory.category_id"
          class="tag-category"
        >
          <label>
            {{ tagCategory["category_name_" + $i18n.locale] }}
          </label>
          <div class="tag-list">
            <template v-for="tag in tagCategory.icons">
              <div
                :key="'tag-id-' + tag.id"
                class="tag"
                v-if="tag[$i18n.locale === 'th' ? 'name' : 'name_en'] !== ''"
              >
                <div class="icon" @click="clickTag(tag)">
                  <img
                    :src="tag.imagefile"
                    :srcset="
                      tag.imagefile.replace('/icons_2x/', '/icons/') +
                      ' 1x, ' +
                      tag.imagefile +
                      ' 2x'
                    "
                    loading="lazy"
                  />
                </div>
                <label @click="clickTag(tag)">
                  {{ tag[$i18n.locale === "th" ? "name" : "name_en"] }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="tag-category-container" v-else>
        <div class="topic">
          {{ $t("searchPanel.searchByTrafficIcon") }}
        </div>
        <div class="tag-category">
          <div class="tag-list">
            <div
              class="tag"
              v-for="eventType in trafficEventType"
              :key="eventType.id"
            >
              <div class="icon" @click="clickTrafficTag(eventType)">
                <img
                  :src="`${baseTrafficIconUrl.replace(
                    '{{icon}}',
                    eventType.icon
                  )}`"
                  loading="lazy"
                />
              </div>
              <label @click="clickTrafficTag(eventType)">
                {{ eventType[$i18n.locale === "th" ? "name" : "name_en"] }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="suggest-list"
        v-if="
          keywordInput.length >= 2 &&
          suggestList.length > 0 &&
          $route.hash === '#search'
        "
      >
        <div
          v-for="(suggest, index) in suggestList"
          class="suggest"
          :key="suggest.w"
          @click.stop="clickSuggest(suggest, index)"
          v-html="suggest.d"
        ></div>
      </div>
    </div>
    <!-- Desktop -->
    <div
      v-if="isLongdoMapV3"
      :class="{
        'recommend-container-desktop-map3': isLongdoMapV3,
          hide:
          isHidingRecommendContainer,
          'show-suggest': (!isHidingRecommendContainer && (keywordInput.length != 0) & (suggestList.length > 0))
      }"
      @click="closeSuggest"
    >
      <div v-if="showSearchHistory && !isTrafficProduct" class="search-history-container" :class="{
         hide: (keywordInput.length != 0) & (suggestList.length > 0)
      }">
        <div class="topic">
          {{
              $t("searchPanel.searchHistory")
          }}
        </div>
        <div ref="search-history-list" class="search-panel-history" v-if="!isTrafficProduct">
          <div
            class="history-list"
            v-for="(history, index) in searchHistory"
            :key="index"
            @click="clickHistory(history)"
          >
            <i class="material-icons-round">
              {{ history.type === "poi" ? "history" : "keyboard" }}
            </i>
            <span> {{ history.name }}</span>
            <i
              @click.stop="removeHistory(history)"
              class="material-icons-round remove ripple"
              >close</i
            >
          </div>
        </div>
      </div>
      <div
        class="example-container"
        :class="{
          hide:
            isHidingRecommendContainer ||
            (keywordInput.length != 0) & (suggestList.length > 0),
        }"
      >
        <div v-if="isTrafficProduct" class="toggle-mode-container">
          <button
            :class="{ active: isSearchTrafficEventMode }"
            @click="toggleSearchMode"
            :disabled="isSearchTrafficEventMode"
          >
            {{ $t("searchPanel.trafficEventMode") }}
          </button>
          <button
            :class="{ active: !isSearchTrafficEventMode }"
            @click="toggleSearchMode"
            :disabled="!isSearchTrafficEventMode"
          >
            {{ $t("searchPanel.placeMode") }}
          </button>
        </div>
        <div class="topic" v-if="isMapProduct">
          {{ $t("searchPanel.example") }}
          <!-- $t("searchPanel.searchHistory") -->
        </div>
        <div class="topic" v-else>
          {{ $t("searchPanel.example") }}
        </div>
        <div class="example-panel-normal">
          <div class="example" v-if="!isSearchTrafficEventMode">
            <div class="topic">
              {{ $t("searchPanel.placeName") }}
            </div>
            <div class="value">
              <span @click="searchExample('บริษัท เมตามีเดีย เทคโนโลยี จำกัด')">
                {{ $t("searchPanel.placeNameExample[0]") }}
              </span>
            </div>
          </div>
          <div class="example" v-else>
            <div class="topic">
              {{ $t("searchPanel.trafficEventName") }}
            </div>
            <div class="value">
              <span @click="searchExample('รถบรรทุก')">
                {{ $t("searchPanel.trafficEventNameExample[0]") }}
              </span>
            </div>
          </div>
          <div class="example" v-if="!isSearchTrafficEventMode">
            <div class="topic">
              {{ $t("searchPanel.adminBoundary") }}
            </div>
            <div class="value">
              <span @click="searchExample('แขวงสีลม เขตบางรัก กรุงเทพมหานคร')">
                {{ $t("searchPanel.adminBoundaryExample[0]") }}
              </span>
            </div>
          </div>
          <div class="example">
            <div class="topic">
              {{
                $t(
                  `searchPanel.${
                    isSearchTrafficEventMode ? "trafficCategory" : "category"
                  }`
                )
              }}
            </div>
            <div class="value">
              <span
                @click="
                  searchExample(
                    isSearchTrafficEventMode
                      ? 'event:อุบัติเหตุ'
                      : $t('searchPanel.categoryExample[0]')
                  )
                "
              >
                {{
                  $t(
                    `searchPanel.${
                      isSearchTrafficEventMode
                        ? "trafficCategoryExample[0]"
                        : "categoryExample[0]"
                    }`
                  )
                }} </span
              >,
              <span
                @click="
                  searchExample(
                    isSearchTrafficEventMode
                      ? 'event:ก่อสร้าง'
                      : $t('searchPanel.categoryExample[1]')
                  )
                "
              >
                {{
                  $t(
                    `searchPanel.${
                      isSearchTrafficEventMode
                        ? "trafficCategoryExample[1]"
                        : "categoryExample[1]"
                    }`
                  )
                }}
              </span>
            </div>
          </div>
          <div class="example" v-if="!isSearchTrafficEventMode">
            <div class="topic">
              {{ $t("searchPanel.coordinate") }}
            </div>
            <div class="value">
              <span @click="searchExample('13.72264,100.52931')">
                13.72264,100.52931
              </span>
              {{ $t("searchPanel.or") }}
              <span @click="searchExample('665365,1517575,47N')">
                665365,1517575,47N
              </span>
            </div>
          </div>
          <div class="example" v-if="!isSearchTrafficEventMode">
            <div class="topic">
              {{ $t("searchPanel.layer") }}
            </div>
            <div class="value">
              <span
                @click="
                  clickSuggest({
                    w: 'layer: traffic',
                  })
                "
              >
                {{ layerList[0].displayName[$i18n.locale] }} </span
              >,
              <span
                @click="
                  clickSuggest({
                    w: 'layer: evstation',
                  })
                "
              >
                {{ layerList[8].displayName[$i18n.locale] }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Example -->
      <div
        class="tag-category-container"
        :class="{
          hide:
            isHidingRecommendContainer ||
            (keywordInput.length != 0) & (suggestList.length > 0),
          'traffic-mode': isSearchTrafficEventMode,
          'traffic-product': isTrafficProduct,
        }"
      >
        <div class="topic">
          {{
            $t(`searchPanel.searchBy${isTrafficProduct ? "Traffic" : ""}Icon`)
          }}
        </div>
        <div
          class="tag-category-container-list"
          ref=""
          v-if="!isSearchTrafficEventMode"
        >
          <div
            v-for="tagCategory in tagCategoryList"
            :key="'tag-category-id-' + tagCategory.category_id"
            class="tag-category"
          >
            <label>
              {{ tagCategory["category_name_" + $i18n.locale] }}
            </label>
            <div class="tag-list">
              <template v-for="tag in tagCategory.icons">
                <div
                  :key="'tag-id-' + tag.id"
                  class="tag"
                  v-if="tag[$i18n.locale === 'th' ? 'name' : 'name_en'] !== ''"
                >
                  <div class="icon" @click="clickTag(tag)">
                    <img
                      :src="tag.imagefile.replace('/icons_2x/', '/icons_4x/')"
                      :srcset="
                        tag.imagefile +
                        ' 1x, ' +
                        tag.imagefile.replace('/icons_2x/', '/icons_4x/') +
                        ' 2x'
                      "
                      loading="lazy"
                    />
                  </div>
                  <label @click="clickTag(tag)">
                    {{ tag[$i18n.locale === "th" ? "name" : "name_en"] }}
                  </label>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="tag-category-container-list" v-else>
          <div class="tag-category">
            <div class="tag-list">
              <div
                class="tag"
                v-for="eventType in trafficEventType"
                :key="eventType.id"
              >
                <div class="icon" @click="clickTrafficTag(eventType)">
                  <img
                    :src="`${baseTrafficIconUrl.replace(
                      '{{icon}}',
                      eventType.icon
                    )}`"
                    loading="lazy"
                  />
                </div>
                <label @click="clickTrafficTag(eventType)">
                  {{ eventType[$i18n.locale === "th" ? "name" : "name_en"] }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="suggest-list-desktop"
        v-if="
          keywordInput.length >= 2 &&
          suggestList.length > 0 &&
          $route.hash === '#search'
        "
      >
        <div
          v-for="suggest in suggestList"
          class="suggest-desktop"
          :key="suggest.w"
          @click.stop="clickSuggest(suggest)"
          v-html="suggest.d"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchPanel',
  props: {
    map: {
      type: Object
    }
  },
  data () {
    return {
      isHidingSearchPanel: false,
      keywordInput: '',
      keyword: '',
      crosshairLocation: {
        lat: localStorage.lat
          ? Number(localStorage.lat)
          : Number(process.env.VUE_APP_DEFAULT_LAT),
        lon: localStorage.lon
          ? Number(localStorage.lon)
          : Number(process.env.VUE_APP_DEFAULT_LON)
      },
      searchLocation: {
        lat: localStorage.lat
          ? Number(localStorage.lat)
          : Number(process.env.VUE_APP_DEFAULT_LAT),
        lon: localStorage.lon
          ? Number(localStorage.lon)
          : Number(process.env.VUE_APP_DEFAULT_LON)
      },
      suggestTagScrollPosition: 0,
      suggestTagButtonListWidth: 0, // total width of all button
      suggestListWidth: 1024,
      suggestList: [],
      suggestTask: null,
      showSearchHistory: false,
      searchHistory: [],
      flashExploreList: [],
      trafficEventType: [],
      flashPlaceList: [
        'bangkok',
        'innerBangkok',
        'outerBangkok',
        'silom',
        'victoryMonument',
        'ladprao',
        'ramkhamhaeng',
        'rangsit',
        'ayutthaya',
        'chainat',
        'saraburi',
        'nakhonratchasima',
        'chiangmai',
        'chiangrai',
        'phuket'
      ],
      cacheData: [],
      itemsPerPage: 20,
      suggestPosotion: 0,
      keyboardArrow: null,
      offset: 0,
      offsetTrafficEvent: 0,
      selectedTag: null,
      isSmart: false,
      baseTrafficIconUrl:
        process.env.VUE_APP_LONGDO_TRAFFIC_EVENT_TYPE_PATH_ICON,
      isSearchTrafficEventMode: process.env.VUE_APP_PRODUCT === 'traffic',
      trafficEventResult: [],
      trafficEventSearchIntervalId: null
    }
  },
  updated () {
    const self = this
    if (self.$route.hash !== '#search') {
      self.$refs['search-input'].blur()
      if ('search' in self.$route.query) {
        self.keywordInput = self.$route.query.search
      } else {
        // https://mmtech.slack.com/archives/G9X7WTW2Y/p1622262193009400
        // self.keywordInput = ''
      }
      if (self.isTrafficProduct && 'searchEvent' in self.$route.query) {
        self.keywordInput = self.$route.query.searchEvent
        self.isSearchTrafficEventMode = true
      }
      if (
        self.$route.name === 'Main' &&
        Object.keys(self.$route.query).length === 0
      ) {
        self.keywordInput = ''
      }
    }
  },
  computed: {
    isHidingInputPanel () {
      const self = this
      return (
        self.$route.hash !== '#routing' &&
        self.$route.hash !== '#choose-location-for-routing'
      )
    },
    isHidingSearchButton () {
      const self = this
      return self.$route.hash === '#search'
    },
    isHidingBackButton () {
      const self = this
      return self.$route.hash !== '#search'
    },
    isHidingClearButton () {
      const self = this
      return self.keywordInput.length <= 0 && self.$route.hash === '#search'
    },
    isHidingRecommendContainer () {
      const self = this
      return self.$route.hash !== '#search'
    },
    flashTrafficEventType () {
      const self = this
      if (
        self.trafficEventType !== null &&
        self.trafficEventType.length !== 0
      ) {
        const filterOrder = [8, 1, 2, 3, 5, 10, 6, 18, 19]
        return filterOrder.map((id) =>
          self.trafficEventType.find((obj) => obj.id === id)
        )
      } else {
        return []
      }
    }
  },
  async created () {
    const self = this
    try {
      self.flashExploreList = (await self.api.getFlashExploreList()).data
    } catch (error) {}
    if (self.isTrafficProduct) {
      try {
        self.trafficEventType = (await self.api.getTrafficEventType()).data
      } catch (error) {
        self.trafficEventType = []
      }
    }
  },
  beforeMount () {
    const self = this
    self.getSearchHistory()
  },
  async mounted () {
    const self = this
    self.$parent.$on('clickMap', self.toggleDisplaySearchPanel)
    self.$parent.$on('location', self.changeCrosshairLocation)
    self.$parent.$on('clickHasMore', self.more)
    self.$parent.$on('clickHasMoreTrafficEvent', self.moreTrafficEvent)
    self.$parent.$on('saveSearchHistory', async (data) => {
      await self.saveSearchHistory(data)
    })
    if ('tag' in self.$route.query) {
      self.$emit('showTag', self.$route.query.tag)
    }
    if ('search' in self.$route.query) {
      if ('lat' in self.$route.query) {
        self.crosshairLocation.lat = Number(self.$route.query.lat)
      }
      if ('lon' in self.$route.query) {
        self.crosshairLocation.lon = Number(self.$route.query.lon)
      }
      self.keywordInput = self.$route.query.search
      self.search(false, true)
    }
    if (self.isTrafficProduct && 'searchEvent' in self.$route.query) {
      self.keywordInput = self.$route.query.searchEvent
      self.isSearchTrafficEventMode = true
      if ('offset' in self.$route.query) {
        self.offsetTrafficEvent = parseInt(self.$route.query.offset)
        self.searchTrafficEvent(false, true)
      } else {
        self.searchTrafficEvent(false)
      }
    } else if (self.isTrafficProduct) {
      await self.searchAllActiveTrafficEvent()
    }
    self.$root.$on('changeSearchTag', (tag) => {
      if (tag) {
        self.keywordInput = tag.name
        self.search(true, true)
      }
    })
    self.suggestListWidth = self.$refs.suggestTags?.clientWidth
    self.$root.$on('resize', (size) => {
      const suggestTagListWidth = self.$refs.suggestTags?.clientWidth
      try {
        if (suggestTagListWidth < 840) {
          self.$refs.suggestTags.classList.add('show-btn-scroll')
        } else {
          self.$refs.suggestTags.classList.remove('show-btn-scroll')
        }
      } catch (e) {
        console.warn("Can't add classlist suggest tag")
      }
    })
    self.$root.$on('createEventSuccess', () => {
      if ('searchEvent' in self.$route.query) {
        if ('offset' in self.$route.query) {
          self.offsetTrafficEvent = parseInt(self.$route.query.offset)
          self.searchTrafficEvent(false, true)
        } else {
          self.searchTrafficEvent(false)
        }
      } else {
        self.searchAllActiveTrafficEvent()
      }
      // self.$root.$emit('showMessageToast', self.$t('searchPanel.createTrafficIncidentSuccess'))
    })

    self.$root.$on('editEventSuccess', () => {
      if ('searchEvent' in self.$route.query) {
        if ('offset' in self.$route.query) {
          self.offsetTrafficEvent = parseInt(self.$route.query.offset)
          self.searchTrafficEvent(false, true)
        } else {
          self.searchTrafficEvent(false)
        }
      } else {
        self.searchAllActiveTrafficEvent()
      }
      // self.$root.$emit('showMessageToast', self.$t('searchPanel.editTrafficIncidentSuccess'))
    })

    self.$root.$on('deleteEventSuccess', () => {
      if ('searchEvent' in self.$route.query) {
        if ('offset' in self.$route.query) {
          self.offsetTrafficEvent = parseInt(self.$route.query.offset)
          self.searchTrafficEvent(false, true)
        } else {
          self.searchTrafficEvent(false)
        }
      } else {
        self.searchAllActiveTrafficEvent()
      }
      self.searchAllActiveTrafficEvent()
      // self.$root.$emit('showMessageToast', self.$t('searchPanel.deleteTrafficIncidentSuccess'))
    })
    self.$refs.suggestTags.addEventListener('scroll', (evt) => {
      const scrollLeft = evt.target.scrollLeft
      const suggestTagListWidth = self.$refs.suggestTags?.clientWidth
      const scrollWidth = evt.target.scrollWidth

      if (
        Number.parseFloat(scrollWidth) -
          Number.parseFloat(suggestTagListWidth) ===
        Number.parseFloat(scrollLeft)
      ) {
        self.$refs.suggestTags.classList.remove('show-btn-scroll')
      } else {
        self.$refs.suggestTags.classList.add('show-btn-scroll')
      }
    })

    self.isSearchTrafficEventMode = self.isTrafficProduct
    await self.calculateButtonListWidth()
  },
  methods: {
    async getHistoryDataFormNativeApp () {
      const self = this
      try {
        const historyOnApp = await self.lji.getStorage({
          key: 'searchHistory'
        })
        if (typeof JSON.parse(historyOnApp) !== 'object') {
          return '[]'
        }
        return historyOnApp || '[]'
      } catch (error) {
        return '[]'
      }
    },
    async addHistoryDataToNativeApp (history) {
      const self = this
      await self.lji.addStorage({
        key: 'searchHistory',
        value: JSON.stringify(history)
      })
    },
    async saveSearchHistory  (placeData) {
      let history = []
      const self = this
      if (self.isOnMobileApp) {
        history = await self.getHistoryDataFormNativeApp()
        history = JSON.parse(history)
      } else {
        history = await self.getAllSearchHistory()
      }
      const place = {
        id: placeData.id || '',
        type: placeData.type || 'keyword',
        name: placeData.name || '',
        name_en: placeData.name_en || placeData.name || ''
      }
      if (place.id.length === 0) {
        return
      }
      if (history.length === 0) {
        if (self.isOnMobileApp) {
          await self.addHistoryDataToNativeApp([place])
        } else {
          localStorage.setItem(
            process.env.VUE_APP_SEARCH_HISTORY_KEY,
            JSON.stringify([place])
          )
        }
      } else {
        if (place.type === 'keyword') {
          const historyByKeyWord = await self.getSearchHistoryByKeyword(place.name)
          if (!historyByKeyWord) {
            if (history.length < 10) {
              history.push(place)
            } else {
              history.shift()
              history.push(place)
            }
            if (self.isOnMobileApp) {
              await self.addHistoryDataToNativeApp(history)
            } else {
              localStorage.setItem(
                process.env.VUE_APP_SEARCH_HISTORY_KEY,
                JSON.stringify(history)
              )
            }
          }
        } else {
          const historyData = await self.getSearchHistoryById(place.id)
          if (!historyData) {
            if (history.length < 10) {
              history.push(place)
            } else {
              history.shift()
              history.push(place)
            }
            if (self.isOnMobileApp) {
              await self.addHistoryDataToNativeApp(history)
            } else {
              localStorage.setItem(
                process.env.VUE_APP_SEARCH_HISTORY_KEY,
                JSON.stringify(history)
              )
            }
          }
        }
      }
      return true
    },
    async removeSearchHistory (placeId) {
      const self = this
      let history = []
      if (self.isOnMobileApp) {
        history = await self.getHistoryDataFormNativeApp()
        history = JSON.parse(history)
      } else {
        history = JSON.parse(
          localStorage.getItem(process.env.VUE_APP_SEARCH_HISTORY_KEY)
        )
      }
      const isHasId = history.findIndex((h) => {
        return h.id === placeId
      })
      if (isHasId === -1) {
        return false
      }
      history.splice(isHasId, 1)
      if (self.isOnMobileApp) {
        await self.addHistoryDataToNativeApp(history)
      } else {
        localStorage.setItem(
          process.env.VUE_APP_SEARCH_HISTORY_KEY,
          JSON.stringify(history)
        )
      }
      return true
    },
    async getSearchHistoryById (placeId) {
      const self = this
      let history = []
      if (self.isOnMobileApp) {
        history = await self.getHistoryDataFormNativeApp()
        history = JSON.parse(history)
      } else {
        history = JSON.parse(
          localStorage.getItem(process.env.VUE_APP_SEARCH_HISTORY_KEY))
      }
      if (!history || history.length === 0) {
        return false
      }
      const isHasId = history.findIndex((h) => {
        return h.id === placeId
      })
      if (isHasId === -1) {
        return false
      }
      const tempHistory = history[isHasId]
      history.splice(isHasId, 1)
      history.push(tempHistory)
      if (self.isOnMobileApp) {
        await self.addHistoryDataToNativeApp(history)
      } else {
        localStorage.setItem(
          process.env.VUE_APP_SEARCH_HISTORY_KEY,
          JSON.stringify(history)
        )
      }
      return true
    },
    async getSearchHistoryByKeyword (keyword) {
      const self = this
      var searched = []
      if (self.isOnMobileApp) {
        searched = await self.getHistoryDataFormNativeApp()
      } else {
        searched = localStorage.getItem(process.env.VUE_APP_SEARCH_HISTORY_KEY)
      }
      const searchList = JSON.parse(searched)
      const isHasKeyword = searchList.findIndex((k) => {
        return k.name_th === keyword || k.name_en === keyword
      })
      if (isHasKeyword === -1) {
        return false
      }
      const tempHistory = searchList[isHasKeyword]
      searchList.splice(isHasKeyword, 1)
      searchList.push(tempHistory)

      if (self.isOnMobileApp) {
        await self.addHistoryDataToNativeApp(searchList)
      } else {
        localStorage.setItem(
          process.env.VUE_APP_SEARCH_HISTORY_KEY,
          JSON.stringify(searchList)
        )
      }
      return true
    },
    async getAllSearchHistory () {
      const self = this
      if (self.isOnMobileApp) {
        const history = await self.getHistoryDataFormNativeApp()
        return JSON.parse(history)
      } else {
        const searcHistory = localStorage.getItem(process.env.VUE_APP_SEARCH_HISTORY_KEY)
        if (!searcHistory) {
          return []
        }
        return JSON.parse(searcHistory)
      }
    },
    jumpToPlace (event) {
      const self = this
      const place = event.target.value
      switch (place) {
        case 'bangkok':
          self.map.location({ lat: 13.75377, lon: 100.53115 }, false)
          self.map.zoom(12)
          break
        case 'innerBangkok':
          self.map.location({ lat: 13.75377, lon: 100.53115 }, false)
          self.map.zoom(14)
          break
        case 'outerBangkok':
          self.map.location({ lat: 13.75377, lon: 100.53115 }, false)
          self.map.zoom(10)
          break
        case 'silom':
          self.map.location({ lat: 13.72616, lon: 100.52806 }, false)
          self.map.zoom(15, false)
          break
        case 'victoryMonument':
          self.map.location({ lat: 13.76503, lon: 100.53831 }, false)
          self.map.zoom(15, false)
          break
        case 'ladprao':
          self.map.location({ lat: 13.81397, lon: 100.55945 }, false)
          self.map.zoom(14, false)
          break
        case 'ramkhamhaeng':
          self.map.location({ lat: 13.7482, lon: 100.60627 }, false)
          self.map.zoom(14, false)
          break
        case 'rangsit':
          self.map.location({ lat: 14.06421, lon: 100.61725 }, false)
          self.map.zoom(12, false)
          break
        case 'ayutthaya':
          self.map.location({ lat: 14.34788, lon: 100.58192 }, false)
          self.map.zoom(11, false)
          break
        case 'chainat':
          self.map.location({ lat: 15.23561, lon: 100.20665 }, false)
          self.map.zoom(11, false)
          break
        case 'saraburi':
          self.map.location({ lat: 14.52, lon: 100.91533 }, false)
          self.map.zoom(11, false)
          break
        case 'nakhonratchasima':
          self.map.location({ lat: 14.967, lon: 102.0761 }, false)
          self.map.zoom(11, false)
          break
        case 'chiangmai':
          self.map.location({ lat: 18.8, lon: 98.991 }, false)
          self.map.zoom(10, false)
          break
        case 'chiangrai':
          self.map.location({ lat: 19.81, lon: 99.76 }, false)
          self.map.zoom(10, false)
          break
        case 'phuket':
          self.map.location({ lat: 7.95541, lon: 98.991 }, false)
          self.map.zoom(10, false)
          break
      }
    },
    onClickDirection () {
      const self = this
      self.$router.push({
        hash: '#routing'
      })
    },
    async searchTrafficEvent (isUpdateUrl = true, isMore = false) {
      // hard code
      // delete all white space in keyword (only when fetch api)
      const self = this
      self.keyword = self.utility.allTrim(self.keywordInput)
      if (self.keyword === '') {
        self.$router.replace({
          path: self.$route.path,
          query: {}
        })
        self.searchAllActiveTrafficEvent()
        return
      }
      if (isUpdateUrl) {
        self.$router.replace({
          path: self.$route.path,
          query: {
            searchEvent: self.keyword,
            offset: self.offsetTrafficEvent
          }
        })
      }
      if (!isUpdateUrl && isMore) {
        // if enter directly from url with offset
        const res = await self.api.searchTrafficEvent(
          self.keyword.replace(/ /g, ''),
          0,
          self.offsetTrafficEvent + self.itemsPerPage
        )
        self.trafficEventResult =
          res.status === 200 && res.data?.item ? res.data.item : []
        self.$emit('searchTrafficResult', self.trafficEventResult)
        if (self.trafficEventSearchIntervalId) {
          clearInterval(self.trafficEventSearchIntervalId)
        }
        const key = self.keyword.replace(/ /g, '')
        self.trafficEventSearchIntervalId = setInterval(async () => {
          const res = await self.api.searchTrafficEvent(
            key,
            0,
            self.offsetTrafficEvent + self.itemsPerPage
          )
          self.trafficEventResult =
            res.status === 200 && res.data?.item ? res.data.item : []
          self.$emit('searchTrafficResult', self.trafficEventResult)
        }, 1000 * 60 * 3)
      } else if (isMore) {
        const res = await self.api.searchTrafficEvent(
          self.keyword.replace(/ /g, ''),
          self.offsetTrafficEvent
        )
        const eventResult =
          res.status === 200 && res.data?.item ? res.data.item : []
        if (res.data.item.length === 0) {
          self.$emit('noMoreTrafficEvent')
          return
        }
        self.trafficEventResult = self.trafficEventResult.concat(eventResult)
        self.$emit('searchTrafficResult', self.trafficEventResult)
        if (self.trafficEventSearchIntervalId) {
          clearInterval(self.trafficEventSearchIntervalId)
        }
        const key = self.keyword.replace(/ /g, '')
        self.trafficEventSearchIntervalId = setInterval(async () => {
          const res = await self.api.searchTrafficEvent(
            key,
            0,
            self.offsetTrafficEvent + self.itemsPerPage
          )
          self.trafficEventResult =
            res.status === 200 && res.data?.item ? res.data.item : []
          self.$emit('searchTrafficResult', self.trafficEventResult)
        }, 1000 * 60 * 3)
      } else {
        if (isUpdateUrl) {
          self.$router.replace({
            path: self.$route.path,
            query: {
              searchEvent: self.keyword
            }
          })
        }
        self.offsetTrafficEvent = 0
        const res = await self.api.searchTrafficEvent(
          self.keyword.replace(/ /g, '')
        )
        self.trafficEventResult =
          res.status === 200 && res.data?.item ? res.data.item : []
        self.$emit('searchTrafficResult', self.trafficEventResult)
        if (self.trafficEventSearchIntervalId) {
          clearInterval(self.trafficEventSearchIntervalId)
        }
        const key = self.keyword.replace(/ /g, '')
        self.trafficEventSearchIntervalId = setInterval(async () => {
          const res = await self.api.searchTrafficEvent(key)
          self.trafficEventResult =
            res.status === 200 && res.data?.item ? res.data.item : []
          self.$emit('searchTrafficResult', self.trafficEventResult)
        }, 1000 * 60 * 3)
      }
      self.$parent.$emit('openTrafficIncidentPanel')
    },
    async searchAllActiveTrafficEvent () {
      const self = this
      self.offsetTrafficEvent = 0
      const res = await self.api.getTrafficIncident()
      self.trafficEventResult =
        res.status === 200 && res.data?.item ? res.data.item : []
      self.$emit('searchTrafficResult', self.trafficEventResult)
      if (self.trafficEventSearchIntervalId) {
        clearInterval(self.trafficEventSearchIntervalId)
      }
      self.trafficEventSearchIntervalId = setInterval(async () => {
        const res = await self.api.getTrafficIncident()
        self.trafficEventResult =
          res.status === 200 && res.data?.item ? res.data.item : []
        self.$emit('searchTrafficResult', self.trafficEventResult)
      }, 1000 * 60 * 3)
    },
    clickTrafficTag (eventType) {
      const self = this
      self.keywordInput = `event:${eventType.name}`
      self.keyword = `event:${eventType.name}`
      self.searchTrafficEvent()
    },
    toggleSearchMode () {
      const self = this
      self.isSearchTrafficEventMode = !self.isSearchTrafficEventMode
    },
    waitForElm (selector) {
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector))
        }

        const observer = new MutationObserver((mutations) => {
          if (document.querySelector(selector)) {
            observer.disconnect()
            resolve(document.querySelector(selector))
          }
        })

        observer.observe(document.body, {
          childList: true,
          subtree: true
        })
      })
    },
    clickRecentIncident () {
      const self = this
      if ('searchEvent' in self.$route.query) {
        self.$router.replace({
          name: self.$route.name,
          hash: self.$route.hash,
          params: self.$route.params,
          query: {}
        })
      }
      self.$parent.$emit('openTrafficIncidentPanel')
    },
    async calculateButtonListWidth () {
      const self = this
      // calculate total width of all button for showing left and right scroll button
      await self.waitForElm('.tag-list-suggest .ripple.taglist')
      const buttonList = document.querySelectorAll('.tag-list-suggest .ripple')
      self.suggestTagButtonListWidth = 0
      buttonList.forEach((button) => {
        self.suggestTagButtonListWidth += button.clientWidth + 6 // margin
      })
    },
    focusSearchInput () {
      const self = this
      // self.calculatePanelHeight()
      if (self.$route.hash !== '#search') {
        self.$router.push({
          path: self.$route.path,
          query: self.$route.query,
          hash: '#search'
        })
      }
      setTimeout(() => {
        self.$refs['search-input'].select()
        try {
          self.$refs['search-history-list'].scrollTop = 0
        } catch (error) {
          console.warn('cannot scroll')
        }
      }, 100)
      if (!self.isSearchTrafficEventMode) {
        self.suggest()
      }
    },
    changeSearchInput (evt) {
      const self = this
      self.keywordInput = evt.target.value
      if (self.isSearchTrafficEventMode) {
        // do nothing in trafficmode
        return false
      }
      document.querySelectorAll('.suggest-desktop').forEach((e) => {
        e.classList.remove('active')
      })
      this.suggestPosotion = 0
      self.suggest()
    },
    onKeydown (evt) {
      const self = this
      self.keywordInput = evt.target.value
      if (self.isSearchTrafficEventMode) {
        // do nothing in traffic mode
        return false
      }
      const maximunSuggest = self.suggestList.length - 1
      const { key } = evt
      const suggestListHtml = document.querySelectorAll('.suggest-desktop')
      if (!self.keywordInput || suggestListHtml.length <= 0) {
        return
      }
      try {
        if (key === 'ArrowDown') {
          if (self.keyboardArrow === 'ArrowUp') {
            self.suggestPosotion++
          }
          if (self.suggestPosotion === 0) {
            suggestListHtml[self.suggestList.length - 1].classList.remove(
              'active'
            )
            suggestListHtml[0].classList.add('active')
            self.suggestPosotion++
          } else {
            if (suggestListHtml[self.suggestPosotion - 1]) {
              suggestListHtml[self.suggestPosotion - 1].classList.remove(
                'active'
              )
            }
            suggestListHtml[self.suggestPosotion].classList.add('active')
            self.suggestPosotion === maximunSuggest
              ? (self.suggestPosotion = 0)
              : self.suggestPosotion++
            self.keyboardArrow = key
          }
          self.keywordInput = document.querySelector(
            '.suggest-desktop.active'
          )?.innerText
        } else if (key === 'ArrowUp') {
          if (self.keyboardArrow === 'ArrowDown') {
            --self.suggestPosotion
          }
          if (self.suggestPosotion === maximunSuggest) {
            suggestListHtml[maximunSuggest].classList.remove('active')
            suggestListHtml[self.suggestPosotion - 1].classList.add('active')
            self.suggestPosotion--
          } else {
            if (suggestListHtml[self.suggestPosotion]) {
              suggestListHtml[self.suggestPosotion].classList.remove('active')
            }
            self.suggestPosotion === 0
              ? (self.suggestPosotion = maximunSuggest)
              : --self.suggestPosotion
            suggestListHtml[self.suggestPosotion].classList.add('active')
            self.keyboardArrow = key
          }
          self.keywordInput = document.querySelector(
            '.suggest-desktop.active'
          )?.innerText
        } else {
          // self.suggestPosotion = 0
        }
      } catch (error) {
        console.log(error)
        console.warn('Search panel plugin can find element')
      }
    },
    closeSuggest () {
      const self = this
      setTimeout(() => {
        self.suggestList = []
      }, 300)
    },
    toggleDisplaySearchPanel (isShowingMapOnly) {
      const self = this
      self.isHidingSearchPanel = isShowingMapOnly
    },
    clickSearchIcon () {
      const self = this
      self.$router.push({
        path: self.$route.path,
        query: self.$route.query,
        hash: '#search'
      })
      self.$refs['search-input'].focus()
    },
    clickBack () {
      const self = this
      self.$router.replace({
        path: self.$route.path,
        query: self.$route.query,
        hash: ''
      })
    },
    clickClear () {
      const self = this
      self.suggestPosotion = 0
      // Clear road line
      if (self.isLongdoMapV3) {
        try {
          self.map.Overlays.clear()
        } catch (error) {
          console.warn("Can't clear overlay")
        }
      }
      if (
        self.$route.hash === '#explore' ||
        self.$route.hash === '#my-places'
      ) {
        self.$router.push({
          path: self.$route.path,
          hash: ''
        })
      } else if (self.$route.hash !== '#search') {
        if (
          'search' in self.$route.query ||
          'searchEvent' in self.$route.query
        ) {
          self.$router.push({
            path: self.$route.path,
            hash: ''
          })
        }
        if (self.$route.name === 'Place') {
          self.$router.push({
            name: 'Main'
          })
        }
        // https://mmtech.slack.com/archives/G9X7WTW2Y/p1622262193009400
        // 16 May 66
        // https://mmtech.slack.com/archives/C02FDEMLP/p1684139238879079?thread_ts=1682483057.970859&cid=C02FDEMLP
        self.keywordInput = ''
        self.$refs['search-input'].focus()
      } else {
        if (
          'search' in self.$route.query ||
          'searchEvent' in self.$route.query
        ) {
          self.$router.push({
            path: self.$route.path,
            hash: '#search'
          })
        }
        self.keywordInput = ''
        self.$refs['search-input'].focus()
      }
      self.suggestList = []
      self.selectedTag = null
      self.$emit('clearSearch')
    },
    changeCrosshairLocation (newCrosshairLocation) {
      const self = this
      self.crosshairLocation = newCrosshairLocation
    },
    searchFromTagScrollHandler () {
      const self = this
      const suggestTags = self.$refs.suggestTags
      self.suggestTagScrollPosition = suggestTags.scrollLeft
    },
    scroll (direction) {
      const self = this
      const suggestTags = self.$refs.suggestTags
      switch (direction) {
        case 'left':
          suggestTags.scrollLeft = suggestTags.scrollLeft - 100
          self.suggestTagScrollPosition = suggestTags.scrollLeft
          break
        case 'right':
          suggestTags.scrollLeft = suggestTags.scrollLeft + 100
          self.suggestTagScrollPosition = suggestTags.scrollLeft
          break
      }
    },
    searchExample (exampleKeyword) {
      const self = this
      self.keywordInput = exampleKeyword
      if (!self.isSearchTrafficEventMode) {
        self.search(true, false)
      } else {
        self.searchTrafficEvent(true, false)
      }
    },
    clickHistory (history) {
      const self = this
      if (typeof history === 'object') {
        switch (history.type) {
          case 'poi':
            self.$router.push({
              name: 'Place',
              params: {
                poiId: history.id
              },
              query: {}
            })
            break
          case 'keyword':
            this.keywordInput = history.name
            this.search(true, true)
            break
        }
      } else {
        return null
      }
    },
    async removeHistory (history) {
      const self = this
      await self.removeSearchHistory(history.id)
      await self.getSearchHistory()
    },
    searchFromTag (tag) {
      const self = this
      self.keywordInput = 'tag: ' + tag.tag
      self.search(true, false)
    },
    // Search Place
    async search (isUpdateUrl, isSmart = false, fromBack = false) {
      const self = this
      self.suggestPosotion = 0
      self.keyword = self.utility.allTrim(self.keywordInput)
      await self.saveSearchHistory({
        id: Date.now(),
        type: 'keyword',
        name: self.keyword,
        name_en: self.keyword
      })
      try {
        if (
          self.keywordInput.match('layersLayer') !== 0 ||
          self.keywordInput.match('layersชั้นข้อมูล') !== 0
        ) {
          const layerNameString = self.keywordInput.split(': ')[1].trim()
          const { name } =
            self.$i18n.locale === 'th'
              ? self.layerList.filter((l) => {
                return l.displayName.th === layerNameString
              })[0]
              : self.layerList.filter((l) => {
                return l.displayName.en === layerNameString
              })[0]
          if (name) {
            self.$root.$emit('openLayer', name)
            self.keywordInput = ''
            self.suggestList = []
            self.clickBack()
            return
          }
        }
      } catch (error) {
        console.warn("Can't add layer by search")
      }
      if (self.$i18n.locale === 'th') {
        self.keyword = self.keyword.replace('หมวดสถานที่:', 'Tag:')
      } else {
        self.keyword = self.keyword.replace('Category:', 'Tag:')
      }
      self.isSmart = isSmart
      if (isUpdateUrl) {
        self.searchLocation = self.crosshairLocation
      }
      if (self.keyword.length < 2) {
        return false
      }
      self.$emit('beforeSearch', self.keyword)

      const latLon = self.utility.validateLatLonFromString(self.keyword)
      const utm = self.utility.validateUtmFromString(self.keyword)
      if (latLon || utm) {
        if (utm) {
          const latLon = self.utility.convertUtmToLatLon(utm)
          self.searchLocation = latLon
        }
        if (isUpdateUrl) {
          self.searchLocation = latLon
          self.$router.replace({
            // path: self.$route.path,
            name: 'Main',
            query: {
              lat: self.searchLocation.lat,
              lon: self.searchLocation.lon,
              search: latLon.text
            },
            hash: ''
          })
        }
        self.$emit('searchLocation', latLon)
        const data = {
          id: 'RGCP0001',
          lat: self.searchLocation.lat,
          lon: self.searchLocation.lon,
          icon: 'reddot.png',
          name: `${self.searchLocation.lat}, ${self.searchLocation.lon}`,
          name_en: `${self.searchLocation.lat}, ${self.searchLocation.lon}`,
          type: 'reverseGeocoding',
          shortdesc: '',
          shortdesc_en: '',
          source: 'longdo'
        }
        self.$emit('searchResult', {
          meta: null,
          data: [data],
          hasmore: false,
          searchobject: [{
            id: data.id,
            lat: data.lat,
            long: data.lon,
            markicon: process.env.VUE_APP_LONGDO_MAP_ICON + data.icon,
            markicon2x: process.env.VUE_APP_LONGDO_MAP_HD_ICON + data.icon,
            name: data.name,
            name_en: data.name,
            objecttype: data.type,
            shortdesc: data.address,
            shortdesc_en: data.address,
            source: data.source
          }]
        }, false, true)
      } else if (self.keyword.length >= 2) {
        if (isUpdateUrl) {
          self.$router.replace({
            // path: self.$route.path,
            name: 'Main',
            query: {
              lat: self.searchLocation.lat,
              lon: self.searchLocation.lon,
              search: self.keyword
            },
            hash: ''
          })
        }
        // const params = {
        //   search: self.keyword,
        //   bookmark: self.offset, // offset
        //   center_lat: self.searchLocation.lat,
        //   center_long: self.searchLocation.lon,
        //   showall: self.itemsPerPage
        // }

        // clicking back won't reset offset since we will use cache
        self.offset = fromBack ? self.offset : 0
        let params = {
          keyword: self.keyword,
          lon: self.searchLocation.lon,
          lat: self.searchLocation.lat,
          offset: self.offset,
          locale: self.$i18n.locale,
          isSmart: self.isSmart
        }

        self.isSmart = false

        if (self.keyword.indexOf('tag: ') === 0) {
          params.keyword = ' '
          params = { ...params, tag: self.keyword.slice(5) }
          if (!self.isSmart) {
            delete params.keyword
          }
        }

        const cacheIndexList = []
        self.cacheData.forEach((cache, index) => {
          if (
            cache.key.keyword === params.keyword &&
            cache.key.lon === params.lon &&
            cache.key.lat === params.lat &&
            cache.key.locale === params.locale &&
            cache.key.isSmart === params.isSmart &&
            cache.key.offset <= params.offset
          ) {
            cacheIndexList.push(index)
          }
        })
        let result
        if (cacheIndexList.length > 0) {
          cacheIndexList.forEach((cacheIndex, iter) => {
            if (iter === 0) {
              // for copying object
              result = JSON.parse(
                JSON.stringify(self.cacheData[cacheIndex].value)
              )
            } else {
              result.data.data = result.data.data.concat(
                self.cacheData[cacheIndex].value.data.data
              )
            }
          })
        } else {
          params.timestamp = new Date().getTime()
          // result = await self.api.searchPlace(params)
          result = await self.api.searchPlaceRestApi(
            params,
            self.isUseMwaAndNso
          )
          if (result === false && params.isSmart) {
            params.isSmart = false
            result = await self.api.searchPlaceRestApi(
              params,
              self.isUseMwaAndNso
            )
          }
          self.cacheData.push({
            key: params,
            value: result
          })
        }

        // Send a tag if no rusults are found
        if (result.data.data.length === 0) {
          window.gtag(
            'event',
            isSmart ? 'smart_search_empty' : 'search_empty',
            {
              event_category: 'search',
              event_label: isSmart ? 'smart_search_empty' : 'search_empty',
              keyword: params.keyword,
              timestamp: new Date().toISOString()
            }
          )
        } else {
          window.gtag('event', isSmart ? 'smart_search' : 'search', {
            event_category: 'search',
            event_label: isSmart ? 'smart_search' : 'search',
            keyword: params.keyword,
            timestamp: new Date().toISOString()
          })
        }
        // self.$emit('searchResult', result.data)
        self.$emit('searchResult', {
          meta: result.data.meta,
          data: result.data.data,
          hasmore: result.data.meta.hasmore,
          searchobject: result.data.data.map((i) => {
            i.icon = i.icon || 'reddot.png'
            const data = {
              id: i.id,
              lat: i.lat,
              long: i.lon,
              markicon: process.env.VUE_APP_LONGDO_MAP_ICON + i.icon,
              markicon2x: process.env.VUE_APP_LONGDO_MAP_HD_ICON + i.icon,
              name: i.name,
              name_en: i.name,
              objecttype: i.type,
              shortdesc: i.address,
              shortdesc_en: i.address,
              source: i.source
            }
            if (
              data.objecttype === 'tag' ||
              (data.objecttype === 'other' && data.name.indexOf('tag: ') === 0)
            ) {
              data.objecttype = 'tag'
              data.markicon =
                'https://map.longdo.com/mmmap/images/icon-search-type-tag.png'
              data.markicon2x =
                'https://map.longdo.com/mmmap/images/icon-search-type-tag.png'
            }
            if (data.objecttype === 'khet') {
              data.geocode = Number(data.id.replaceAll('K', ''))
              data.markicon =
                'https://map.longdo.com/mmmap/images/icon-search-type-area.png'
              data.markicon2x =
                'https://map.longdo.com/mmmap/images/icon-search-type-area.png'
            }
            if (data.objecttype === 'road') {
              data.markicon =
                'https://map.longdo.com/mmmap/images/icon-search-type-road.png'
              data.markicon2x =
                'https://map.longdo.com/mmmap/images/icon-search-type-road.png'
            }
            if (data.objecttype === 'geom') {
              data.markicon =
                'https://map.longdo.com/mmmap/images/icon-geotype-polygon.png'
              data.markicon2x =
                'https://map.longdo.com/mmmap/images/icon-geotype-polygon.png'
              // https://map.longdo.com/mmmap/images/icon-geotype-line.png
            }
            if (data.objecttype === 'layer') {
              data.markicon =
                'https://map.longdo.com/mmmap/images/icon-geotype-layer.png'
              data.markicon2x =
                'https://map.longdo.com/mmmap/images/icon-geotype-layer.png'
            }
            if (data.objecttype === 'water') {
              data.objecttype =
                data.id[0] === 'X' ? 'water-line' : 'water-area'
              data.markicon =
                'https://map.longdo.com/mmmap/images/icon-search-type-water.png'
              data.markicon2x =
                'https://map.longdo.com/mmmap/images/icon-search-type-water.png'
            }
            if (data.source === 'google') {
              data.id = `G${new Date().getTime()}_${Math.random()}`
              data.markicon = i.icon
              data.markicon2x = i.icon
            }
            return data
          })
        })

        if (self.keyword.indexOf('tag:') !== -1) {
          const splitedKeyword = self.keyword.split(':')
          const tagName = splitedKeyword[1].trim()
          self.$emit('showTag', tagName)
        }

        let index = self.layerList
          .map((l) => l.displayName.th)
          .indexOf(self.keyword)
        if (index === -1) {
          index = self.layerList
            .map((l) => l.displayName.en.toLowerCase())
            .indexOf(self.keyword.toLowerCase())
        }
        if (index !== -1) {
          self.$root.$emit('openLayer', self.layerList[index].name)
        }
      }
    },
    async moreTrafficEvent () {
      const self = this
      self.$emit('beforeMoreTrafficEvent')
      self.offsetTrafficEvent += self.itemsPerPage
      self.searchTrafficEvent(true, true)
    },
    async more () {
      const self = this
      self.$emit('beforeMore')
      self.offset += self.itemsPerPage
      // const params = {
      //   search: self.keyword,
      //   bookmark: self.offset, // offset
      //   center_lat: self.searchLocation.lat,
      //   center_long: self.searchLocation.lon,
      //   showall: self.itemsPerPage
      // }
      let params = {
        keyword: self.keyword,
        lon: self.searchLocation.lon,
        lat: self.searchLocation.lat,
        offset: self.offset,
        locale: self.$i18n.locale,
        isSmart: self.isSmart || false
      }

      if (self.keyword.indexOf('tag: ') === 0) {
        params.keyword = ' '
        params = { ...params, tag: self.keyword.slice(5) }
      }

      let cacheIndex = -1
      self.cacheData.forEach((cache, index) => {
        if (
          cache.key.keyword === params.keyword &&
          cache.key.lon === params.lon &&
          cache.key.lat === params.lat &&
          cache.key.locale === params.locale &&
          cache.key.isSmart === params.isSmart &&
          cache.key.offset === params.offset
        ) {
          cacheIndex = index
        }
      })

      let result
      if (cacheIndex !== -1) {
        result = self.cacheData[cacheIndex].value
      } else {
        params.timestamp = new Date().getTime()
        // result = await self.api.searchPlace(params)
        result = await self.api.searchPlaceRestApi(params, self.isUseMwaAndNso)
        if (result === false && params.isSmart) {
          params.isSmart = false
          result = await self.api.searchPlaceRestApi(
            params,
            self.isUseMwaAndNso
          )
        }
        self.cacheData.push({
          key: params,
          value: result
        })
      }
      // self.$emit('moreResult', result.data)
      self.$emit('moreResult', {
        meta: result.data.meta,
        data: result.data.data,
        hasmore: result.data.meta.hasmore,
        searchobject: result.data.data.map((i) => {
          const data = {
            id: i.id,
            lat: i.lat,
            long: i.lon,
            markicon: process.env.VUE_APP_LONGDO_MAP_ICON + i.icon,
            markicon2x: process.env.VUE_APP_LONGDO_MAP_HD_ICON + i.icon,
            name: i.name,
            name_en: i.name,
            objecttype: i.type,
            shortdesc: i.address,
            shortdesc_en: i.address,
            source: i.source
          }
          if (
            data.objecttype === 'tag' ||
            (data.objecttype === 'other' && data.name.indexOf('tag: ') === 0)
          ) {
            data.objecttype = 'tag'
            data.markicon =
              'https://map.longdo.com/mmmap/images/icon-search-type-tag.png'
            data.markicon2x =
              'https://map.longdo.com/mmmap/images/icon-search-type-tag.png'
          }
          if (data.objecttype === 'khet') {
            data.geocode = Number(data.id.replaceAll('K', ''))
            data.markicon =
              'https://map.longdo.com/mmmap/images/icon-search-type-area.png'
            data.markicon2x =
              'https://map.longdo.com/mmmap/images/icon-search-type-area.png'
          }
          if (data.objecttype === 'road') {
            data.markicon =
              'https://map.longdo.com/mmmap/images/icon-search-type-road.png'
            data.markicon2x =
              'https://map.longdo.com/mmmap/images/icon-search-type-road.png'
          }
          if (data.objecttype === 'geom') {
            data.markicon =
              'https://map.longdo.com/mmmap/images/icon-geotype-polygon.png'
            data.markicon2x =
              'https://map.longdo.com/mmmap/images/icon-geotype-polygon.png'
            // https://map.longdo.com/mmmap/images/icon-geotype-line.png
          }
          if (data.objecttype === 'layer') {
            data.markicon =
              'https://map.longdo.com/mmmap/images/icon-geotype-layer.png'
            data.markicon2x =
              'https://map.longdo.com/mmmap/images/icon-geotype-layer.png'
          }
          if (data.objecttype === 'water') {
            data.objecttype = data.id[0] === 'X' ? 'water-line' : 'water-area'
            data.markicon =
              'https://map.longdo.com/mmmap/images/icon-search-type-water.png'
            data.markicon2x =
              'https://map.longdo.com/mmmap/images/icon-search-type-water.png'
          }
          if (data.source === 'google') {
            data.id = `G${new Date().getTime()}_${Math.random()}`
            data.markicon = i.icon
            data.markicon2x = i.icon
          }
          return data
        })
      })
    },
    async suggest () {
      const self = this
      if (self.suggestTask) {
        clearTimeout(self.suggestTask)
      }
      const keyword = self.utility.allTrim(self.keywordInput)
      self.suggestTask = setTimeout(async () => {
        if (keyword.length < 2) {
          return false
        }
        const params = {
          keyword: keyword,
          limit: 10,
          isUseMwaAndNso: self.isUseMwaAndNso
        }
        const result = await self.api.suggestPlace(params)
        if (result.status === 200) {
          const filterTagList = self.tagList.filter((t) => {
            return (
              t.name.indexOf(keyword) !== -1 ||
              t.name_en.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
              t.tag.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
            )
          })
          var suggestTagList = []
          filterTagList.forEach((t) => {
            if (t.name) {
              suggestTagList.push({
                d:
                  '<img loading="lazy" src="' +
                  t.imagefile +
                  '" style="vertical-align: middle; width: 24px; margin-right: 6px;"><span style="vertical-align: middle; font-weight: bold;">' +
                  self.$t('searchPanel.tag') +
                  '</span><span style="vertical-align: middle;">: ' +
                  (self.$i18n.locale === 'th' ? t.name : t.name_en) +
                  '</span>',
                id: '',
                s: 'poi3',
                w: 'tag: ' + t.tag
              })
            }
          })

          const filterLayerList = self.layerList.filter((l) => {
            if (self.isLongdoMapV3) {
              return (
                (l.displayName.th.indexOf(keyword) !== -1) & (l.v3 === true) ||
                (l.displayName.en
                  .toLowerCase()
                  .indexOf(keyword.toLowerCase()) !==
                  -1) &
                  (l.v3 === true)
              )
            } else {
              return (
                (l.displayName.th.indexOf(keyword) !== -1) & (l.v2 === true) ||
                (l.displayName.en
                  .toLowerCase()
                  .indexOf(keyword.toLowerCase()) !==
                  -1) &
                  (l.v2 === true)
              )
            }
          })
          var suggestLayerList = []
          filterLayerList.forEach((l) => {
            suggestLayerList.push({
              d:
                '<i class="material-icons-round" style="vertical-align: middle; font-size: 24px; color: rgb(56, 148, 238); margin-right: 6px;">layers</i><span style="vertical-align: middle; font-weight: bold;">' +
                self.$t('searchPanel.layer') +
                '</span><span style="vertical-align: middle;">: ' +
                l.displayName[self.$i18n.locale] +
                '</span>' +
                (l.type === 'link'
                  ? '<i class="material-icons-round" style="vertical-align: middle; font-size: 14px; color: rgb(175, 175, 175); margin-left: 6px;">open_in_new</i>'
                  : ''),
              id: '',
              s: 'layer',
              w: 'layer: ' + l.name
            })
          })

          self.suggestList = suggestLayerList
            .concat(suggestTagList)
            .concat(result.data.data)

          // Send data to Google Analytics
          if (self.suggestList.length > 0) {
            window.gtag('event', 'suggest', {
              event_category: 'suggest',
              event_label: 'suggest',
              keyword: keyword,
              timestamp: new Date().toISOString()
            })
          } else {
            window.gtag('event', 'suggest_empty', {
              event_category: 'suggest',
              event_label: 'suggest_empty',
              keyword: keyword,
              timestamp: new Date().toISOString()
            })
          }
        }
      }, 500)
    },
    clickSuggest (suggest, index) {
      const self = this
      window.gtag('event', 'click_suggest', {
        event_category: 'suggest',
        event_label: 'click_suggest',
        keyword: suggest.w,
        index: index,
        timestamp: new Date().toISOString()
      })
      if (suggest.w.indexOf('layer:') !== -1) {
        const layerName = suggest.w.split(': ')[1]
        self.$root.$emit('openLayer', layerName)
        self.keywordInput = ''
        self.suggestList = []
        self.clickBack()
      } else {
        self.keywordInput = suggest.w
        self.suggestList = []
        self.search(true, false)
      }
    },
    clickTag (tag) {
      const self = this
      self.$emit('showTag', tag.tag)
      self.selectedTag = tag
      self.suggestList = []
      self.clickBack()
    },
    async getSearchHistory () {
      const self = this
      const searchHistory = await self.getAllSearchHistory()
      if (searchHistory.length !== 0) {
        self.showSearchHistory = true
        self.searchHistory = searchHistory.reverse()
      } else {
        self.searchHistory = []
        self.showSearchHistory = false
      }
    }
  },
  watch: {
    isSearchTrafficEventMode () {
      const self = this
      self.keywordInput = ''
      self.keyword = ''
    },
    async '$i18n.locale' () {
      const self = this
      await self.calculateButtonListWidth()
      if (self.isLongdoMapV3 && self.map && self.isTrafficProduct) {
        self.$emit('searchTrafficResult', self.trafficEventResult)
      }
    },
    $route (to, from) {
      const self = this
      self.$nextTick(async () => {
        if (self.$route.hash === '#search') {
          self.getSearchHistory()
        }

        if (
          'search' in to.query &&
          to.hash === '' &&
          from.hash !== '#search' &&
          from.hash !== '#layers' &&
          from.hash !== '#maps'
        ) {
          // for clicking back
          self.search(false, true, true)
        }
        if ('searchEvent' in from.query && !('searchEvent' in to.query)) {
          // change back from search result to all event
          await self.searchAllActiveTrafficEvent()
        } else if (
          'searchEvent' in to.query &&
          'searchEvent' in from.query &&
          to.query.searchEvent !== from.query.searchEvent
        ) {
          await self.searchTrafficEvent()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media only screen and (min-width: $mobileMaxSize) {
  .routing-mode {
    display: none;
  }

  .list-suggest {
    position: absolute;
    top: 12px;
    left: 500px;
    z-index: 90;

    &.traffic-product {
      > select {
        height: 42px;
        padding: 0px 10px 0px 10px;
        border-radius: 6px;
        border: 0.5px solid #bcbcbc;
      }
    }
  }

  .list-suggest-map3 {
    position: absolute;
    top: 50px;
    left: 525px;
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 400px - 215px);
    overflow: hidden;
    gap: 30px;

    &.traffic-product {
      > select {
        height: 32px;
        padding: 0px 10px 0px 10px;
        border-radius: 6px;
        border: 0.5px solid #bcbcbc;
      }
      flex-direction: row-reverse;
      > .tag-list-suggest-bar {
        width: calc(100% - 200px);
        > .btn-scroll-right {
          right: -24px;
        }
      }
    }
    .tag-list-suggest-bar {
      position: relative;
      width: calc(100% - 24px);
      .tag-list-suggest {
        transition: opacity 0.3s ease 0s;
        padding-top: 7px;
        height: 35px;
        overflow: scroll;
        display: flex;
        z-index: 85;
        scrollbar-width: none;

        button {
          height: 30px;
          padding: 0px 12px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 2px solid white;
          border-radius: 15px;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 13px;
          margin: 0px 3px;
          -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
          background-color: white;
          cursor: pointer;
          outline: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: rgba(40, 40, 40, 0.7);
          -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s,
            -webkit-box-shadow 0.3s ease 0s;
          transition: background-color 0.3s ease 0s, border 0.3s ease 0s,
            -webkit-box-shadow 0.3s ease 0s;
          transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s,
            border 0.3s ease 0s;
          transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s,
            border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
          font-family: Prompt;

          img {
            padding-right: 5px;
          }
        }
      }
      .btn-scroll-left {
        position: absolute;
        z-index: 100;
        height: 30px;
        padding: 0px 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 2px solid white;
        border-radius: 15px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 1.5em;
        margin: 7px 3px 0px;
        -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
        cursor: pointer;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: rgba(40, 40, 40, 0.7);
        -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s,
          -webkit-box-shadow 0.3s ease 0s;
        transition: background-color 0.3s ease 0s, border 0.3s ease 0s,
          -webkit-box-shadow 0.3s ease 0s;
        transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s,
          border 0.3s ease 0s;
        transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s,
          border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        font-family: Prompt;
        &.hide {
          pointer-events: none;
          transition: opacity 0.3s ease 0s;
          opacity: 0;
        }
      }

      .btn-scroll-right {
        position: absolute;
        top: 0px;
        right: -24px;
        z-index: 100;
        height: 30px;
        padding: 0px 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 2px solid white;
        border-radius: 15px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 1.5em;
        margin: 7px 3px 0px;
        -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
        cursor: pointer;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: rgba(40, 40, 40, 0.7);
        -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s,
          -webkit-box-shadow 0.3s ease 0s;
        transition: background-color 0.3s ease 0s, border 0.3s ease 0s,
          -webkit-box-shadow 0.3s ease 0s;
        transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s,
          border 0.3s ease 0s;
        transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s,
          border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        font-family: Prompt;
        &.hide {
          pointer-events: none;
          transition: opacity 0.3s ease 0s;
          opacity: 0;
        }
      }

      .tag-list-suggest.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }

      .tag-list-suggest::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

  .search-panel.hide {
    > .search-on-map {
      top: 0;
    }

    .ldmap-toggle {
      top: 0;
    }

    > button.selected-tag {
      top: calc(0px - #{$searchInput} - 12px) !important;
    }
  }
}

@media only screen and (max-width: $mobileMaxSize) {
  .search-panel.hide {
    > .search-on-map {
      top: calc(0px - #{$searchInput} - 12px) !important;
    }

    .ldmap-toggle {
      top: calc(0px - #{$searchInput} - 12px) !important;
    }

    > button.selected-tag {
      top: 12px;
    }
  }
}

.search-panel {
  @media only screen and (max-width: $mobileMaxSize) {
    .search-map3 {
      top: 10px !important;
    }

    .search-on-map {
      &.active {
        max-width: calc(100% - 100px);
      }

      i {
        line-height: 1.5;
      }
    }
  }

  @media only screen and (min-width: $mobileMaxSize) {
    .search-map3 {
      top: 50px !important;
    }

    .search-on-map {
      &.active {
        max-width: calc(100% - 100px);
      }
    }

    .search-on-map.search-map3 {
      &.active {
        max-width: 460px !important;
      }
    }
  }

  > .search-on-map {
    @media only screen and (max-width: $mobileMaxSize) {
      width: calc(100% - 26px);
      max-width: 100%;
      height: $searchInput;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 120;
      position: fixed;
      top: 12px;
      left: 12px;
      transition: top 0.3s ease 0s, max-width 0.3s ease 0s;
    }

    @media only screen and (min-width: $mobileMaxSize) {
      width: calc(100% - 24px);
      max-width: 384px;
      height: $searchInput;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 120;
      position: fixed;
      top: 12px;
      left: 12px;
      transition: top 0.3s ease 0s, max-width 0.3s ease 0s;
    }

    > input {
      background-color: rgba(255, 255, 255, 0.95);
      width: 100%;
      height: $searchInput;
      padding: 0px calc(6px + 36px + 6px);
      box-sizing: border-box;
      border-radius: 6px;
      border: none;
      font-size: 18px;
      outline: none;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
      line-height: $searchInput;
      appearance: none;
    }

    > input:focus {
      background-color: rgba(255, 255, 255, 1);
    }

    > button.search-icon {
      position: absolute;
      top: calc((#{$searchInput} - 36px) / 2);
      left: 6px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;

      > i {
        color: $grey;
      }
    }

    > button.back {
      position: absolute;
      top: calc((#{$searchInput} - 36px) / 2);
      left: 6px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;

      > i {
        color: $grey;
      }
    }

    > button.clear {
      position: absolute;
      top: calc((#{$searchInput} - 36px) / 2);
      right: 6px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;

      > i {
        color: $grey;
      }
    }

    > button.ldmap-search {
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
      background-color: $darkPrimary;
      border-radius: 6px;
      box-sizing: border-box;
      color: #ffffff;
      right: calc(384px - 460px);
      width: 70px;
      height: $searchInput;
      position: absolute;
      top: calc((#{$searchInput} - 42px) / 2);
      padding: 12px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      padding: 0px;
      cursor: pointer;

      > i {
        color: white;
      }
    }

    > button.ldmap-direction {
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 6px;
      box-sizing: border-box;
      color: #ffffff;
      right: calc(384px - 505px);
      width: 40px;
      height: calc(#{$searchInput} - 1px);
      position: absolute;
      top: calc((#{$searchInput} - 42px) / 2);
      padding: 12px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      padding: 0px;
      cursor: pointer;

      > i {
        color: rgba(40, 40, 40, 0.7);
      }
    }

    @media (max-width: $mobileMaxSize) {
      .ldmap-search {
        display: none !important;
      }

      .ldmap-direction {
        display: none !important;
      }

      .ldmap-search.active {
        display: block !important;
      }
    }
  }

  > button.selected-tag-map3 {
    max-width: 90vw;
    width: max-content;

    @media screen and (min-width: $mobileMaxSize) {
      top: calc(#{$searchInput} + 12px + 50px) !important;
    }

    span {
      margin-left: 5px;
      flex-shrink: 0;
      > img {
        margin-top: 4px;
        max-height: 18px;
        max-width: 18px;
        padding: 0px;
        box-sizing: border-box;
        margin-right: 6px;
      }
    }

    b {
      color: $primary;
      margin-right: 5px;
    }

    position: absolute;
    top: calc(#{$searchInput} + 12px + 12px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 110;
    height: auto;
    padding: 0px 12px;
    box-sizing: border-box;
    border-radius: 15px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    outline: none;
    user-select: none;
    border: 1px solid $extraLightPrimary;
    transition: top 0.3s ease 0s;
  }

  > button.selected-tag {
    @media screen and (min-width: $mobileMaxSize) {
      top: calc(#{$searchInput} + 12px + 50px) !important;
    }

    position: absolute;
    top: calc(#{$searchInput} + 12px + 12px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 110;
    height: auto;
    padding: 6px 12px;
    box-sizing: border-box;
    border-radius: 15px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: $primary;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    outline: none;
    user-select: none;
    border: 1px solid $extraLightPrimary;
    transition: top 0.3s ease 0s;

    > img {
      max-height: 16px;
      max-width: 16px;
      padding: 0px;
      box-sizing: border-box;
      margin-right: 6px;
    }
  }

  > .recommend-container-desktop-map3 {
    flex-direction: column;
    display: flex;
    position: fixed;
    left: 10px;
    width: 462px;
    top: 100px;
    z-index: 110;

    &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }
    &.show-suggest {
        height: 0 !important;
    }

    > .search-history-container {
      padding: 12px 12px 12px 12px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 100%;
      background-color: white;
      transition: opacity 0.3s ease 0s;
      -webkit-transition: opacity 0.3s ease 0s;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      opacity: 1;

      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }

      > .topic {
        font-family: Prompt;
        font-weight: bold;
        padding-bottom: 12px;
        font-size: 16px;
      }
      > .search-panel-history{
        overflow: scroll;
        max-height: 128px;
        margin-top: -10px;
        > .history-list {
          cursor: pointer;
          border-radius: 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 6px;
          span {
            max-width: calc(100% - 100px);
            color: #7d7d7d;
          }
          i {
            color: #7d7d7d;
            margin-right: 12px;
          }
          .remove {
              color: #afafaf;
              margin-left: auto;
              margin-right: 0px;
            }
        }
      }
    }

    > .example-container {
      padding: 12px 12px 12px 12px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 100%;
      background-color: white;
      transition: opacity 0.3s ease 0s;
      -webkit-transition: opacity 0.3s ease 0s;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      opacity: 1;

      > .topic {
        font-family: Prompt;
        font-weight: bold;
        padding-bottom: 12px;
        font-size: 16px;
      }

      > .toggle-mode-container {
        display: flex;
        gap: 10px;
        margin-bottom: 14px;
        > button {
          font-family: Prompt;
          background-color: #f4fcf9;
          color: $darkPrimary;
          border-radius: 999px;
          padding: 7px 22px;
          border-color: transparent;

          &.active {
            background-color: $darkPrimary;
            color: #f4fcf9;
          }
        }
      }

      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }

      > .example-panel-normal {
        > .example {
          display: flex;
          flex-direction: row;

          @media (min-width: $mobileMaxSize) {
            > .topic {
              font-family: Prompt;
              width: 120px;
              font-size: 14px;
              line-height: 1.8;
            }

            > .value {
              font-family: Prompt;
              width: calc(100% - 120px);
              font-size: 14px;
              line-height: 1.8;

              > span {
                font-family: Prompt;
                color: $primary;
                cursor: pointer;
                font-size: 14px;
              }

              > span:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-height: 800px) {
      > .example-container {
        display: none;
      }
    }

    > .tag-category-container {
      overflow: hidden;
      overflow-y: auto;
      padding: 12px 12px 12px 12px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 100%;
      background-color: white;
      transition: opacity 0.3s ease 0s;
      -webkit-transition: opacity 0.3s ease 0s;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      opacity: 1;
      // top: 300px;

      &.traffic-product {
        top: 350px;

        &.traffic-mode {
          top: 275px;
        }
      }

      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }

      // Case height as less 520
      @media only screen and (max-height: 520px) {
        display: none;
      }

      > .topic {
        font-family: Prompt;
        font-weight: bold;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      > .tag-category-container-list {
        overflow: hidden;
        overflow-y: auto;
        height: calc(45vh - 100px);

        > .tag-category {
          margin: 12px 0px 32px 0px;

          > label {
            font-family: Prompt;
            font-size: 14px;
            font-weight: bold;
          }

          > .tag-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: 6px;

            > .tag {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: calc(100% / 2);
              padding: 9px 0px 9px 12px;
              box-sizing: border-box;

              > .icon {
                width: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                > img {
                  max-width: 24px;
                  max-height: 24px;
                }
              }

              @media (max-width: $mobileMaxSize) {
                > label {
                  // width: calc(100% - 16px);
                  font-family: Prompt;
                  padding-left: 12px;
                  font-size: 13px;
                  margin-right: auto;
                  cursor: pointer;
                }
              }

              @media (min-width: $mobileMaxSize) {
                > label {
                  font-family: Prompt;
                  font-size: 14px !important;
                  padding-left: 12px;
                  margin-right: auto;
                  cursor: pointer;
                }
              }
            }

            @media (max-width: $mobileMaxSize) {
              > .tag {
                width: calc(100% / 2);
              }
            }

            @media (min-width: $mobileMaxSize) {
              > .tag {
                width: calc(100% / 2);
              }
            }
          }
        }
      }
    }

    > .suggest-list-desktop {
      position: fixed;
      z-index: 120;
      padding: 12px;
      top: calc(12px + #{$searchInput} + 45px);
      left: 10px;
      background-color: rgba(255, 255, 255, 1);
      width: 460px;
      border-radius: 12px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      max-height: calc(100vh - 200px);
      overflow: hidden;
      overflow-y: auto;

      > .suggest-desktop {
        font-family: Prompt;

        cursor: pointer !important;
        width: 100%;
        padding: 9px 1px;

        &.active {
          border-radius: 10px;
          background-color: $extraLightPrimary;
        }
      }
    }

    @media only screen and (max-width: $mobileMaxSize) {
      display: none;
    }
  }

  // Map3 Mobile
  > .recommend-container-map3 {

    > .search-history-container {
      padding: 12px 12px 12px 12px;
      margin-bottom: 10px;
      width: 100%;
      background-color: white;
      transition: opacity 0.3s ease 0s;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      opacity: 1;

      > .topic {
        font-family: Prompt;
        font-weight: bold;
        padding-bottom: 12px;
        font-size: 16px;
      }
      > .search-panel-history{
        overflow: scroll;
        margin-top: -10px;
        > .history-list {
          border-radius: 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 6px;
          span {
            max-width: calc(100% - 100px);
            color: #7d7d7d;
          }
          i {
            color: #7d7d7d;
            margin-right: 12px;
          }
          .remove {
              color: #afafaf;
              margin-left: auto;
              margin-right: 0px;
            }
        }
      }
    }

    @media only screen and (min-width: $mobileMaxSize) {
      display: none;
    }

    @media only screen and (max-width: $mobileMaxSize) {
      width: 100%;
      height: 100%;
      position: fixed;
      bottom: 0px;
      left: 0px;
      z-index: 110;
      background-color: rgb(255, 255, 255);
      transition: opacity 0.3s ease 0s;
      padding: calc(12px + #{$searchInput}) 0px 0px 0px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      opacity: 1;
    }

    > .example-container {
      margin: 20px 12px 12px 12px;

      > .toggle-mode-container {
        display: flex;
        gap: 10px;
        margin-bottom: 14px;

        > button {
          font-family: Prompt;
          background-color: #f4fcf9;
          color: $darkPrimary;
          border-radius: 999px;
          padding: 7px 22px;
          border-color: transparent;

          &.active {
            background-color: $darkPrimary;
            color: #f4fcf9;
          }
        }
      }

      &.hide {
        opacity: 0;
      }

      > .topic {
        font-weight: bold;
        padding-bottom: 12px;
        font-size: 16px;
      }

      > .example {
        display: flex;
        flex-direction: row;

        @media (min-width: $mobileMaxSize) {
          > .topic {
            width: 120px;
            font-size: 16px;
            line-height: 1.8;
          }

          > .value {
            width: calc(100% - 120px);
            font-size: 16px;
            line-height: 1.8;

            > span {
              color: $primary;
              cursor: pointer;
              font-size: 16px;
            }

            > span:hover {
              text-decoration: underline;
            }
          }
        }

        @media (max-width: $mobileMaxSize) {
          > .topic {
            width: 120px;
            font-size: 14px;
            line-height: 1.8;
          }

          > .value {
            width: calc(100% - 120px);
            font-size: 14px;
            line-height: 1.8;

            > span {
              color: $primary;
              cursor: pointer;
              font-size: 14px;
            }

            > span:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    > .tag-category-container {
      margin: 12px;

      > .topic {
        font-weight: bold;
        font-size: 16px;
        padding-top: 12px;
      }

      > .tag-category {
        margin: 12px 0px 32px 0px;

        > label {
          font-weight: bold;
        }

        > .tag-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 6px;

          > .tag {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: calc(100% / 2);
            padding: 9px 0px 9px 12px;
            box-sizing: border-box;

            > .icon {
              width: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              > img {
                max-width: 16px;
                max-height: 16px;
              }
            }

            @media (max-width: $mobileMaxSize) {
              > label {
                // width: calc(100% - 16px);
                font-family: Prompt;
                padding-left: 12px;
                font-size: 13px;
                margin-right: auto;
                cursor: pointer;
              }
            }

            @media (min-width: $mobileMaxSize) {
              > label {
                font-size: 16px !important;
                padding-left: 12px;
                margin-right: auto;
                cursor: pointer;
              }
            }
          }

          @media (max-width: $mobileMaxSize) {
            > .tag {
              width: calc(100% / 2);
            }
          }

          @media (min-width: $mobileMaxSize) {
            > .tag {
              width: calc(100% / 2);
            }
          }
        }
      }
    }

    > .suggest-list {
      @media only screen and (max-width: $mobileMaxSize) {
        position: fixed;
        top: calc(12px + #{$searchInput} + 6px);
        left: 12px;
        background-color: rgba(255, 255, 255, 1);
        width: calc(100% - 24px);
        border-radius: 12px;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
        padding: 12px;
        box-sizing: border-box;
        max-height: calc(100% - 12px - 54px - 12px);
        overflow: hidden;
        overflow-y: auto;

        > .suggest {
          width: 100%;
          padding: 9px 5px;
        }
      }

      @media only screen and (min-width: $mobileMaxSize) {
        position: fixed;
        top: calc(12px + #{$searchInput} + 6px);
        left: 10px;
        background-color: rgba(255, 255, 255, 1);
        width: 460px;
        border-radius: 12px;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        max-height: calc(100% - 12px - 54px - 12px);
        overflow: hidden;
        overflow-y: auto;

        > .suggest {
          width: 100%;
          padding: 9px 5px;
        }
      }
    }
  }

  > .recommend-container-map3.hide {
    @media only screen and (min-width: $mobileMaxSize) {
      pointer-events: none;
      transition: opacity 0.3s ease 0s;
      opacity: 0;
    }

    @media only screen and (max-width: $mobileMaxSize) {
      bottom: -100%;
      opacity: 0;
    }
  }

  > .recommend-container {
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 110;
    background-color: rgb(255, 255, 255);
    transition: opacity 0.3s ease 0s;
    padding: calc(12px + #{$searchInput}) 0px 0px 0px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    opacity: 1;

    > .example-container {
      margin: 20px 12px 12px 12px;

      > .toggle-mode-container {
        display: flex;
        gap: 10px;
        margin-bottom: 14px;

        > button {
          font-family: Prompt;
          background-color: #f4fcf9;
          color: $darkPrimary;
          border-radius: 999px;
          padding: 7px 22px;
          border-color: transparent;

          &.active {
            background-color: $darkPrimary;
            color: #f4fcf9;
          }
        }
      }

      > .topic {
        font-weight: bold;
        padding-bottom: 12px;
        font-family: Prompt;
        font-size: 16px;
      }

      > .example {
        display: flex;
        flex-direction: row;

        @media (min-width: $mobileMaxSize) {
          > .topic {
            width: 120px;
            font-size: 16px;
            line-height: 1.8;
          }

          > .value {
            width: calc(100% - 120px);
            font-size: 16px;
            line-height: 1.8;

            > span {
              color: $primary;
              cursor: pointer;
              font-size: 16px;
            }

            > span:hover {
              text-decoration: underline;
            }
          }
        }

        @media (max-width: $mobileMaxSize) {
          > .topic {
            width: 120px;
            font-size: 14px;
            line-height: 1.8;
          }

          > .value {
            width: calc(100% - 120px);
            font-size: 14px;
            line-height: 1.8;

            > span {
              color: $primary;
              cursor: pointer;
              font-size: 14px;
            }

            > span:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    > .tag-category-container {
      margin: 12px;

      > .topic {
        font-weight: bold;
        font-family: Prompt;
        font-size: 16px;
        padding-top: 24px;
      }

      > .tag-category {
        margin: 12px 0px 32px 0px;

        > label {
          font-weight: bold;
        }

        > .tag-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 6px;

          > .tag {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: calc(100% / 2);
            padding: 9px 0px 9px 12px;
            box-sizing: border-box;

            > .icon {
              width: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              > img {
                max-width: 16px;
                max-height: 16px;
              }
            }

            @media (max-width: $mobileMaxSize) {
              > label {
                // width: calc(100% - 16px);
                padding-left: 12px;
                font-size: 13px;
                margin-right: auto;
                cursor: pointer;
              }
            }

            @media (min-width: $mobileMaxSize) {
              > label {
                font-size: 16px !important;
                padding-left: 12px;
                margin-right: auto;
                cursor: pointer;
              }
            }
          }

          @media (min-width: 375px) {
            > .tag {
              width: calc(100% / 2);
            }
          }

          @media (min-width: 425px) {
            > .tag {
              width: calc(100% / 2);
            }
          }

          @media (min-width: $mobileMaxSize) {
            > .tag {
              width: calc(100% / 4);
            }
          }

          @media (min-width: 1024px) {
            > .tag {
              width: calc(100% / 5);
            }
          }

          @media (min-width: 1440px) {
            > .tag {
              width: calc(100% / 6);
            }
          }

          @media (min-width: 2560px) {
            > .tag {
              width: calc(100% / 10);
            }
          }
        }
      }
    }

    > .suggest-list {
      position: fixed;
      top: calc(12px + #{$searchInput} + 6px);
      left: 12px;
      background-color: rgba(255, 255, 255, 1);
      width: calc(100% - 24px);
      border-radius: 12px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      padding: 12px;
      box-sizing: border-box;
      max-height: calc(100% - 12px - 54px - 12px);
      overflow: hidden;
      overflow-y: auto;

      > .suggest {
        width: 100%;
        padding: 9px 5px;
      }
    }
  }

  > .recommend-container.hide {
    bottom: -100%;
    opacity: 0;
  }
}
</style>
