<template>
  <div :key="method.id" class="transit-method-card transit-method ripple" @click="clickTransitMethodCard">
    <div class="how-to">
      <div v-for="(howTo, j) in method.howTo" :key="'how-to-' + j">
        <i class="material-icons-round">{{ transitIconList[howTo.by] }}</i>
        <div v-if="howTo.text" class="line" :class="{ 'has-icon': howTo.by < 6 }"
          :style="howTo.color ? { backgroundColor: howTo.color, color: howTo.textColor } : {}">
          <div v-if="howTo.by < 6" class="icon" :style="howTo.by === 4 ? { backgroundColor: 'rgb(255, 255, 255)' } : {}">
            <img :src="calTransitImgPath(howTo.by, howTo.text, 2)"
              :srcset="calTransitImgPath(howTo.by, howTo.text, 1)+calTransitImgPath(howTo.by, howTo.text, 2)"
              :style="howTo.by === 2 ? { transform: 'translateY(-0.5px)' } : {}">
          </div>
          <template v-if="howTo.by < 3 || howTo.by === 10">
            {{ $t('routingPanel.' + howTo.text) }}
          </template>
          <template v-else>
            {{ howTo.text }}
          </template>
        </div>
        <div v-if="howTo.distance" class="distance">
          {{ howTo.distance }} {{ $t('routingPanel.meter') }}
        </div>
      </div>
    </div>
    <div class="detail">
      <div>
        <div class="fee" v-if="method.fee > 0">
          {{ method.fee + ' ' + $t('routingPanel.baht') }}
        </div>
        <div class="fee" v-if="method.fee > 0 && method.hasTaxiFee"> + </div>
        <div class="fee" v-if="method.hasTaxiFee">{{ $t('routingPanel.taxiFee') }}</div>
      </div>
      <div>
        <div class="distance">{{ method.displayDistance }} {{ 1000 > method.distance ? $t('routingPanel.meter') :
          $t('routingPanel.kilometer') }}</div>
        <div class="time">{{ (method.hr !== 0 ? (method.hr + ' ' + $t('routingPanel.hr') + ' ') : '') + method.min + ' ' +
          $t('routingPanel.min') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransitMethodCard',
  props: {
    method: {
      type: Object
    }
  },
  data () {
    return {
      transitIconList: ['', 'train', 'subway', 'tram', 'train', 'directions_bus', 'directions_bus', 'airport_shuttle', 'directions_boat', 'directions_walk', 'local_taxi']
    }
  },
  methods: {
    clickTransitMethodCard () {
      const self = this
      self.$emit('click')
    },
    calTransitImgPath (by, text, size) {
      const self = this
      return self.baseUrl + 'img/routing/transit_' + by + (by === 2 && text === 'Blue' ? '_blue' : '') + '@' + size + 'x.png?version=' + self.appVersion
    }
  }
}
</script>

<style scoped lang="scss">
.transit-method {
  height: 100px;
  @media screen and (max-width: $mobileMaxSize) {
    width: calc(100% - 12px - 12px);
    margin: 12px;
  }
  flex-shrink: 0;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;

  >.how-to {
    height: calc(100% - 36px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    overflow-x: auto;
    padding: 0px 12px;
    flex: 1 0 auto;

    >div {
      padding-right: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      flex: 0 0 auto;

      >i {
        color: $grey;
      }

      >.line {
        height: 20px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0px 8px;
        flex-wrap: nowrap;
        font-size: 14px;
        margin-left: 3px;

        >.icon {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          overflow: hidden;
          background-color: rgba(255, 255, 255, 0.75);
          margin-right: 6px;

          >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      >.line.has-icon {
        padding-left: 3px;
      }

      >.distance {
        position: sticky;
        font-size: 12px;
        color: $grey;
        transform: translateY(50%);
        margin-left: -3px;
      }
    }
  }

  >.how-to::-webkit-scrollbar {
    display: none;
  }

  >.detail {
    height: 36px;
    background: linear-gradient(90.56deg, $darkPrimary 0%, $primary 100%);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;

    div {
      display: flex;
      align-items: center;

      >* {
        font-family: Prompt;
        color: rgb(255, 255, 255);
      }

      >.fee {
        margin-right: 6px;
      }

      >.distance {
        opacity: 0.6;
        margin-right: 6px;
      }

      >.time {
        font-size: 18px;
        flex: 0 0 auto;
      }
    }
  }
}
</style>
